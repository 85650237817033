import React, { ReactNode } from 'react'
import { useActionWithLoading } from '../../../util/useWithLoading';
import { ProgressIndicator } from '../../Util/common.styles';
import { DocIcon, DocIconSize, DocLink } from './DocumentsPage.styles'

interface Props {
    download: () => Promise<any>;
    title: string;
    iconSize?: DocIconSize;
    extraAction?: ReactNode;
}

export const DownloadableDoc = (props: Props) => {
    const { title, download, iconSize, extraAction } = props;

    const downloadAction = useActionWithLoading(download);

    const startDownload = () => {
        if(!downloadAction.isLoading) {
            downloadAction.action();
        }
    }

    return (
        <DocLink onClick={startDownload}>
            <DocIcon size={iconSize} /> {title} <ProgressIndicator isShown={downloadAction.isLoading} /> {extraAction}
        </DocLink>
    )
}
