import { useState, useEffect } from 'react';
import { apiFetch, FetchTypes } from '../../util/apiFetch';

export const NotificationSettingsTypes = [
    { key: "chat", label: "Получать уведомления о сообщениях в чате на почту" },
]

export type NotificationSettings = { [k: string]: boolean };

export interface NotificationSettingsData {
    settings: NotificationSettings;
    updateSettings: (newSettings: NotificationSettings) => void;
    isLoading: boolean;
}

const loadSettings = () => apiFetch<NotificationSettings>('/api/user/notification-settings');
const putSettings = (settings: NotificationSettings) => apiFetch<NotificationSettings>('/api/user/notification-settings', FetchTypes.PUT, settings);

export const useNotificationSettings = (userid: string): NotificationSettingsData => {
    const [settings, setSettings] = useState<NotificationSettings>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        setIsLoading(true);
        loadSettings()
            .then(setSettings)
            .then(() => {
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }, [userid]);

    const updateSettings = (newSettings: NotificationSettings) => {
        const updated = { ...settings, ...newSettings };
        setSettings(updated);
        setIsLoading(true);
        putSettings(updated)
            .then(setSettings)
            .then(() => {
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    return {
        settings,
        updateSettings,
        isLoading,
    }
}