import { ContestTerms, Notice, TimerInfo } from "../typings/schema";
import { apiFetch, FetchTypes } from "./apiFetch";

export interface ContestInfo {
    countdown: TimerInfo;
    notice: Notice;
    info: { sections: ContestTerms[] };

};

export const getContestInfo = () => apiFetch<ContestInfo>('/api/contest/info/full');
export const updateContestInfo = (data: Partial<ContestInfo>) => apiFetch<ContestInfo>('/api/contest/info/full', FetchTypes.PUT, data);
