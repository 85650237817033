import React from 'react';
import { SubmenuContainer } from '../../toolympus/components/frame/Submenu';
import { GamepadOutlined, GroupOutlined } from '@mui/icons-material';
import { useUser } from '../../presentation/UserContext/UserContext';
import { PremootRoundsParticipantsPage } from './PremootRoundsParticipantsPage';
import { PremootRoundsGridPage } from './PremootRoundsGridPage';
import { ConfirmationDialogProvider } from '../../toolympus/components/primitives';

const Subpages = [
  {
    key: "participants",
    icon: <GroupOutlined />,
    label: "Участники",
    items: [{ key: "", label:  "xx", component: PremootRoundsParticipantsPage }]
  },
  {
    key: "rounds",
    icon: <GamepadOutlined />,
    label: "Раунды",
    items: [{ key: "", label:  "xx", component: PremootRoundsGridPage }]
  },
];

export const PremootRoundsRootPage = () => {
  const { user } = useUser();
  
  return (
    <ConfirmationDialogProvider>
      <SubmenuContainer
        groups={(user.roles || []).includes("admin") ? Subpages : []}
        noCloseButton
        />
    </ConfirmationDialogProvider>
  );
}
