import React from 'react';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ClipboardButton } from '../../toolympus/components/primitives/Buttons';
import { LoadingIndicator } from "../../toolympus/components/primitives/LoadingIndicator";
import { createTsv } from '../../toolympus/components/primitives/tsv';
import { TableForFields } from "../../toolympus/components/schemed";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { FieldType } from "../../toolympus/hooks/useSchema";

interface SpeakerResult {
    speaker: string;
    result: number;
    scores_cnt: number;
    scores: string;
}

interface Props {
    isActive?: boolean;
}

export const SpeakersResults = ({ isActive }: Props) => {
    const data = useLoadedData<SpeakerResult[]>("/api/v2/final-rounds/speaker-stats", [], isActive);

    const getTsv = () => createTsv(
        [ "Спикер", "Результат", "Оценок", "Все оценки"],
        r => ([r.speaker, (r.result || 0).toFixed(2), r.scores_cnt, r.scores]),
        data.data);

    return <>
        <ActionRow>
            {data.isLoading && <LoadingIndicator />}
            <OccupyFreeSpace />
            <ClipboardButton getValue={() => getTsv()} />
        </ActionRow>

        <TableForFields
            data={data.data}
            schema={{
                speaker: { type: FieldType.text, label: "Спикер" },
                result: { type: FieldType.number, label: "Результат" },
                scores_cnt: { type: FieldType.number, label: "Оценок" },
                scores: { type: FieldType.text, label: "Все оценки" },
            }}
            fields={[
                ["speaker"],
                ["result", { precision: 2 }],
                ["scores_cnt"],
                ["scores"],
            ]}
            />
    </>;
}