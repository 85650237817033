import React, { useState } from 'react';
import { PasswordChange } from '../typings/schema';
import { useSnackbar } from 'notistack';
import ChangePasswordPage from '../presentation/RestorePassword/ChangePasswordPage';
import { useLocation } from 'react-router-dom';
import { apiFetch, FetchTypes } from '../toolympus/api/core';

interface Props {
}

export const apiChangePassword = ({ email, password, passwordRepeat, token }: PasswordChange) => {
    return apiFetch("/api/restore-access/reset",
        FetchTypes.POST,
        { login: email, password, passwordRepeat, token },
        { noAuth: true })
}

export const ChangePasswordContainer: React.FC<Props> = (props: Props) => {
    const query = new URLSearchParams(useLocation().search);
    const [formData, setFormData] = useState<PasswordChange>({
        token: query.get('token') || '',
        email: query.get('email') || '',
        password: '',
        passwordRepeat: '' });
    const [isRequested, setIsRequested] = useState(false);
    const snack = useSnackbar();

    const onRequest = (data: PasswordChange) => {
        apiChangePassword(data)
            .then(() => setIsRequested(true))
            .catch(error => snack.enqueueSnackbar("Что-то пошло не так, возможно пароли не совпадают или ссылка истекла. Попробуйте снова.", { variant: 'error', autoHideDuration: 5000 }))
    }

    return (
        <ChangePasswordPage
            formData={formData}
            onDataChanged={setFormData}
            onRequest={onRequest}
            isRequested={isRequested}
        />
    );
}