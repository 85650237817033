import { useState } from 'react';

export interface ActionWithLoading {
    isLoading: boolean;
    action: () => Promise<any>;
}

export const useActionWithLoading = (action: () => Promise<any>): ActionWithLoading => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const actionEx = () => {
        setIsLoading(true);
        return action()
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                throw e;
            });
    };

    return {
        isLoading,
        action: actionEx,
    }
}