import { useCallback, useMemo, useState } from "react";
import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Schema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { CaseQuestion } from "./useTeamQuestionsData";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { apiFetch } from "../../toolympus/api/core";
import { useTeamsList } from "../../containers/admin/useTeamsList";
import { toLocalDatetimeInternal } from "../../toolympus/api/datetimeUtil";
import { exportRecordsToXlsxSheet } from "../../toolympus/api/xlsx";
import { useReorderItemsBySortorder } from "../../toolympus/components/primitives/ReorderItems";

export const exportQuestionsXlsx = (items: CaseQuestion[], schema: Schema, filename: string) => {
  exportRecordsToXlsxSheet({
    columns: [
      "team_id",
      "question",
      "answer",
      "is_answer_published",
      "created_datetime",
    ],
    filename,
    schema,
    sheetTitle: "Вопросы по фабуле",
    prepare: q => ({
      ...q,
      created_datetime: toLocalDatetimeInternal(q.created_datetime),
    }),
  }, items);
}


const AdminQuestionsApiPath = "/api/question/admin";


export const useAdminQuestionsList = () => {
  const data = useLoadedData<CaseQuestion[]>(AdminQuestionsApiPath, []);
  const newItem = useNewItem<CaseQuestion, CaseQuestion>(AdminQuestionsApiPath, { question: "" });
  const editItem = useEditItem2<CaseQuestion>({
    getApiPath: item => `${AdminQuestionsApiPath}/${item._id}`,
  });

  const { schema } = useSingleSchema(`${AdminQuestionsApiPath}/uiconfig`);

  const [view,setView] = useState<string>("all");
  const filter = useTextFilter<CaseQuestion>(r => `${r.question} ${r.answer}`);

  const removeItem = (id: string) => {
    return apiFetch<CaseQuestion>(`${AdminQuestionsApiPath}/${id}`, "delete")
      .then(x => { data.reload(); editItem.cancel(); return x; });
  }

  const records = useMemo(() => {
    const byView = view === "all"
      ? data.data
      : view === "no-answer"
        ? data.data.filter(q => !(q.answer?.trim()))
        : data.data.filter(q => !!(q.answer?.trim()));

    return filter.filterData(byView);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, filter.filter, data.data]);

  const teams = useTeamsList();

  const teamNbrById = useMemo(() => {
    const teamNbrsById = teams.teams.reduce<Record<string,string>>((r,t) => { r[t._id] = t.number; return r; }, {});
    return (id: string) => teamNbrsById[id] || "-";
  }, [teams.teams]);

  const exportQuestions = useCallback(() => {
    const exportable = records.map(r => ({
      ...r,
      team_id: teamNbrById(r.team_id || ""),
      created_datetime: toLocalDatetimeInternal(r.created_datetime),
    }));
    return exportQuestionsXlsx(exportable, schema, `Мозолин ${new Date().getFullYear()} - вопросы.xlsx`);

  }, [teamNbrById, records, schema]);

  const reorderItems = useReorderItemsBySortorder<CaseQuestion>({
    updateItemOrder: (item, sortorder) => {
      return apiFetch<CaseQuestion>(`${AdminQuestionsApiPath}/${item._id}`, "put", { sortorder });
    },
    onSave: () => data.reload(),
  });

  return {
    ...data,
    data: records,
    ...filter,
    schema,
    view,
    setView,

    create: () => newItem.startEditing(),

    newItem: {
      ...newItem,
      save: (x?: Partial<CaseQuestion>) => {
        return newItem.save(x)
          .then(x => {
            data.reload();
            return x;
          });
      },
    },
    editItem: {
      ...editItem,
      save: (x?: Partial<CaseQuestion>) => {
        return editItem.save(x)
          .then(x => {
            data.reload();
            return x;
          });
      },
    },
    removeItem,
    teamNbrById,
    exportQuestions,

    reorderItems,
  }
}
