import moment from "moment";

export const EducationLevels: {[key:string]: string} = {
    "BS": "Бакалавриат",
    "MS": "Магистратура",
    "SP": "Специалитет",
    '': ''
}

export const getEducationLabel = (key: string) => {
    if (EducationLevels[key]){
        return EducationLevels[key];
    } else{
        return '';
    }
}

export const compareDate = (a: string, b: string) => {
    if (moment(a) < moment(b)){
        return -1;
    } else if (moment(a) > moment(b)){
        return 1;
    }

    return 0;
}