import React from 'react';
import { useVolunteerRegistration } from './useVolunteerRegistration';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import { FullWidthTextField, LoadingView, MarginGrid, SaveButtonView } from '../../../presentation/Util/common.styles';
import { GenericTextField } from '../../../presentation/Util/UtilTextfields';
import SuccessButton from '../../../presentation/Util/SuccessButton';
import { RenderElementProps } from 'slate-react';
import { extractCustomBlockFields } from '../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';

const VolunteerParticipationOption: Record<string, string> = {
  premoots_only: "только в премутах",
  finals_only: "только в очных раундах",
  both: "и в премутах, и в очных раундах",
}


export const VolunteerRegistrationForm = (props: RenderElementProps) => {
  const data = useVolunteerRegistration();
  const { update, errors } = data;
  const item = data.createdItem || data.item;
  const isSent = !!data.createdItem;

  const blockFields = extractCustomBlockFields(props.element);
  const successMessage = blockFields.success_message || "Заявка отправлена. Спасибо!";

  return (
    <Box width="100%">
      
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <GenericTextField
                value={item?.email || ""}
                onChange={e => update({ email: e })}
                type="email"
                error={errors.fieldHasErrors("email")}
                label="E-mail"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <GenericTextField
                value={item?.phone || ""}
                onChange={e => update({ phone: e })}
                error={errors.fieldHasErrors("phone")}
                label="Телефон"
                disabled={isSent}
                />
            </Grid>
        </MarginGrid>
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <GenericTextField
                value={item?.lastname || ""}
                onChange={e => update({ lastname: e })}
                error={errors.fieldHasErrors("lastname")}
                label="Фамилия"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <GenericTextField
                value={item?.firstname || ""}
                onChange={e => update({ firstname: e })}
                error={errors.fieldHasErrors("firstname")}
                label="Имя"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <GenericTextField
                value={item?.middlename || ""}
                onChange={e => update({ middlename: e })}
                error={errors.fieldHasErrors("middlename")}
                label="Отчество"
                disabled={isSent}
                required={false}
                />
            </Grid>
        </MarginGrid>
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <FullWidthTextField
                    select
                    required
                    variant="outlined"
                    label="Планирую принять участие"
                    value={item?.participation || null}
                    error={errors.fieldHasErrors("participation")}
                    onChange={e => update({ participation: e.target.value })}
                    disabled={isSent}
                >
                    {['premoots_only', 'finals_only', 'both'].map(option => (
                        <MenuItem key={option} value={option}>{VolunteerParticipationOption[option]}</MenuItem>
                    ))}
                </FullWidthTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={<Checkbox disabled={isSent} checked={item?.pdc_consent || false} onChange={(e, checked) => update({ pdc_consent: checked})} />}
                label="На основании ФЗ РФ от 27.07.2006 № 152-ФЗ «О персональных данных» даю согласие на обработку персональных данных"
                />
            </Grid>
        </MarginGrid>

        <SaveButtonView>
          {data.isLoading &&
            <LoadingView>
              <CircularProgress />
            </LoadingView>
          }
          {!isSent && <SuccessButton
            disabled={!item?.pdc_consent || data.isLoading}
            onClick={() => data.save()}
            title="Отправить заявку"
          />}
          {isSent && <Typography>
            {successMessage}
            </Typography>}
        </SaveButtonView>
    </Box>
  );
}


