import { ValidationErrors } from "../typings/schema";
import moment from 'moment';
import 'moment/locale/ru';

export const handleValidationError = (errors: { [property: string]: any }) => {
    const mainErrors = Object.keys(errors).filter(x => !Array.isArray(errors[x])).map(x => x);
    const coachErrors = errors['coaches'] && Array.isArray(errors['coaches']) ?
    errors['coaches'].reduce((val, currentVal, currentIndex: number) => {
        if (currentVal) {
            const errorsKeys = Object.keys(currentVal).flat();
            const errs = { [currentIndex]: errorsKeys }
            return { ...val, ...errs };
        }
        return val;
    }, {}) : {};
    const memberErrors = errors['members'] && Array.isArray(errors['members']) ?
        errors['members'].reduce((val, currentVal, currentIndex: number) => {
            if (currentVal) {
                const errorsKeys = Object.keys(currentVal)
                    .map(key => {
                        if (currentVal[key] && key === 'education') {
                            return Object.keys(currentVal[key]);
                        }
                        else {
                            return key;
                        }
                    })
                    .flat();

                const errs = { [currentIndex]: errorsKeys }

                return { ...val, ...errs };
            }
            return val;
        }, {}) : {};
    return {
        errors: mainErrors,
        memberErrors: memberErrors,
        coachErrors: coachErrors
    } as ValidationErrors;
}

export const getLocalDate = (date: Date | string) => {
    var momentDate = moment(date);
    momentDate.locale('ru')
    return momentDate.format('D MMMM YYYY');
}

export const getLocalDateTime = (date: Date | string) =>
    moment.utc(date).local().locale('ru').format("D MMMM, HH:mm");

export const getDateTime = (date: Date | string) =>
    moment(date).locale('ru').format("D MMMM, HH:mm");

export const getFullLocalDateTime = (date: Date | string) => {
    return moment.utc(date).local().locale('ru').format('D MMMM YYYY HH:mm');
}
