import React, { ReactNode } from 'react';
import { Badge } from '@mui/material';
import { useMessager } from './MessagerContext';
import { Chat } from './types';

interface Props {
    children: ReactNode;
    isThatChat?: (c: Chat) => boolean
}

export const MessageIconMenu = ({ children, isThatChat }: Props) => {
    const { chats: { list } } = useMessager();

    const isThatChatX = isThatChat || (() => true);

    const unread = list.filter(chat => isThatChatX(chat) && chat.has_unread).length;
    
    return unread ?
        <Badge badgeContent={unread} color="secondary" invisible={!unread} style={{}}>
            {children}
        </Badge>
        : <></>;
}
