import React from 'react';
import { IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { ContestState } from '../../typings/schema';
import { apiFetch, FetchTypes } from '../../util/apiFetch';
import { useLoadedState, usePersistedState } from '../../util/useLoadedState';
import { useActionWithLoading } from '../../util/useWithLoading';
import { ContestControlPage } from './presentation/ContestControlPage';
import { useTimerState } from '../../util/useTimerState';
import { useMaintenanceEdit } from '../../util/useMaintenance';
import { useExportDocuments } from '../DocumentsExport/useExportDocuments';

export interface TeamKeys {
    _id: string;
    number: string;
}

export type Match = { source: TeamKeys, destination: TeamKeys, incoming: TeamKeys };

export const ContestControlContainer = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const timer = useTimerState();
    const maintenance = useMaintenanceEdit();

    const contest = usePersistedState<ContestState>(
        () => apiFetch('/api/contest/state', FetchTypes.GET),
        (s) => apiFetch('/api/contest/state', FetchTypes.PUT, s),
    );

    const matches = useLoadedState<Match[]>(
        () => apiFetch<Match[]>('/api/contest/match/claims', FetchTypes.GET).catch(() => [])
    );

    const initMatching = useActionWithLoading(
        () => apiFetch<void>('/api/contest/match/claims', FetchTypes.POST, undefined)
            .then(() => matches.reload()));
    const cancelMatching = useActionWithLoading(
        () => apiFetch<void>('/api/contest/match/claims', FetchTypes.DELETE, undefined)
            .then(() => matches.reload()));

    const sendInvitesToArbiters = useActionWithLoading(
        () => apiFetch<{ sent: { email: string }[] }>('/api/admin/arbiter/invite-assigned', FetchTypes.POST)
            .then(result => {
                console.log(result);
                enqueueSnackbar(
                    `Отправили приглашения на ${result.sent.length} адресов:\n${result.sent.map(r => r.email).join(',')}`, {
                    persist: true,
                    variant: 'success',
                    action: (key: any) => <IconButton style={{ color: 'white' }} onClick={() => closeSnackbar(key)}><Cancel /></IconButton>
                });
            })
    )

    const documentsExport = useExportDocuments();

    return <ContestControlPage
        contest={contest.state}
        updateContest={contest.update}
        matches={matches.state}
        initMatching={initMatching}
        cancelMatching={cancelMatching}
        documentsExport={documentsExport}
        sendInvitesToArbiters={sendInvitesToArbiters}
        isLoading={contest.isLoading || matches.isLoading}
        timer={timer}
        maintenance={maintenance}
    />
}
