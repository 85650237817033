import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LocalizationProvider from '../../toolympus/components/localization/LocalizationProvider';
import { messages } from '../../components/messages';
import { FormatsProvider } from '../../toolympus/components/schemed';
import { MuiTheme } from './theme';

export const Providers = (props: PropsWithChildren<{}>) => {
  return (
    <LocalizationProvider locale="ru" defaultLocale="ru" messages={messages}>
        <FormatsProvider formats={{ date: "DD.MM.YYYY" }}>
            <ThemeProvider theme={MuiTheme}>
                <StylesProvider injectFirst>
                    <StyledThemeProvider theme={MuiTheme}>
                      <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                        {props.children}
                      </DatesLocalizationProvider>
                    </StyledThemeProvider>
                </StylesProvider>
            </ThemeProvider>
        </FormatsProvider>
    </LocalizationProvider>
  );
}
