import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FormGrid } from '../../../toolympus/components/primitives';

export const FieldTitle = styled.div`
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.54);
`

export const TextView = styled(Typography)`
    font-size: 1rem;
`

export const AvatarView = styled.div`
    margin-bottom: 1rem;
`

export const PopupView = styled(FormGrid)`
`;
PopupView.defaultProps = { columns: "1fr 200px", noMargin: true };

export const FieldsView = styled(FormGrid)`
    width: 100%;
`;
FieldsView.defaultProps = { columns: "1fr 1fr" };
