import React, { PropsWithChildren, useContext } from "react";

export const createProvided = <T,>(useSource: () => T, defaultValue: T) => {
  const context = React.createContext<T>(defaultValue);
  
  const Provider = (props: PropsWithChildren<{}>) => {
    const value = useSource();
    return <context.Provider value={value}>
      {props.children}
    </context.Provider>
  };

  const useValue = () => useContext(context);

  return {
    context,
    Provider,
    useValue,
  }
}
