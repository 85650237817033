import React, { ReactNode } from 'react';
import { Divider } from '@mui/material';
import { PageHeader } from './PageHeader';
import { PageContent } from './common.styles';

export const BasicPageWrapper = ({ children }: { children?: ReactNode }) => {
    return (
      <BasicPageWrapperOnlyOut>
        <PageContent>
            {children}
        </PageContent>

      </BasicPageWrapperOnlyOut>
    )
}

export const BasicPageWrapperOnlyOut = ({ children }: { children?: ReactNode }) => {
  return (
      <div>
          <PageHeader />
          <Divider />
          {children}
      </div>
  )
}
