import { useHistory } from "react-router-dom";
import { apiFetch } from "../../toolympus/api/core";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { login, useUser } from "../../toolympus/userContext/UserContext";

export interface Archive {
    name: string;
    created_datetime: string;
}

const apiPath = "/api/archive";

export const useArchives = () => {
    const data = useLoadedData<Archive[]>(apiPath, []);
    
    const history = useHistory();
    const userContext = useUser();

    const create = (name: string) => {
        return apiFetch<Archive>(apiPath, "post", { name })
            .then(() => data.reload());
    }

    const switchToArchive = (name: string) => {
        apiFetch<string>(`${apiPath}/switch/${name}`, "post")
            .then(token => {
                login(userContext, token);
                history.replace("/")
            });
    }

    const clean = () => {
        return apiFetch(`${apiPath}/clean`, "post");
    }

    return {
        ...data,
        create,
        switchToArchive,
        clean,
    }
}