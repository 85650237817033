import { useEffect, useMemo, useState } from 'react';
import { AdminTeamInfo, ExludedItem, TeamDocuments } from '../../typings/schema';
import { TabFilter, TextFilter, useTabFilter, useTextFilter } from '../../util/useFilter';
import { apiFetch, fetchGet, fetchPost, FetchTypes } from '../../util/apiFetch';
import { compareDate } from '../../presentation/Util/common';


export interface TeamsList {
    teams: AdminTeamInfo[];
    resendConfirmationRequest: (team: AdminTeamInfo) => Promise<void>;
    excludeTeam: (team: AdminTeamInfo, exlItem: ExludedItem | null) => Promise<void>;
    deleteTeam: (team: AdminTeamInfo) => Promise<void>;
    updateFinalsStage: (team: AdminTeamInfo, stage: 'rating' | null) => Promise<void>;
    textFilter: TextFilter<AdminTeamInfo>;
    tabFilter: TabFilter<AdminTeamInfo>;
    isLoaded: boolean;
}

const getTeamData = () => {
    return fetchGet<AdminTeamInfo[]>('/api/admin/team');
}

const resendEmailConfirmationRequest = (team: AdminTeamInfo) => {
    return fetchPost<void>(`/api/user/${team._id}/request-confirmation`, null);
}

const putExludingTeam = (team: AdminTeamInfo, exlItem: ExludedItem | null) => {
    return apiFetch<AdminTeamInfo>(`/api/admin/team/${team._id}`, FetchTypes.PUT, { excluded: exlItem } || { excluded: null });
}

const countDocuments = (documents?: TeamDocuments) => {
    return [
        documents?.claim1,
        documents?.response1,
    ].filter(d => !!d).length;
}


export const useTeamsList = (): TeamsList => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [teams, setTeams] = useState<AdminTeamInfo[]>([])

    const teamStr = (t: AdminTeamInfo) => {
        const coaches = (t.info.coaches || []).map(c => `${c.name} ${c.email}`).join(' ');
        const members = t.info.members.map(m => `${m.lastname} ${m.email} ${m.education?.school}`).join(' ');

        return `${t.number} ${t.info.email} ${coaches} ${members}`;
    }

    const textFilter = useTextFilter(teamStr);

    const tabFilter = useTabFilter<AdminTeamInfo>(
        "all",
        [
            ["responses_yes", "Отзыв есть", team => (team.documentsCount || 0) === 2],
            ["claims_yes_responses_no", "Иски есть, отзыва нет", team => (team.documentsCount || 0) === 1],
            ["claims_yes", "Иски есть", team => (team.documentsCount || 0) >= 1],
            ["docs_no", "Документов нет", team => (team.documentsCount || 0) <= 0],
            ["excluded", "Исключенные", team => team.excluded ? true : false],
            ["active", "Активные", team => !team.excluded],
            ["all", "Все",],
        ]);

    const fetchData = () => {
        return getTeamData()
            .then(unsorted => (unsorted || []).sort((a, b) => compareDate(b.registered_datetime, a.registered_datetime)))
            .then(teams => teams.map(t => ({ ...t, documentsCount: countDocuments(t.documents) })))
            .then(data => setTeams(data))
            .then(() => setIsLoaded(true));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const invokeEmailConfirmationRequest = (team: AdminTeamInfo) =>
        resendEmailConfirmationRequest(team).then(() => fetchData());

    const excludeTeam = (team: AdminTeamInfo, item: ExludedItem | null) => 
        putExludingTeam(team, item).then(() => fetchData());

    const deleteTeam = (team: AdminTeamInfo) => {
        return apiFetch<void>(`/api/admin/team/${team._id}`, FetchTypes.DELETE);
    }

    const updateFinalsStage = (team: AdminTeamInfo, stage: 'rating' | null) => {
        return apiFetch<AdminTeamInfo>(`/api/admin/team/${team._id}`, FetchTypes.PUT, { finals_stage: stage })
            .then(() => fetchData());
    }

    const filteredTeams = useMemo(
      () => teams.filter((t: AdminTeamInfo) => textFilter.filter(t) && tabFilter.filter(t)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [teams, textFilter.filter, tabFilter.selectedKey]);

    return {
        teams: filteredTeams,
        tabFilter,
        textFilter,
        resendConfirmationRequest: invokeEmailConfirmationRequest,
        excludeTeam,
        deleteTeam,
        updateFinalsStage,
        isLoaded,
    }
}