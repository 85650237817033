import React from 'react';
import styled from '@emotion/styled';
import { Divider, Button, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { Arbitrators, Arbitrator } from './Arbitrators';
import { Sides, Side, SideText, AttachmentsWrapper } from './Sides';
import { PageSectionPadded, PageSectionHeader } from '../../presentation/Util/common.styles';
import { OnlineRoundDataWithConference } from '../Contest/useOnlineRound';
import { DownloadableDoc } from '../../presentation/Team/Documents/DownloadableDoc';
import { TeamOwnDocumentsIds } from '../../typings/schema';
import { RoundDateApproval } from './RoundDateApproval';
import { StartScoringButton } from './Scoring/Common';
import { TeamRole } from '../Contest/useOnlineRoundScores';
import { DocumentKey, OnlineRoundScoring2 } from '../Contest/useOnlineRoundScoring2';
import { CallMade, Videocam } from '@mui/icons-material';
import { useUser } from '../../presentation/UserContext/UserContext';
import { Buttons, Dialog, FormGrid, PseudoLink2 } from '../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';

interface Props {
    roundData: OnlineRoundDataWithConference;
    currentTeamId?: string;
    showAllDocs?: boolean;
    scoring?: OnlineRoundScoring2;
}

interface TeamDocumentsProps {
    teamNbr: string;
    documents: TeamOwnDocumentsIds;
    downloadDocument: (id: string, name: string) => Promise<void>;
    scoreDocument?: (documentKey: 'claim1' | 'response1') => void;
    isDocumentScored?: (documentKey: 'claim1' | 'response1') => boolean;
}


const TeamDocuments = ({ teamNbr, documents, downloadDocument, scoreDocument, isDocumentScored }: TeamDocumentsProps) => {
    const docs = [
        ['claim1', documents.claim1, "Иск 1"],
        ['response1', documents.response1, "Отзыв 1"],
    ] as [DocumentKey, TeamOwnDocumentsIds["claim1"], string][];
    return <div>
        <SideText>{teamNbr}</SideText>
        <AttachmentsWrapper>
            {docs.filter(([_,doc,]) => !!doc).map(([kind, doc, title]) => (
                !!doc && <DownloadableDoc
                    key={doc.doc_id}
                    iconSize="small"
                    title={title}
                    download={() => downloadDocument(doc.doc_id, `${title}_${teamNbr}.pdf`)}
                    extraAction={scoreDocument && (
                        <StartScoringButton
                            startScoring={() => scoreDocument(kind)}
                            isScored={isDocumentScored && isDocumentScored(kind)} />)}
                    />
            ))}
        </AttachmentsWrapper>
    </div>;
}

const HearingsHeader = styled(PageSectionHeader)`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const ExternalConferencingDialog = (props: Pick<OnlineRoundDataWithConference, "round" | "externalConferencing">) => {
  const { externalConferencing } = props;

  const isSetToExternal = externalConferencing.item?.conferencing_tool === "external";

  return (
    <Dialog
      close={externalConferencing.cancel}
      isOpen={externalConferencing.isEditing}
      dialogTitle="Внешняя система видеоконференцсвязи"
      noFullscreen
      actions={<>
        {externalConferencing.hasChanges && <Button color="primary" variant="contained" onClick={() => externalConferencing.save()}><FormattedMessage id="common.save" /></Button>}
        <Button onClick={() => externalConferencing.cancel()}><FormattedMessage id="common.close" /></Button>
      </>}>
      <Typography>Если вы планируете использовать внешнюю систему для проведения раунда, пожалуйста, укажите ссылку для подключения в поле ниже.</Typography>
      
      <FormGrid columns="1fr">
        <Typography variant="caption" color="textSecondary">Мы будем использовать</Typography>
        <Buttons>
          <Button size="small" color={isSetToExternal ? "inherit" : "primary"} variant={isSetToExternal ? "text" : "contained"} onClick={() => externalConferencing.update({ conferencing_tool: "internal", conferencing_external_link: "" })}>
            платформу конкурса
          </Button>

          <Button size="small" color={!isSetToExternal ? "inherit" : "primary"} variant={!isSetToExternal ? "text" : "contained"} onClick={() => externalConferencing.update({ conferencing_tool: "external" })}>
            внешнюю систему
          </Button>
        </Buttons>

        {isSetToExternal &&
          <TextField
            value={externalConferencing.item?.conferencing_external_link || ""}
            onChange={e => externalConferencing.update({ conferencing_external_link: e.target.value })}
            label="Ссылка для подключения"
            fullWidth
            autoFocus
            error={!externalConferencing.item?.conferencing_external_link}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton size="small" href={externalConferencing.item?.conferencing_external_link || ""} target="_blank" rel="noopener noreferrer"><CallMade /></IconButton>
              </InputAdornment>
            }}
            />}
      </FormGrid>
    </Dialog>
  )
}

export const OnlineRoundView = (props: Props) => {
    const { roundData, currentTeamId, showAllDocs, scoring, } = props;
    const { round, arbitrators, downloadDocument } = roundData;
    
    const userContext = useUser();

    if(!round) {
        return <></>;
    }

    const team1Nbr = round.team1_id === currentTeamId ? "Вы" : `Команда ${(round.team1_nbr || 'XXX')}`;
    const team2Nbr = round.team2_id === currentTeamId ? "Вы" : `Команда ${(round.team2_nbr || 'YYY')}`;

    return (<>
        <PageSectionPadded key="dateapproval">
            <HearingsHeader>
                Слушания
                {userContext.contestState?.conferencing_enabled && <Button size="small" color="primary" variant="contained" startIcon={<Videocam />} onClick={roundData.conference.open}>
                    подключиться
                </Button>}
                {roundData.conference.popup}
            </HearingsHeader>

            {round.conferencing_tool !== "external" && <Typography variant="caption" style={{ display: "block", marginBottom: "1rem" }}>
              Если вы планируете использовать внешнюю систему для проведения раунда, пожалуйста, укажите ссылку для подключения <PseudoLink2 color="primary" onClick={() => roundData.externalConferencing.startEditing(round)}>здесь</PseudoLink2>.
            </Typography>}

            {round.conferencing_tool === "external" && <Typography variant="caption" style={{ display: "block", marginBottom: "1rem" }}>
              Участники раунда согласовали использование <PseudoLink2 onClick={() => roundData.externalConferencing.startEditing(round)}>внешней системы</PseudoLink2> для проведения раунда.
            </Typography>}

            <ExternalConferencingDialog round={round} externalConferencing={roundData.externalConferencing} />
            
            <RoundDateApproval roundData={roundData} />
        </PageSectionPadded>
        <Divider />

        <PageSectionPadded key="case1">
            <PageSectionHeader>Документы</PageSectionHeader>

            <Sides>
                <Side
                    key="1"
                    teamKey="team1"
                    role={TeamRole.claimant}
                    title={team1Nbr}
                    startScoring={scoring ? scoring.scorePerformance : undefined}
                    isScored={scoring ? scoring.isPerformanceScored : undefined}
                    attachments={<>
                        <DownloadableDoc
                            iconSize="small"
                            title={"Иск 1"}
                            download={() => downloadDocument(
                                round.team1_documents.claim1?.doc_id as string,
                                `Иск_1_${team1Nbr}.pdf`)} />
                    </>}
                    />

                <Side
                    key="2"
                    teamKey="team2"
                    role={TeamRole.respondant}
                    title={team2Nbr}
                    startScoring={scoring ? scoring.scorePerformance : undefined}
                    isScored={scoring ? scoring.isPerformanceScored : undefined}
                    attachments={<>
                        <DownloadableDoc
                            iconSize="small"
                            title={"Отзыв"}
                            download={() => downloadDocument(
                                round.team2_documents.response1?.doc_id as string,
                                `Отзыв_${team2Nbr}.pdf`)} />
                    </>}
                    />
            </Sides>
        </PageSectionPadded>

        <Divider />

        {showAllDocs && (<>
            <PageSectionPadded key="docs">
                <PageSectionHeader>
                    Документы для оценки
                </PageSectionHeader>

                <Sides>
                    <TeamDocuments
                        key="team1"
                        teamNbr={team1Nbr}
                        documents={round.team1_documents}
                        downloadDocument={downloadDocument}
                        scoreDocument={scoring ? k => scoring.scoreDocument('team1', k) : undefined}
                        isDocumentScored={scoring ? k => scoring.isDocumentScored('team1', k) : undefined} />
                    <TeamDocuments
                        key="team2"
                        teamNbr={team2Nbr}
                        documents={round.team2_documents}
                        downloadDocument={downloadDocument}
                        scoreDocument={scoring ? k => scoring.scoreDocument('team2', k) : undefined}
                        isDocumentScored={scoring ? k => scoring.isDocumentScored('team2', k) : undefined} />
                </Sides>
            </PageSectionPadded>

            <Divider />
        </>)}

        <PageSectionPadded key="arbiters">
            <PageSectionHeader>Арбитры</PageSectionHeader>

            <Arbitrators>
                {arbitrators.map(a => (<Arbitrator key={a._id} arbitrator={a} />))}
            </Arbitrators>
        </PageSectionPadded>
    </>);
}
