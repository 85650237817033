import { Dialog, DialogContent, FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';
import { ProgressIndicator } from '../../presentation/Util/common.styles';
import DialogTitle from '../../presentation/Util/DialogTitle';
import { DialogState } from '../../util/useDialogState';
import { NotificationSettingsData, NotificationSettingsTypes } from './useNotificationSettings';

interface Props {
    settings: NotificationSettingsData;
    state: DialogState;
}

export const NotificationSettingsDialog = (props: Props) => {
    const { settings, state } = props;

    return (
        <Dialog open={state.isShown} onClose={state.hide} maxWidth='sm'>
            <DialogTitle onClose={state.hide}>
                Настройки уведомлений <ProgressIndicator size="small" isShown={settings.isLoading} />
            </DialogTitle>

            <DialogContent>
                {NotificationSettingsTypes.map(({ key, label}) => (
                    <FormGroup row key={key}>
                        <FormControlLabel
                            control={<Switch
                                        color="primary"
                                        checked={settings.settings[key] !== false}
                                        onChange={e => settings.updateSettings({ [key]: e.target.checked })}
                                        name={key} />}
                            label={label}
                        />
                    </FormGroup>
                ))}
            </DialogContent>
        </Dialog>
    );
}
