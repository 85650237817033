import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, } from 'react-router-dom';
import { TeamRoutes } from './TeamRoutes';
import { ArbiterRoutes } from './ArbiterRoutes';
import { fetchGet } from '../../util/apiFetch';
import { TeamNumber } from '../../typings/schema';
import { isAuthenticated } from '../../util/tokenStorage';
import { AdminRoutes } from './AdminRoutes';
import { PremootOrgRoutes } from './PremootOrgRoutes';
import { RootRedirectContainer } from '../../containers/RootRedirectContainter';
import { useUser, checkContextRole, EmptyUser } from '../UserContext/UserContext';
import { useLoginGuard } from '../../toolympus/hooks/auth/useLoginGuard';
import { ContestStateProvider } from '../../util/useContestState';
import { ActionsConfigProvider } from '../../toolympus/components/Actions/ActionsConfigProvider';
import { ApiConfgurationProvider } from '../../toolympus/components/ApiConfguration';

interface Props {
}

const getTeamNumber = () => {
    return fetchGet<TeamNumber>('/api/team/number');
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ContentApp: React.FC<Props> = (props: Props) => {
    const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(true);

    const userContext = useUser();
    const isAuth = isAuthenticated();
    let query = useQuery();
    
    const user = useUser();
    useLoginGuard('/login', { 
        onGuardInvoked: () => {
            user.setUser(EmptyUser);
        },
        isAuthenticated: isAuth,
    });

    useEffect(() => {
        if (isAuth && checkContextRole(userContext, 'team')) {
            getTeamNumber()
                .then(number => {
                    setEmailConfirmed(number.email_confirmed);
                })
        }
        else if (isAuth && checkContextRole(userContext, 'admin')){
            setEmailConfirmed(true);
        }
    }, [userContext, isAuth]);

    return (
      <ContestStateProvider>
        <ApiConfgurationProvider actionsApiPath="/api/actions" emailTemplatesApiPath="/api/email">
          <ActionsConfigProvider>
            <Switch>
                {checkContextRole(userContext, 'team') &&
                    TeamRoutes.map(r => {
                        const path = `/team/${r.path}`;
                        const code = query.get("code");
                        return (
                            <Route exact path={path} key={path} >
                                <r.component isConfirmed={isEmailConfirmed} code={code} />
                            </Route>
                        )
                    })}
                {checkContextRole(userContext, 'arbiter') &&
                    ArbiterRoutes.map(r => {
                        const path = `/arbiter/${r.path}`;
                        return (
                            <Route exact path={path} key={path} >
                                <r.component />
                            </Route>
                        )
                    })}
                {checkContextRole(userContext, 'premoot-org') &&
                    PremootOrgRoutes.map(r => {
                        const path = `/premoot-org/${r.path}`;
                        return (
                            <Route exact path={path} key={path}>
                                <r.component />
                            </Route>
                        )
                    })}
                {checkContextRole(userContext, 'admin') &&
                    AdminRoutes.map(r => {
                        const path = `/admin/${r.path}`;
                        return (
                            <Route path={path} key={path}>
                                <r.component />
                            </Route>
                        )
                    })}
                <Route path="*">
                    <RootRedirectContainer />
                </Route>
            </Switch>
          </ActionsConfigProvider>
        </ApiConfgurationProvider>
      </ContestStateProvider>
    )
}