import { useState } from "react";
import { ScoringData, useScoring } from "../../toolympus/components/Contests/ScoringSheets";
import { OnlineRound, ScoringItem } from "../../typings/schema";


interface ScoringInfo {
    type: 'document' | 'or-performance';
    id: string;
    teamNbr: string;
    documentTitle?: string;
    performanceTitle?: string;

}


const DocTitles = {
    claim1: "Иск 1",
    claim2: "Иск 2",
    response1: "Отзыв",
    response2: "Отзыв",
}

export type TeamKey = 'team1' | 'team2';
export type DocumentKey = 'claim1' | 'response1';
export type PerformanceKey = 'or-performance' | 'or-performance2';

export interface OnlineRoundScoring2 {
    currentInfo: ScoringInfo | null;
    currentScoring: ScoringData | null;

    scoreDocument: (teamKey: TeamKey, documentKey: DocumentKey) => void;
    isDocumentScored: (teamKey: TeamKey, documentKey: DocumentKey) => boolean;
    scorePerformance: (teamKey: TeamKey, performanceKey: PerformanceKey) => void;
    isPerformanceScored: (teamKey: TeamKey, performanceKey: PerformanceKey) => boolean;

    cancelScoring: () => void;
    isActive: boolean;

}


export const useOnlineRoundScoring2 = (round?: OnlineRound, onSubmitScore?: () => void): OnlineRoundScoring2 => {
    const [currentInfo, setCurrentInfo] = useState<ScoringInfo | null>(null);

    const currentScoring = useScoring(`/api/scoring/score`, currentInfo?.type, currentInfo?.id);

    const cancelScoring = () => setCurrentInfo(null);

    const submitScore = () => {
        return currentScoring.submit().then(() => {
            cancelScoring();
            if(onSubmitScore) {
                onSubmitScore();
            }
         });
    }

    const getScoringItem = (teamKey: TeamKey, itemKey: DocumentKey | PerformanceKey): ScoringItem | undefined => {
        if(round?.scoring) {
            const teamScoring = round?.scoring[teamKey === 'team1' ? round.team1_id : round.team2_id]

            if(teamScoring) {
                return teamScoring[itemKey];
            }
        }
        return undefined;
    }

    const scoreDocument = (teamKey: TeamKey, documentKey: DocumentKey) => {
        if(!round || ! round.scoring) {
            return;
        }

        const item = getScoringItem(teamKey, documentKey);

        if(!item) {
            return;
        }

        setCurrentInfo({
            type: 'document',
            id: item._id,
            teamNbr: teamKey === 'team1' ? round.team1_nbr : round.team2_nbr,
            documentTitle: DocTitles[documentKey],
        });
    }

    const scorePerformance = (teamKey: TeamKey, performanceKey: PerformanceKey) => {
        if(!round || ! round.scoring) {
            return;
        }

        const item = getScoringItem(teamKey, performanceKey);

        if(!item) {
            return;
        }

        setCurrentInfo({
            type: 'or-performance',
            id: item._id,
            teamNbr: teamKey === 'team1' ? round.team1_nbr : round.team2_nbr,
            performanceTitle: performanceKey === 'or-performance' ? 'Выступление 1' : 'Выступление 2',
        });
    }

    return {
        currentInfo,
        currentScoring: {
            ...currentScoring,
            submit: submitScore,
        },

        cancelScoring,
        isActive: !!currentInfo,
        
        scoreDocument,
        isDocumentScored: (tk, dk) => getScoringItem(tk, dk)?.is_scored || false,

        scorePerformance,
        isPerformanceScored: (tk, pk) => getScoringItem(tk, pk)?.is_scored || false,
    }
}