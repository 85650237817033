import { useState } from 'react';

export interface DialogState {
    isShown: boolean;
    show: () => void;
    hide: () => void;
    setIsShown: (v: boolean) => void;
}

export const useDialogState = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    return {
        isShown,
        setIsShown,
        show: () => setIsShown(true),
        hide: () => setIsShown(false),
    }
}