import { checkRole, useUser } from '../../presentation/UserContext/UserContext';
import { apiUploadFile, downloadFile } from '../../toolympus/api/core';
import { LoadedData, useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { apiFetch, FetchTypes } from '../../util/apiFetch';
import { useContestState } from '../../util/useContestState';
import { Premoot } from './premootData';

export const usePremootList = () => {
    const data = useLoadedData<Premoot[]>('/api/premoot/public', []);

    return data;
}

export type PremootData = LoadedData<Premoot> & {
    apply: () => Promise<void>;
    cancelApplication: () => Promise<void>;
    canApply: boolean;
    downloadDocument: (kind: string) => void;
    uploadDocument: (kind: string, file: any) => void;
    deleteDocument: (kind: string) => void;
};

export const usePremoot = (id: string): PremootData => {
    const all = usePremootList();
    const data = all.data.find(p => p._id === id) || { title: "", _id: id, is_published: true };

    const { user } = useUser();
    const { data: state } = useContestState();

    const apply = () => {
        return apiFetch<void>(`/api/premoot/public/${id}/application`, FetchTypes.POST)
            .then(() => all.reload());
    }

    const cancelApplication = () => {
        return apiFetch<void>(`/api/premoot/public/${id}/application`, FetchTypes.DELETE)
            .then(() => all.reload());
    }

    const downloadDocument = (kind: string) => downloadFile(`/api/premoot/document/${data._id}/${user._id}/${kind}`, `${kind}.pdf`);

    const uploadDocument = (kind: string, file: any) =>
        apiUploadFile(`/api/premoot/public/${data._id}/document/${kind}`, FetchTypes.PUT, "file", file)
        .then(() => all.reload());

    const deleteDocument = (kind: string) =>
        apiFetch(`/api/premoot/public/${data._id}/document/${kind}`, FetchTypes.DELETE)
        .then(() => all.reload());

    return {
        ...all,
        data,
        setData: () => { },
        apply,
        cancelApplication,
        canApply: data._id !== "" && !data.is_applied && checkRole(user, "team") && state.premoots_application_available,
        downloadDocument,
        uploadDocument,
        deleteDocument,
    }
}
