import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { AppRoutes} from './AppRoutes';
import { SnackbarProvider } from 'notistack';
import { decodeToken, isAuthenticated, getApiToken } from '../../util/tokenStorage';
import { EmptyUser, User, UserContext } from '../UserContext/UserContext';
import { UserContext as UserContextTools } from '../../toolympus/userContext/UserContext';
import { AppWrapp } from '../../frame/AppWrap';
import { useContestState } from '../../util/useContestState';
import ErrorBoundary from '../../toolympus/components/app/ErrorBoundary';
import { Providers } from './Providers';

const InRouter = () => {
  return (
    <AppWrapp>
      <Switch>
        {AppRoutes.map(r => (
          <Route exact path={r.path} key={r.path} component={r.component} />
        ))}
      </Switch>
    </AppWrapp>)
}


const App: React.FC = () => {
    const token = getApiToken();
    const [user, setUser] = useState<User>(isAuthenticated() && token ? decodeToken(token) as User : EmptyUser);
    const contestState = useContestState();

    useEffect(() => {
        document.title = 'Конкурс по арбитражу корпоративных споров им. В.П. Мозолина';

    }, []);

    return (
        <UserContext.Provider value={{
            user,
            setUser,
            contestState: contestState.data,
            isContestStateLoaded: !contestState.isLoading,
            }}>
            <UserContextTools.Provider value={{
                user,
                setUser: u => setUser(u as User),
            }}>
              <Providers>
                <Router>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <ErrorBoundary>
                            <InRouter />
                        </ErrorBoundary>
                    </SnackbarProvider>
                </Router>
              </Providers>
            </UserContextTools.Provider>
        </UserContext.Provider>
    );
}

export default App;