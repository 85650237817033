import React, { useCallback } from 'react';
import { PseudoLink2 } from '../../toolympus/components/primitives';
import { useAdminQuestionsList } from './useAdminQuestionsList';
import { SimpleList2 } from '../../toolympus/components/primitives/SimpleList2';
import { ActionTriggerEditButton } from '../../toolympus/components/Actions';
import { AdminQuestionEditDialog } from './AdminQuestionEditDialog';
import { FieldElementRenderer } from '../../toolympus/components/schemed';
import { CaseQuestion } from './useTeamQuestionsData';
import { Button, IconButton } from '@mui/material';
import { FormatListNumbered, GetAppOutlined } from '@mui/icons-material';
import { ReorderItemsDialog } from '../../toolympus/components/primitives/ReorderItems';

interface Props {
  
}

const QuestionsActionTriggers = [
  "mozolin.logic.questions.questions-entities/question-received",
];

const QuestionReorderDisplay = (props: { item: CaseQuestion }) => <>{props.item.question}</>;

export const AdminQuestionsListPage = (props: Props) => {
  const data = useAdminQuestionsList();

  const fieldElement = useCallback((f: string): FieldElementRenderer<CaseQuestion> => {
    switch(f) {
      case "question":
      case "answer":
        return (row,s,orig) => <PseudoLink2 onClick={() => data.editItem.startEditing({ ...row })}>{orig}</PseudoLink2>
      case "team_id":
        return (row) => <>{data.teamNbrById(row.team_id || "")}</>
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.teamNbrById]);

  return (<>
    <SimpleList2
      data={data}
      fields={[
        ["team_id"],
        ["question"],
        ["answer"],
        ["is_answer_published"],
      ]}
      schema={data.schema}
      title="Вопросы по фабуле"
      headerActionsL={<>
        <Button size="small" startIcon={<FormatListNumbered />} onClick={() => data.reorderItems.startEditing(data.data)}>
          порядок
        </Button>
      </>}
      headerActionsR={<>
        <IconButton size="small" onClick={() => data.exportQuestions()}>
          <GetAppOutlined />
        </IconButton>
        <ActionTriggerEditButton
          triggers={QuestionsActionTriggers}
          apiPath="/api/actions/trigger"
          />
      </>}
      fieldElement={fieldElement}
      tabs={[
        ["Все", "all"],
        ["Без ответа", "no-answer"],
        ["С ответом", "with-answer"],
      ]}
      />
    
    <AdminQuestionEditDialog
      data={data.newItem}
      schema={data.schema}
      teamNbrById={data.teamNbrById}
      isNew
      />
    <AdminQuestionEditDialog
      data={data.editItem}
      schema={data.schema}
      remove={id => data.removeItem(id)}
      teamNbrById={data.teamNbrById}
      />

    <ReorderItemsDialog
      data={data.reorderItems}
      title="Переупорядочить вопросы"
      itemKey={q => q._id || ""}
      ItemComponent={QuestionReorderDisplay}
      />
  </>);
}
