import { LoadedData, useLoadedData } from "../../../../hooks/useLoadedData";
import { Round } from "../types";

export interface PartyRounds extends LoadedData<Round[]> {
    byStages: [string, Round[]][];
}


const StageOrder: Record<string,number> = {
    "rating": 0,
    "po_1_16": 1,
    "po_1_8": 2,
    "po_1_4": 3,
    "po_1_2": 4,
    "po_final": 5,
}


export const usePartyRounds = (apiPath: string, kind: 'player' | 'arbiter' | 'public'): PartyRounds => {
    const data = useLoadedData<Round[]>(`${apiPath}/${kind}/round`, []);
    const byStages = [] as [string, Round[]][];
    data.data.forEach(r => {
        const stage = byStages.find(([s,]) => s === r.stage_code);
        if(stage) {
            stage[1].push(r);
        } else {
            byStages.push([r.stage_code, [r]]);
        }
    })

    return {
        ...data,
        byStages: byStages.sort(([a],[b]) => (StageOrder[a] || -1) > (StageOrder[b] || -1) ? 1 : -1),
    };
}
