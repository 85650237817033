import React, { ReactNode } from 'react';
import { AdminArbiterInfo, OnlineRoundsConfirmationStatus } from '../../../typings/schema';
import { Button, Dialog, DialogContent, Grid, IconButton, Tooltip } from '@mui/material';
import DialogTitle from '../../Util/DialogTitle';
import Avatar from '../../Util/Avatar';
import { getFullLocalDateTime } from '../../../util/common';
import { getEducationLabel } from '../../Util/common';
import { FieldTitle, TextView, AvatarView, PopupView, FieldsView } from './ArbiterPopup.styles';
import { ParticipationOptionNameShort, PremootParticipationOptionName } from '../../../util/constants';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { useArbitratorCertificate } from '../../../containers/team/useTeamCertificate';
import { CallMade, CancelOutlined, Email, GetAppOutlined } from '@mui/icons-material';
import { DownloadCertificateDialog } from '../../Team/Documents/TeamCertificateBlock';
import { Buttons, OccupyFreeSpace, useCopyText } from '../../../toolympus/components/primitives';
import { ArbiterInviteData } from './useInviteArbiter';
import { Underline } from '../../Util/common.styles';
import { Link } from 'react-router-dom';
import { RecordsTags } from '../../../toolympus/components/Tags';

interface CellProps {
    children: ReactNode;
    fullWidth?: boolean;
}

interface LabeledCellProps {
    label?: string;
    value: ReactNode;
    fullWidth?: boolean;
}


const Cell = ({ children, fullWidth }: CellProps) => {
    const [xs,sm,md] = (fullWidth ? [12, 12, 12] : [12, 5, 5]) as (12|5)[];

    return (
        <Grid item xs={xs} sm={sm} md={md}>
            {children}
        </Grid>);
};
const LabeledCell = ({ label, value, fullWidth }: LabeledCellProps) => {
    return (
        <Cell fullWidth={fullWidth}>
            {label && <FieldTitle>{label}</FieldTitle>}
            <TextView>{value}</TextView>
        </Cell>);
};

interface ArbiterPopupProps {
    arbiter: AdminArbiterInfo;
    onClosePopup: VoidFunction;
    toggleDuplicate: (isDuplicate: boolean) => void;
    deleteArbiter: (id: string) => Promise<void>;
    clearOnlineRoundsConfirmation: (id: string) => void;
    arbiterInvite: ArbiterInviteData;
    conflicts: RecordsTags;
}

const ArbiterPopup: React.FC<ArbiterPopupProps> = (props: ArbiterPopupProps) => {
    const arb = props.arbiter;
    const info = arb.info;

    const certificate = useArbitratorCertificate(false);
    const copyText = useCopyText();

    return (
        <Dialog
            open
            onClose={props.onClosePopup}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle
              onClose={props.onClosePopup}
              actions={<>
                <OccupyFreeSpace />

                <Button
                  size="medium"
                  color="primary"
                  onClick={() => certificate.forAdmin.startEditing({ partyId: arb._id, names: "", template: "" })}
                  startIcon={<GetAppOutlined />}
                >
                  сертификат
                </Button>
                <DownloadCertificateDialog data={certificate.forAdmin} />
                
                <Button
                  size="medium"
                  color="primary"
                  onClick={() => props.toggleDuplicate(arb.is_duplicate && arb.is_duplicate !== undefined ? false : true)}
                >
                  {arb.is_duplicate && arb.is_duplicate !== undefined ? 'Не дубликат' : 'Отметить как дубликат'}
                </Button>

                <DeleteButton
                  button={<Button color="secondary">удалить</Button>}
                  title="Удалить арбитра?"
                  hint="Внимание: все данные арбитра будут удалены. Операцию невозможно будет отменить. Для подтверждения введите email арбитра:"
                  confirmationText={info.email || "never-guess"}
                  preventGoBack
                  remove={() => props.deleteArbiter(arb._id)}
                  />
              </>}>
                {`${info.lastname} ${info.firstname} ${info.middlename}`}
            </DialogTitle>
            <DialogContent>
                <PopupView>
                    <div>
                      <LabeledCell label="Конфликты" value="" />
                      <div style={{ margin: "0.5rem 0 1rem"}}>
                        {props.conflicts.displayForRecord(arb._id)}
                      </div>

                      <FieldsView noMargin>
                            <LabeledCell label="Дата регистрации" value={getFullLocalDateTime(arb.registered_datetime)} fullWidth />
                            <div />

                            <LabeledCell
                              label="Контактный email"
                              fullWidth
                              value={<Buttons>
                                <Underline onClick={() => copyText(info.email)}>{info.email}</Underline>
                                <Link to={`/admin/system/users?filter=${info.email}`} onClick={e => e.stopPropagation()}>
                                    <IconButton size="small"><CallMade /></IconButton>
                                </Link>
                              </Buttons>}
                              />
                            <LabeledCell
                              label="Контактный телефон"
                              fullWidth
                              value={<Underline onClick={() => copyText(info.phone)}>{info.phone}</Underline>}
                              />

                            <LabeledCell label="Место работы" fullWidth value={info.company} />
                            <LabeledCell label="Должность" fullWidth value={info.position} />

                            <LabeledCell label="Уровень оконченного образования" fullWidth value={getEducationLabel(info.educationLevel)} />
                            <LabeledCell label="Год окончания" fullWidth value={info.educationFinishedYear.toString()} />
                          </FieldsView>
                          
                          <FieldsView columns="1fr">
                            <LabeledCell label="Опыт участия в муткортах" value={info.mootCourtsExperience || "-"} fullWidth />
                            <LabeledCell label="Аффилированность" value={info.affiliation || "-"} fullWidth />
                            <LabeledCell label="Тренер команды" value={info.team_coach || "-"} fullWidth />
                            
                              
                          </FieldsView>
                          <FieldsView>

                            <LabeledCell label="Участие" fullWidth value={ParticipationOptionNameShort[info.willParticipateIn]} />
                            <LabeledCell label="Участие в премутах" fullWidth value={PremootParticipationOptionName[info.will_participate_in_premoots || ""]} />

                            <LabeledCell label="Онлайн раунды" fullWidth value={(arb.onlinerounds || []).length} />
                            <LabeledCell
                              label="Подтверждение ОР"
                              fullWidth
                              value={<Buttons>
                                {arb.online_rounds_confirmation
                                  ? OnlineRoundsConfirmationStatus[arb.online_rounds_confirmation]
                                  : "?"}
                                {!arb.online_rounds_confirmation && <Tooltip title="Пригласить">
                                    <IconButton size="small" onClick={() => props.arbiterInvite.openFor(arb)}><Email /></IconButton>
                                </Tooltip>}

                                {!!arb.online_rounds_confirmation && <Tooltip title="Сбросить статус подтверждения">
                                    <IconButton size="small" onClick={() => props.clearOnlineRoundsConfirmation(arb._id)}><CancelOutlined /></IconButton>
                                </Tooltip>}
                              </Buttons>}
                              />
                            
                      </FieldsView>
                    </div>

                    
                    <AvatarView>
                      <Avatar src={`/api/arbiter/photo/${arb._id}`} />
                    </AvatarView>
                </PopupView>
            </DialogContent>
        </Dialog>
    )
}

export default ArbiterPopup;
