import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';
import { Tile } from '../../../../toolympus/components/PowerDoc/plugins/Tiles/useTiles';
import OtherDocIcon from './icon-document.svg';
import { downloadFile } from '../../../../toolympus/api/core';

const TileImage = styled.img`
  width: 38px;
  height: auto;
`;

export const TileLink = styled.a`
    display: flex;
    flex-flow: row;
    align-items: center;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: black !important;
    gap: 15px;

    margin-bottom: 16px;
    
    ${props => props.theme.breakpoints.down('sm')} {
        margin-right: 0;
        margin-bottom: 8px;
    }

    & > span:first-child {
        flex-shrink: 0;
    }

    & > span {
        display: block;
    }
`;
TileLink.defaultProps = { target: "blank" };

export const LinksWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 30px;

    ${props => props.theme.breakpoints.down('sm')} {
        flex-flow: column;
        align-items: flex-start;
    }
`;
LinksWrapper.defaultProps = { className: 'DocumentsList' };

export const LinksTilesBlock = (props: RenderElementProps) => {
  const tiles: Tile[] = (props.element as any).tiles || [];
  const isProtectedFiles = (props.element as any).protected === "true";

  return <LinksWrapper>
    {tiles.map(t => (
      <TileLink href={t.link || ""} key={t.id} onClick={e => {
        if(isProtectedFiles && t.link) {
          e.preventDefault();
          e.stopPropagation();
          downloadFile(t.link);
        }
      }}>
        <TileImage src={t.image_url || OtherDocIcon}></TileImage>
        <span>{t.title}</span>
      </TileLink>))}
  </LinksWrapper>;
}
