import React from "react"
import styled from '@emotion/styled';
import { BasicPageWrapperOnlyOut } from "../../presentation/Util/BasicPageWrapper";
import { CmsPage } from "../DynamicPages/CmsPage";
import { NewItemProps } from "../../toolympus/api/useNewItem";
import { Buttons, FormGrid, LoadingIndicator, SectionTitle } from "../../toolympus/components/primitives";
import { Button, TextField, Typography } from "@mui/material";
import { RenderElementProps } from "slate-react";
import { extractCustomBlockFields } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { createProvided } from "../../toolympus/components/primitives/createProvided";
import { ContestState } from "../../typings/schema";
import { CaseQuestion, useTeamQuestionsData as useTeamQuestionsDataX } from "./useTeamQuestionsData";
import { Check } from "@mui/icons-material";
import isHotkey from "is-hotkey";


const TeamQuestionsData = createProvided(useTeamQuestionsDataX, {
  contestState: {} as ContestState,
  newQuestion: { item: {} as CaseQuestion, update: () => {}, save: () => {} } as any as NewItemProps<CaseQuestion, CaseQuestion>,
  isSubmittedSuccessfully: false,
  myQyestions: { data: [], isLoading: true, reload: () => {}, setData: () => {} },
  allQuestions: { data: [], isLoading: true, reload: () => {}, setData: () => {} },
  isLoading: true,
});

const useTeamQuestionsData = TeamQuestionsData.useValue;

const QuestionCard = styled(FormGrid)<{ idx?: number }>`
  box-shadow: 1px 0px 5px -2px #22222280;
  border-radius: 8px;
  padding: 0.25rem 0.75rem 0.5rem;
  background: ${props => props.theme.palette.grey[50]};
  position: relative;

  & > p {
    white-space: pre-line;
  }

  & .answer {
    margin-top: 1rem;
    padding-left: 1rem;
    border-left: 3px solid ${props => props.theme.palette.primary.main};
    font-style: italic;
  }

  &:before {
    display: ${props => props.idx ? "block" : "none"};
    content: '${props => props.idx}.';
    position: absolute;
    top: 7px;
    left: -24px;
    font-size: 0.85rem;
    color: ${props => props.theme.palette.text.secondary};
  }
`;
QuestionCard.defaultProps = { columns: "1fr" };


const QuestionForm = (props: RenderElementProps) => {
  const { contestState, newQuestion, isSubmittedSuccessfully, isLoading } = useTeamQuestionsData();
  const fields = extractCustomBlockFields(props.element);

  if(!contestState.case_questions_submission_allowed) {
    return null;
  }

  const disabled = !newQuestion.item || newQuestion.isLoading;
  
  return <FormGrid columns="1fr">
    {fields.title && <SectionTitle>{fields.title}</SectionTitle>}
    <TextField
      multiline
      value={newQuestion.item?.question || ""}
      onChange={e => newQuestion.update({ question: e.target.value })}
      disabled={disabled}
      label={fields.label}
      helperText={fields.hint}
      autoFocus
      onKeyPress={e => {
        if(isHotkey("ctrl+enter", e)) {
          newQuestion.save();
        }
      }}
      />

    <Buttons style={{ justifyContent: "flex-end" }}>
      {isSubmittedSuccessfully && <Check color="primary" />}
      {isLoading && <LoadingIndicator />}
      <Button
        color="primary"
        variant="contained"
        size="small"
        disabled={disabled || (newQuestion.item?.question?.length || 0) < 10}
        onClick={() => newQuestion.save()}>
        отправить
      </Button>
    </Buttons>
  </FormGrid>
}

const MyQuestions = (props: RenderElementProps) => {
  const { myQyestions } = useTeamQuestionsData();
  const fields = extractCustomBlockFields(props.element);

  return myQyestions.data.length === 0
    ? null
    : <FormGrid columns="1fr" gap="dense">
      {fields.title && <SectionTitle>{fields.title}</SectionTitle>}
      {myQyestions.data.map(q => <QuestionCard key={q._id}>
        <Typography>{q.question}</Typography>
      </QuestionCard>)}
    </FormGrid>
}

const Answers = (props: RenderElementProps) => {
  const fields = extractCustomBlockFields(props.element);
  const { contestState, allQuestions } = useTeamQuestionsData();

  if(!contestState.case_questions_answers_available) {
    return null;
  }

  return (!contestState.case_questions_answers_available || allQuestions.data.length === 0)
    ? null
    : <FormGrid columns="1fr" gap="dense">
      {fields.title && <SectionTitle>{fields.title}</SectionTitle>}
      {allQuestions.data.map((q,idx) => <QuestionCard key={q._id} idx={idx+1}>
        <Typography>{q.question}</Typography>
        <Typography className="answer">{q.answer}</Typography>
      </QuestionCard>)}
    </FormGrid>
}

const Blocks = {
  question_form: QuestionForm,
  my_questions: MyQuestions,
  answers: Answers,
}



export const TeamQuestionsPage = () => {
  return (<BasicPageWrapperOnlyOut>
    <TeamQuestionsData.Provider>
      <CmsPage slug="team-questions" customBlocks={Blocks}  />
    </TeamQuestionsData.Provider>
  </BasicPageWrapperOnlyOut>);
}
