import React, { ReactNode, useEffect, useMemo, useState } from "react";
import styled from '@emotion/styled';
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { useDialogState } from "../../util/useDialogState";
import { ConferencingSettings } from "./ConferencingControl";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { LoadingIndicator } from "../../toolympus/components/primitives/LoadingIndicator";
import { Conference, ConferenceDialogBase, useConferenceApiScript } from "./Conference";
import { VideocamOutlined } from "@mui/icons-material";

interface ConferenceRoomSettings extends ConferencingSettings {
    name: string;
    token: string;
}

interface ConferenceRoomX extends ConferenceRoomSettings {
    room_online_round: string;
    room_online_round_delib?: string;
    token_delib?: string;
}

interface ActiveRoom extends ConferenceRoomSettings {
    room: string;
}

const useConferenceRoomApiScriptExt = (room: ConferenceRoomX | null) => {
  const roomSettings = useMemo(() => {
    return room
      ? { ...room, room: room?.room_online_round }
      : { is_available: false, domain: "", name: "", room: "", room_online_round: "", token: "", token_secret: "" };
  }, [room]);
  useConferenceApiScript(roomSettings);
}

const useArbiterConference = (roundId: string) => {
    const settings = useLoadedData<ConferenceRoomX>(`/api/onlineround/${roundId}/arbiter/conference`, {} as ConferenceRoomX, !!roundId);
    const [room, setRoom] = useState<ActiveRoom | null>(null);

    useConferenceRoomApiScriptExt(settings.data);

    return {
        isLoading: settings.isLoading,
        isReady: !settings.isLoading && !!settings.data.token,
        connectNormal: () => {
            if(settings.data.token && settings.data.room_online_round) {
                setRoom({ ...settings.data, room: settings.data.room_online_round });
            }
        },
        connectDelib: () => {
            if(settings.data.token_delib && settings.data.room_online_round_delib) {
                setRoom({ ...settings.data, room: settings.data.room_online_round_delib, token: settings.data.token_delib });
            }
        },
        disconnect: () => setRoom(null),
        room,
    }
}

const Controls = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0;
`;

const ControlsTeam = styled(Controls)`
    justify-content: flex-start;
    gap: 0.5rem;
`;

const RoomConnectWrapper = styled.div`
    padding: 0.5rem 0;
    cursor: pointer;

    & > div:first-child {
        gap: 0.5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
    }
`;

const ArbiterConference = (props: { roundId: string }) => {
    const { isLoading, isReady, room, connectNormal, connectDelib, disconnect } = useArbiterConference(props.roundId);

    return <>
        {isLoading && <LoadingIndicator />}
        {isReady && (!room || !room.is_available) && <>
            <Controls>
                <RoomConnectWrapper onClick={connectNormal}>
                    <div>
                        <Typography>Комната слушаний</Typography>
                        <IconButton size="small" color="primary"><VideocamOutlined /></IconButton>
                    </div>
                    <Typography variant="caption">доступна всем участникам раунда</Typography>
                </RoomConnectWrapper>
                <RoomConnectWrapper onClick={connectDelib}>
                    <div>
                        <Typography>Комната арбитров</Typography>
                        <IconButton size="small" color="primary"><VideocamOutlined /></IconButton>
                    </div>
                    <Typography variant="caption">доступна только арбитрам</Typography>
                </RoomConnectWrapper>
            </Controls>
        </>}
        <Conference room={room} disconnect={disconnect} isModerator />
    </>;
}

const LS_TeamName_Key = "__moz__conf_team_name";

const useTeamConference = (roundId: string) => {
    const [name, setName] = useState<string>(localStorage.getItem(LS_TeamName_Key) || "");
    const [doload, setDoload] = useState<boolean>(false);
    const settings = useLoadedData<ConferenceRoomX>(`/api/onlineround/${roundId}/team/conference?name=${name}`, {} as ConferenceRoomX, !!roundId && doload);
    const [room, setRoom] = useState<ActiveRoom | null>(null);

    const roomSettingsLoad = useLoadedData<ConferenceRoomX>(`/api/onlineround/${roundId}/team/conference?name=placeholder`, {} as ConferenceRoomX, !!roundId);
    useConferenceRoomApiScriptExt(roomSettingsLoad.data);


    useEffect(() => {
        if(settings.data && settings.data.token) {
            setRoom({ ...settings.data, room: settings.data.room_online_round });
        }
    }, [settings.data]);

    return {
        isLoading: settings.isLoading,
        isReady: !settings.isLoading && !!settings.data.token,
        canConnect: name.length > 3,
        connect: () => {
            setDoload(true);
        },
        disconnect: () => setRoom(null),
        room,
        name,
        setName: (v: string) => {
            setName(v);
            localStorage.setItem(LS_TeamName_Key, v);
        },
    }
}

const TeamConference = (props: { roundId: string }) => {
    const { isLoading, room, name, setName, connect, canConnect, disconnect } = useTeamConference(props.roundId);

    return <>
        {(!room || !room.is_available) && <ControlsTeam>
            <TextField
                label="Введите ваше имя"
                value={name}
                onChange={e => setName(e.target.value)}
                />

            <Button size="small" variant="contained" color="primary"
                disabled={isLoading || !canConnect}
                onClick={connect}>
                подключиться
                {isLoading && <LoadingIndicator sizeVariant="s" />}
            </Button>
        </ControlsTeam>}
        <Conference room={room} disconnect={disconnect} isModerator />
    </>;
}



export const useAdminOnlineRoundConference = (roundId: string | undefined) => {
  const settings = useLoadedData<ConferenceRoomX>(`/api/onlineround/${roundId}/admin/conference`, {} as ConferenceRoomX, !!roundId);
  const [room, setRoom] = useState<ActiveRoom | null>(null);
  useConferenceRoomApiScriptExt(settings.data);

  return {
    isLoading: settings.isLoading,
    settings,
    isReady: !settings.isLoading && !!settings.data.token,
    connectNormal: () => {
        if(settings.data.token && settings.data.room_online_round) {
            setRoom({ ...settings.data, room: settings.data.room_online_round });
        }
    },
    connectDelib: () => {
        if(settings.data.token_delib && settings.data.room_online_round_delib) {
            setRoom({ ...settings.data, room: settings.data.room_online_round_delib, token: settings.data.token_delib });
        }
    },
    disconnect: () => setRoom(null),
    room,
  }
}


export const AdminConference = (props: { roundId?: string }) => {
  const { isLoading, isReady, room, connectNormal, connectDelib, disconnect } = useAdminOnlineRoundConference(props.roundId);

  return <>
      {isLoading && <LoadingIndicator />}
      {isReady && (!room || !room.is_available) && <>
          <Controls>
              <RoomConnectWrapper onClick={connectNormal}>
                  <div>
                      <Typography>Комната слушаний</Typography>
                      <IconButton size="small" color="primary"><VideocamOutlined /></IconButton>
                  </div>
                  <Typography variant="caption">доступна всем участникам раунда</Typography>
              </RoomConnectWrapper>
              <RoomConnectWrapper onClick={connectDelib}>
                  <div>
                      <Typography>Комната арбитров</Typography>
                      <IconButton size="small" color="primary"><VideocamOutlined /></IconButton>
                  </div>
                  <Typography variant="caption">доступна только арбитрам</Typography>
              </RoomConnectWrapper>
          </Controls>
      </>}
      <Conference room={room} disconnect={disconnect} isModerator />
  </>;
}



export interface OnlineRoundConferenceConnection {
    open: () => void;
    close: () => void;
    isOpen: boolean;
    popup: ReactNode;
}

export const useTeamOnlineRoundConference = (roundId: string, conferencingExternalLink: string | undefined): OnlineRoundConferenceConnection => {
    const dialogState = useDialogState();

    const popup = (
        <ConferenceDialogBase isOpen={dialogState.isShown} close={dialogState.hide} conferencingExternalLink={conferencingExternalLink}>
            <TeamConference roundId={roundId} />
        </ConferenceDialogBase>
    )

    return {
        open: dialogState.show,
        close: dialogState.hide,
        isOpen: dialogState.isShown,
        popup,
    }
}

export const useArbiterOnlineRoundConference = (roundId: string, conferencingExternalLink: string | undefined): OnlineRoundConferenceConnection => {
    const dialogState = useDialogState();

    const popup = (
        <ConferenceDialogBase isOpen={dialogState.isShown} close={dialogState.hide} conferencingExternalLink={conferencingExternalLink}>
            <ArbiterConference roundId={roundId} />
        </ConferenceDialogBase>
    )

    return {
        open: dialogState.show,
        close: dialogState.hide,
        isOpen: dialogState.isShown,
        popup,
    }
}

