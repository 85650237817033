import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import { Chat, SpecificChat, useSpecificChat } from '../../toolympus/components/Chats';
import { Chat as ChatT } from '../../toolympus/components/Chats/types';
import { FormControl } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { useParams } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import { ChatMessage } from './ChatMessage';

const isOrgChat = (c: ChatT) => c.kind === "org";

export const ChatWithOrg = () => {
    const data = useSpecificChat(isOrgChat, {
        kindForStart: "org",
        participantsForStart: [],
    });

    return <SpecificChatX
        data={data}
        createTip="Здесь вы можете отправить вопрос команде организаторов Конкурса" />;
}

export const OrgChatWithOrgPage = () => {
    const { id } = useParams<{ id: string }>();
    const data = useSpecificChat(c => isOrgChat(c) && c.participants && !!c.participants.find(p => p._id === id), {
        kindForStart: "org",
        participantsForStart: [id],
    });

    return <SpecificChatX
        data={data}
        createTip="Отправьте сообщение, чтобы начать чат с участником." />;
}

const StartChatWrapper = styled.div`
    & > div {
        display: flex;
        flex-flow: row;
        gap: 12px;
        align-items: flex-end;

        & > :nth-child(1) {
            flex-grow: 1;
        }
        & > :nth-child(2) {
            height: max-content;
        }
    }
`;

interface SpecificChatPageProps {
    data: SpecificChat;
    createTip: string;
}

const SpecificChatX = ({ data, createTip }: SpecificChatPageProps) => {
    const { messageForCreate, setMessageForCreate, chat, startChat } = data;
    
    return <>
        {chat &&
            <Chat
                hideTitleRow
                iconButton
                renderMessage={(m,an) => <ChatMessage key={m._id} message={m} authorName={an || ""} />}
                />}
        {!chat && (<StartChatWrapper>
            <Typography>{createTip}</Typography>
            <div>
                <FormControl
                    field="message"
                    schema={{ type: FieldType.textlong, label: "Введите сообщение..." }}
                    row={{ message: messageForCreate }}
                    onChange={(o,c) => setMessageForCreate(c.message)}
                    />
                <IconButton color='primary' onClick={startChat} disabled={messageForCreate.length < 10}><Send /></IconButton>
            </div>
        </StartChatWrapper>)}
    </>;
}

