import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { teamPagesTopPadding } from '../Util/common.styles';

export const EventsView = styled.div`
    margin-top: ${teamPagesTopPadding}px;
`

export const Title = styled(Typography)`
    font-size: 1.8em;
    margin: 12px;
    padding-left: 24px;

    @media(max-width: 600px){
        text-align: center;
        padding-left: 0;
    }
`

export const EventItemView = styled.div`
    padding: 12px;
    display: flex;

    @media(max-width: 600px){
        flex-direction: column;
    }
`
export const EventTextBody = styled.div`
    padding: 4px;

    
`

export const EventTitle = styled(Typography)`
    font-weight: 550;

    @media(max-width: 600px){
        padding-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`

export const EventDateView = styled.div`
    min-width: 150px;
    padding: 6px 0px 0px 24px;

    @media(max-width: 600px){
        padding: 6px 0 0 0;
    }
`

export const EventDateLabel = styled(Typography)`
    font-size: 1em;
    color: gray;
`

export const EventBody = styled(Typography)`
    margin-top: 4px;
`

export const TeamView = styled.div`
    padding: 0 3vw;
    @media (max-width: 600px) {
        padding: 0 12px;
        display: flex;
        justify-content: center;
    }
`;

export const ConfirmMessageView = styled.div`
    margin-right: 1rem;
    margin-top: 0.5rem;
`