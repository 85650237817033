import React, { ComponentType, PropsWithChildren } from 'react';
import LoadingPage from '../../presentation/Util/LoadingPage';
import { MozolinViewer } from './MozolinPageViewer';
import { useCmsPage } from './useCmsPage';
import { RenderElementProps } from 'slate-react';
import { PageContent } from '../../presentation/Util/common.styles';

interface Props {
  slug: string;
  wrapperClasses?: string;
  customBlocks?: Record<string, ComponentType<RenderElementProps>>;
  wrapperComponent?: ComponentType<PropsWithChildren<{}>>;
}

export const CmsPage = ({ slug, wrapperClasses, customBlocks, wrapperComponent }: Props) => {
  const data = useCmsPage(slug);

  const Wrapper = wrapperComponent || PageContent;

  
  if (data.isLoading) {
    return <LoadingPage />;
  } else {
      return (
        <Wrapper className={wrapperClasses}>
          <MozolinViewer
            content={data.data.content}
            update={() => {}}
            viewMode
            customBlocks={customBlocks}
            />
        </Wrapper>
      )
  }
}
