import ExcelJS from "exceljs";
import { Schema } from "../hooks/useSchema";
import { downloadBuffer } from "./core";


interface ExportRecordsConfig<T> {
  columns: string[];
  schema?: Schema;
  extraLabels?: Record<string, string>;
  sheetTitle?: string;
  filename?: string;
  prepare?: (r: T) => any;
}

export const exportRecordsToXlsxSheet = <T,>(cfg: ExportRecordsConfig<T>, records: T[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(cfg.sheetTitle || "Export");
  sheet.columns = cfg.columns.map(f => ({ header: (cfg?.schema || {})[f]?.label?.toString() || (cfg.extraLabels || {})[f] || f, key: f }));

  records.forEach(record => sheet.addRow(cfg.prepare
    ? cfg.prepare(record)
    : record));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", cfg.filename || "export.xlsx"));
}

export const importXlsxFile = <TRes,>(file: File, process: (wb: ExcelJS.Workbook) => TRes): Promise<TRes> => {
  return new Promise<TRes>((resolve,reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(e.target?.result as ArrayBuffer).then(wb => {
        try {
          if(e.target) {
            const result = process(wb);
            resolve(result);
          }
        } catch(e) {
          reject(e);
        }});
    });
    reader.readAsArrayBuffer(file);
  });
}


interface ImportRowsCfg {
  sheet?: number;
  ignoreFirstRow?: boolean;
}

export const importXlsxRows = <TRecord,>(file: File, processRow: (row: ExcelJS.Row, idx: number) => TRecord, cfg?: ImportRowsCfg): Promise<TRecord[]> => {
  return importXlsxFile(
    file,
    wb => {
      const sheet = wb.worksheets[cfg?.sheet || 0];
      const result: TRecord[] = [];
      sheet.eachRow((row, idx) => {
        if(!cfg?.ignoreFirstRow || idx > 1) {
          result.push(processRow(row, idx));
        }
      });
      return result;
    }
  )
}
