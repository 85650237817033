import { OnlineRoundsResult } from "../../presentation/Admin/OnlineRounds/useOnlineRoundsResults";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";

export const useOnlineRoundResults = () => {
    const data = useLoadedData<OnlineRoundsResult[]>('/api/contest/onlinerounds/result/public', []);
    
    return {
        results: data.data,
        isLoading: data.isLoading,
    };
}