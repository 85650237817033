import React, { useContext } from "react";
import { ContestState } from "../../typings/schema";
import { storeApiToken, decodeToken } from "../../util/tokenStorage";

export interface User {
    roles: string[];
    _id: string;
    email: string;
    teamnbr?: string;
}

export interface IUserContext {
    user: User;
    setUser: (u: User) => void;
    contestState?: ContestState;
    isContestStateLoaded?: boolean;
}

export const EmptyUser = { roles: [], _id: "", email: ""};

export const UserContext = React.createContext<IUserContext>({
    user: EmptyUser,
    setUser: (u: User) => {},
})


export const useUser = () => useContext(UserContext);

export const login = (context: IUserContext, token: string) => {
    storeApiToken(token);
    const decoded = decodeToken<User>(token);
    context.setUser(decoded);
    return decoded;
}

export const checkRole = (user: User, role: string) => (user?.roles || []).find(r => r === role) ? true : false;

export const checkContextRole = (context: IUserContext, role: string) => checkRole(context.user, role);