import { ComponentType, ReactNode } from 'react';
import { FieldSchema } from '../../hooks/useSchema';
import { fieldLabel, OnFieldChange } from './Common';
import { ValidationErrors } from './useValidationErrors';

export interface FormControlBaseProps {
    row: any;
    field: string;
    schema: FieldSchema;
}

export interface FormControlBase2Props extends FormControlBaseProps {
  onChange: OnFieldChange;
  errors?: ValidationErrors;
  error?: boolean;
}

interface KnownExtraProps {
    labelIdPrefix: string;
    showValues: boolean;
    visibleIf: (x:any) => boolean;
    disabled: boolean;
    readOnly: boolean;
    utcToLocal: boolean;
    format?: string;
    formatAsDate?: boolean;
    withArea: boolean;
    autoRows: boolean;
    autoComplete: boolean;
    hint: ReactNode;
    emptyLabel?: string;
    wrapperStyle?: React.CSSProperties;
    editable: boolean;
    controlProps?: any;
    cellStyle?: React.CSSProperties;
    slugSource?: string | null;
    processChange?: (v: any) => any;
    control?: ComponentType<FormControlBase2Props>;
    tableControl?: ComponentType<FormControlBaseProps>;
}

export type ExtraProps = Partial<KnownExtraProps> & Record<any, any>;

export interface DisplayProps extends FormControlBaseProps {
    config?: ExtraProps;
}

export interface FormControlProps extends FormControlBase2Props {
    extraProps?: ExtraProps;
}

interface ExpandedProps extends FormControlProps {
    label: string;
    value: any;
    controlProps?: any;
    isVisible: boolean;
}

export const expandProps = (props: FormControlProps): ExpandedProps => {
    const { row, field, schema, extraProps, error, errors } = props;

    const extraControlProps = {
        ...(extraProps?.disabled ? { disabled: true } : {}),
        ...(extraProps?.readOnly ? { readOnly: true } : {}),
    };
    const inputProps = { maxLength: (schema || {})["max-length"], minLength: (schema || {})["min-length"], ...(extraProps?.controlProps || {}) };
    const controlProps = { margin: "dense", ...extraProps?.controlProps, view: {...extraProps || {}}, ...extraControlProps, inputProps: inputProps };
    const hasError = error || (errors && errors?.fieldHasErrors(field)) || undefined;

    return {
        ...props,
        error: hasError,
        value: row[field],
        label: extraProps?.label || fieldLabel(schema, field),
        controlProps: controlProps,
        isVisible: extraProps?.visibleIf ? extraProps.visibleIf(row) as boolean : true,
    }
}
