import React from 'react';
import { CmsPage } from './CmsPage';
import { PublicPageWrapper } from '../../presentation/Util/common.styles';
import { CoachRegistrationForm } from './Registrations/CoachRegistrationForm';

const Blocks = {
  coach_registration_form: CoachRegistrationForm,
}

export const CoachRegistrationPage = () => {
  return <CmsPage
    slug="coach-registration"
    wrapperClasses="page-content"
    wrapperComponent={PublicPageWrapper}
    customBlocks={Blocks}
    />
}
