import React, { ReactNode } from 'react';

import {
    Switch,
    FormGroup,
    FormControlLabel,
    CircularProgress,
    TableHead,
    TableCell,
    Table,
    TableRow,
} from '@mui/material';
import { Match } from '../ContestControlContainer';
import { LoadingStub } from '../../LoadingStub';
import {
    PageContent,
    DangerButton,
    PrimaryButton, ProgressIndicator, FullWidthDatePicker
} from '../../../presentation/Util/common.styles';
import { ActionWithLoading } from '../../../util/useWithLoading';
import { ContestState } from '../../../typings/schema';
import { GenericTextField } from '../../../presentation/Util/UtilTextfields';
import styled from '@emotion/styled';
import { TimerAPI } from '../../../util/useTimerState';
import { MaintenanceEdit } from '../../../util/useMaintenance';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { ExportDocumentsData } from '../../DocumentsExport/useExportDocuments';
import { DocumentsExportSection } from '../../DocumentsExport/presentation/DocumentsExportPage';
import { NumberEdit } from '../../../toolympus/components/schemed/NumberEdit';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { ConflictTagManagement } from '../ConflictTagManagement';
import { ConferencingControl } from '../ConferencingControl';
import { ArchivesForm } from '../../Archives/ArchivesForm';
import { ConfirmationDialogProvider } from '../../../toolympus/components/primitives/ConfirmationDialog';
import { ControlsSectionLabel, ControlToggle } from './ContestControlPrimitives';
import { Buttons } from '../../../toolympus/components/primitives';
import moment from 'moment';


interface Props {
    contest: ContestState | null;
    updateContest: (_: ContestState) => Promise<ContestState>;
    matches: Match[] | null;
    isLoading: boolean;
    initMatching: ActionWithLoading;
    cancelMatching: ActionWithLoading;
    documentsExport: ExportDocumentsData;
    sendInvitesToArbiters: ActionWithLoading;
    timer: TimerAPI;
    maintenance: MaintenanceEdit;
}

const TimerView = styled.div`
    width: 400px;
    @media (max-width: 600px) {
        width: 100%;
    }
`

const TimerItemView = styled.div`
    margin-bottom: 1rem;
`


export const ContestControlPage: React.FC<Props> = (props: Props) => {
    const { contest, updateContest, matches, isLoading, initMatching, cancelMatching, maintenance } = props;
    const { timerInfo, updateTimer, postTimerUpdate } = props.timer;

    const tabsState = useTabsState([
        ["control", <Buttons>Состояние {isLoading && <CircularProgress size={25} />}</Buttons>],
        ["premoots", "Премуты"],
        ["documentsexport", "Выгрузка документов"],
        ["matchingclaims", "Матчинг: иски"],
        ["conflicts", "Теги конфликтов"],
        ["conferencing", "Настройки ВКС"],
        ["countdown", "Обратный отсчет"],
        ["maintenancenotice", "Сообщение о недоступности"],
        ["archives", "Архивирование и перезапуск"],
    ], undefined, 'tab');

    if (contest === null || matches === null) {
        return <LoadingStub />
    }

    return (
        <div>
            <TabsHeader state={tabsState} scrollable noMargin />
            
            {/* /admin/system/pages */}
            <PageContent>
                <TabPanel state={tabsState.forTab("control")}>
                    {([
                        ["_label_registrations", "Регистрация участников"],
                        ["registration_enabled", "Регистрация команд разрешена", [
                            ["Документация", "/admin/system/documentation"],
                            ["Условия конкурса", "/admin/system/terms"],
                            ["Редактирование страниц", "/admin/system/pages"]
                        ]],
                        ["arbiter_registration_enabled", "Регистрация арбитров разрешена"],
                        ["coach_registration_enabled", "Регистрация тренеров разрешена"],
                        ["volunteer_registration_enabled", "Регистрация волонтеров разрешена"],
                        
                        ["case_questions_submission_allowed", "Отправка вопросов по фабуле разрешена"],
                        ["case_questions_answers_available", "Ответы на вопросы по фабуле опубликованы"],
                        
                        ["_label_documents", "Загрузка документов"],
                        ["claims_upload_allowed", "Загрузка исков разрешена"],
                        ["claims_other_available", "Чужие иски доступны", [
                            ["Матчинг исков", "/admin/control?tab=matchingclaims"]
                        ]],
                        ["responses_upload_available", "Загрузка отзывов доступна (видят блок с отзывом)"],
                        ["responses_upload_allowed", "Загрузка отзывов разрешена (могут загружать отзыв)"],
                        
                        ["_label_onlinerounds", "Онлайн раунды"],
                        ["online_rounds_available", "Онлайн раунды доступны", [
                            ["Раунды", "/admin/onlinerounds"]
                        ]],
                        ["conferencing_enabled", "Видеоконференция включена", [
                            ["Настройки ВКС", "/admin/control?tab=conferencing"]
                        ]],
                        ["is_online_rounds_scoring_active", "Простановка оценок арбитрами разрешена", [
                            ["Оценочные листы", "/admin/system/scoring-sheets"]
                        ]],
                        ["onlinerounds_date_approval_enabled", "Согласование времени онлайн раундов доступно"],
                        ["onlinerounds_results_available", "Результаты онлайн раундов доступны"],
                        
                        ["_label_finals", "Финальные раунды"],
                        ["finals_available", "Финальные раунды доступны", [
                            ["Раунды", "/admin/finals"]
                        ]],
                        ["finals_results_available", "Результаты финальных раундов доступны"],

                        ["_label_misc", "Разное"],
                        ["team_certificates_available", "Сертификаты об участии доступны командам"],
                    ] as [string, string, [ReactNode, string][] | undefined][])
                        .map(([key, label, links]) => key.startsWith("_label")
                            ? <ControlsSectionLabel label={label} />
                            : (
                                <ControlToggle
                                    key={key}
                                    name={key}
                                    value={(contest as unknown as { [_: string]: boolean })[key]}
                                    update={v => updateContest({ ...contest, [key]: v })}
                                    disabled={isLoading}
                                    label={label}
                                    links={links}
                                    />
                    ))}
                </TabPanel>

                <TabPanel state={tabsState.forTab("premoots")}>
                    <FormGroup row key="premoots_application_available">
                            <FormControlLabel
                                control={<Switch
                                    color="primary"
                                    disabled={isLoading}
                                    checked={(contest as unknown as { [_: string]: boolean })["premoots_application_available"]}
                                    onChange={(e) => updateContest({ ...contest, premoots_application_available: e.target.checked })}
                                    name="premoots_application_available" />}
                                label="Подача заявок на участие в премутах разрешена"
                            />
                    </FormGroup>
                    <NumberEdit
                        field="premoots_max_applications"
                        row={contest}
                        schema={{ type: FieldType.number, label: "Максимум заявок на премут для команды"}}
                        onChange={(o,c) => updateContest({ ...o, ...c })}
                    />
                </TabPanel>

                <TabPanel state={tabsState.forTab("conflicts")}>
                    <ConflictTagManagement />
                </TabPanel>

                <TabPanel state={tabsState.forTab("conferencing")}>
                    <ConferencingControl />
                </TabPanel>
            
                <TabPanel state={tabsState.forTab("documentsexport")}>
                    <DocumentsExportSection {...props.documentsExport} />
                </TabPanel>
            
                <TabPanel state={tabsState.forTab("matchingclaims")}>
                    <div>
                        {matches.length === 0 && (
                            <PrimaryButton onClick={initMatching.action} disabled={initMatching.isLoading}>
                                <ProgressIndicator isShown={initMatching.isLoading} invertColor />запустить матчинг
                            </PrimaryButton>)}
                        {matches.length > 0 && (
                            <DangerButton onClick={cancelMatching.action} disabled={cancelMatching.isLoading}>
                                <ProgressIndicator isShown={cancelMatching.isLoading} />отменить матчинг
                            </DangerButton>)}
                    </div>
                    {matches.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableCell key="rownbr"></TableCell>
                                <TableCell key="source">Команда</TableCell>
                                <TableCell key="destination">Ее иски получает команда</TableCell>
                                <TableCell key="incoming">Она получает иски команды</TableCell>
                            </TableHead>
                            {matches.map(({ source, destination, incoming }, idx) => (
                                <TableRow key={source._id}>
                                    <TableCell key="rownbr">{idx + 1}.</TableCell>
                                    <TableCell key="source">{source.number}</TableCell>
                                    <TableCell key="destination">{destination.number}</TableCell>
                                    <TableCell key="incoming">{incoming.number}</TableCell>
                                </TableRow>
                            ))}
                        </Table>)}
                </TabPanel>

                <TabPanel state={tabsState.forTab("countdown")}>
                    <TimerView>
                        <TimerItemView>
                            <GenericTextField
                                required
                                value={timerInfo?.deadlineText}
                                onChange={val => updateTimer({ ...timerInfo, deadlineText: val })}
                                label="Текст до окончания"
                            />
                        </TimerItemView>
                        <GenericTextField
                            required
                            value={timerInfo?.finishedText}
                            onChange={val => updateTimer({ ...timerInfo, finishedText: val })}
                            label="Текст после окончания"
                        />
                        <TimerItemView>
                            <FullWidthDatePicker
                                label="Дата окончания"
                                value={moment(timerInfo?.deadlineTime) || null}
                                onChange={date => updateTimer({ ...timerInfo, deadlineTime: date?.format('YYYY-MM-DD') })}
                            />
                        </TimerItemView>
                        <TimerItemView>
                            <FormControlLabel
                                control={<Switch
                                    color="primary"
                                    checked={timerInfo?.isOn}
                                    onChange={e => updateTimer({ ...timerInfo, isOn: e.target.checked })}
                                    name='Включен' />}
                                label='Включен'
                            />
                        </TimerItemView>
                        <PrimaryButton
                            disabled={!timerInfo
                                || !timerInfo?.deadlineText
                                || !timerInfo?.deadlineTime
                                || !timerInfo?.finishedText
                                || timerInfo?.isOn === undefined ? true : false}
                            onClick={postTimerUpdate}
                        >
                            Сохранить
                        </PrimaryButton>
                    </TimerView>
                </TabPanel>

                <TabPanel state={tabsState.forTab("maintenancenotice")}>
                    <TimerView>
                        <TimerItemView>
                            <GenericTextField
                                required
                                value={maintenance.notice.text}
                                onChange={val => maintenance.updateNotice({ text: val })}
                                label="Текст сообщения"
                            />
                        </TimerItemView>
                        
                        <TimerItemView>
                            <FormControlLabel
                                control={<Switch
                                    color="primary"
                                    checked={maintenance.notice.isOn}
                                    onChange={e => maintenance.updateNotice({ isOn: e.target.checked })}
                                    name='Включено' />}
                                label='Включено'
                            />
                        </TimerItemView>
                        <PrimaryButton
                            disabled={!maintenance.notice.text && maintenance.notice.isOn}
                            onClick={() => maintenance.save()}
                        >
                            Сохранить
                        </PrimaryButton>
                    </TimerView>
                </TabPanel>


                <TabPanel state={tabsState.forTab("archives")}>
                    <ConfirmationDialogProvider>
                        <ArchivesForm />
                    </ConfirmationDialogProvider>
                </TabPanel>
            </PageContent>
        </div>
    )
}