import React from "react"
import { BasicPageWrapperOnlyOut } from "../../presentation/Util/BasicPageWrapper";
import { usePlayerRounds } from "../../toolympus/components/Contests/Grid/Parties/usePlayerRounds";
import { PartyRoundsList } from "../../toolympus/components/Contests/Grid/Parties/PartyRoundsList";
import { CmsPage } from "../DynamicPages/CmsPage";


const PlayerRounds = () => {
  const data = usePlayerRounds("/api/v2/final-rounds");
  return <PartyRoundsList data={data} />;
}

export const MyRoundsContainer = () => {
  return (<BasicPageWrapperOnlyOut>
    <CmsPage slug="team-finals" customBlocks={{ rounds: PlayerRounds }}  />
  </BasicPageWrapperOnlyOut>);
}
