import styled from '@emotion/styled';

export const LoginTitle = styled.div`
    font-size: 1.25em;
    font-style: italic;
    margin-bottom: 2rem;
    text-align: center;

    @media(max-width: 600px) {
        & > a {
            display: block;
        }
    }
`

export const SignupTextfieldView = styled.div`
    margin-bottom: 1rem;
`

export const LoginButtonView = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
`

export const BottomTipView = styled.div`
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
    color: #ABABAB;
`

export const LoginWrapper = styled.div`
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;  
    resize: both;
    overflow: auto;
    flex-direction: column;

    padding: 50px 20px;
`

export const LoginForm = styled.form`
    border-top: ${props => '7px solid ' + props.theme.palette.primary.main};
    padding: 10px 50px;

    ${props => props.theme.breakpoints.down('sm')} {
        padding: 0;

        & .Title {
            font-size: 24px;
        }

        & .Hint {
            font-size: 14px;
        }
    }
`;

LoginForm.defaultProps = { className: 'LoginForm' };
