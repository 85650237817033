import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { DialogState } from '../../../util/useDialogState';
import { DangerButton, ProgressIndicator } from '../../Util/common.styles';

const DialogLine = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;

    margin-top: 0.5rem;

    & > *:nth-child(2) {
        flex: 0 0 auto;
        margin-left: 2rem;
    }
`;

const Warning = styled(Typography)`
    color: ${props => props.theme.palette.error.dark};
    font-style: italic;
`;

interface Props {
    dialogState: DialogState;
    cancel: () => void;
    isCancelling: boolean;
    confirmationText: string;
}

export const CancelDialog = (props: Props) => {
    const { dialogState, cancel, isCancelling, } = props;
    const [confirmationText, setConfirmationText] = useState<string>("");

    const targetConfirmationText = props.confirmationText;

    return (
        <Dialog
            open={dialogState.isShown}
            onClose={dialogState.hide}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>
                Отменить матчинг для онлайн раундов
            </DialogTitle>
            <DialogContent>
                <DialogLine>
                    <div>
                        <Typography>
                            Чтобы отменить матчинг, введите слова "{targetConfirmationText}" в текстовое поле и нажмите кнопку.
                        </Typography>
                        <Warning>
                            Если вы это сделаете, отменить ничего уже не получится.
                        </Warning>
                    </div>
                </DialogLine>
                
                <DialogLine>
                    <TextField
                        value={confirmationText}
                        onChange={e => setConfirmationText(e.target.value)}
                        variant="outlined" />
                    <DangerButton
                        onClick={() => { cancel(); dialogState.hide(); }}
                        disabled={isCancelling || confirmationText !== targetConfirmationText}>
                        <ProgressIndicator isShown={isCancelling} invertColor />отменить матчинг
                    </DangerButton>
                </DialogLine>
            </DialogContent>
        </Dialog>);
}