import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { apiFetch, FetchTypes } from '../../toolympus/api/core';
import { Tag, useAllTags } from '../../toolympus/components/Tags';
import { RecordTagEditProps, RecordTagsEdit } from '../../toolympus/components/Tags/RecordTagsEditPopup';

interface Props {
    isOpen: boolean;
    close: () => void;
    submit: () => void;
    tagSelection: RecordTagEditProps;
}

const Popup = (props: Props) => {
    return (
        <Dialog open={props.isOpen} onClose={props.close}>
            <DialogContent>
                <Typography>Выберите теги для исключения арбитров</Typography>
                <RecordTagsEdit {...props.tagSelection} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={props.submit}>запустить</Button>
            </DialogActions>
        </Dialog>
    )
}

export const useOnlineRoundsArbsAutoassign = (onComplete?: () => void) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const { data: allTags } = useAllTags('/api/conflict/tag');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const submit = () => {
        setIsLoading(true);
        apiFetch('/api/contest/match/onlinerounds/auto-assign-arbitrators', FetchTypes.POST, { exclude: selectedTags.map(t => t._id) })
            .then(() => {
                setIsLoading(false);
                setIsActive(false);
                if(onComplete) {
                    onComplete();
                }
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    const tagSelection: RecordTagEditProps = {
        addNewTag: () => {},
        blockAddNew: true,
        selectedTags,
        addTag: t => setSelectedTags(ts => [...ts, t]),
        removeTag: t => setSelectedTags(ts => ts.filter(te => te._id !== t._id)),
        availableTags: allTags,
        clear: () => { },
    }

    return {
        isLoading,
        start: () => setIsActive(true),
        popup: <Popup isOpen={isActive} close={() => setIsActive(false)} submit={submit} tagSelection={tagSelection} />
    };
}
