import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoginWrapper } from '../presentation/Login/LoginPage.styles';
import { apiFetch, FetchTypes } from '../toolympus/api/core';
import { LoadingIndicator } from '../toolympus/components/primitives/LoadingIndicator';
import { useQuery } from '../toolympus/hooks/useQueryParamsState';

const useArbiterReject = () => {
    const [isDone, setIsDone] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const params = useQuery();
    const id = params.get("id");
    const code = params.get("code");

    useEffect(() => {
        if(id && id.length && code && code.length) {
            setIsLoading(false);
            apiFetch(`/api/arbiter/onlinerounds/reject/${id}?code=${code}`, FetchTypes.POST, null, { noAuth: true })
                .then(() => {
                    setIsLoading(false);
                    setIsDone(true);
                })
                .catch(() => {
                    setIsLoading(false);
                    setIsError(true);
                })
        }

    }, [id, code]);

    return {
        isDone,
        isError,
        isLoading,
    }
}

export const ArbiterORRejectPage = () => {
    const { isDone, isError, isLoading } = useArbiterReject();
    return (
        <LoginWrapper>
            {isLoading && <Typography>Отправляем отказ...</Typography>}
            {isLoading && <LoadingIndicator />}
            {isDone && <Typography>Мы получили информацию. Спасибо!</Typography>}
            {isError && <Typography>Что-то пошло не так. Пожалуйста, напишите нам на <a href="mailto:corp@mootcourt.ru">corp@mootcourt.ru</a></Typography>}
            {!isLoading && !isDone && !isError && <Typography>В ссылке отсутствует код. Попробуйте скопировать ссылку из письма или напишите нам на <a href="mailto:corp@mootcourt.ru">corp@mootcourt.ru</a></Typography>}
        </LoginWrapper>
    );
}
