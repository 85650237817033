import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { TimerInfo } from '../../typings/schema';

interface TermTimerProps {
    info: TimerInfo;
}

const getDaysDiff = (currentDate: Date, deadline: Moment) => {
    const momentDate = moment(currentDate);
    return deadline.diff(momentDate, 'days');
}

const getHoursDiff = (currentDate: Date, deadline: Moment) => {
    const momentDate = moment({
        year: deadline.year(),
        month: deadline.month(),
        day: deadline.date(),
        hour: currentDate.getHours(),
        minute: currentDate.getMinutes(),
        seconds: currentDate.getSeconds()
    });
    const diff = deadline.diff(momentDate)
    return moment.utc(diff).format('HH:mm:ss');
}

const getDaysWord = (days: number) => {
    if (days % 100 > 10 && days % 100 < 20) {
        return "дней";
    }
    else if (days % 10 === 1) {
        return "день";
    } else if (days % 10 > 1 && days % 10 < 5) {
        return "дня";
    } else {
        return "дней";
    }
}

const getDiffs = (date: Date, deadline: Moment) => {
    const daysDiff = getDaysDiff(date, deadline);
    const daysWord = getDaysWord(daysDiff);
    const hoursDiff = getHoursDiff(date, deadline);

    return { daysDiff, daysWord, hoursDiff };
}

const TermTimer: React.FC<TermTimerProps> = (props: TermTimerProps) => {
    const [time, setTime] = useState<Date>(new Date());
    const { info } = props;

    useEffect(() => {
        setInterval(() => setTime(new Date()), 1000)
    }, []);

    if (!info.deadlineText || !info.deadlineTime || !info.finishedText) {
        return <div></div>
    }

    const parseDeadlineText = (text: string, time: string) => 
        text.indexOf('{deadline}') > 0 ? 
            text.replace('{deadline}', time) : 
            (text + ` ${time}`);

    const deadline = moment(info.deadlineTime);
    const { daysDiff, daysWord, hoursDiff } = getDiffs(time, deadline);
    const timerText = daysDiff >= 0 ? parseDeadlineText(info.deadlineText, `${daysDiff} ${daysWord} ${hoursDiff}`) : info.finishedText;

    return (
        <>
            <span>{timerText}</span>
        </>
    )
}

export default TermTimer;