import styled from '@emotion/styled/macro';
import { PageContentSidePadding } from '../../Util/common.styles';

export const DocumentItemView = styled.div`
    padding: 1rem ${PageContentSidePadding};
`

export const DocumentPackTitle = styled.div`
    font-size: 1.5em;
    margin-bottom: 1rem;
    font-weight: 100;
`

export const DocumentItemDescription = styled.div`
    font-size: 1.2em;
    margin-bottom: 1rem;
`;

export const BottomDocumentText = styled.div`
    margin-bottom: 1rem;
    font-weight: 100;
`

export const BottomText = styled.div`
    padding-top: 4rem;
    text-align: center;
    font-style: italic;
`

export const SuitTitle = styled.div`
    font-weight: bold;
    margin-bottom: 0.5rem;
`

export const DropzoneView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    &:hover{
        cursor: pointer;
    }
`

export const SuitItemView = styled.div`
    min-width: 300px;
`

export const SuccesFileLoadView = styled.div`
    display: flex;
`;

export const LoadedFileTitle = styled.div`
    font-weight: 550;
`

export const DeleteButtonView = styled.div`
    padding-top: 0.8rem;
    color: #C93636;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px dotted #C93636;
    display: inline-block;
    &:hover{
        cursor: pointer;
    }
`

export const IconView = styled.div`
    margin-right: 0.3rem;
    font-size: 2.5rem;
    color: #4CAF50;
`



export type DocIconSize = 'small' | 'large';
interface DocIconProps {
    size?: DocIconSize;
}

export const DocIcon = styled.span<DocIconProps>`
    display: inline-block;
    background: url(/icons/icon-document.svg);
    background-size: cover;
    height: ${props => props.size === "small" ? 23 : 46}px;
    width: ${props => props.size === "small" ? 19 : 37.7}px;
    min-height: ${props => props.size === "small" ? 23 : 38}px;
    margin-right: 12px;
    margin-bottom: ${(props: DocIconProps) => props.size === 'small' ? 4 : 8}px;
    vertical-align: middle;
`;

export const DocLink = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333333 !important;
    margin: 0.25rem 2rem 0.25rem 0;
    font-size: 1.2rem;

    & > ${DocIcon} {
        margin-bottom: 0;
    }

    &:hover{
        cursor: pointer;
    }
`;
DocLink.defaultProps = { target: "blank" };

export const ReviewsList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-bottom: 1rem;

  ${props => props.theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr;
  }  
`;
ReviewsList.defaultProps = { className: 'ReviewsList' };

export const Review = styled.div`
    overflow: auto;
`;
Review.defaultProps = { className: 'Review' };
