import { downloadFile } from '../../util/apiFetch';
import { useActionWithLoading, ActionWithLoading } from '../../util/useWithLoading';

export interface ExportDocumentsData {
    exportClaims1: ActionWithLoading;
    exportClaims2: ActionWithLoading;
    exportResponses1: ActionWithLoading;
    exportResponses2: ActionWithLoading;
    isLoading: boolean;
}

const exportClaims = (key: string) => downloadFile(`/api/admin/documents/export/${key}`, `${key}.zip`);    


export const useExportDocuments = () => {
    const exportClaims1 = useActionWithLoading(
        () => exportClaims('claim1'));
    const exportClaims2 = useActionWithLoading(
        () => exportClaims('claim2'));
    const exportResponses1 = useActionWithLoading(
        () => exportClaims('response1'));
    const exportResponses2 = useActionWithLoading(
        () => exportClaims('response2'));

    return {
        exportClaims1,
        exportClaims2,
        exportResponses1,
        exportResponses2,
        isLoading: exportClaims1.isLoading || exportClaims2.isLoading || exportResponses1.isLoading || exportResponses2.isLoading,
    }
}
