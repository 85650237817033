import React, { useCallback, useEffect, useState } from 'react';
import ArbiterListPage from '../../presentation/Admin/ArbiterList/ArbiterListPage';
import { apiFetch, fetchGet, FetchTypes } from '../../util/apiFetch';
import { AdminArbiterInfo } from '../../typings/schema';
import LoadingPage from '../../presentation/Util/LoadingPage';
import { compareDate } from '../../presentation/Util/common';
import { useComments } from '../../components/Comments';

interface Props {

}

const getArbiterData = () => {
    return fetchGet<AdminArbiterInfo[]>('/api/admin/arbiter');
}

const setIsDuplicate = (id: string, isDuplicate: boolean) => {
    return apiFetch<AdminArbiterInfo>(`/api/admin/arbiter/${id}`, FetchTypes.PUT, { is_duplicate: isDuplicate });
}

const ArbiterListContainer: React.FC<Props> = (props: Props) => {
    const [arbiters, setArbiters] = useState<AdminArbiterInfo[]>([]);
    const comments = useComments('arbiter');

    const sortArbiters = (unsorted: AdminArbiterInfo[]) => (unsorted || []).sort((a, b) => compareDate(b.registered_datetime, a.registered_datetime));

    const reload = useCallback(() => {
      getArbiterData()
          .then(unsorted => sortArbiters(unsorted))
          .then(data => setArbiters(data));
    }, []);

    useEffect(() => {
      reload();
    }, [reload]);

    const toggleDuplicate = (id: string, isDuplicate: boolean) => {
        setIsDuplicate(id, isDuplicate)
            .then(updated => {
                let updatedArbs = arbiters?.slice();
                if (updatedArbs && updatedArbs.find(a => a._id === updated._id)){
                    updatedArbs[updatedArbs.findIndex(a => a._id === updated._id)] = updated;
                    setArbiters(updatedArbs);
                }
            })
    }

    const clearOnlineRoundsConfirmation = (id: string) => {
      apiFetch<AdminArbiterInfo>(`/api/admin/arbiter/${id}`, FetchTypes.PUT, { online_rounds_confirmation: null })
          .then(() => reload());
  }

    const deleteArbiter = (id: string) => {
        return apiFetch<void>(`/api/admin/arbiter/${id}`, FetchTypes.DELETE)
            .then(() => setArbiters(arbs => arbs.filter(a => a._id !== id)));
    }

    if (!arbiters) {
        return (<LoadingPage />);
    }
    return (
        <ArbiterListPage
            arbiters={arbiters}
            toggleDuplicate={toggleDuplicate}
            deleteArbiter={deleteArbiter}
            clearOnlineRoundsConfirmation={clearOnlineRoundsConfirmation}
            comments={comments}
        />
    )
}

export default ArbiterListContainer;