export const messages = {
    error: {
        volunteer_registration_disabled: "Регистрация волонтеров закрыта",
        coach_registration_disabled: "Регистрация тренеров закрыта",

        registration: {
            registration_disabled: "Регистрация отключена",
            email_occupied: "Такой email уже зарегистрирован",
            team_either_coach_or_no_coach: "Необходимо указать тренеров или отметить, что их нет",
        },

        certificates_not_available: "Сертификат недоступен",

        premoot: {
            premoot_not_found_for_organizer: "Премут не найден",
            registration_closed: "Регистрация на премуты закрыта",
            already_applied: "Вы уже подали заявку на участие в этом премуте",
            too_many_applications: "Вы уже подали максимум заявок на участие в премутах",
            document_not_found: "Документ не найден",
            organizer_already_organizes_other: "Этот пользователь уже является организатором другого премута",
        }
    },
}