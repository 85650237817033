import React from 'react';
import styled from '@emotion/styled';
import { FormatAlignJustifyOutlined, PrintOutlined } from '@mui/icons-material';
import { CMSMenu } from '../../toolympus/components/CMS';
import { PowerEditorBaseProps, PowerEditorBase } from '../../toolympus/components/PowerDoc';
import { PowerEditorSettingsProvider } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../../toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PanelPlugin } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tiles';
import { MediaLibProvider } from '../../toolympus/components/medialib';
import { PageContent } from '../../presentation/Util/common.styles';

const Icons = {
    cms_pages: <FormatAlignJustifyOutlined />,
    document_templates: <PrintOutlined />,
}

const PageContentWrapper = styled(PageContent)`
  padding: 0;

  & > .form-wrapper {
    border: none;
    box-shadow: none;
    padding: 0.5rem 2rem 4rem;
  }
`;


export const CMS = () => {
    return (
      <PageContentWrapper>
        <MediaLibProvider apiPath="/api/medialib" spaceId="1" getFilepath={mf => `/media/${mf.filename}`}>
            <PowerEditorSettingsProvider
                EditorComponent={MozolinEditorUnwrapped}>

                <CMSMenu apiPath="/api/cms/" icons={Icons} includeMediaLib />

            </PowerEditorSettingsProvider>
        </MediaLibProvider>
      </PageContentWrapper>
    );
}


export const MozolinEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const mediaFilesPlugin = useMediaFilesPlugin();

    return (
        <PowerEditorBase
            {...props}
            plugins={[
                mediaFilesPlugin,
                CustomFieldsBlockPlugin,
                TilesPlugin,
                ColumnsPlugin,
                PanelPlugin,
                ScriptPlugin,
                ExpanderPlugin,
                TablesPlugin,
            ]}
            />
    );
}

