import { ReactNode } from "react";
import { useSnackbar as usn } from 'notistack';

interface Snackbar {
    showError: (message: ReactNode) => void;
    showSuccess: (message: ReactNode) => void;
    showSaveSuccess: () => void;
}

export const useSnackbar = (): Snackbar => {
    const snack = usn();
    const duration = 3000;

    const showError = (m: ReactNode) => snack.enqueueSnackbar(m, { variant: 'error', autoHideDuration: duration });
    const showSuccess= (m: ReactNode) => snack.enqueueSnackbar(m, { variant: 'success', autoHideDuration: duration });

    return {
        showError,
        showSuccess,
        showSaveSuccess: () => showSuccess('Изменения сохранены'),
    }
}
