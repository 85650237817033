import React, { useState } from 'react';
import { ActionTriggersEditData, useActionTriggersEdit } from './useActionTriggersEdit';
import { Button, IconButton, IconButtonProps } from '@mui/material';
import { AllInclusive, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Dialog, DialogState, LoadingIndicator, Tooltip, useDialogState, useSaveable } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { ActionTriggerEditForm } from './ActionTriggerEditForm';

interface Props extends Omit<UnboundProps, "data" | "dialog"> {
  apiPath: string;
  triggers: string | string[];
}

export const ActionTriggerEditButton = (props: Props) => {
  const { apiPath, triggers, ...otherProps } = props;
  const dialog = useDialogState(false);
  const data = useActionTriggersEdit(props.apiPath, props.triggers, { noLoad: !dialog.isOpen });
  return <ActionTriggerEditButtonUnbound data={data} dialog={dialog} {...otherProps} />;
}

interface UnboundProps {
  data: ActionTriggersEditData;
  dialog: DialogState;
  buttonProps?: Partial<IconButtonProps>;
}

const KeepAllExpandedLSKey = "__tl_triggers_actions_editors_expanded";

export const ActionTriggerEditButtonUnbound = (props: UnboundProps) => {
  const { data, dialog, buttonProps } = props;

  const [keepAllExpanded, setKeepAllExpanded] = useState<boolean>(localStorage.getItem(KeepAllExpandedLSKey) === "t");
  const toggleKeepAllExpanded = () => {
    setKeepAllExpanded(!keepAllExpanded);
    localStorage.setItem(KeepAllExpandedLSKey, keepAllExpanded ? "f" : "t");
  }

  useSaveable(data);

  return (<>
    <Tooltip text_id="extras.actions.triggers.edit_triggers_actions_title">
      <IconButton size="small" {...(buttonProps || {})} onClick={() => dialog.open()}><AllInclusive /></IconButton>
    </Tooltip>
    <Dialog
      dialogTitle={<FormattedMessage id="extras.actions.triggers.edit_triggers_actions_title" />}
      {...dialog}
      titleActions={<>
        {(data.isLoading || data.isSaving) && <LoadingIndicator sizeVariant="s" />}
        <Button size="small" color={keepAllExpanded ? "primary" : "inherit"} startIcon={keepAllExpanded ? <VisibilityOutlined /> : <VisibilityOffOutlined />} onClick={() => toggleKeepAllExpanded()}>
          <FormattedMessage id="common.expand_all" />
        </Button>
      </>}
      actions={<>
        <Button onClick={() => dialog.close()}><FormattedMessage id="common.close" /></Button>
        {data.hasChanges &&
          <Button color="primary" variant="contained" disabled={data.isSaving} onClick={() => data.save()}>
            <FormattedMessage id="common.save" />
          </Button>}
      </>}
      fullWidth
      maxWidth="lg">

      <ActionTriggerEditForm
        data={data}
        keepAllExpanded={keepAllExpanded}
        />
    </Dialog>
  </>);
}
