import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, Divider, Typography, Menu, MenuItem, CircularProgress, } from '@mui/material';
import { PageHeader } from '../../presentation/Util/PageHeader';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { PremootData, usePremoot } from './premootParticipationData';
import { LinkView, PageContent, PageContentNoGutter } from '../../presentation/Util/common.styles';
import { useParams } from 'react-router-dom';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ApplicationStatus } from './ApplicationStatus';
import { CheckCircleOutline, MoreHoriz } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import { SuitItemView, SuccesFileLoadView, IconView, LoadedFileTitle, DeleteButtonView, DropzoneView } from '../../presentation/Team/Documents/DocumentsPage.styles';
import { useSnackbar } from '../Snackbar';
import { CmsPage } from '../DynamicPages/CmsPage';

interface Props {
    
}

const PremootMenu = ({ cancelApplication}: { cancelApplication: () => any }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const close = () => {
        setAnchorEl(null);
    };

    return <div>
        <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}><MoreHoriz /></IconButton>
        <Menu open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={close}>
            <MenuItem key="delete" onClick={() => cancelApplication()}>
                отменить заявку
            </MenuItem>
        </Menu>
    </div>
}

const DocumentsSectionWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.75rem;
    row-gap: 0.25rem;

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`;

const DocumentsSection = ({ data }: { data: PremootData }) => {
    const snack = useSnackbar();

    const doc = (kind: string, title: string, docPresent: boolean, isLoading: boolean) => (
        <SuitItemView>
            {docPresent || isLoading ?
                <SuccesFileLoadView>
                    <IconView>
                        {isLoading ?
                            <CircularProgress /> :
                            <CheckCircleOutline fontSize="inherit" />
                        }
                    </IconView>
                    <div>
                        <LoadedFileTitle title={title}>{title}</LoadedFileTitle>
                        <div>
                            {isLoading ?
                                'загружается'
                                :
                                <LinkView onClick={() => data.downloadDocument(kind)}>
                                    {'успешно загружен'}
                                </LinkView>}
                        </div>
                        {docPresent && <DeleteButtonView onClick={() => data.deleteDocument(kind)}>Удалить</DeleteButtonView>}
                    </div>
                </SuccesFileLoadView> :
                <Dropzone
                    onDrop={acceptedFiles => data.uploadDocument(kind, acceptedFiles[0])}
                    accept="application/pdf"
                    onDropRejected={() => snack.showError("Файл не подходит. Принимаются только файлы в формате PDF")}
                    multiple={false}
                >
                    {({ getRootProps, getInputProps }) => (
                        <DropzoneView {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Typography variant="subtitle1" style={{ fontWeight: 550, alignSelf: 'flex-start' }}>{title}</Typography>
                            <Typography>Кликните для загрузки или перетащите документ в это поле</Typography>
                        </DropzoneView>
                    )}
                </Dropzone>}
        </SuitItemView>
    );

    return (
        <DocumentsSectionWrapper>
            {doc("s1", "Позиция Истца", !!data.data.doc1, false)}
            {doc("s2", "Позиция Ответчика", !!data.data.doc2, false)}
        </DocumentsSectionWrapper>
    )
}


export const PremootPublicPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const premootData = usePremoot(id);
    const { data: premoot, isLoading, apply, cancelApplication, canApply } = premootData;

    return (
        <div>
            <PageHeader />
            <Divider />

            <PageContent>
                <ActionRow>
                    <Typography variant="h5" style={{ marginLeft: 0 }}>{premoot.title}</Typography>
                    {isLoading && <LoadingIndicator />}
                    <OccupyFreeSpace />
                    <ApplicationStatus premoot={premoot} />
                    {canApply && <Button color="primary" onClick={() => apply()}>участвовать</Button>}
                    {premoot.is_applied && <PremootMenu cancelApplication={cancelApplication} />}
                </ActionRow>
                <MarkdownDisplay text={premoot.full_description || ""} />
                <Divider />
                {premoot.is_applied && (
                  <CmsPage
                    slug="premoot-docs-upload"
                    customBlocks={{ documents_upload: () => <DocumentsSection data={premootData} />}}
                    wrapperComponent={PageContentNoGutter}
                    />
                )}
                
            </PageContent>
            
        </div>
    );
}
