import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import { BrushOutlined, Close, Send } from '@mui/icons-material'
import isHotkey from 'is-hotkey'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoadingIndicator } from '../primitives/LoadingIndicator'
import { Markdown } from '../schemed/Markdown'
import { ChatContainer, MessageEnterer, MessageItem, MessagesList } from './components'
import { useMessager } from './MessagerContext'
import { Message, Participant } from './types'
import { useMessageInputKeeper } from './useMessageInputKeeper'

interface RenderMessageCfg {
    editMessage?: (m: Message) => void;
}

type RenderMessageType = ((item: Message) => ReactNode) | ((item: Message, authorName?: string, cfg?: RenderMessageCfg) => ReactNode);

export interface ChatProps {
    hideTitleRow?: boolean;
    renderMessage?: RenderMessageType;
    iconButton?: boolean;
    icon?: ReactNode;
    allowMarkdownEditor?: boolean;
    allowEditMessage?: boolean;
}

export const Chat = ({ renderMessage, hideTitleRow, iconButton, icon, allowMarkdownEditor, allowEditMessage }: ChatProps) => {
    const {chat: { chat, sendMsg, editMsg }, chats: {setSelectedChat}} = useMessager();
    const chatId = chat?._id;

    const messageInputKeeper = useMessageInputKeeper();
    const [text, setTextX] = useState('');
    const setText = (t: string) => {
        setTextX(t);
        messageInputKeeper.update(chat?._id, t);
    }
    
    const [isEditMarkdownMode, setIsEditMarkdownMode] = useState<boolean>(false);
    const [editedMessage, setEditedMessage] = useState<Message | null>(null);
    
    useEffect(() => {
        if(chatId) {
            setTextX(messageInputKeeper.get(chatId));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId])


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => () => setSelectedChat(null), []);

    const lastMessageTime = chat?.messages.length ? chat?.messages[chat?.messages.length-1].time : "";

    const scrollRef = useRef(null);
    const scrollRef2 = useRef(null);
    useEffect(() => {
        if(lastMessageTime && lastMessageTime.length) {
            setTimeout(() => {
                if(scrollRef.current != null) {
                    // @ts-ignore 
                    scrollRef.current.scrollIntoView(({ behavior: "smooth" }));
                }
                if(scrollRef2.current != null) {
                    // @ts-ignore 
                    scrollRef2.current.scrollIntoView(({ behavior: "smooth" }));
                }
            }, 200);
        }
    }, [lastMessageTime]);

    if (!chat) {
        return <LoadingIndicator />
    }


    const participant = (id: string) => 
        (chat.participants.find(({_id}) => id === _id) as Participant || { _id: id, name: "" });

    const doSend = () => {
        if(text && text.length) {
            const resultText = isEditMarkdownMode ?  `!md ${text}` : text;
            if(!editedMessage) {
                sendMsg(resultText);
                setText("");
            } else {
                editMsg(editedMessage._id, resultText);
                setText("");
                setEditedMessage(null);
            }
        }
    }

    const startEditMessage = (m: Message) => {
        if(editedMessage && editedMessage._id === m._id) {
            setText("");
            setEditedMessage(null);
        } else {
            setEditedMessage(m);
    
            const isMd = !!m.text.startsWith("!md");
            setText(isMd ? m.text.substring("!md ".length) : m.text);
            setIsEditMarkdownMode(isMd);
        }
    }

    return <ChatContainer>
        {!hideTitleRow ?
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{chat.title}</Typography>

                <IconButton size="small" onClick={() => setSelectedChat(null)}>
                    <Close />
                </IconButton>
            </Box>
            : <div />}
        <MessagesList>
            {chat.messages.map(message => {
                const editMessage = allowEditMessage ? startEditMessage : undefined;

                return renderMessage ? 
                    renderMessage(
                        message,
                        participant(message.author_id).name,
                        { editMessage })
                    :
                    (<MessageItem
                        key={message._id}
                        authorName={participant(message.author_id).name}
                        message={message}
                        editMessage={editMessage}
                        />)}
            )}
            <div ref={scrollRef2} />
        </MessagesList>
        <MessageEnterer>
            {isEditMarkdownMode && <Markdown
                label={<FormattedMessage id="chat.typeMessage" />}
                value={text}
                setValue={v => setText(v)}
                onKeyDown={e => {
                    if (isHotkey("mod+Enter", e)) {
                        doSend();
                    }
                }}
                />}
            {!isEditMarkdownMode && (
            <TextField 
                fullWidth
                multiline 
                label={<FormattedMessage id="chat.typeMessage" />} 
                variant='outlined' 
                value={text} 
                onChange={e => setText(e.target.value)}
                onKeyDown={e => {
                    if (isHotkey("mod+Enter", e)) {
                        doSend();
                    }
                }}
                InputProps={{
                    endAdornment: allowMarkdownEditor && (
                        <IconButton size="small" color="primary" onClick={() => setIsEditMarkdownMode(true)}>
                            <BrushOutlined />
                        </IconButton>),
                }}
            />)}
            {!iconButton && (
                <Button color="primary" ref={scrollRef} size="small" disabled={!text} onClick={doSend}>
                    <FormattedMessage id={editedMessage ? "chat.edit" : "chat.send"} />
                </Button>)}
            {iconButton && <IconButton color="primary" ref={scrollRef} size="small" disabled={!text} onClick={doSend}>{icon || <Send />}</IconButton>}
        </MessageEnterer>
        
    </ChatContainer>
}