import { Button } from '@mui/material';
import React from 'react';
import { useDialogState } from '../../toolympus/components/primitives/Dialogs';
import { SimpleList } from '../../toolympus/components/primitives/SimpleList';
import { usePremootListManagement } from './premootData';
import { PremootOrganizersPopup } from './PremootOrganizersPopup';

interface Props {
    
}

export const PremootEditList = (props: Props) => {
    const data = usePremootListManagement();
    const organizersPopupState = useDialogState();

    return (
        <>
            <SimpleList
                title="Премуты"
                data={data}
                fields={[
                    ["title"],
                    ["brief_description"],
                    ["organizers_ids"],
                    ["is_published"],
                ]}
                linkFields={["title"]}
                getLinkValue={(p => `/admin/premoot/${p._id}`)}
                schema={data.schema}
                tabs={[["Все", "all"], ["Опубликованные", "published"]]}
                headerActions={<Button color="primary" onClick={organizersPopupState.open}>организаторы</Button>}
                />
            
            <PremootOrganizersPopup {...organizersPopupState} />
        </>
    );
}
