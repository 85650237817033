import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

export const Title = styled(Box)<{ noLine?: boolean }>`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${props => props.theme.palette.primary.main};
    padding-top: 15px;
    border-top: ${props => !props.noLine? '7px solid ' + props.theme.palette.primary.main : 'none'};
`;

Title.defaultProps = { className: 'Title' };

export const Anchor = styled.a`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    
    color: ${props => props.theme.palette.secondary.main};
    text-decoration: none;
`;

Anchor.defaultProps = { className: 'Anchor' };

export const Link = ({href, children}: {href: string, children: ReactNode}) => {
    const history = useHistory();

    return <Anchor onClick={e => {e.preventDefault(); history.push(href);}} href={href}>{children}</Anchor>
}

export const ItalicText = styled(Typography)`
    font-style: italic;

    @media(max-width: 600px) {
        & > a {
            display: block;
        }
    }
`
