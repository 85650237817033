import React, { ReactNode } from 'react';
import {
    AccountBalance,
    Description,
    Group,
    HelpOutline,
    SportsHockey,
    SportsSoccer,
    StarOutlined,
    School,
    EmojiEvents,
} from '@mui/icons-material';
import { TeamContainer } from '../../containers/team/TeamContainer';
import ConfirmEmailContainer from '../../containers/team/ConfirmEmailContainer';
import { OnlineRoundContainer } from '../../containers/team/OnlineRoundContainer';
import { OnlineRoundResultsContainer } from '../../components/OnlineRoundResults/OnlineRoundResultsContainer';
import { MyRoundsContainer } from '../../components/MyRounds/MyRoundsContainer';
import { IUserContext } from '../UserContext/UserContext';
import { PremootPublicListPage, PremootPublicPage, } from '../../components/Premoot';
import { QuestionsPage } from '../../components/Chats/QuestionsPages';
import { OrgChatUnreadIndicator } from '../../components/Chats/ChatsUnreadIndicator';
import TermsTeamSection from '../Terms/TermsTeamSection';
import { DocumentsPage } from '../Team/Documents/DocumentsPage';
import { TeamFinalsResultsPage } from '../../components/MyRounds/PublicFinalsResultsPage';
import { TeamQuestionsPage } from '../../components/Questions/TeamQuestionsPage';

export interface RouteDef {
    path: string;
    component: (props: any) => JSX.Element | null;
    title: ReactNode;
    icon: ReactNode;
    hidden?: boolean;
    hiddenFn?: (u: IUserContext) => boolean
}

export const TeamRoutes = [
    { path: '', component: TermsTeamSection, title: 'Главная', icon: <AccountBalance />, hidden: false },
    { 
        path: 'results',
        component: TeamFinalsResultsPage,
        title: 'Результаты конкурса',
        icon: <EmojiEvents />,
        hidden: false,
        hiddenFn: (user: IUserContext) => !user.contestState?.finals_results_available,
    },
    {
        path: 'final-rounds',
        component: MyRoundsContainer,
        title: 'Мои раунды',
        icon: <SportsSoccer />,
        hiddenFn: (user: IUserContext) => !user.contestState?.finals_available,
},
    { path: 'team', component: TeamContainer, title: 'Команда', icon: <Group />, hidden: false },
    { path: 'docs', component: DocumentsPage, title: 'Документы', icon: <Description />, hidden: false },
    { path: 'premoot', component: () => <PremootPublicListPage kind="team" />, title: 'Премуты', icon: <School />, hidden: false, },
    { path: 'premoot/:id', component: () => <PremootPublicPage />, title: 'Премут', icon: <School />, hidden: true, },
    { 
        path: 'onlineround',
        component: OnlineRoundContainer,
        title: 'Онлайн раунд',
        icon: <SportsHockey />,
        hiddenFn: (user: IUserContext) => !user.contestState?.online_rounds_available,
    },
    {
        path: 'results-online', 
        component: OnlineRoundResultsContainer, 
        title: 'Результаты онлайн раундов',
        icon: <StarOutlined />,
        hiddenFn: (user: IUserContext) => !user.contestState?.onlinerounds_results_available
    },
    { path: 'confirm-email', component: ConfirmEmailContainer, title: 'Подтверждение email', icon: <Description />, hidden: true },
    { path: "case-questions", component: TeamQuestionsPage, title: "Вопросы по фабуле", icon: <HelpOutline /> },
    { path: 'questions', component: QuestionsPage, title: <>Чат с организатором<OrgChatUnreadIndicator /></>, icon: <HelpOutline /> },
] as RouteDef[];