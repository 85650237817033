export interface Message {
    author_id: string;
    chat_id: string;
    text: string;
    time: string;
    _id: string;
}

export interface Chat {
    _id: string;
    kind: string;
    title: string;
    participants: Participant[];
    last_message: Message;
    has_unread: boolean;
}

export type FullChat = Chat & {
    messages: Message[]
}

export type Participant = {
    _id: string,
    name: string,
    avatar_url: string
}

export type User = {
    _id: string;
    email: string;
}

export type SelectUserDialogType = <T extends {_id: string}>(
    props: {
        list: T[];
        disabled?: (id: string) => boolean;
        select: (id: string) => void;
        label: (item: T) => string | JSX.Element
    }
) => JSX.Element;

export enum MessageType {
    message = "message",
    edit_message = "edit-message",
    chats = "chats",
    chat = "chat",
}
