import { downloadFile } from "../../toolympus/api/core"
import { useAction } from "../../toolympus/api/useAction"
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../toolympus/hooks/useSchema";

export const useTeamCertificate = (loadAvailability?: boolean) => {
  const apiPath = "/api/team/certificate";
  const { data: availability } = useLoadedData<{ certificate_available: boolean }>(`${apiPath}/check`, { certificate_available: false }, loadAvailability || false);
  const forTeam = useAction<void>(
    () => downloadFile(apiPath, "Сертификат Мозолин.pdf"),
  );

  const forAdmin = useEditItem2<CertificateSettings>({
    getApiPath: () => "",
    save: (item) => {
      const params = Object.entries({ names: item.names, "template-slug": item.template }).filter(([k,v]) => !!v).map(([k,v]) => `${k}=${v}`).join("&");

      const apiPath = item.coach === undefined
        ? `/api/admin/team/${item.partyId}/certificate?${params}`
        : `/api/admin/team/${item.partyId}/certificate-coach?${params}&coach=${item.coach}`

      return downloadFile(apiPath, "Сертификат Мозолин.pdf")
        .then(() => item);
    }
  });

  const downloadCoachCertificate = (coachIdx: number) => downloadFile(`/api/team/certificate-coach?coach=${coachIdx}`, "Сертификат Мозолин.pdf");

  return {
    forTeam,
    forAdmin,
    downloadCoachCertificate,
    isAvailabile: availability.certificate_available,
  }
}


export interface CertificateSettings {
  template?: string;
  names?: string;
  coach?: number;
  partyId?: string;
}

export const CertificateSettingsSchema: Schema = {
  names: { label: "Заменить имя", type: FieldType.textlong  },
  template: { label: "Заменить шаблон",  },
}

export const useArbitratorCertificate = (loadAvailability?: boolean) => {
  const apiPath = "/api/arbiter/certificate";
  const { data: availability } = useLoadedData<{ certificate_available: boolean }>(`${apiPath}/check`, { certificate_available: false }, loadAvailability || false);
  const forArbitrator = useAction<void>(
    () => downloadFile(apiPath, "Сертификат Мозолин.pdf"),
  );

  const forAdmin = useEditItem2<CertificateSettings>({
    getApiPath: () => "",
    save: (item) => {
      const params = Object.entries({ names: item.names, "template-slug": item.template }).filter(([k,v]) => !!v).map(([k,v]) => `${k}=${v}`).join("&");
      return downloadFile(`/api/admin/arbiter/${item.partyId}/certificate?${params}`, "Сертификат Мозолин.pdf")
        .then(() => item);
    }
  });

  return {
    forArbitrator,
    forAdmin,
    isAvailabile: availability.certificate_available,
  }
}

