import React from 'react';
import { FullWidthTextField } from './common.styles';

interface Props {
    required?: boolean;
    label?: string;
    defaultValue?: string;
    value?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    type?: string;
    error?: boolean;
    style?: React.CSSProperties;
    multiline?: boolean;
}

export const GenericTextField: React.FC<Props> = (props: Props) => {
    const required = props.required !== undefined ? props.required : true;
    const isError = props.error !== undefined ? props.error : false;
    const onChange = (value: string) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    return (
        <FullWidthTextField
            disabled={props.disabled}
            required={required}
            error={isError}
            variant="outlined"
            label={props.label}
            onChange={e => onChange(e.target.value)}
            defaultValue={props.defaultValue}
            value={props.value}
            type={props.type ? props.type : 'input'}
            multiline={props.multiline}
        />
    )
}
