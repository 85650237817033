export const readBlobAsDataURL = (blob: Blob): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve((e.target.result as string) || undefined);
            }
        }
        reader.readAsDataURL(blob);
    });
};