import React from 'react';
import { TextField, Button } from '@mui/material';
import { useInviteSignup } from '../../toolympus/hooks/auth';
import { LoginWrapper, LoginForm, SignupTextfieldView, LoginButtonView, } from '../Login/LoginPage.styles';
import {  Title } from '../../Common/Primitives';
import { GenericTextField } from '../Util/UtilTextfields';
import { useUser, login } from '../UserContext/UserContext';
import { getApiToken } from '../../util/tokenStorage';
import { useHistory } from 'react-router-dom';

interface Props {
    
}

export const SignupInvitePage = (props: Props) => {
    const data = useInviteSignup({
        signupApiPath: '/api/signup-invite',
        loginOnSuccess: true,

    });

    const userContext = useUser();
    const history = useHistory();

    const send = () => {
        return data.send().then(() => {
            const token = getApiToken();
            if(token) {
                login(userContext, token);
                history.push('/premoot-org/my-premoot');
            }
        });
    }

    return (
        <LoginWrapper>
            <LoginForm onSubmit={e => {e.preventDefault(); send()}}>
                <Title marginBottom="20px" noLine>Регистрация</Title>
                <SignupTextfieldView>
                    <GenericTextField
                        value={data.credentials.login}
                        label="Контактный email"
                        type="email"
                        disabled
                    />
                </SignupTextfieldView>
                <SignupTextfieldView>
                    <TextField
                        style={{ width: '100%' }}
                        required
                        value={data.credentials.password}
                        onChange={e => data.update({ ...data.credentials, password: e.target.value })}
                        variant="outlined"
                        type="password"
                        label="Пароль"
                    />
                </SignupTextfieldView>
                <SignupTextfieldView>
                    <TextField
                        style={{ width: '100%' }}
                        required
                        value={data.credentials.passwordRepeat}
                        onChange={e => data.update({ ...data.credentials, passwordRepeat: e.target.value })}
                        variant="outlined"
                        type="password"
                        label="Пароль еще раз"
                    />
                </SignupTextfieldView>

                <LoginButtonView>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!data.credentials.password || !data.credentials.passwordRepeat || data.credentials.password !== data.credentials.passwordRepeat}
                        type="submit"
                        fullWidth
                    >
                        Зарегистрироваться
                    </Button>
                </LoginButtonView>
            </LoginForm>
        </LoginWrapper>
    );
}
