import React, { useMemo } from 'react';
import { ArbitratorScoringStatsExt, OnlineRoundsList } from '../../../containers/admin/useOnlineRoundsList';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check, GetAppOutlined } from '@mui/icons-material';
import { FailureTableCell, SuccessTableCell } from './OnlineRoundsListPage';
import { ButtonOptionPicker, OccupyFreeSpace, PseudoLink2, useCopyText, useTabsState } from '../../../toolympus/components/primitives';
import { TableControls } from '../Admin.styles';
import ExcelJS from "exceljs";
import { downloadBuffer } from '../../../toolympus/api/core';

interface Props {
  roundsData: OnlineRoundsList;
}

const exportArbsStats = (items: ArbitratorScoringStatsExt[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Арбитры ОР");
  sheet.columns = [
    { header: "Имя", key: "firstname" },
    { header: "Фамилия", key: "lastname" },
    { header: "E-mail", key: "email" },
    { header: "Все оценки", key: "is_scored" },
    { header: "Документы", key: "docs_scored" },
    { header: "Слушания", key: "perfs_scored" },
  ];

  items.forEach(item => sheet.addRow({
    email: item.arbitrator?.info?.email || "",
    firstname: item.arbitrator?.info?.firstname || "",
    lastname: item.arbitrator?.info?.lastname || "",
    is_scored: item.isScored ? "да" : "",
    docs_scored: `${item.scoredDocs} / ${item.totalDocs}`,
    perfs_scored: `${item.scoredPerf} / ${item.totalPerf}`,

  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `Мозолин Арбитры ОР ${new Date().getFullYear()}.xlsx`));
}

export const OnlineRoundsArbitratorsScoringStatus = (props: Props) => {
  const copyText = useCopyText();

  const tabs = useTabsState([
    ["all", "Все"],
    ["unscored", "Не оценившие"],
  ], "all");

  const list = useMemo(() => {
    if(tabs.current === "unscored") {
      return props.roundsData.arbitratorsScoringData.filter(a => !a.isScored);
    } else {
      return props.roundsData.arbitratorsScoringData;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.current, props.roundsData.arbitratorsScoringData])

  return (<>
    <TableControls>
      <ButtonOptionPicker
        options={[
          ["Все", "all"],
          ["Не оценившие", "unscored"],
        ]}
        selected={tabs.current}
        setSelected={v => tabs.setCurrent(v as string)}
        />
        
        <OccupyFreeSpace />
        <Button size="small" startIcon={<GetAppOutlined />} color="primary" onClick={() => exportArbsStats(list)}>
            выгрузить
        </Button>
    </TableControls>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='left'></TableCell>
            <TableCell align='left'>Арбитр</TableCell>
            <TableCell align='left'>E-mail</TableCell>
            <TableCell align='center'>Все оценки</TableCell>
            <TableCell align='center'>Оценки по документам</TableCell>
            <TableCell align='center'>Оценки по слушаниям</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((a,idx) => (
            <TableRow key={a.arbitrator_id}>
              <TableCell align="left">
                {idx+1}.
              </TableCell>
              <TableCell align="left">
                {a.arbitrator?.info?.firstname} {a.arbitrator?.info?.lastname}
              </TableCell>

              <TableCell align="left">
                <PseudoLink2 onClick={() => copyText(a.arbitrator?.info?.email || "")}>
                  {a.arbitrator?.info?.email}
                </PseudoLink2>
              </TableCell>
              
              {a.isScored
                ? <SuccessTableCell align="center"><Check htmlColor="" /></SuccessTableCell>
                : <TableCell align="center" />}

              
              {a.scoredDocs === a.totalDocs ?
                  <SuccessTableCell align='center'>{a.scoredDocs} / {a.totalDocs}</SuccessTableCell> : 
                  <FailureTableCell align='center'>{a.scoredDocs} / {a.totalDocs}</FailureTableCell>}
              {a.scoredPerf === a.totalPerf ?
                  <SuccessTableCell align='center'>{a.scoredPerf} / {a.totalPerf}</SuccessTableCell> : 
                  <FailureTableCell align='center'>{a.scoredPerf} / {a.totalPerf}</FailureTableCell>}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}
