import { useEffect, useMemo } from "react";
import { generateCode } from "../PowerDoc/plugins/common";
import { useActionsConfig } from "./ActionsConfigProvider";
import { ActionInstance } from "./actions.types";

export const useActionEdit = (item: ActionInstance, updateItem: (c: Partial<ActionInstance>) => void) => {
  const { actions, schema, ensureLoaded } = useActionsConfig();

  useEffect(() => {
    ensureLoaded();
  }, [ensureLoaded]);

  const actionConfig = useMemo(() => {
    return actions.find(a => a.action_type === item.action_type);
  }, [actions, item.action_type]);

  const action = item;
  const params = action.action_params || {};

  const update = (changes: Partial<ActionInstance>) => {
    const copy = { ...changes };
    if(changes.action_type && changes.action_type !== action.action_type) {
      const newConfig = actions.find(a => a.action_type === changes.action_type);
      if(newConfig) {
        copy.action_params = (newConfig.params || []).reduce(
          (r,param) => param?.default_value ? { ...r, [param.code]: param.default_value} : r,
          {});
      }
    }
    updateItem(copy);
  }
  
  const updateNestedActions = (updated: ActionInstance[]) => update({ action_params: { ...(item.action_params || {}), actions: updated }});

  const pasteAction = () => {
    getActionFromClipboard()
      .then(a => {
        if(a) {
          updateNestedActions([...item.action_params?.actions || [], a]);
        }
      })
  }

  return {
    item,
    params,
    schema,
    actionConfig,
    update,
    pasteAction,
    updateNestedActions,
  }
}

export const copyActionToClipboard = (action: ActionInstance) => {
  const copy = { ...action };
  copy._id = generateCode();
  const text = JSON.stringify({ _tl_action_copy: copy});
  navigator.clipboard.writeText(text);
}

export const getActionFromClipboard = (): Promise<ActionInstance | null> => {
  return navigator.clipboard.readText()
    .then(text => {
      if(text.includes("_tl_action_copy")) {
        const data = JSON.parse(text);
        const action = data._tl_action_copy as ActionInstance;
        if(action) {
          action._id = generateCode();
          return action;
        }
      }
      return null;
    });
}
