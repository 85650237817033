import { apiFetch, downloadFile, FetchTypes } from "../../../toolympus/api/core";
import { EditItemProps, useEditItem } from "../../../toolympus/api/useNewItem";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { Schema, useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useActionWithLoading } from "../../../util/useWithLoading";

export interface OnlineRoundsResult {
    team_id: string;
    team_nbr: string;
    has_unscored: boolean;
    documents: number;
    performance: number;
    total: number;
    penalty: number;
    bonus: number;
    penalty_comment?: string;
    result: number;
    finals_stage?: string | null;
}

export interface OnlineRoundsResultsData {
    data: OnlineRoundsResult[];
    isLoading: boolean;
    isCalced: boolean;

    recalc: () => void;
    exportResults: () => void;
    isActionLoading: boolean;

    penaltyEdit: EditItemProps<OnlineRoundsResult>;
    updateFinalsStage: (team_id: string, stage: 'rating' | null) => void;

    schema: Schema;
}

const ApiPath = "/api/contest/onlinerounds/result";

export const useOnlineRoundsResults = (): OnlineRoundsResultsData => {
    const data = useLoadedData<OnlineRoundsResult[]>(ApiPath, []);

    const recalc = useActionWithLoading(() => apiFetch<OnlineRoundsResult[]>(ApiPath, FetchTypes.POST).then(x => data.setData(x)));
    const exportResults = useActionWithLoading(() => downloadFile(`${ApiPath}/export`, "onlinerounds.xlsx"));

    const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

    const penaltyEdit = useEditItem<OnlineRoundsResult>(ApiPath, "team_id");

    const updateFinalsStage = (team_id: string, stage: 'rating' | null) => {
        return apiFetch(`/api/admin/team/${team_id}`, FetchTypes.PUT, { finals_stage: stage })
            .then(() => data.reload());
    }


    return {
        ...data,
        isCalced: !data.isLoading && data.data.length > 0,

        recalc: recalc.action,
        exportResults: exportResults.action,
        isActionLoading: recalc.isLoading || exportResults.isLoading,

        schema,
        penaltyEdit: {
            ...penaltyEdit,
            save: () => { return penaltyEdit.save().then(x => { data.reload(); return x; }); }
        },
        updateFinalsStage,
    }
}