import React, { ReactNode } from 'react';
import { MessagerProvider } from '../../toolympus/components/Chats';
import { socketByToken } from '../../toolympus/hooks/useWebsocket';
import { isAuthenticated } from '../../util/tokenStorage';

interface Props {
    children?: ReactNode;
}

export const ChatsProvider = ({ children }: Props) => {
    const isAuth = isAuthenticated();
    return (
        <MessagerProvider
            getSocket={socketByToken('/chat/token', token => 'chat/channel?token=' + token)}
            dontConnect={!isAuth}>
            {children}
        </MessagerProvider>
    );
}
