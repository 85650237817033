import React, { useState } from 'react';
import SignupPage from '../presentation/Signup/SignupPage';
import { TeamSignupData, ValidationErrors } from '../typings/schema';
import { defaultTeamSignupData } from '../util/constants';
import { FetchTypes, apiFetch, ValidationError } from '../util/apiFetch';
import { apiLogin } from './LoginContainer';
import { useHistory } from 'react-router-dom';
import { handleValidationError } from '../util/common';
import { useUser, login } from '../presentation/UserContext/UserContext';

interface Props {

}

interface SignupContainerState {
    isLoading: boolean;
    errors?: ValidationErrors;
}

const defaultSignupContainerState: SignupContainerState = { isLoading: false };

export const SignupContainer: React.FC<Props> = (props: Props) => {
    const [signupData, setSignupData] = useState<TeamSignupData>(defaultTeamSignupData);
    const [containerState, setContainerState] = useState<SignupContainerState>(defaultSignupContainerState);
    const history = useHistory();
    const userContext = useUser();

    const onSignup = (data: TeamSignupData) => {
        setSignupData(data);
        setContainerState({ ...containerState, isLoading: true, errors: {} })
        apiFetch("/api/team/register", FetchTypes.POST, data, { noAuth: true })
            .then(response => {
                apiLogin(data.email, data.password)
                    .then(token => login(userContext, token))
                    .then(() => history.push('/team'))
            })
        .catch(error => {
            if (error instanceof ValidationError) {
                const caught = error.caughtErrors;
                const parsedErrors = handleValidationError(caught);
                setContainerState({ ...containerState, isLoading: false, errors: parsedErrors });
                throw error;
            }
            else {
                setContainerState({ ...containerState, isLoading: false });
                throw error;
            }
        })
    }

    return (
        <SignupPage
            signupData={signupData}
            onSignup={onSignup}
            isLoading={containerState.isLoading}
            errors={containerState.errors}
        />)
}