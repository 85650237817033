import React from 'react'
import { CircularProgress } from '@mui/material'
import { LoadingPageView } from './common.styles';

interface Props {
}

const LoadingPage: React.FC<Props> = (props: Props) => {

    return (
        <LoadingPageView>
            <CircularProgress/>
        </LoadingPageView>
    )
}

export default LoadingPage;