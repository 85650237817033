import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@mui/material';
import { OnlineRoundsConfirmation } from './useOnlineRound';
import SuccessButton from '../../presentation/Util/SuccessButton';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: 1rem;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: center;
`;

const CancelButton = styled(Button)`
    color: #fff;
    background: ${props => props.theme.palette.error.main};
`;

interface Props {
    confirmation: OnlineRoundsConfirmation;
}

export const ArbiterORConfirmation = (props: Props) => {
    const { confirm, reject, isAnswered, isLoaded, isProcessing } = props.confirmation;
    return !isAnswered && isLoaded ? (
            <Wrapper>
                <Typography>Пожалуйста, подтвердите участие в онлайн раундах</Typography>
                <SuccessButton size="medium" onClick={confirm} disabled={isProcessing}>Подтвердить</SuccessButton>
                <CancelButton variant="contained" onClick={reject} disabled={isProcessing}>Отказаться</CancelButton>
                {isProcessing && <LoadingIndicator />}
            </Wrapper>
        )
        : <></>;
}
