import React from 'react'
import { SuccessButtonView } from './common.styles';

interface Props {
    title?: string;
    disabled?: boolean;
    width?: string;
    size?: 'large' | 'medium' | 'small';
    onClick?: () => void;
    type?: "button" | "submit" | "reset" | undefined;
    children?: JSX.Element | string
}

const SuccessButton: React.FC<Props> = (props: Props) => {

    return (
        <SuccessButtonView
            disabled={props.disabled}
            variant="contained"
            size={props.size || "large"}
            color="primary"
            type={props.type}
            width={props.width}
            onClick={props.onClick}
        >
            {props.title || props.children}
        </SuccessButtonView>
    )
}

export default SuccessButton;