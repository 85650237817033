import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from '../../toolympus/components/primitives/BaseThemeSettings';

export const MuiTheme = createTheme({
  typography: {
      fontFamily: ["Univers LT CYR"].join(',')
  },
  palette: {
      primary: {
          main: '#00AEEB',
          contrastText: '#ffffff',
      },
      secondary: {
          main: '#EA212E',
      },
  },
  components: {
    ...BaseMuiThemeComponentsSettings,
  },
});
