import React, { useState } from 'react';
import {
    Typography,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
} from '@mui/material';
import {
    SecurityView,
    SignupContainer,
    LoadedView,
    InformationTextView,
    RegistrationDisabled,
} from './SignupPage.styles';
import SuccessButton from '../Util/SuccessButton';
import { ArbitratorSignup, ValidationErrors } from '../../typings/schema';
import { SaveButtonView, LoadingView, Hint } from '../Util/common.styles';
import ArbiterForm from '../ArbiterForm/ArbiterForm';
import { Title, Link } from '../../Common/Primitives';
import { useUser } from '../UserContext/UserContext';

interface Props {
    arbiterData: ArbitratorSignup;
    onSignup: (data: ArbitratorSignup) => void;
    onAvatarUpload: (blob: Blob) => void;
    photoUrl?: string;
    isLoaded: boolean;
    isLoading: boolean;
    errors?: ValidationErrors;
}

const ArbiterSignupPage: React.FC<Props> = (props: Props) => {
    const [arbiterData, setArbiterData] = useState<ArbitratorSignup>(props.arbiterData);
    const [isPDAgreed, setIsPDAgreed] = useState<boolean>(false);

    const { contestState, isContestStateLoaded } = useUser();
    const registrationDisabled = isContestStateLoaded && !contestState?.arbiter_registration_enabled;

    const checkFilled = () => {
        return arbiterData?.email && arbiterData?.phone
            && arbiterData?.firstname && arbiterData?.middlename && arbiterData?.lastname
            && arbiterData?.company && arbiterData?.position
            && arbiterData?.educationFinishedYear !== undefined && arbiterData?.educationLevel
            && arbiterData?.affiliation && arbiterData?.mootCourtsExperience
            && (arbiterData?.will_participate_in_premoots && (arbiterData?.will_participate_in_premoots || "").length);
    }

    const onSaveButtonClick = () => {
        props.onSignup(arbiterData);
    }

    

    return (
        <SignupContainer>
            <Title>Регистрация арбитра</Title>
            <Box height={10}/>
            <Hint>Хотите зарегистрировать команду? <Link href="/signup">Заполните другую форму</Link></Hint>
            <Box height={40}/>

            {registrationDisabled && <RegistrationDisabled />}

            {!registrationDisabled && props.isLoaded && (
                <LoadedView>
                    <Typography>Заявка отправлена. Организационный комитет конкурса свяжется с вами</Typography>
                </LoadedView>
            )}

            {!registrationDisabled && !props.isLoaded && (
                <ArbiterForm
                    arbiterData={arbiterData}
                    updateArbiter={setArbiterData}
                    errors={props.errors}
                    photoUrl={props.photoUrl}
                    onAvatarUpload={props.onAvatarUpload}
                    children={
                        <div>
                            <SecurityView>
                                <InformationTextView>
                                    Информация, предоставляемая арбитрами, является конфиденциальной и используется организаторами для регистрации и идентификации арбитров Конкурса.
                            </InformationTextView>
                                <FormControlLabel
                                    control={<Checkbox checked={isPDAgreed} onChange={(e, checked) => setIsPDAgreed(checked)} />}
                                    label="Согласен на хранение и обработку персональных данных"
                                />
                            </SecurityView>
                            <SaveButtonView>
                                {props.isLoading &&
                                    <LoadingView>
                                        <CircularProgress />
                                    </LoadingView>
                                }
                                <SuccessButton
                                    disabled={!(isPDAgreed && checkFilled()) || props.isLoading}
                                    onClick={onSaveButtonClick}
                                    title="Отправить заявку"
                                />
                            </SaveButtonView>
                        </div>
                    }
                />
            )}
        </SignupContainer>
    )
}

export default ArbiterSignupPage;