import { useEffect, useState } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { ActionInstance } from "./actions.types";
import { apiFetch } from "../../api/core";

export interface ActionTrigger {
  _id: string;
  actions: ActionInstance[];

  label?: string;
  label_id?: string;
  hint?: string;
  hint_id?: string;
}

const toStringJoinedKeys = (keys: string | string[]): string => {
  const strVal = (typeof keys === 'string' || keys instanceof String) ? keys : keys.join(",");
  const encoded = encodeURIComponent(strVal as string);
  return encoded.replaceAll("%2C", ",");
}

export const useActionTriggersEdit = (apiPath: string, triggers: string | string[], cfg?: { noLoad?: boolean }) => {
  const triggersKeys = toStringJoinedKeys(triggers);

  const data = useLoadedData<ActionTrigger[]>(`${apiPath}?triggers=${triggersKeys}`, [], !cfg?.noLoad);

  const [editData, setEditData] = useState<Record<string, ActionTrigger>>({});
  const [changed, setChanged] = useState<Record<string, boolean>>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const resetEditData = (triggers: ActionTrigger[]) => {
    setEditData(triggers.reduce<Record<string, ActionTrigger>>((r,t) => { r[t._id] = { ...t }; return r; }, {}));
  }

  useEffect(() => {
    if(Object.keys(editData).length === 0 && data.data.length) {
      resetEditData(data.data);
    }
  }, [data.data, editData]);

  const updateActions = (id: string, actions: ActionInstance[]) => {
    if(isSaving) {
      return;
    }

    setEditData(x => ({ ...x, [id]: { ...(x[id] || {}), actions }}));
    setChanged(x => ({ ...x, [id]: true }));
  }

  const hasChanges = Object.values(changed).filter(x => x).length > 0;
  const save = async () => {
    if(hasChanges) {
      setIsSaving(true);
      const changedIds = Object.entries(changed).filter(([k,v]) => v).map(([k,]) => k);
      for (let i = 0; i < changedIds.length; i++) {
        const id = changedIds[i];
        const updated = await apiFetch<ActionTrigger>(`${apiPath}`, "PUT", { _id: id, actions: editData[id].actions || [] });
        setChanged(x => ({ ...x, [id]: false }));
        setEditData(x => ({ ...x, [id]: { ...(x[id] || {}), ...updated }}))
      }
      setChanged({});
      data.reload();
      setIsSaving(false);
    }
  }


  return {
    ...data,
    editData,
    updateActions,
    save,
    hasChanges,
    isSaving,
  }
}

export type ActionTriggersEditData = ReturnType<typeof useActionTriggersEdit>;
