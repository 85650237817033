import React, { useState } from 'react';
import ParticipantsForm from '../Participants/ParticipantsForm';
import { TeamView } from './TeamStyles.styles';
import SuccessButton from '../Util/SuccessButton';
import { SaveButtonView, LoadingView } from '../Util/common.styles';
import { TeamDataUpdate, TeamMember, TeamData, ValidationErrors, Coach } from '../../typings/schema';
import { CircularProgress } from '@mui/material';
import { PageHeader } from '../Util/PageHeader';
import { TeamCertificateBlock } from './Documents/TeamCertificateBlock';
import { useContestState } from '../../util/useContestState';

interface Props {
    isLoading: boolean;
    teamData: TeamData;
    onTeamDataUpdate: (teamData: TeamDataUpdate) => void;
    validationErrors?: ValidationErrors;
}

export const useEditTeamData = <T extends TeamData>(dflt: T) => {
    const [teamData, setTeamData] = useState<T>(dflt);

    const updatePhone = (phone: string) => {
        setTeamData({ ...teamData, phone: phone });
    }

    const updateParticipants = (participants: TeamMember[]) => {
        setTeamData({ ...teamData, members: participants });
    }

    const updateCoach = (idx: number, coach: Coach) => {
        const coaches: Coach[] = (teamData.coaches || [{}]);
        coaches[idx] = { ...coach };
        setTeamData({ ...teamData, coaches });
    }

    const addCoach = () => {
        setTeamData({ ...teamData, coaches: [...(teamData.coaches || []), {}]});
    }
    const deleteCoach = (idx: number) => {
        const coaches = [...(teamData.coaches || [])];
        coaches.splice(idx, 1);
        setTeamData({ ...teamData, coaches });
    }

    const isValidCoach = (c: Coach) => (c.name && c.name.trim().length > 0) || (c.email && c.email.trim().length > 0) || (c.phone && c.phone.trim().length > 0);

    return {
        teamData,
        setTeamData,
        updatePhone,
        updateParticipants,
        updateCoach,
        addCoach,
        deleteCoach,
        isValidCoach,
    }
}



const TeamPage: React.FC<Props> = (props: Props) => {
    const {
        teamData,
        setTeamData,
        updatePhone,
        updateParticipants,
        updateCoach,
        addCoach,
        deleteCoach,
        isValidCoach,
    } = useEditTeamData(props.teamData);

    const onSave = () => {
        const data: TeamDataUpdate = {
            phone: teamData.phone,
            members: teamData.members,
            coaches: (teamData.coaches || []).filter(isValidCoach)
        };
        
        props.onTeamDataUpdate(data);
    }

    const isUpdateAllowed = () => {
        const isAllowed =
            teamData && teamData.phone
                && teamData.members
                && teamData.members.length >= 2
                && teamData.members
                    .every(m => m.phone && m.email
                        && m.firstname && m.middlename && m.lastname
                        && m.education
                        && ((m.education.level !== undefined && m.education.school && m.education.year) || m.education.sameAsFirst) ? true : false)
                ? true : false;
        return isAllowed;
    }

    const { data: state } = useContestState();

    return (
        <div>
            <PageHeader />
            <TeamView>
              <TeamCertificateBlock coachesCount={(teamData.coaches || []).length} />
                <ParticipantsForm
                    participants={teamData.members}
                    phone={teamData.phone}
                    email={teamData.email}
                    isEditing
                    saveButtonName="Сохранить"
                    updateParticipants={updateParticipants}
                    updateEmail={() => null}
                    updatePhone={updatePhone}
                    validationErrors={props.validationErrors}
                    coaches={teamData.coaches || []}
                    updateCoach={updateCoach}
                    addCoach={addCoach}
                    deleteCoach={deleteCoach}
                    no_coach={teamData.no_coach}
                    updateNoCoach={v => setTeamData(x => ({ ...x, no_coach: v }))}
                >
                    <SaveButtonView>
                        {props.isLoading &&
                            <LoadingView>
                                <CircularProgress />
                            </LoadingView>
                        }
                        <SuccessButton
                            onClick={onSave}
                            disabled={state.team_certificates_available || !isUpdateAllowed()}
                            title={state.team_certificates_available ? "Изменения заблокированы" : "Сохранить"}
                        />
                    </SaveButtonView>
                </ParticipantsForm>
            </TeamView>
        </div>
    )
}

export default TeamPage;