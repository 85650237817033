import React from 'react';
import {
    SportsHockey,
    School,
} from '@mui/icons-material';
import { PremootEditPageForOrganizer, PremootPublicListPage, PremootPublicPage, } from '../../components/Premoot';


export const PremootOrgRoutes = [
    { path: 'my-premoot', component: () => <PremootEditPageForOrganizer />, title: 'Мой премут', icon: <SportsHockey />, hidden: false, },
    { path: 'all', component: () => <PremootPublicListPage kind="premoot-org" />, title: 'Все премуты', icon: <School />, hidden: false, },
    { path: 'premoot/:id', component: () => <PremootPublicPage />, title: 'Премут', icon: <School />, hidden: true, },
];

