import React from 'react';
import { BasicPageWrapperOnlyOut } from '../../presentation/Util/BasicPageWrapper';
import { PublicResultsForm } from '../../toolympus/components/Contests/Grid';
import { CmsPage } from '../DynamicPages/CmsPage';
import { usePublicResults } from '../../toolympus/components/Contests/Grid/Parties/usePublicResults';
import { PageContent } from '../../presentation/Util/common.styles';

interface Props {
  kind: "player" | "arbiter";
}

const PublicResultsBlock = (props: Props) => {
  const data = usePublicResults("/api/v2/final-rounds", props.kind);
  return <PublicResultsForm {...props} data={data} />
}

export const PublicFinalsResultsPage = (props: Props) => {
  return (
    <BasicPageWrapperOnlyOut>
        <CmsPage
          slug="finals-results"
          wrapperComponent={PageContent}
          customBlocks={{ results: () => <PublicResultsBlock kind={props.kind} /> }} />
    </BasicPageWrapperOnlyOut>
  );
}

export const ArbiterFinalsResultsPage = () => <PublicFinalsResultsPage kind="arbiter" />
export const TeamFinalsResultsPage = () => <PublicFinalsResultsPage kind="player" />
