import { useEffect, useState } from "react";
import { TimerInfo } from "../typings/schema";
import { getContestInfo, updateContestInfo } from "./contestInfo";

export interface TimerAPI {
    timerInfo: TimerInfo;
    updateTimer: (timer: TimerInfo) => void;
    postTimerUpdate: () => void;
}

export const useTimerState = (): TimerAPI => {
    const [timerInfo, setTimerInfo] = useState<TimerInfo>({ isOn: false, deadlineText: '', deadlineTime: '', finishedText: '' });

    useEffect(() => {
        getContestInfo()
            .then(info => setTimerInfo(info.countdown))
    }, [])

    const putUpdate = () => {
        if (!timerInfo) return;

        updateContestInfo({ countdown: timerInfo})
            .then(updated => setTimerInfo(updated.countdown))
    }

    return { timerInfo, updateTimer: setTimerInfo, postTimerUpdate: putUpdate };
}