import React, { useEffect, useState } from 'react';
import { AdminTeamInfo, TeamDocuments } from '../../../typings/schema';
import {
    TableContainer,
    TableHead,
    TableBody,
    Table,
    TableRow,
    TableCell,
    Tooltip,
    Button,
    IconButton,
} from '@mui/material';
import {
    ListViewStuffed,
    TotalParticipantsText,
    TableControls,
} from '../Admin.styles';
import { getFullLocalDateTime, getLocalDateTime } from '../../../util/common';
import { CallMade, CancelOutlined, ChatOutlined, Check } from '@mui/icons-material';
import { LinkView, Underline } from '../../Util/common.styles';
import { TeamControlDialog } from './TeamControlDialog';
import { PageHeader } from '../../Util/PageHeader';
import GetApp from '@mui/icons-material/GetApp';
import { TabFilter } from '../../Util/TabFilter';
import { TeamsList } from '../../../containers/admin/useTeamsList';
import { TableComments } from '../../../toolympus/components/Comments/useTableComments';
import { Link } from 'react-router-dom';
import { useTags } from '../../../toolympus/components/Tags';
import { Buttons, OccupyFreeSpace, SearchField, useCopyText } from '../../../toolympus/components/primitives';
import { exportTeams } from './ExportTeams';
import { useFormats } from '../../../toolympus/components/schemed';
import { getUniqueSchools } from './TeamsData';
import { utc } from '../../../toolympus/components/timezone';

interface Props {
    teamsList: TeamsList;
    comments: TableComments;
}

const getDocumentsTooltipText = (documents?: TeamDocuments) => {
    return (
        <div>
            Иск №1: {documents?.claim1?.loaded_datetime ? getLocalDateTime(documents?.claim1?.loaded_datetime) : ''}
        </div>
    )
}



const TeamListPage: React.FC<Props> = (props: Props) => {
    const [controlPopupOpenForTeam, setControlPopupOpenForTeam] = useState<AdminTeamInfo | null>(null);

    const conflicts = useTags("/api/conflict/tag", "team");

    const copyText = useCopyText();


    useEffect(() => {
        setControlPopupOpenForTeam(current => current ?
            props.teamsList.teams.find(t => t._id === current._id) || null
            : null);
    }, [props.teamsList.teams]);

    const { formatDate } = useFormats();

    return (
        <ListViewStuffed>
            <PageHeader useMargin={false} />
            
            <TableControls>
                <TabFilter
                    selectedTab={props.teamsList.tabFilter.selectedKey}
                    selectTab={props.teamsList.tabFilter.selectKey}
                    tabs={props.teamsList.tabFilter.tabs}
                />
            </TableControls>
            <Buttons>
                <SearchField
                  filter={props.teamsList.textFilter.value}
                  setFilter={v => props.teamsList.textFilter.setValue(v)}
                  noButton
                  autoFocus
                  />
                
                <OccupyFreeSpace />

                <Button
                    color="primary"
                    size="small"
                    startIcon={<GetApp />}
                    onClick={() => exportTeams(props.teamsList.teams, `teams-${formatDate(new Date())}.xlsx`)}>
                    Выгрузить
                </Button>
                <TotalParticipantsText style={{ flex: "initial" }}>Всего команд: {props.teamsList.teams.length}</TotalParticipantsText>
            </Buttons>


            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'></TableCell>
                            <TableCell align='center'>Номер</TableCell>
                            <TableCell align='center'>Финалы</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Телефон</TableCell>
                            <TableCell>Конфликты</TableCell>
                            <TableCell align='center'>Тренер</TableCell>
                            <TableCell align='center'>Дата регистрации</TableCell>
                            <TableCell align='center'>Подтверждение email</TableCell>
                            <TableCell align='center'>Число участников</TableCell>
                            <TableCell align='center'>ВУЗы</TableCell>
                            <TableCell align='center'>Документов загружено</TableCell>
                            <TableCell align='center'>Причина исключения</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.teamsList.teams.map(team => (
                                <TableRow key={team.number}>
                                    <TableCell align='center'>{props.comments.buttonForRecord(team._id)}</TableCell>
                                    <TableCell align='center'>
                                        <LinkView onClick={() => setControlPopupOpenForTeam(team)}>
                                            {team.number}
                                        </LinkView>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Button
                                          onClick={() => props.teamsList.updateFinalsStage(team, team.finals_stage ? null : 'rating')}
                                          variant={team.finals_stage ? 'contained' : 'outlined'}
                                          color={team.finals_stage ? 'primary' : 'inherit'}
                                          size="small">
                                          {team.finals_stage ? 'да' : 'нет'}
                                        </Button>
                                    </TableCell>
                                    <TableCell onClick={() => copyText(team.info.email)} style={{ whiteSpace: "nowrap" }}>
                                        <Underline>{team.info.email}</Underline>
                                        <Link to={`/admin/system/users?filter=${team.info.email}`} onClick={e => e.stopPropagation()}>
                                            <IconButton size="small"><CallMade /></IconButton>
                                        </Link>
                                    </TableCell>
                                    <TableCell onClick={() => copyText(team.info.phone)}>
                                      <Underline>{team.info.phone}</Underline>
                                    </TableCell>
                                    
                                    <TableCell>
                                      {conflicts.displayForRecord(team._id)}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <LinkView onClick={() => setControlPopupOpenForTeam(team)}>
                                            {(team.info.coaches || []).map(c => c.name).join(", ")}
                                        </LinkView>
                                    </TableCell>
                                    <Tooltip title={getLocalDateTime(team.registered_datetime)}>
                                        <TableCell align='center'>{formatDate(utc.toLocal(team.registered_datetime))}</TableCell>
                                    </Tooltip>
                                    <TableCell align='center'>
                                        {team.email_confirmed
                                            ? <Check />
                                            : <IconButton onClick={() => setControlPopupOpenForTeam(team)}><CancelOutlined /></IconButton>}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <LinkView onClick={e => setControlPopupOpenForTeam(team)}>
                                            {team.info?.members ? team.info.members.length : 0}
                                        </LinkView>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <LinkView onClick={e => setControlPopupOpenForTeam(team)}>
                                            {team.info?.members ?
                                                getUniqueSchools(team.info.members)
                                                : ''}
                                        </LinkView>
                                    </TableCell>
                                    <Tooltip title={getDocumentsTooltipText(team.documents)}>
                                        <TableCell align='center'>{team.documentsCount}</TableCell>
                                    </Tooltip>
                                    <Tooltip title={team.excluded && team.excluded.datetime ? getFullLocalDateTime(team.excluded.datetime) : ''}>
                                        <TableCell align='center'>{team.excluded?.reason}</TableCell>
                                    </Tooltip>
                                    <TableCell align='center'>
                                        <Tooltip title="Написать сообщение команде">
                                            <Link to={`/admin/chats/${team._id}`}>
                                                <IconButton size="small">
                                                    <ChatOutlined />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TeamControlDialog
              team={controlPopupOpenForTeam}
              onClose={() => setControlPopupOpenForTeam(null)}
              resendConfirmationRequest={props.teamsList.resendConfirmationRequest}
              excludeTeam={props.teamsList.excludeTeam}
              deleteTeam={props.teamsList.deleteTeam}
              />
            
            {props.comments.popup}
            {conflicts.popup}
        </ListViewStuffed>
    );
}

export default TeamListPage;