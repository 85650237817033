import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import DialogTitle from '../../presentation/Util/DialogTitle';
import { getDateTime } from '../../util/common';
import { useDialogState } from '../../util/useDialogState';
import { OnlineRoundData } from '../Contest/useOnlineRound';
import moment from 'moment';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { useUser } from '../../presentation/UserContext/UserContext';


const ApprovalMissing = styled(RadioButtonUnchecked)`
    color: ${props => props.theme.palette.grey[200]};
`;
const ApprovalDone = styled(CheckCircle)`
    color: ${props => props.theme.palette.success.main};
`;
const Confirmations = styled.div`
`;
const GreenButton = styled(Button)`
    color: ${props => props.theme.palette.success.main};
`;
const Container = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledDialogContent = styled(DialogContent)`
    & > p {
        padding: 0.5rem 0;
    }
`;



interface Props {
    roundData: OnlineRoundData;
}

export const RoundDateApproval = (props: Props) => {
    const round = props.roundData.round;
    const roundTime = round?.round_time;
    const approvals = (round?.round_time_approvals || {});

    const dialogState = useDialogState();
    const [pickedDateTime, setPickedDateTime] = useState<moment.Moment | null>(roundTime ? moment(roundTime) : null);

    const userContext = useUser();
    const user = userContext.user;

    useEffect(() => {
        setPickedDateTime(() => roundTime ? moment(roundTime) : moment().add(1, "d"));
    }, [roundTime]);

    const suggest = () => {
        if(pickedDateTime) {
            // there are issues with .format affecting the state in moment - see Dates.tests.ts
            const copyForDate = moment(pickedDateTime);
            const copyForTime = moment(pickedDateTime);
            const fullStr = `${copyForDate.format('yyyy-MM-DD')}T${copyForTime.format('HH:mm:00')}`;

            props.roundData.suggestTime(fullStr);
        }
        dialogState.hide();
    }

    if (!round) {
        return <></>;
    }

    return (
        <>
            <Container>
                {roundTime && <Typography>{`${getDateTime(roundTime)} (МСК)`}</Typography>}
                {!roundTime && (
                    <Button key="suggest" onClick={dialogState.show} color="primary">
                        Предложить время
                    </Button>)}
                {roundTime && (
                    <Confirmations>
                        {[
                            { id: round.team1_id, label: `Команда ${round.team1_nbr}` },
                            { id: round.team2_id, label: `Команда ${round.team2_nbr}` },
                            ...props.roundData.arbitrators.map(({ _id, firstname, lastname }) => ({ id: _id, label: `${firstname} ${lastname}` })),
                        ].map(({ id, label}) => (
                            <Tooltip key={id} title={`${label}: ${approvals[id] ? 'согласовано' : 'ждем согласования'}`}>
                                {approvals[id] ? <ApprovalDone /> : <ApprovalMissing />}
                            </Tooltip>
                        ))}
                    </Confirmations>)}
            </Container>
            {roundTime && (
                <div>
                    {!approvals[user._id] && <GreenButton key="approve" onClick={props.roundData.approveTime}>
                        Согласовать
                    </GreenButton>}
                    {userContext.contestState?.onlinerounds_date_approval_enabled && <Button key="suggest" onClick={dialogState.show} color="primary">
                        {roundTime ? "Предложить другое" : "Предложить"}
                    </Button>}
                </div>
            )}

            <Dialog
                open={dialogState.isShown}
                onClose={dialogState.hide}
                maxWidth='sm'
                fullWidth>
                <DialogTitle onClose={dialogState.hide}>
                    Дата и время слушаний по онлайн раунду
                </DialogTitle>
                <StyledDialogContent>
                    <Typography>
                        Выберите дату и время (МСК).
                    </Typography>
                    <DateTimePicker
                        ampm={false}
                        disablePast
                        format="DD.MM.YYYY, HH:mm"
                        value={pickedDateTime ? pickedDateTime : null}
                        onChange={datetime => setPickedDateTime(datetime || null)}
                        />
                    <Typography>
                        Другие участники раунда получат уведомление о предложенном времени и смогут согласовать его или предложить свой вариант.
                    </Typography>
                    {roundTime && <Typography style={{ fontStyle: 'italic'}}>
                            Если вы предложите новое время, согласование начнется заново.
                        </Typography>}
                    <DialogActions>
                      <Button key="close" color="primary" variant="contained" onClick={suggest}>
                        Предложить
                      </Button>
                      <Button key="close" onClick={dialogState.hide}>
                        Отмена
                      </Button>
                    </DialogActions>
                </StyledDialogContent>
            </Dialog>
        </>
    );
}
