import React from 'react';
import { IconButton } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useDialogState } from '../../util/useDialogState';
import { NotificationSettingsDialog } from './NotificationSettingsDialog';
import { useNotificationSettings } from './useNotificationSettings';
import { useUser } from '../../presentation/UserContext/UserContext';

export const SettingsMenuButton = () => {
    const user = useUser().user;
    const dialogState = useDialogState();
    const notificationSettings = useNotificationSettings(user._id);

    return (
        <>
            <IconButton className="settings-button" size="small" onClick={dialogState.show} color="primary">
                <Settings />
            </IconButton>
            <NotificationSettingsDialog state={dialogState} settings={notificationSettings} />
        </>
    );
}
