import { useEffect } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { apiFetch } from "../../toolympus/api/core";
import { Schema } from "../../toolympus/hooks/useSchema";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useActionWithConfirmation, useItemActionWithConfirmation } from "../../toolympus/api/useAction";

export interface Team {
  _id?: string;
  display_name: string;
  email?: string;
}

export interface Arbitrator {
  _id?: string;
  display_name: string;
  email?: string;
}

export interface PremootRoundsConfig {
  teams: Team[];
  arbitrators: Arbitrator[];
}

const ApiPath = "/api/premoot-rounds/config";

export const TeamSchema: Schema = {
  display_name: { label: "Номер" },
  _id: { label: "ID" },
}

export const ArbitratorSchema: Schema = {
  display_name: { label: "Имя" },
  _id: { label: "ID" },
}

export const usePremootRoundsConfig = () => {
  const data = useCrudItem<PremootRoundsConfig>(ApiPath, {
    defaultValue: { teams: [], arbitrators: [] },
  });

  useEffect(() => {
    apiFetch(ApiPath, "post", { teams: [], arbitrators: [] })
      .then(() => data.reload())
      .catch(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const newTeams = useEditItem2<{ numbers: string }>({
    getApiPath: () => "",
    save: (item) => {
      const numbers = (item.numbers || "").split("\n").map(number => number.trim());
      data.save({ teams: [...(data.data.teams || []), ...numbers.map(n => ({ display_name: n }))] })
      return Promise.resolve(item);
    },
  });

  const removeTeam = useItemActionWithConfirmation<Team, {}>(
    team => data.save({ teams: (data.data.teams || []).filter(t => t._id !== team._id )})
      .then(x => { data.reload(); return x; }), {
        title: "Удалить команду",
        confirmationLabel: "Удалить",
  });

  const removeAllTeams = useActionWithConfirmation(
    () => data.save({ teams: [] }), {
      title:"Удалить все команды",
      confirmationLabel: "Удалить",
  });


  const newArbitrators = useEditItem2<{ numbers: string }>({
    getApiPath: () => "",
    save: (item) => {
      const numbers = (item.numbers || "").split("\n").map(number => number.trim());
      data.save({ arbitrators: [...(data.data.arbitrators || []), ...numbers.map(n => ({ display_name: n }))] })
      return Promise.resolve(item);
    },
  });

  const removeArbitrator = useItemActionWithConfirmation<Arbitrator, {}>(
    arbitrator => data.save({ arbitrators: (data.data.arbitrators || []).filter(t => t._id !== arbitrator._id )})
      .then(x => { data.reload(); return x; }), {
        title: "Удалить арбитра",
        confirmationLabel: "Удалить",
  });

  const removeAllArbitrators = useActionWithConfirmation(
    () => data.save({ arbitrators: [] }), {
      title:"Удалить всех арбитров",
      confirmationLabel: "Удалить",
  });


  return {
    ...data,

    newTeams,
    removeTeam,
    removeAllTeams,
    
    newArbitrators,
    removeArbitrator,
    removeAllArbitrators,
  };
}


export type PremootRoundsConfigData = ReturnType<typeof usePremootRoundsConfig>;
