import { useState, useEffect, useCallback, useMemo } from 'react';
import { AdminArbiterInfo } from '../../typings/schema';
import { fetchGet } from '../../util/apiFetch';
import { TextFilter, useTextFilter } from '../../util/useFilter';

export interface ArbiterList {
    arbiters: AdminArbiterInfo[];
    arbitersDict: { [key: string]: AdminArbiterInfo};
    textFilter: TextFilter<AdminArbiterInfo>;
    reload: () => void;
}

const getArbiterData = () => {
    return fetchGet<AdminArbiterInfo[]>('/api/admin/arbiter');
}

export const useArbiterList = (): ArbiterList => {
    const [arbiters, setArbiters] = useState<AdminArbiterInfo[]>([]);
    const textFilter = useTextFilter<AdminArbiterInfo>(a => `${a.info.lastname} ${a.info.firstname} ${a.info.middlename} ${a.info.company}`);

    const reload = useCallback(() => {
            getArbiterData()
                .then(setArbiters);
        },[])

    useEffect(() => {
        reload();
    }, [reload]);

    const arbitersDict = useMemo(
        () => arbiters.reduce((result, a) => ({ ...result, [a._id]: a }), {}),
        [arbiters]);

    return {
        arbiters: arbiters.filter(textFilter.filter),
        arbitersDict,
        reload,
        textFilter,
    };
}