import { useUser } from "../../userContext/UserContext";

const localStorageKey = "__chats_message_input_keeper";

export interface MessageInputKeeper {
    get: (chatId?: string) => string;
    update: (chatId: string | undefined, text: string) => void;
}

export const useMessageInputKeeper = (): MessageInputKeeper => {
    const { user } = useUser();

    const getStored = () => {
        const storedStr = localStorage.getItem(localStorageKey);
        const stored = storedStr && storedStr.length ? JSON.parse(storedStr) : {};
        if(stored.user_id !== user?._id) {
            return { user_id: user?._id };
        } else {
            return stored;
        }
    }

    const get = (chatId?: string) => chatId ? getStored()[chatId] || "" : "";

    const update = (chatId: string | undefined, text: string) => {
        if(user?._id && chatId) {
            const stored = getStored();
            localStorage.setItem(localStorageKey, JSON.stringify({ ...stored, [chatId]: text }));
        }
    }

    return { get, update };
}