import styled from '@emotion/styled';
import { Typography, Grid } from '@mui/material';
import CloseSrc from "./Close.png";

export const SubHeading = styled(Typography)`
    font-size: 1.35em;
    margin-bottom: 8px;
`;

interface FormProps {
    align?: string;
}

export const ParticipantsFormView = styled.div`
    align-self: ${(props: FormProps) => props.align || 'none'};
    margin-top: 40px;
`;

export const SectionView = styled.div`
    padding-top: 30px;
`

export const SectorHeading = styled(Typography)`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`

export const AddParticipantText = styled.div`
    text-decoration: underline;
    color: #405E7D;
    font-size: 1.1em;
    &:hover {
        cursor: pointer;
    }
`

export const NumberView = styled.div`
    position: absolute;
    top: 24px;
    left: -14px;
    font-size: 1.5em;
    font-weight: 525;
`

export const ParticipantItemView = styled.div`
    padding: 20px 20px 30px 20px;

    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    border: 1px solid ${props => props.theme.palette.primary.main};
    position: relative;

    ${props => props.theme.breakpoints.down('sm')} {
        padding: 20px;
    }
`

export const CloseButtonView = styled.img`
    width: 18px;
    height: 18px;

    position: absolute;
    top: -9px;
    right: 20px;
    cursor: pointer;
    background: white;
`;
CloseButtonView.defaultProps = { className: 'CloseButtonView', src: CloseSrc };

export const PrcticipantNumber = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: ${props => props.theme.palette.primary.main};

    position: absolute;
    top: -10px;
    left: 18px;

    background: white;
    padding: 0 2px;
`;
PrcticipantNumber.defaultProps = {className: 'PrcticipantNumber'};

export const MarginGrid = styled(Grid)`
    margin-bottom: 12px;
`

export const GridWithNumber = styled(Grid)`
    margin-bottom: 12px;
    overflow: visible;
    position: relative;
`

export const EducationCheckboxView = styled.div`
    margin-bottom: 0.5rem;
`

export const AddParticipantContainer = styled.div`
    margin-top: -20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 40px;

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
        gap: 20px;
    }
`;
AddParticipantContainer.defaultProps = { className: 'AddParticipantContainer' };

export const BottomTipView = styled.div`
    padding-top: 0.3rem;

    ${props => props.theme.breakpoints.down('sm')} {
        text-align: center;
    }
`;