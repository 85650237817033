import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { StartScoringButton } from './Scoring/Common';
import { TeamRole } from '../Contest/useOnlineRoundScores';
import { PerformanceKey, TeamKey } from '../Contest/useOnlineRoundScoring2';

export const SideText = styled(Typography)`
    font-size: 1.25em;
    margin-bottom: 0.5em;

    @media(max-width: 600px) {
        margin-top: 1em;
    }
`;

SideText.defaultProps = { variant: 'body1' };

const SideKind = styled.span`
    color: ${props => props.theme.palette.grey[500]};
`;

const SideContainer = styled.div`
`;


export const Sides = styled.div`
    @media(min-width: 601px){
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 600px){
        display: flex;
        flex-flow: column;
    }
`;

export const AttachmentsWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    padding-left: 0.5rem;
`;

const PerformanceWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
`;

const RoleName: { [key: string]: string } = {
    [TeamRole.claimant]: "Истец",
    [TeamRole.respondant]: "Ответчик",
}

interface Props {
    role: TeamRole;
    title: string;
    teamKey: TeamKey;
    startScoring?: (teamRole: TeamKey, performanceKey: PerformanceKey) => void;
    isScored?: (teamRole: TeamKey, performanceKey: PerformanceKey) => boolean;
    attachments?: ReactNode;
}

export const Side = (props: Props) => {
    const { role, teamKey, title, startScoring, isScored, attachments } = props;

    return <SideContainer>
        <SideText>
            <SideKind>{RoleName[role as string]}:</SideKind>
            &nbsp;{title}&nbsp;
            {startScoring && <>
                <PerformanceWrapper key="1">
                    <Typography>Выступление 1</Typography>
                    <StartScoringButton
                        startScoring={() => startScoring && startScoring(teamKey, 'or-performance')}
                        isScored={isScored && isScored(teamKey, 'or-performance')} />
                </PerformanceWrapper>
                <PerformanceWrapper key="2">
                    <Typography>Выступление 2</Typography>
                    <StartScoringButton
                        startScoring={() => startScoring && startScoring(teamKey, 'or-performance2')}
                        isScored={isScored && isScored(teamKey, 'or-performance2')} />
                </PerformanceWrapper>
            </>}
                
        </SideText>
        {attachments && <AttachmentsWrapper>{attachments}</AttachmentsWrapper>}
    </SideContainer>
}
