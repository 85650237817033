import React, { useState, useEffect } from 'react';
import TeamPage from '../../presentation/Team/TeamPage';
import { TeamDataUpdate, TeamData, ValidationErrors } from '../../typings/schema';
import { TeamContainerProps } from '../../typings/TeamContainerProps';
import { fetchGet, apiFetch, FetchTypes, ValidationError } from '../../util/apiFetch';
import LoadingPage from '../../presentation/Util/LoadingPage';
import { handleValidationError } from '../../util/common';
import { useSnackbar } from '../../components/Snackbar';

const getTeamData = () => {
    return fetchGet<TeamData>('/api/team/info');
}

const updateTeamData = (data: TeamDataUpdate) => {
    return apiFetch<TeamData>('/api/team/info', FetchTypes.PUT, data)
}

interface TeamContainerState {
    isLoading: boolean;
    errors?: ValidationErrors;
}

const defaultTeamContainerState: TeamContainerState = { isLoading: false };

export const TeamContainer: React.FC<TeamContainerProps> = (props: TeamContainerProps) => {
    const [teamData, setTeamData] = useState<TeamData>();
    const [containerState, setContainerState] = useState<TeamContainerState>(defaultTeamContainerState);
    const { showSaveSuccess } = useSnackbar();

    useEffect(() => {
        setContainerState(c => { return { ...c, isLoading: true } });
        getTeamData()
            .then(data => {
                setTeamData(data)
            })
            .then(() => setContainerState(c => { return { ...c, isLoading: false } }))
    }, []);

    const onTeamDataUpdate = (teamData: TeamDataUpdate) => {
        setContainerState({ ...containerState, isLoading: true, errors: {} });
        updateTeamData(teamData)
            .then(data => setTeamData(data))
            .then(() => {
                setContainerState({ ...containerState, isLoading: false, errors: undefined });
                showSaveSuccess();
            })
            .catch(error => {
                if (error instanceof ValidationError) {
                    const caught = error.caughtErrors;
                    const parsedErrors = handleValidationError(caught);
                    setContainerState({ ...containerState, isLoading: false, errors: parsedErrors });
                }
                throw error;
            })
    }

    if (!teamData) {
        return (<LoadingPage />);
    }
    else {
        return (
            <TeamPage
                isLoading={containerState.isLoading}
                onTeamDataUpdate={onTeamDataUpdate}
                teamData={teamData}
                validationErrors={containerState.errors}
            />)
    }
}