import React, { useState } from 'react';
import RequestRestorePasswordPage from '../presentation/RestorePassword/RequestRestorePasswordPage';
import { PasswordResetRequest } from '../typings/schema';
import { apiFetch, FetchTypes } from '../toolympus/api/core';
import { useSnackbar } from 'notistack';

interface Props {

}

export const apiRequestRestore = (email: string) => {
    return apiFetch<string>("/api/restore-access/request", FetchTypes.POST, { login: email }, { noAuth: true })
}

export const RequestRestorePasswordContainer: React.FC<Props> = (props: Props) => {
    const [formData, setFormData] = useState<PasswordResetRequest>({ email: ''});
    const [isRequested, setIsRequested] = useState(false);
    const snack = useSnackbar();

    const onRequest = (data: PasswordResetRequest) => {
        apiRequestRestore(data.email)
            .then(token => setIsRequested(true))
            .catch(error => snack.enqueueSnackbar("Что-то пошло не так. Попробуйте снова.", { variant: 'error', autoHideDuration: 5000 }))
    }

    return (
        <RequestRestorePasswordPage
            formData={formData}
            onDataChanged={setFormData}
            onRequest={onRequest}
            isRequested={isRequested}
        />
    )
}