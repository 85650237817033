import React from 'react';
import {
    Group,
    Person,
    ChatOutlined,
    Settings,
    SportsHockey,
    SportsSoccer,
    ChildFriendly,
    InfoOutlined,
    PrintOutlined,
    Rowing,
    EmojiPeople,
} from '@mui/icons-material';
import ArbiterListContainer from '../../containers/admin/ArbiterListContainer';
import TeamListContainer from '../../containers/admin/TeamListContainer';
import { ContestControlContainer } from '../../components/Contest/ContestControlContainer';
import { PremootEditList } from '../../components/Premoot/PremootEditList';
import { PremootEditPage } from '../../components/Premoot/PremootEditPage';
import { ChatListOrgPage } from '../../components/Chats/ChatListOrgPage';
import { OrgStartQuestionsPage } from '../../components/Chats/QuestionsPages';
import { ChatsUnreadIndicator } from '../../components/Chats/ChatsUnreadIndicator';
import { OnlineRoundsAdminPage } from '../Admin/OnlineRounds/OnlineRoundsAdminPage';
import { FinalsConfigurationPage } from '../../components/Finals';
import { SystemSubmenu } from '../../toolympus/components/Contests/SystemSubmenu';
import { Documentation } from '../../components/Documentation';
import { CMS } from '../../components/CMS';
import { VolunteerApplicationsList } from '../../components/Applications/VolunteerApplicationsList';
import { CoachApplicationsList } from '../../components/Applications/CoachApplicationsList';
import { MediaLibProvider } from '../../toolympus/components/medialib';
import { PremootRoundsRootPage } from '../../components/PremootRounds';
import { AdminQuestionsListPage } from '../../components/Questions/AdminQuestionsListPage';

const roles = [
    {key: "admin", name: "Админ"},
    {key: "team", name: "Команда"},
    {key: "arbiter", name: "Арбитр"},
    {key: "arbiter-unapproved", name: "Арбитр неутв"},
    {key: "premoot-org", name: "Организатор премута"},
];

export const AdminRoutes = [
    { path: 'control', component: ContestControlContainer, title: 'Управление конкурсом', icon: <Settings />, hidden: false },
    { path: 'team', component: TeamListContainer, title: 'Команды', icon: <Group />, hidden: false },
    { path: 'arbiter', component: ArbiterListContainer, title: 'Арбитры', icon: <Person />, hidden: false },
    { path: 'coach', component: CoachApplicationsList, title: 'Тренеры', icon: <Rowing />, hidden: false },
    { path: 'volunteer', component: VolunteerApplicationsList, title: 'Волонтеры', icon: <EmojiPeople />, hidden: false },

    { path: 'premoot/:id', component: PremootEditPage, title: 'Премуты', icon: <ChildFriendly />, hidden: true },
    { path: 'premoot', component: PremootEditList, title: 'Премуты', icon: <ChildFriendly />, hidden: false, },
    {
      icon: <div />,
      path: "premoot-rounds",
      title: "Раунды премутов",
      component: PremootRoundsRootPage,
    },

    { path: 'questions', component: AdminQuestionsListPage, title: 'Вопросы по фабуле', icon: <ChatOutlined />, hidden: false },
    { path: 'chats/:id', component: OrgStartQuestionsPage, title: 'Чаты с участниками', icon: <ChatOutlined />, hidden: true },
    { path: 'chats', component: ChatListOrgPage, title: <>Чаты с участниками<ChatsUnreadIndicator /></>, icon: <ChatOutlined />, hidden: false },
    { path: 'onlinerounds', component: OnlineRoundsAdminPage, title: 'Онлайн раунды', icon: <SportsHockey />, hidden: false },
    { 
        icon: <SportsSoccer />,
        path: 'finals',
        title: 'Финальные раунды',
        component: FinalsConfigurationPage,
    },

    
    {
        path: 'system',
        title: 'Система',
        component: () => (
          <MediaLibProvider apiPath="/api/medialib" spaceId="1" getFilepath={mf => `/media/${mf.filename}`}>
            <SystemSubmenu
                scoringSheets={{ apiPath: "/api/scoring/sheet" }}
                queryConsole={{}}
                notificationTasks={{ apiPath: "/api/notifications/task" }}
                users={{ userRoles: roles, allowInvites: true, noMassInviteNew: true, allowPaswordChange: true  }}
                emails={{ apiPath: "/email" }}
                robud={{}}
                files={[
                    { apiPath: "/api/files/public", key: "files-public", label: "Публичные", title: "Публичные файлы" },
                    { apiPath: "/api/files/arbiter", key: "files-arbiter", label: "Для арбитров", title: "Файлы для арбитров" },
                ]}
                extraItems={[
                    { key: "cms", label: "CMS", icon: <PrintOutlined />, items: [{ key: "cms", label: "", component: CMS }]},
                    { key: "documentation", label: "Документация", icon: <InfoOutlined />, items: [{ key: "documentation", label: "", component: Documentation }] }
                ]}
            />
          </MediaLibProvider>),
        icon: <Settings />,
        alsoActivateForPath: (p: string) => p.startsWith("/admin/system"),
    },
];

