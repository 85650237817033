import { useState } from "react";
import { useUser } from "../../presentation/UserContext/UserContext";
import { downloadFile } from "../../toolympus/api/core";
import { useCrudUnpagedList, CrudListData, useCrudItem, CrudItemData } from "../../toolympus/api/useSimpleCrud";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../toolympus/hooks/useSchema";
import { useUserManagement } from "../../toolympus/hooks/useUserManagement";
import { checkRole } from "../../toolympus/userContext/UserContext";
import { apiFetch, FetchTypes } from "../../util/apiFetch";
import { exportPremootApplicationsToXlsx } from "./premootApplicationsXlsx";

export interface Premoot {
    _id: string;
    title: string;
    brief_description?: string | null;
    full_description?: string | null;
    organizers_ids?: string[] | null;
    is_published: boolean;
    is_applied?: boolean;
    is_application_approved?: boolean;
    doc1?: string;
    doc2?: string;
}

export interface PremootApplication {
    premoot_id: string;
    team_id: string;
    teamnbr: string;
    is_approved_org: boolean;
    is_approved_adm: boolean;
    created_datetime: string;
    doc1?: string;
    doc2?: string;
}

export const usePremootSchemaCurtailed = (): Schema => {
    const { user } = useUser();
    return {
        title: { type: FieldType.text, label: "Название" },
        brief_description: { type: FieldType.markdown, label: "Краткое описание" },
        full_description: { type: FieldType.markdown, label: "Полное описание" },
        organizers_ids: {
            type: FieldType.multiselect,
            label: "Организаторы",
            values: [{ value: user._id, label: user.email }],
            valueDict: { [user._id]: user.email },
        },
        is_published: { type: FieldType.bool, label: "Опубликован" },
    }
}

export const usePremootSchema = (): Schema => {
    const users = useUserManagement('/api/user', '');

    const orgs = users.users.filter(u => checkRole(u, "premoot-org"));
    const schema = usePremootSchemaCurtailed();
    return {
        ...schema,
        organizers_ids: {
            type: FieldType.multiselect,
            label: "Организаторы",
            values: orgs.map(o => ({ value: o._id, label: (o as any).email })),
            valueDict: orgs.reduce((r,o) => ({ ...r, [o._id]: (o as any).email}), {}),
        },
    }
}

export const usePremootApplicationSchema = (): Schema => {
    return {
        teamnbr: { type: FieldType.text, label: "Команда" },
        schools: { type: FieldType.text, label: "ВУЗы" },
        is_approved_org: { type: FieldType.bool, label: "Организатор" },
        is_approved_adm: { type: FieldType.bool, label: "Администратор" },
        created_datetime: { type: FieldType.datetime, label: "Подана" },
        doc1: { type: FieldType.text, label: "Док 1" },
        doc2: { type: FieldType.text, label: "Док 2" },
    }   
}

type PremootListData = CrudListData<Premoot> & {
    schema: Schema;
}

export const usePremootListManagement = (): PremootListData => {
    const data = useCrudUnpagedList<Premoot>('/api/premoot/admin', {
        newItem: { title: "Премут" },
        defaultView: "all",
        newItemPath: p => `/admin/premoot/${p._id}`,
    });

    const schema = usePremootSchema();

    return {
        ...data,
        schema,
    }
};

export type PremootEditData = CrudItemData<Premoot> & {
    schema: Schema;
    applicationsSchema: Schema;
    applications: PremootApplication[];
    applicationsView: string;
    setApplicationsView: (v: string) => void;
    updateApplication: (o: PremootApplication, c: Partial<PremootApplication>) => void;
    downloadDocument: (team_id: string, kind: string) => void;
    exportApplications: () => void;
};

interface PESettings {
    premootPath: string;
    applicationsPath: string;
    defaultApplicationsView: string;
    premootDeleteReturnPath?: string;
    premootSchema: Schema;
}

const usePremootEditBase = ({ premootPath, applicationsPath, premootSchema, defaultApplicationsView, premootDeleteReturnPath }: PESettings): PremootEditData => {
    const data = useCrudItem<Premoot>(premootPath, {
        returnPath: premootDeleteReturnPath,
        defaultValue: { title: "", _id: "", is_published: false, },
    });

    const applicationsSchema = usePremootApplicationSchema();

    const [applicationsView, setApplicationsView] = useState<string>(defaultApplicationsView);
    const applicationsData = useLoadedData<PremootApplication[]>(`${applicationsPath}?view=${applicationsView}`, []);

    const updateApplication = (o: PremootApplication, c: Partial<PremootApplication>) => {
        return apiFetch<PremootApplication>(`${applicationsPath}/${o.team_id}`, FetchTypes.PUT, c)
            .then(() => applicationsData.reload());
    }

    const downloadDocument = (team_id: string, kind: string) => {
        const { teamnbr } = applicationsData.data.find(a => a.team_id === team_id) || { teamnbr: "" };
        return downloadFile(`/api/premoot/document/${data.data._id}/${team_id}/${kind}`, `${teamnbr}_${kind}.pdf`);
    }
    
    const exportApplications = () => {
      exportPremootApplicationsToXlsx(applicationsData.data, applicationsSchema, `${data.data.title}.xlsx`)
    }

    return {
        ...data,
        applications: applicationsData.data,
        schema: premootSchema,
        applicationsSchema,
        applicationsView,
        setApplicationsView,
        updateApplication,
        downloadDocument,
        exportApplications,
    }
}

export const usePremootEdit = (id: string): PremootEditData => {
    return usePremootEditBase({
        premootPath: `/api/premoot/admin/${id}`,
        applicationsPath: `/api/premoot/admin/${id}/applications`,
        defaultApplicationsView: "premoot",
        premootDeleteReturnPath: '/admin/premoot',
        premootSchema: usePremootSchema(),
    });
}

export const usePremootEditOrg = (): PremootEditData => {
    return usePremootEditBase({
        premootPath: `/api/premoot/org`,
        applicationsPath: `/api/premoot/org/applications`,
        defaultApplicationsView: "premoot",
        premootSchema: usePremootSchemaCurtailed(),
    });
}
