import React, { useEffect } from 'react';
import { ActionTriggersEditData } from './useActionTriggersEdit';
import { Divider, Typography } from '@mui/material';
import { Fingerprint } from '@mui/icons-material';
import { TabPanel, TabsHeader, useTabsState } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { ActionsListEditor } from './ActionsListEditor';

interface Props {
  data: ActionTriggersEditData;
  keepAllExpanded?: boolean;
}

export const ActionTriggerEditForm = (props: Props) => {
  const { data } = props;
  const tabs = useTabsState(data.data.map(trigger => (
    [trigger._id, trigger.label_id ? <FormattedMessage id={trigger.label_id} /> : (trigger.label || trigger._id)])));


  useEffect(() => {
    if(data.data.length && !tabs.current) {
      tabs.setCurrent(data.data[0]._id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, tabs.current]);

  return (
    <>
      {data.data.length > 1 && <TabsHeader state={tabs} scrollable noMargin />}
      
      {data.data.map((trigger) => (
        <TabPanel key={trigger._id} state={tabs.forTab(trigger._id)}>
          {data.data.length <= 1 && <Typography variant="h6">
            {trigger.label_id && <FormattedMessage id={trigger.label_id} />}
            {!trigger.label_id && trigger.label ? trigger.label : ""}
            {!trigger.label_id && !trigger.label ? trigger._id : ""}
          </Typography>}

          <Typography variant="subtitle2" color="textSecondary">
            <Fingerprint fontSize="small" style={{ transform: "translateY(25%)"}} /> {trigger._id}
          </Typography>

          {(trigger.hint_id || trigger.hint) &&
            <Typography style={{ whiteSpace: "pre-line" }}>
              {trigger.hint_id ? <FormattedMessage id={trigger.hint_id} /> : trigger.hint}
            </Typography>}

          <Divider style={{ margin: "0.5rem 0" }} />
          

          <ActionsListEditor
            actions={data.editData[trigger._id]?.actions || []}
            update={v => data.updateActions(trigger._id, v)}
            keepAllExpanded={props.keepAllExpanded}
            />
        </TabPanel>
      ))} 
    </>
  );
}
