import React from 'react';
import TeamListPage from '../../presentation/Admin/TeamList/TeamListPage';
import LoadingPage from '../../presentation/Util/LoadingPage';
import { useTeamsList } from './useTeamsList';
import { useComments } from '../../components/Comments';

const TeamListContainer: React.FC = () => {
    const teamsList = useTeamsList();
    const comments = useComments('team');

    if (!teamsList.isLoaded) {
        return (<LoadingPage />);
    }
    return (
        <TeamListPage
            teamsList={teamsList}
            comments={comments}
        />
    )
}

export default TeamListContainer;