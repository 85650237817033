import React from 'react';
import { ArbitratorSchema, PremootRoundsConfigData } from './usePremootRoundsConfig';
import { ActionRow, Buttons, LoadingIndicator, OccupyFreeSpace, SaveButton, SimpleDialog } from '../../toolympus/components/primitives';
import { TableForFields } from '../../toolympus/components/schemed';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';

interface Props {
  data: PremootRoundsConfigData;
}

export const PremootRoundsArbitratorsList = (props: Props) => {
  const { data } = props;

  return (
    <>
      <ActionRow
        firstItemNoMargin
        >

        <OccupyFreeSpace />

        <LoadingIndicator style={{ visibility: data.isLoading ? "visible" : "hidden" }}/>
        <IconButton size="small" onClick={() => data.newArbitrators.startEditing({ numbers: "" })}><Add /></IconButton>
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        <Button size="small" color="secondary" startIcon={<DeleteOutlined />} onClick={() => data.removeAllArbitrators.run()}>удалить всех</Button>
      </ActionRow>
      
      <TableForFields
        data={data.data.arbitrators}
        fields={[
          ["display_name"],
          ["_id"],
        ]}
        schema={ArbitratorSchema}
        rowButtons={row => <Buttons><IconButton size="small" onClick={() => data.removeArbitrator.run(row)}><DeleteOutlined /></IconButton></Buttons>}
        />

      <SimpleDialog
        dialogTitle="Добавить арбитров"
        isOpen={data.newArbitrators.isEditing}
        close={() => data.newArbitrators.cancel()}
        save={() => data.newArbitrators.save()}
        noFullscreen
        noSubmitOnEnter>
        <Typography>Укажите имена арбитров - каждое на отдельной строке</Typography>
        {data.newArbitrators.item && <TextField
          fullWidth
          multiline
          value={data.newArbitrators.item.numbers}
          onChange={e => data.newArbitrators.update({ numbers: e.target.value })}
          />}
      </SimpleDialog>
    </>
  );
}
