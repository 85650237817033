import React from "react"
import { useScoredRound } from "../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext";
import { ScoringControlProps } from "../../toolympus/components/Contests/ScoringSheets";
import { Select } from "../../toolympus/components/schemed/Select";
import { useQuery } from "../../toolympus/hooks/useQueryParamsState";
import { FieldType } from "../../toolympus/hooks/useSchema";
import { TeamMember } from "../../typings/schema";
import { CmsPage } from "../DynamicPages/CmsPage";
import { RoundInfoBlock, RoundScoreBlock } from "../../toolympus/components/Contests/Grid/Parties/ScoreRoundForm";
import { useScoreRoundByCode } from "../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { createProvided } from "../../toolympus/components/primitives/createProvided";



const SelectPlayer = (props: ScoringControlProps) => {
    const { data: { scores, update }, criteria } = props;
    const { round, score } = useScoredRound();

    const members = ((round?._id && score?.position && round.info?.members && round.info?.members[score.position]) || []) as TeamMember[];
    const team = round?._id && score?.position ? round.players[score.position] : null;

    const membersX = members.map(m => { const name = `${m.firstname} ${m.lastname}`; return { label: name, value: `${name}, ${team?.display_name}`}})

    return <Select
                field="speaker"
                row={{ speaker: scores[criteria?._id || ""]}}
                schema={{ type: FieldType.select,  label: " ", values: membersX, valueDict: membersX.reduce((r,x) => ({ ...r, [x.value]: x.label }), {})}}
                onChange={(o,c) => update({ [criteria?._id || ""]: c.speaker })}
                />
}

const useScoreRound = (): ReturnType<typeof useScoreRoundByCode> | null => {
  const query = useQuery();
  return useScoreRoundByCode("/api/v2/final-rounds", {
    arbiter_id: query.get("arbiter_id") || "",
    round_id: query.get("id") || "",
    access_code: query.get("code") || "",
    });
}

const {
  Provider: RoundScoringProvider,
  useValue: useRoundScoring,
} = createProvided(useScoreRound, null);


const RoundScoreBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundScoreBlock data={data} customComponents={{ speaker: SelectPlayer }} />
    : null;
}

const RoundInfoBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundInfoBlock data={data} />
    : null;
}


export const ScoreFormContainer = () => {
    return (
      <RoundScoringProvider>
        <CmsPage slug="finals-scoring" customBlocks={{
          info: RoundInfoBlockP,
          scores: RoundScoreBlockP,
        }}
          />
      </RoundScoringProvider>
    )
    
}