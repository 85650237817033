import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Dialog } from '../../toolympus/components/primitives/Dialogs';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { FormControl, TableForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { usePremootOrganizers } from './usePremootOrganizers';

interface Props {
    isOpen: boolean;
    close: () => void;
}

export const PremootOrganizersPopup = (props: Props) => {
    const data = usePremootOrganizers();
    const [emailsToInvite, setEmailsToInvite] = useState<string>("");
    return (
        <Dialog
            {...props}
            dialogTitle="Организаторы"
            actions={<Button onClick={() => props.close()}>закрыть</Button>}
            titleActions={<Button onClick={() => data.inviteUninvited()}>пригласить неприглашенных</Button>}
            >

            <TableForFields
                fields={[
                    ["email", { disabled: true }],
                    ["invited_datetime", { utcToLocal: true, disabled: true }]
                ]}
                schema={{
                    email: { type: FieldType.text, label: "E-mail" },
                    invited_datetime: { type: FieldType.datetime, label: "Приглашен" },
                }}
                data={data.organizers}
                rowButtons={user => <Button size="small" onClick={() => data.inviteOrganizer(user._id)}>пригласить</Button>}
                />

            <FormGrid columns="1fr max-content">
                <FormControl
                    field="emailsToInvite"
                    schema={{ type: FieldType.text, label: "E-mail"}}
                    row={{ emailsToInvite }}
                    onChange={(o,c) => setEmailsToInvite(c.emailsToInvite)}
                    extraProps={{ hint: "Введите e-mail'ы для создания пользователей через пробел или точку с запятой"}}
                    />
                <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                        data.addOrganizers(emailsToInvite.split(/[\s;]+/));
                        setEmailsToInvite("");
                    }}
                    disabled={emailsToInvite.trim().length === 0}
                    >
                    создать пользователей
                </Button>
            </FormGrid>
        </Dialog>
    );
}
