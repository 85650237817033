export interface TeamMember {
    firstname: string;
    middlename: string;
    lastname: string;
    email: string;
    phone: string;
    education: {
        sameAsFirst: boolean;
        school: string;
        level: 'BS' | 'MS' | 'SP';
        year: number;
    };
    mootCourtsExperience?: string;
}

export interface TeamSignupData {
    email: string;
    phone: string;
    members: TeamMember[]; // at least one
    password: string;
    passwordRepeat: string;
    coaches?: Coach[];
    no_coach?: boolean;
}

export interface TeamLogin {
    email: string;
    password: string;
}

export interface PasswordResetRequest {
    email: string;
}

export interface PasswordChange {
    token: string;
    email: string;
    password: string;
    passwordRepeat: string;
}

export interface TeamDataUpdate {
    phone: string;
    members: TeamMember[];
    coaches?: Coach[];
    no_coach?: boolean;
}

export interface TeamData extends TeamDataUpdate {
    email: string;
}

export interface TeamNumber {
    number: string;
    email_confirmed: boolean;
}

export interface OtherTeamNumber {
    _id: string;
    number: string;
}

export interface TeamDocuments {
    claim1?: Document | null;
    claim2?: Document | null;
    claim_other1?: Document | null;
    claim_other2?: Document | null;
    response1?: Document | null;
    response2?: Document | null;
    response_other1?: Document | null;
    response_other2?: Document | null;
}

export interface Document {
    loaded_datetime: string;
    doc_id: string;
    doc_name: string;
}

export type ParticipationOption = 'online' | 'inperson' | 'both';

export interface ArbitratorSignup {
    firstname: string;
    middlename: string;
    lastname: string;
    email: string;
    phone: string;
    company: string;
    position: string;
    city: string;
    educationLevel: string;
    educationFinishedYear: number;
    affiliation: string;
    mootCourtsExperience?: string;
    willParticipateIn: ParticipationOption;
    will_participate_in_premoots?: "yes" | "no" | null;
    team_coach?: string;
}

export interface ArbitratorLimited {
    _id: string;
    firstname: string;
    middlename: string;
    lastname: string;
    email?: string;
}

// сейчас ивенты можно захардкодить 2шт в том виде, в котором они на макете
export interface Event {
    date: string;
    title: string;
    contents: string; // likely markdown t
}

export interface ValidationErrors {
    errors?: [];
    memberErrors?: { [id: number]: [] }
    coachErrors?: [];
}

// вот это в токене вшито будет
export interface UserInfo {
    roles: ('arbiter' | 'team' | 'admin')[];
    teamname?: string;
}

export interface ExludedItem {
    reason: string;
    datetime?: string;
}

export interface AdminTeamInfo {
    _id: string;
    number: string;
    registered_datetime: string;
    email_confirmation_code: string;
    repeated_confirmation_request_at?: string;
    email_confirmed: boolean;
    documents: TeamDocuments;
    info: TeamData;
    documentsCount?: number;
    excluded?: ExludedItem | null;
    finals_stage?: string | null;
}

export interface AdminArbiterInfo {
    _id: string;
    registered_datetime: string;
    info: ArbitratorSignup;
    onlinerounds: string[];
    online_rounds_confirmation?: 'rejected' | 'confirmed' | null;
    online_rounds_invite_datetime?: string;
    is_duplicate: boolean;
    invite_sent?: boolean;
}

export const OnlineRoundsConfirmationStatus = {
    rejected: 'Отказался',
    confirmed: 'Подтвердил',
}

export interface Question {
    text: string;
    author: string;
    isMy: boolean;
    time: string;
}

export type Message = Question;

export interface QuestionRequest {
    text: string;
}

export interface Chat {
    _id: string;
    teamnbr: string;
    lastMessage: Question | null;
}

export interface Coach {
    name?: string;
    email?: string;
    phone?: string;
}

export interface ContestState {
    registration_enabled: boolean;
    arbiter_registration_enabled: boolean;
    claims_upload_allowed: boolean;
    claims_other_available: boolean;
    responses_upload_available: boolean;
    responses_upload_allowed: boolean;
    is_online_rounds_scoring_active: boolean;
    online_rounds_available: boolean;
    onlinerounds_date_approval_enabled: boolean;
    onlinerounds_results_available: boolean;
    premoots_application_available: boolean;
    finals_available: boolean;
    finals_results_available: boolean;
    team_certificates_available?: boolean;
    conferencing_enabled: boolean;
    case_questions_submission_allowed?: boolean;
    case_questions_answers_available?: boolean;
}

interface Doc {
    doc_id: string;
    kind: "claim1" | "response1";
}

export interface TeamOwnDocumentsIds {
    claim1?: Doc;
    claim2?: Doc;
    response1?: Doc;
    response2?: Doc;
}

export interface ScoringItem {
    _id: string;
    is_scored: boolean;
}

export type TeamScoringInfo = Record<string, ScoringItem>;

export interface OnlineRound {
    _id: string;
    chat_id: string;
    team1_id: string;
    team1_nbr: string;
    team1_documents: TeamOwnDocumentsIds;
    team2_id: string;
    team2_nbr: string;
    team2_documents: TeamOwnDocumentsIds;
    title: string;
    arbitrators: string[];
    arbiter_scoring?: Record<string, { document_scores?: number, performance_scores?: number }>;
    extra_pair?: boolean | null;
    round_time?: string;
    round_time_approvals?: { [key: string]: boolean};
    conferencing_tool?: string;
    conferencing_external_link?: string;
    document_scores?: number;
    performance_scores?: number;
    scoring?: Record<string, TeamScoringInfo>;
}

export interface TextSection {
    type: 'text';
    title: string;
    content?: string;
}

export interface SectionDocument {
    name: string;
    url: string;
    doc_type?: string | null;
}

export interface DocumentsSection {
    type: 'documents';
    title: string;
    description?: string;
    documents?: SectionDocument[];
}

export interface SectionStage {
    name: string;
    dates: string;
    state: StageState;
}

export enum StageState {
    Done = 'done',
    Inprogress = 'inprogress',
    Upcoming = 'upcoming'
}

export interface StagesSection {
    type: 'stages';
    title: string;
    description?: string;
    stage?: SectionStage[];
}

export type ContestTerms = TextSection | DocumentsSection | StagesSection;

export interface TimerInfo {
    deadlineText?: string;
    deadlineTime?: string;
    isOn?: boolean;
    finishedText?: string;
}


export interface Notice {
    text: string;
    isOn: boolean;
}
// под доки схема разговора с апи такая же как с аватарами/фотками employer'ов в Manuwor/Skllled