import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '../util/tokenStorage';
import { useUser, checkContextRole } from '../presentation/UserContext/UserContext';

interface Props {
}

export const RootRedirectContainer: React.FC<Props> = (props: Props) => {
    const userContext = useUser();

    if (!isAuthenticated()) {
        return null;
    } else if (checkContextRole(userContext, 'team')) {
        return (<Redirect to={{ pathname: '/team' }} />)
    } else if (checkContextRole(userContext, 'admin')) {
        return (<Redirect to={{ pathname: '/admin/team' }} />)
    } else {
        return (<div></div>)
    }
}