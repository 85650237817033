import { TeamMember, ArbitratorSignup, TeamSignupData, TeamLogin, TextSection } from "../typings/schema";
import { Moment } from "moment";
import moment from "moment";

export const defaultParticipant: TeamMember = {
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    phone: '',
    education: {
        sameAsFirst: false,
        school: '',
        level: 'BS',
        year: 1
    },
    mootCourtsExperience: ''
}

export const defaultArbiterSignup: ArbitratorSignup = {
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    position: '',
    city: '',
    educationLevel: '',
    educationFinishedYear: 0,
    affiliation: '',
    willParticipateIn: 'both'
}

export const ParticipationOptionName = {
    'both': "И в онлайн, и в устных раундах",
    'online': "Только в онлайн раундах",
    'inperson': "Только в устных раундах",
};

export const PremootParticipationOptionName = {
    yes: "да",
    no: "нет",
    "": "-"
};

export const ParticipationOptionNameShort = {
    'both': "онлайн, устные",
    'online': "только онлайн",
    'inperson': "только устные",
};

export const defaultTeamSignupData: TeamSignupData = {
    email: '',
    phone: '',
    members: [defaultParticipant, defaultParticipant],
    password: '',
    passwordRepeat: ''
}

export const defaultLoginData: TeamLogin = {
    email: '',
    password: ''
}

export const ParentBackgroundColor: string = '#CCCCCC';
export const ChildBackgroundColor: string = '#FFFFFF';

export const FieldsForScores = [
    ["law_handling", "Знание и применение права"],
    ["facts_handling", "Знание фактов"],
    ["answering_questions", "Умение отвечать на вопросы арбитров"],
    ["structure", "Логика, структурность, полнота изложения"],
    ["other", "Иное: профессиональное поведение, зрительный контакт, соблюдение временного регламента"]
];
export const SuitDeadline: Moment = moment('2020-09-27T23:59:59');

export const defaultSection: TextSection = {
    type: 'text',
    title: '',
    content: ''
}

export const boolToYesNo = (v?: boolean) => v ? "да" : "нет";
