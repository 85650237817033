import ExcelJS from "exceljs";
import { downloadBuffer } from "../../../toolympus/api/core";
import { toLocalDatetimeInternal } from "../../../toolympus/api/datetimeUtil";
import { AdminTeamInfo } from "../../../typings/schema";
import { boolToYesNo } from "../../../util/constants";
import { EducationLevels } from "../../Util/common";
import { getUniqueSchools } from "./TeamsData";

export const exportTeams = (teams: AdminTeamInfo[], filename: string) => {
    const workbook = new ExcelJS.Workbook();
    const sheetTeams = workbook.addWorksheet("Команды");
    sheetTeams.columns = [
        ["number", "Номер"],
        ["email", "E-mail"],
        ["phone", "Телефон"],
        ["email_confirmed", "E-mail подтвержден"],
        ["schools", "ВУЗ"],
        ["finals_stage", "В финале"],
        ["registered_datetime", "Дата регистрации"],
        ["excludedDatetime", "Исключена"],
        ["excludedReason", "Причина исключения"],
        ["members_count", "Участников"],
        ["coaches_count", "Тренеров"],
        ["no_coach", "Без тренера"],
        ["documentsCount", "Документов загружено"],
        ["claim1_upload_datetime", "Иск загружен"],
        ["response1_upload_datetime", "Отзыв загружен"],
    ].map(([key, header]) => ({ header, key}));
  
    teams.forEach(team => sheetTeams.addRow({
        ...team,
        ...team.info,
        excludedReason: team.excluded?.reason,
        excludedDatetime: toLocalDatetimeInternal(team.excluded?.datetime),
        registered_datetime: toLocalDatetimeInternal(team.registered_datetime),
        claim1_upload_datetime: toLocalDatetimeInternal(team.documents?.claim1?.loaded_datetime),
        response1_upload_datetime: toLocalDatetimeInternal(team.documents?.response1?.loaded_datetime),

        schools: getUniqueSchools(team.info.members),
        members_count: team.info.members.length,
        coaches_count: (team.info.coaches || []).length,
        finals_stage: boolToYesNo(!!team.finals_stage),
        no_coach: boolToYesNo(!!team.info.no_coach),
        email_confirmed: boolToYesNo(!!team.email_confirmed),
    }));


    const sheetMembers = workbook.addWorksheet("Участники");
    sheetMembers.columns = [
        ["number", "Номер команды"],
        ["lastname", "Фамилия"],
        ["firstname", "Имя"],
        ["middlename", "Отчество"],
        ["email", "E-mail"],
        ["phone", "Телефон"],
        ["excluded", "Команда исключена"],
        ["finals_stage", "В финале"],
        ["school", "ВУЗ"],
        ["level", "Уровень"],
        ["year", "Курс"],
        ["mootCourtsExperience", "Опыт участия в муткортах"],
    ].map(([key, header]) => ({ header, key}));
  
    teams.forEach(team => team.info.members.forEach(member => sheetMembers.addRow({
        ...member,
        ...member.education,
        number: team.number,
        excluded: boolToYesNo(!!team.excluded?.datetime),
        finals_stage: boolToYesNo(!!team.finals_stage),
        level: EducationLevels[member.education.level],
    })));

    const sheetCoaches = workbook.addWorksheet("Тренеры");
    sheetCoaches.columns = [
        ["number", "Номер команды"],
        ["name", "Имя"],
        ["email", "E-mail"],
        ["phone", "Телефон"],
        ["excluded", "Команда исключена"],
        ["finals_stage", "В финале"],
        ["schools", "ВУЗ команды"],
    ].map(([key, header]) => ({ header, key}));
  
    teams.forEach(team => (team.info.coaches || []).forEach(coach => sheetCoaches.addRow({
        ...coach,
        number: team.number,
        excluded: boolToYesNo(!!team.excluded?.datetime),
        finals_stage: boolToYesNo(!!team.finals_stage),
        schools: getUniqueSchools(team.info.members),
    })));
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
  
  }
