import React from 'react';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { usePremootRoundsConfig } from './usePremootRoundsConfig';
import { PremootRoundsTeamList } from './PremootRoundsTeamList';
import { PremootRoundsArbitratorsList } from './PremootRoundsArbitratorsList';

export const PremootRoundsParticipantsPage = () => {
  const tabs = useTabsState([
    ["teams", "Команды"],
    ["arbs", "Арбитры"],
  ], "teams", "tab");

  const data = usePremootRoundsConfig();

  return (
    <div>
      <TabsHeader
        state={tabs}
        noMargin
        />


      <TabPanel state={tabs.forTab("teams")}>
        <PremootRoundsTeamList data={data} />
      </TabPanel>

      <TabPanel state={tabs.forTab("arbs")}>
        <PremootRoundsArbitratorsList data={data} />
      </TabPanel>
    </div>
  );
}
