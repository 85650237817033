import React from 'react';
import { RenderElementProps } from 'slate-react';
import { EditorPlugin } from '../../../../toolympus/components/PowerDoc';
import { createWithVoidInjector } from '../../../../toolympus/components/PowerDoc/plugins/common';
import { TilesBlockElementType, TilesBlockElement } from '../../../../toolympus/components/PowerDoc/plugins/Tiles/TilesBlockElement';
import { LinksTilesBlock } from './LinksTilesBlock';


export const CustomTilesBlockWrap = (props: RenderElementProps) => {
  if(props.element.display_mode === "links") {
    return <LinksTilesBlock {...props} />
  }

  return <TilesBlockElement {...props} />
}

const withTiles = createWithVoidInjector(TilesBlockElementType)

export const CustomTilesPlugin: EditorPlugin = {
  key: "custom-tiles",
  inject: withTiles,
  customBlocks: { [TilesBlockElementType]: CustomTilesBlockWrap },
}