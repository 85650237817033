import { Avatar } from '@mui/material';
import styled from '@emotion/styled';

export const ArbiterAvatar = styled(Avatar)`
    margin: 1rem;
    width: 200px;
    height: 200px;
`

export const AvatarView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
`

export const ArbiterTextFieldView = styled.div`
    margin-bottom: 1rem;
`

export const ArbiterTeamCoachField = styled(ArbiterTextFieldView)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;  


  & > p {
    padding-left: 4px;
  }

  & > .MuiFormControl-root {
    width: 31%;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    flex-flow: row wrap;
   
    & > .MuiFormControl-root {
      width: 100%;
    } 
  }
`;

export const ArbiterTip = styled.div`
    padding: 1.5rem 1rem 0 1rem;
    font-style: italic;
    font-size: 0.9em;
`