import React from 'react'
import { useParams } from 'react-router-dom'
import { useArbiterOnlineRound } from '../Contest/useOnlineRound';
import { ArbiterOnlineRoundPage } from './presentation/ArbiterOnlineRoundPage';

export const ArbiterOnlineRoundContainer = () => {
    const { roundid } = useParams<{ roundid: string}>();
    const roundData = useArbiterOnlineRound(roundid);

    return <ArbiterOnlineRoundPage roundData={roundData} />
}
