import React, { useCallback, useState } from 'react';
import { ActionInstance } from './actions.types';
import { ListEditor } from '../primitives/ListEditor';
import { ActionActionButtons, ActionEditor } from './ActionEditor';
import { generateCode } from '../PowerDoc/plugins/common';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { getActionFromClipboard } from './useActionEdit';

interface Props {
  actions: ActionInstance[];
  update: (updated: ActionInstance[]) => void;
  keepAllExpanded?: boolean;
}

export const ActionsListEditor = (props: Props) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const getIsCollapsed = useCallback((a: ActionInstance) => {
    return !props.keepAllExpanded && !expanded[a._id]
  }, [props.keepAllExpanded, expanded]);

  const setIsCollapsed = useCallback((a: ActionInstance, isCollapsed: boolean) => {
      setExpanded(s => ({ ...s, [a._id]: !isCollapsed }));
  }, []);

  const pasteAction = () => {
    getActionFromClipboard()
      .then(a => {
        if(a) {
          props.update([...props.actions, a]);
        }
      });
  }

  return (
    <ListEditor
      idField="_id"
      items={props.actions}
      update={props.update}
      itemEditor={ActionEditor}
      itemExtraActions={ActionActionButtons}
      setIsCollapsed={setIsCollapsed}
      getIsCollapsed={getIsCollapsed}
      createDflt={() => ({
        _id: generateCode(),
        action_params: {},
      })}
      extraActions={<>
        <Button
          size="small"
          startIcon={<InsertDriveFileOutlined />}
          onClick={pasteAction}>
            <FormattedMessage id="common.paste" />
        </Button>
      </>}
      />
  );
}
