import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { TeamView, ConfirmMessageView } from './TeamStyles.styles';
import SuccessButton from '../Util/SuccessButton';
import { ConfirmationState } from '../../containers/team/ConfirmEmailContainer';
import { PageHeader } from '../Util/PageHeader';

interface Props {
    confirmationState: ConfirmationState;
    onMainClick: () => void;
}

const ConfirmEmailPage: React.FC<Props> = (props: Props) => {
    return (
        <TeamView>
            <PageHeader />
            <div>
                {props.confirmationState === ConfirmationState.Confirmed
                    && <div style={{ display: 'flex' }}>
                        <ConfirmMessageView>
                            <Typography>Email успешно подтвержден</Typography>
                        </ConfirmMessageView>
                        <SuccessButton
                            title="На главную"
                            disabled={false}
                            onClick={() => props.onMainClick()}
                        />
                    </div>}
                {props.confirmationState === ConfirmationState.Loading
                    && <div style={{ display: 'flex' }}>
                        <ConfirmMessageView>
                            <Typography>Подтверждаем email</Typography>
                        </ConfirmMessageView>
                        <CircularProgress />
                    </div>
                }
                {props.confirmationState === ConfirmationState.Error
                    && <div style={{ display: 'flex' }}>
                        <ConfirmMessageView>
                            <Typography>Произошла ошибка</Typography>
                        </ConfirmMessageView>
                    </div>
                }
            </div>
        </TeamView>
    )
}

export default ConfirmEmailPage;