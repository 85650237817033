import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { Select } from '../../../toolympus/components/schemed/Select';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { AdminArbiterInfo } from '../../../typings/schema';

type Template = [string, string];

export interface ArbiterInviteData {
    isActive: boolean;
    arbiter: AdminArbiterInfo | null;
    
    openFor: (arbiter: AdminArbiterInfo) => void;
    cancel: () => void;

    template: string;
    setTemplate: (t: string) => void;
    templateOptions: Template[];
    send: () => void;
    isSending: boolean;

    popup: ReactNode;
}

export const useInviteArbiter = (): ArbiterInviteData => {
    const templateOptions: Template[] = [["arbiter-invite-or", "Приглашение в ОР"]];
    const [template, setTemplate] = useState<string>(templateOptions[0][0]);
    const [arbiter, setArbiter] = useState<AdminArbiterInfo | null>(null);
    const [isSending, setIsSending] = useState<boolean>(false);

    const send = () => {
        if(arbiter && template) {
            setIsSending(true);
            apiFetch(`/api/admin/arbiter/${arbiter._id}/invite?template=${template}`, FetchTypes.POST)
                .then(() => {
                    setIsSending(false);
                    setArbiter(null);
                })
                .catch(e => {
                    setIsSending(false);
                    throw e;
                });
        }
    }

    const data = {
        arbiter,
        isActive: !!arbiter,

        openFor: (a:AdminArbiterInfo) => setArbiter(a),
        cancel: () => setArbiter(null),

        template,
        setTemplate,
        templateOptions,

        send,
        isSending,

        popup: null,
    }

    return {
        ...data,
        popup: <Popup data={data} />
    }
}


const Popup = (props: { data: ArbiterInviteData }) => {
    const { isActive, cancel, arbiter, templateOptions, template, setTemplate, send, isSending } = props.data;
    return <Dialog open={isActive} onClose={cancel}>
        <DialogTitle>
            Пригласить арбитра
        </DialogTitle>
        <DialogContent>
            {arbiter && <Typography>{arbiter.info.lastname} {arbiter.info.firstname} {arbiter.info.middlename}, {arbiter.info.email}</Typography>}
            <Select
                field="template"
                row={{ template }}
                onChange={(o,c) => setTemplate(c.template)}
                schema={{
                    type: FieldType.select,
                    label: "Шаблон письма",
                    values: templateOptions.map(([value,label]) => ({ value, label: `${label} (${value})` })),
                    valueDict: templateOptions.reduce((r, [value, label]) => ({ ...r, [value]: `${label} (${value})` }), {}),
                }}
                />
        </DialogContent>
        <DialogActions>
            <Button onClick={cancel}>отмена</Button>
            <Button variant="contained" color="primary" disabled={isSending || !template} onClick={send}>
                отправить {isSending && <LoadingIndicator sizeVariant="s" />}
            </Button>
        </DialogActions>
    </Dialog>
}
