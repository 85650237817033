import React, { useState, useEffect } from 'react';
import { Divider } from '@mui/material';
import {
    DocumentItemView,
    DocumentPackTitle,
    BottomDocumentText,
    DocumentItemDescription,
    ReviewsList,
    Review,
} from './DocumentsPage.styles';
import { Suit } from './Suit';
import { TeamDocuments, Document } from '../../../typings/schema';
import { PageHeader } from '../../Util/PageHeader';
import { useUser } from '../../UserContext/UserContext';
import { downloadFile } from '../../../util/apiFetch';
import { DownloadableDoc } from './DownloadableDoc';
import { Component } from '../../../toolympus/components/Pages';
import { MarkdownDisplay } from '../../../toolympus/components/schemed/Markdown';
import { StyledLink } from '../../Util/common.styles';
import { CmsPage } from '../../../components/DynamicPages/CmsPage';
import { RenderElementProps } from 'slate-react';
import { extractCustomBlockFields } from '../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { useTeamDocuments } from '../../../containers/team/useTeamDocuments';


interface Props {
    documents: TeamDocuments;
    onFileAdd: (fileId: string, file: any) => void;
    onFileDelete: (fileId: string) => void;
}

type LocalDocument = Document & { isLoading: boolean; }

const defSuit: LocalDocument = { loaded_datetime: '', doc_id: '', doc_name: '', isLoading: false };
const defSuits = { "claim1": defSuit, "claim2": defSuit };


interface DocumentsBlockProps extends Props {
    component: Component;
}

const useDocumentsTexts = (c: Component) => {
    const sections = c.content_text.split(/===+/);
    const [s1,s2,s3,s4] = sections;

    const titleAndTextFromSection = (section: string) => {
        const sectionX = (section || "").trim();
        if(!sectionX) {
            return { title: "", text: "" };
        }
        const firstLineEnds = sectionX.indexOf("\n");
        if(firstLineEnds < 0) {
            return { title: sectionX, text: "" };
        } else {
            return { title: sectionX.substring(0, firstLineEnds), text: sectionX.substring(firstLineEnds+1) };
        }
    }

    return {
        claims: titleAndTextFromSection(s1),
        claimsForResponses: titleAndTextFromSection(s2),
        responses: titleAndTextFromSection(s3),
        onlineRounds: titleAndTextFromSection(s4),
    }
}

const DocumentsBlock = (props: DocumentsBlockProps) => {
    const [claims, setClaims] = useState<{ [id: string]: LocalDocument }>(defSuits);
    const context = useUser();

    useEffect(() => {
        const claimItems: { [id: string]: LocalDocument } = {
            "claim1": props.documents['claim1'] ? { ...props.documents['claim1'], isLoading: false } : defSuit,
            "claim2": props.documents['claim2'] ? { ...props.documents['claim2'], isLoading: false } : defSuit,
            'response1': props.documents['response1'] ? { ...props.documents['response1'], isLoading: false } : defSuit,
            'response2': props.documents['response2'] ? { ...props.documents['response2'], isLoading: false } : defSuit
        };
        setClaims(claimItems);
    }, [props.documents])


    const onFileAdd = (claimId: string, file: any) => {
        let claim = claims[claimId];
        if (claim && !claim.isLoading) {
            let newClaims = { ...claims };

            claim = { ...claim, isLoading: true };
            newClaims[claimId] = claim;

            setClaims(newClaims);
            props.onFileAdd(claimId, file);
        }
    }

    const onFileDelete = (claimId: string) => {
        props.onFileDelete(claimId);
    }
    const downloadSuitForReview = (doc?: Document | null): Promise<void> => {
        if (doc) {
            return downloadFile(`/api/document/${doc.doc_id}`, doc.doc_name);
        }
        return Promise.resolve();
    }

    const { claim1, response1 } = claims;

    const isClaimsUploadAllowed = context.contestState?.claims_upload_allowed || false;
    const isResponsesUploadAllowed = context.contestState?.responses_upload_allowed || false;

    const texts = useDocumentsTexts(props.component);

    return <>
        <Divider />
        {context.contestState?.online_rounds_available && <>
            <DocumentItemView>
                <DocumentPackTitle>
                    {texts.onlineRounds.title}
                </DocumentPackTitle>
                <DocumentItemDescription>
                    <MarkdownDisplay text={texts.onlineRounds.text} /> <StyledLink to="/team/onlineround">Перейти</StyledLink>
                </DocumentItemDescription>
            </DocumentItemView>
            <Divider />
        </>}
        {context.contestState?.responses_upload_available && <>
            <DocumentItemView>
                <DocumentPackTitle>
                    {texts.responses.title}
                </DocumentPackTitle>
                <ReviewsList>
                    {[response1,]
                        .map((response, index) => {
                            const responseIndex = index + 1;
                            return (
                                <Review key={`response-claim-${responseIndex}`}>
                                    <Suit
                                        suitKey={`response${responseIndex}`}
                                        docId={response?.doc_id}
                                        title={`Отзыв`}
                                        isLoaded={response?.loaded_datetime && response?.loaded_datetime.length > 0 ? true : false}
                                        isLoading={response?.isLoading}
                                        fileName={response?.doc_name}
                                        onFileAdd={onFileAdd}
                                        onFileDelete={onFileDelete}
                                        uploadAllowed={isResponsesUploadAllowed} />
                                </Review>
                            );
                        })}
                </ReviewsList>
                <BottomDocumentText>
                    <MarkdownDisplay text={texts.responses.text} />
                </BottomDocumentText>
            </DocumentItemView>
            <Divider />
        </>}
        {context.contestState?.claims_other_available && <>
            <DocumentItemView>
                <DocumentPackTitle>
                    {texts.claimsForResponses.title}
                </DocumentPackTitle>
                <ReviewsList>
                    {[props.documents.claim_other1,]
                        .map((oclaim, index) => {
                            return (
                                <Review key={`other-claim-${index}`}>
                                    <DownloadableDoc
                                        title={`Иск`}
                                        download={() => downloadSuitForReview(oclaim)} />
                                </Review>
                            );
                        })}
                </ReviewsList>
                {texts.claimsForResponses.text.length > 0 && <BottomDocumentText>
                    <MarkdownDisplay text={texts.claimsForResponses.text} />
                </BottomDocumentText>}
            </DocumentItemView>
            <Divider />
        </>}
        <>
            <DocumentItemView>
                <DocumentPackTitle>
                    {texts.claims.title}
                </DocumentPackTitle>
                <ReviewsList>
                    {[claim1,]
                        .map((claim, index) => {
                            const claimIndex = index + 1;
                            return (
                                <Review key={`claim-${claimIndex}`}>
                                    <Suit
                                        suitKey={`claim${claimIndex}`}
                                        docId={claim?.doc_id}
                                        title={`Иск`}
                                        isLoaded={claim?.loaded_datetime && claim.loaded_datetime.length > 0 ? true : false}
                                        isLoading={claim?.isLoading}
                                        fileName={claim?.doc_name}
                                        onFileAdd={onFileAdd}
                                        onFileDelete={onFileDelete}
                                        uploadAllowed={isClaimsUploadAllowed} />
                                </Review>
                            );
                        })}
                </ReviewsList>
                <BottomDocumentText>
                    <MarkdownDisplay text={texts.claims.text} />
                </BottomDocumentText>
            </DocumentItemView>
            <Divider />
        </>
    </>;
}

const DocumentsBlockElement = (props: RenderElementProps) => {
  const fields = extractCustomBlockFields(props.element);
  const data = useTeamDocuments();

  return <div className="no-gutter"><DocumentsBlock component={{ content_text: fields.texts || "" } as Component} {...data} /></div>
}

const Blocks = { documents_upload: DocumentsBlockElement };


export const DocumentsPage = (props: Props) => {
  return (
    <div>
      <PageHeader />
      <Divider />

      <CmsPage slug="team-documents" customBlocks={Blocks} />
    </div>
  )
}
