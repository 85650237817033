import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import ArbiterForm from '../../../presentation/ArbiterForm/ArbiterForm';
import { LoadingView, SaveButtonView } from '../../../presentation/Util/common.styles';
import { PageHeader } from '../../../presentation/Util/PageHeader';
import SuccessButton from '../../../presentation/Util/SuccessButton';
import { ArbitratorSignup, ValidationErrors } from '../../../typings/schema';
import styled from '@emotion/styled';
import { useContestState } from '../../../util/useContestState';
import { ArbitratorCertificateBlock } from '../../../presentation/Team/Documents/TeamCertificateBlock';

interface Props {
    profile: ArbitratorSignup;
    updateProfile: (arb: ArbitratorSignup) => void;
    photoUrl?: string;
    errors?: ValidationErrors;
    onAvatarUpload: (blob: Blob) => void;
    isLoading: boolean;
}

const ArbiterProfileView = styled.div`
    padding: 2rem 0 0 3vw;
    width: 70%;    
    @media (max-width: 600px) {
        display: flex;
        width: 95%;
        justify-content: center;
    }
`

const ArbiterProfilePage: React.FC<Props> = (props: Props) => {
    const [profile, setProfile] = useState<ArbitratorSignup>(props.profile);

    const checkFilled = () => {
        const isFilled = profile?.email && profile?.phone
            && profile?.firstname && profile?.middlename && profile?.lastname
            && profile?.company && profile?.position
            && profile?.educationFinishedYear !== undefined && profile?.educationLevel
            && profile?.affiliation && profile?.mootCourtsExperience ? true : false;
        return isFilled;
    }

    const onSaveButtonClick = () => {
        props.updateProfile(profile);
    }

    const { data: state } = useContestState();

    return (
        <div>
            <PageHeader />
            <ArbiterProfileView>
            <ArbitratorCertificateBlock />
                <ArbiterForm
                    arbiterData={profile}
                    updateArbiter={setProfile}
                    errors={props.errors}
                    photoUrl={props.photoUrl}
                    onAvatarUpload={props.onAvatarUpload}
                    children={
                        <SaveButtonView>
                            {props.isLoading &&
                                <LoadingView>
                                    <CircularProgress />
                                </LoadingView>
                            }
                            <SuccessButton
                                disabled={state.team_certificates_available || !checkFilled() || props.isLoading}
                                onClick={onSaveButtonClick}
                                title={state.team_certificates_available ? "Изменения заблокированы" : "Сохранить"}
                            />
                        </SaveButtonView>
                    }
                />
            </ArbiterProfileView>
        </div>
    )
}

export default ArbiterProfilePage;