import React, { createContext, ReactNode, useContext } from "react";
import { useWebSocket } from "../../hooks/useWebsocket"; 
import { UseChat, useChat } from "./useChat";
import { UseChatList, useChatsList } from "./useChatsList";

export type MessagerContextType = {
    socket?: WebSocket | undefined;
    open: boolean;
    chat: ReturnType<UseChat>,
    chats: ReturnType<UseChatList>
}

const MessagerContext = createContext<MessagerContextType>({
    open: false,
    chat: {
        sendMsg: () => {},
        editMsg: () => {},
        chat: null,
    },
    chats: {
        addChat: () => {},
        list: [],
        selectedChat: null,
        setSelectedChat: () => {},
        loading: true
    }
});

interface Props {
    getSocket: () => Promise<WebSocket>;
    children: ReactNode;
    dontConnect?: boolean;
}

export const MessagerProvider = ({getSocket, children, dontConnect}: Props) => {
    const { open, socket, send } = useWebSocket(getSocket, () => {}, dontConnect);

    const chats = useChatsList(open, send, socket);
    const { _id = null} = chats.selectedChat || {};
    const chat = useChat(_id, socket);


    return <MessagerContext.Provider 
        value={{
            open, socket,
            chats, chat
        }}
    >
        {children}
    </MessagerContext.Provider>
}

export const useMessager = () => useContext(MessagerContext);
