/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Close, ExitToApp } from '@mui/icons-material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from '@emotion/styled/macro';
import { ReactComponent as TitleImg } from '../mozolin_logo.svg';
import { ReactComponent as RIMA } from '../rima_logo.svg';
import { ReactComponent as MenuHideIcon } from './menu_hide.svg';
import { SettingsMenuButton } from '../components/Settings/NotificationSettingsControl';

const FixedHead = styled.div<{show: boolean}>`
    position: fixed;
    height: ${props => props.show? '50px' : '0'};
    width: 100%;
    transition: 150ms;

    background: #032749;
    overflow: hidden;
    z-index: +100;
`;
FixedHead.defaultProps = { className: 'FixedHead' };

const FixedHeadBody = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    padding: 0 15px;
`;
FixedHeadBody.defaultProps = { className: 'FixedHeadBody' };

const ContainerSidebar = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background: #032749;
    height: 100%;
    overflow-y: hidden;

    padding-top: 60px;

    ${props => props.theme.breakpoints.down('sm')} {
        justify-content: space-between;
        padding-top: 30px;
        height: 125px;
    }
`;
ContainerSidebar.defaultProps = { className: 'ContainerSidebar' };

const FixedContainerSidebar = styled(ContainerSidebar)<{open: boolean}>`
    position: fixed;
    overflow: hidden;
    width: ${props => props.open? '100%' : '0'};
    transition: 150ms; 
    height: 100% !important;
    z-index: +100;
    top: 0;
    left: 0;

    padding-top: 15px;
    
    ${props => props.theme.breakpoints.up('md')} {
        display: none;
    }
`;

const Lines = styled.div<{ isMenuOpen?: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: ${props => props.isMenuOpen ? 60 : 34}px;
    padding-right: ${props => props.isMenuOpen ? 0 : 34}px;
    width: 40px;

    ${props => props.theme.breakpoints.down('md')} {
        padding-left: 30px;
    }
`;
Lines.defaultProps = { className: 'Lines' };

const Line = styled.div<{ isEven: boolean }>`
    height: 100%;
    width: 2px;
    background: ${props => props.isEven ? props.theme.palette.primary.main : props.theme.palette.secondary.main};
`;
Line.defaultProps = { className: 'Line' };

const RIMALink = styled.a`
    display: block;

    & > img {
        height: 41px;
    }
`;

const Logout = styled(IconButton)`
    transform: scaleX(-1);
`;
Logout.defaultProps = { size: 'small' };

const MenuBlock = styled.div<{mobile: boolean, open?: boolean}>`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    padding-right: 25px;
    padding-left: 15px;
    padding-bottom: 20px;

    height: 100%;
    width: 100%;
    overflow: hidden;

    box-sizing: border-box;

    ${props => props.theme.breakpoints.up('md')} {
        width: ${props => props.open? '100%' : 0};
        opacity: ${props => props.open? '1' : 0};
        padding-right: ${props => props.open && !props.mobile ? 25 : 0}px;
        padding-left: ${props => props.open ? 15 : 0}px;
    }

    ${props => props.theme.breakpoints.down('md')} {
        & ${RIMALink}, & ${Logout}, & .settings-button  {
            display: ${props => !props.open ? 'none' : 'initial'};
        }
    }

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: ${props => props.mobile? '1fr' : '1fr max-content'};
        padding-bottom: 10px;
        width: 100%;
    }
`;
MenuBlock.defaultProps = { className: 'MenuBlock' };

const TitleLogo = styled(TitleImg)<{mobile: boolean}>`
    margin: 0 0 78px;

    ${props => props.theme.breakpoints.down('sm')} {
        height: 75px;
        width: 176px;
        margin-bottom: ${props => props.mobile? '30px' : '0'};
    }

    ${props => props.theme.breakpoints.down('xs')} {
        margin: 0 0 50px;
        line-height: 19px;
    }
`;

export const MenuHide = styled.a<{ isMenuShown?: boolean}>`
    position: absolute;
    top: 22px;
    right: ${props => props.isMenuShown ? 26 : 45}px;
    cursor: pointer;
    
    & > svg {
        transform: scaleX(${props => props.isMenuShown ? "1" : "-1"});
        width: 16px;
        height: 20px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        display: none;
    }
`;


const Menu = styled.div<{mobile: boolean}>`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: auto;
    padding-bottom: 10px;
    box-sizing: border-box;

    ${props => props.theme.breakpoints.down('sm')} {
        display: ${props => props.mobile? 'flex' : 'none'};
    }

    &::-webkit-scrollbar {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
Menu.defaultProps = { className: 'Menu' };

const MenuItem = styled(RouterLink)<{active: boolean}>`
    margin: 5px 0;

    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 10px;

    letter-spacing: 0.01em;

    text-decoration: none;

    color: ${props => props.active ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
`;
MenuItem.defaultProps = { className: 'MenuItem' };

const Bottom = styled.div<{mobile: boolean}>`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: white;
    max-width: 350px;
    padding-top: 10px;

    & a {
        font-style: normal;
        text-decoration: none;

        color: ${props => props.theme.palette.secondary.main};
    }

    ${props => props.theme.breakpoints.down('sm')} {
        justify-self: end;
        text-align: right;
        max-width: 300px;

        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        
        display: ${props => props.mobile? 'block' : 'none'};
    }
`;
Bottom.defaultProps = { className: 'Bottom' };

const MenuToggle = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 25px;
    height: 20px;

    & span {
        border: 1px solid white;
        width: 100%;
    }

    ${props => props.theme.breakpoints.up('md')} {
        display: none;
    }
`;
MenuToggle.defaultProps = { className: 'MenuToggle' };

const BottomAndMenuToggle = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    ${props => props.theme.breakpoints.down('sm')} {
        align-items: flex-end;
        height: 100%;
    }
`;
BottomAndMenuToggle.defaultProps = { className: 'BottomAndMenuToggle' };

export interface Link { 
    path: string;
    name: ReactNode;
    alsoActivateForPath?: (p: string) => boolean;
}

interface SideBarProps {
    links: Link[];
    logout: (() => void) | null
}

const Toggle = styled.div`
    position: absolute;
    top: -50px;

    height: 50px;
    width: 40px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    & .MuiSvgIcon-root {
        color: white;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        display: none
    }
`;
Toggle.defaultProps = { className: 'Toggle' };

const ContactTextBlock = styled.div`
    margin-bottom: 12px;

    & > span {
        width: auto;
    }
    
    ${props => props.theme.breakpoints.up('md')} {
        margin-bottom: 30px;
        & > span:nth-child(2) {
            border-bottom: 1px solid #ffffff;
            padding-bottom: 12px;
        }
    }
`;

const BottomRowWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
`;

export const Sidebar = ({ links, logout }: SideBarProps) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const ref = useRef<HTMLElement | null>(null);
    const [show, setShow] = useState(false);
    const [openMenuX, setOpenMenu] = useState<boolean | null>(null);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const openMenu = openMenuX === null ? isDesktop : openMenuX;

    const bottomText = (mobile: boolean) => 
        <Bottom mobile={mobile}>
            <ContactTextBlock>
                <span>
                    Вы можете связаться с командой<br/>
                </span>
                <span>
                    организаторов по email <a href="mailto:corp@mootcourt.ru">corp@mootcourt.ru</a>
                </span>
            </ContactTextBlock>
            
            <RIMALink href="https://modernarbitration.ru/" target="_blank" rel="noopener noreferrer">
                <RIMA />
            </RIMALink>
            {logout && <>
                <div />
                <BottomRowWrapper>
                    <Logout 
                        onClick={() => {
                            logout();
                            if(mobile) {
                                setOpenMenu(false);
                            }
                        }} 
                        color="primary"
                    >
                        <ExitToApp />
                    </Logout>
                    <SettingsMenuButton />
                </BottomRowWrapper>
            </>}
        </Bottom>

    const sideBar = (mobile: boolean) => <>
            <Lines isMenuOpen={openMenu}>
                {false && !mobile && <Toggle onClick={() => setOpenMenu(!openMenu)}>{!openMenu? <ArrowForwardIosIcon/> : <ArrowBackIosIcon />}</Toggle>}
                <Line isEven={false} />
                <Line isEven={true} />
                <Line isEven={false} />
                <Line isEven={true} />
            </Lines>
            {!mobile && <MenuHide onClick={() => setOpenMenu(!openMenu)} role="button" isMenuShown={openMenu}><MenuHideIcon /></MenuHide>}

            <MenuBlock open={openMenu} mobile={mobile}>
                <Box display="flex" justifyContent="space-between" color="white">
                    <TitleLogo mobile={mobile} />
                    {mobile && <div onClick={() => setOpenMenu(false)}><Close /></div>}
                </Box>
                <Menu mobile={mobile}>
                    {links.map(link => 
                        <MenuItem 
                            to={link.path}
                            onClick={(e) => {
                                if(mobile) {
                                    setOpenMenu(false);
                                }
                            }} 
                            key={link.path}
                            active={currentPath.replace(/\//g, '') === link.path.replace(/\//g, '') || ((link.alsoActivateForPath || false) && link.alsoActivateForPath(currentPath))}
                        >
                            {link.name}
                        </MenuItem>
                    )}
                </Menu>
                {mobile ? 
                    bottomText(mobile)
                    :
                    <BottomAndMenuToggle>
                        {menuToggle}
                        {bottomText(mobile)}
                    </BottomAndMenuToggle>
                }
            </MenuBlock>
        </>

    const menuToggle = <>
        <MenuToggle onClick={() => setOpenMenu(true)}>
            <span />    
            <span />
            <span />
        </MenuToggle>
        <FixedContainerSidebar open={openMenu}>
            {sideBar(true)}
        </FixedContainerSidebar>
    </>

    useEffect(() => {
        const { current } = ref;

        if (current && current.parentElement) {
            const parent = current.parentElement;
            
            parent.addEventListener('scroll', () => {
                setShow(
                    parent.scrollTop > current.clientHeight - 50
                    && document.body.clientWidth < 850
                );
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    return <>
        <FixedHead show={show}>
            <FixedHeadBody>
                {menuToggle}
            </FixedHeadBody>
        </FixedHead>
        <ContainerSidebar ref={ref as any}>
            {sideBar(false)}
        </ContainerSidebar>
    </>
}