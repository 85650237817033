import React from 'react';
import { TableForFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { Coach } from '../../../typings/schema';

const schema: Schema = {
    name: { label: "ФИО", type: FieldType.text },
    email: { label: "Email", type: FieldType.text },
    phone: { label: "Телефон", type: FieldType.text },
};

export const TeamCoachesTable = (props: { coaches: Coach[] }) => {
  return (
    <TableForFields
      schema={schema}
      fields={[["name"], ["email"], ["phone"]]}
      data={props.coaches || []}
      />
  )
}

