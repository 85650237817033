import React from 'react';
import styled from '@emotion/styled';
import { CertificateSettings, CertificateSettingsSchema, useArbitratorCertificate, useTeamCertificate } from '../../../containers/team/useTeamCertificate';
import { Button, Typography } from '@mui/material';
import { LoadingIndicator, Dialog, FormGrid } from '../../../toolympus/components/primitives';
import { GetAppOutlined } from '@mui/icons-material';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { FormControlsForFields } from '../../../toolympus/components/schemed';


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
  margin: 1.5rem 0;
  padding-left: 18px;
  border-left: 4px solid ${props => props.theme.palette.primary.main};
`;
Wrapper.defaultProps = { className: "team-certificate-block" };

interface Props {
  coachesCount?: number;
}

export const TeamCertificateBlock = (props: Props) => {
  const certificate = useTeamCertificate(true);

  return certificate.isAvailabile
    ? (<Wrapper>
        <Typography>Ваш сертификат об участии в конкурсе</Typography>
        <Button
          startIcon={<GetAppOutlined />}
          endIcon={certificate.forTeam.isRunning && <LoadingIndicator sizeVariant="s" color="inherit" />}
          color="primary"
          variant="contained"
          disabled={!certificate.forTeam.canRun}
          onClick={() => certificate.forTeam.run()}
          >
            скачать сертификат
        </Button>
        {new Array(props.coachesCount || 0).fill(0).map((x,i) => (
          <React.Fragment key={i}>
            <Typography>Сертификат тренера {(props.coachesCount || 0) > 1 ? i+1 : ""}</Typography>
            <Button
              startIcon={<GetAppOutlined />}
              color="primary"
              variant="contained"
              onClick={() => certificate.downloadCoachCertificate(i)}
              >
                скачать сертификат
            </Button>
          </React.Fragment>
        ))}
      </Wrapper>)
    : null;
}



export const ArbitratorCertificateBlock = (props: Props) => {
  const certificate = useArbitratorCertificate(true);

  return certificate.isAvailabile
    ? (<Wrapper>
        <Typography>Ваш сертификат об участии в конкурсе</Typography>
        <Button
          startIcon={<GetAppOutlined />}
          endIcon={certificate.forArbitrator.isRunning && <LoadingIndicator sizeVariant="s" color="inherit" />}
          color="primary"
          variant="contained"
          disabled={!certificate.forArbitrator.canRun}
          onClick={() => certificate.forArbitrator.run()}
          >
            скачать сертификат
        </Button>
      </Wrapper>)
    : null;
}

interface DownloadCertificateDialogProps {
  data: EditItemProps<CertificateSettings>;
}

export const DownloadCertificateDialog = ({ data }: DownloadCertificateDialogProps) => {
  return <Dialog
    close={data.cancel}
    isOpen={data.isEditing}
    dialogTitle="Скачать сертификат"
    noFullscreen
    actions={
      <Button
        color="primary"
        endIcon={data.isLoading && <LoadingIndicator sizeVariant="s" />}
        onClick={() => data.save().then(() => data.cancel())}>
          скачать
      </Button>}>
      <FormGrid columns="1fr" noMargin>
        <FormControlsForFields
          fields={[
            ["template"],
            ["names", { autoRows: true }],
          ]}
          schema={CertificateSettingsSchema}
          data={data.item}
          onChange={(o,c) => data.update(c)}
          />
      </FormGrid>
  </Dialog>
}
