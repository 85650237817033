import React from 'react';
import styled from '@emotion/styled';
import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { PageHeaderView, PageSectionPadded, PageSectionHeader, ProgressIndicator } from '../../../presentation/Util/common.styles';
import { useArbiterOnlineRoundsConfirmation, useArbiterOnlineRoundsList } from '../../Contest/useOnlineRound';
import { Link } from 'react-router-dom';
import { ArbiterORConfirmation } from '../../Contest/ArbiterORConfirmation';
import { CmsPage } from '../../DynamicPages/CmsPage';


const RoundItem = styled(ListItemText)`
    &::first-letter {
        text-transform: capitalize;
    }
`;

const SuccessText = styled(Typography)`
    color: ${props => props.theme.palette.success.main};
`;
const FailureText = styled(Typography)`
    color: ${props => props.theme.palette.error.main};
`;

const TotalArbiterScoresPerRound = 2*(2+2); 

const ArbitratorOnlineRoundsList = () => {
  const rounds = useArbiterOnlineRoundsList();

  return (
    <PageSectionPadded>
      <PageSectionHeader>Мои раунды</PageSectionHeader>
      {rounds.isLoading && <ProgressIndicator isShown />}
      <List>
          {rounds.rounds.map(r => {
              const scoresCount = rounds.getCountScoresSet(r._id);
              return (
                  <ListItem button component={Link} to={`/arbiter/onlineround/${r._id}`}>
                      <RoundItem
                          primary={r.title}
                          secondary={scoresCount && scoresCount === TotalArbiterScoresPerRound ?
                              <SuccessText>{scoresCount} / {TotalArbiterScoresPerRound} оценок</SuccessText> : 
                              <FailureText> {scoresCount || 0} / {TotalArbiterScoresPerRound} оценок</FailureText>} />
                  </ListItem>
          )})}
      </List>
    </PageSectionPadded>
  );
}

const ConfirmationBlock = () => {
  const confirmation = useArbiterOnlineRoundsConfirmation(() => {});
  return <ArbiterORConfirmation confirmation={confirmation} />;
}

export const ArbiterOnlineRoundsListPage = () => {

    return (
        <div>
            <PageHeaderView useMargin={true}>
                Онлайн раунды
            </PageHeaderView>
            <Divider />

            <CmsPage
              slug="arbiter-onlinerounds"
              customBlocks={{
                rounds: ArbitratorOnlineRoundsList,
                confirmation: ConfirmationBlock,
              }}
              />
        </div>
    );
}
