import { LoadedData, useLoadedData } from "../../../../hooks/useLoadedData";
import { Schema } from "../../../../hooks/useSchema";
import { PlayerStanding } from "../types";
import { PlayerStandingSchema } from "../useGridStageResults";

export interface PublicResult {
    stage_code: string;
    standing: PlayerStanding[];
}

export interface PublicResults extends LoadedData<PublicResult[]> {
    schema: Schema;
}

export const usePublicResults = (apiPath: string, kind: 'arbiter' | 'player') => {
    const data = useLoadedData<PublicResult[]>(`${apiPath}/${kind}/result`, []);

    return {
        ...data,
        schema: PlayerStandingSchema,
    }
}
