import ExcelJS from "exceljs";
import { downloadBuffer } from "../../../toolympus/api/core";
import { toLocalDatetimeInternal } from "../../../toolympus/api/datetimeUtil";
import { AdminArbiterInfo, OnlineRoundsConfirmationStatus } from "../../../typings/schema";
import { boolToYesNo, ParticipationOptionNameShort, PremootParticipationOptionName } from "../../../util/constants";

const OnlineRoundsConfirmationLabel: Record<string,string> = OnlineRoundsConfirmationStatus;

export const exportArbitrators = (arbitrators: AdminArbiterInfo[], filename: string) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Арбитры");
    sheet.columns = [
        ["email", "E-mail"],
        ["lastname", "Фамилия"],
        ["firstname", "Имя"],
        ["middlename", "Отчество"],
        ["phone", "Телефон"],
        ["city", "Город"],
        ["position", "Должность"],
        ["company", "Компания"],
        ["educationLevel", "Образование"],
        ["educationFinishedYear", "Год окончания"],
        ["affiliation", "Аффилированность"],
        ["team_coach", "Тренер"],
        ["mootCourtsExperience", "Опыт в муткортах"],
        ["willParticipateIn", "Участие"],
        ["will_participate_in_premoots", "Участие в премутах"],
        ["online_rounds_confirmation", "Подтверждение ОР"],
        ["onlinerounds", "Онлайн раундов"],
        ["online_rounds_reject_code", "Код для отказа от ОР"],
        ["invite_sent", "Приглашение отправлено"],
        ["is_duplicate", "Дубликат"],
        ["registered_datetime", "Регистрация"],
    ].map(([key, header]) => ({ header, key}));
  
    arbitrators.forEach(arbitrator => sheet.addRow({
        ...arbitrator,
        ...arbitrator.info,
        registered_datetime: toLocalDatetimeInternal(arbitrator.registered_datetime),
        onlinerounds: (arbitrator.onlinerounds || []).length,
        online_rounds_confirmation: OnlineRoundsConfirmationLabel[arbitrator.online_rounds_confirmation || ""] || "?",
        will_participate_in_premoots: PremootParticipationOptionName[arbitrator.info.will_participate_in_premoots || ""],
        willParticipateIn: ParticipationOptionNameShort[arbitrator.info.willParticipateIn || ""],
        is_duplicate: boolToYesNo(arbitrator.is_duplicate),
        invite_sent: boolToYesNo(arbitrator.invite_sent),
    }));
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
  
  }
