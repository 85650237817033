import { useNewItem } from "../../../toolympus/api/useNewItem";

export interface CoachApplication {
  _id: number;
  created_datetime: string;
  approval_status?: string;

  email: string;
  phone: string; 
  lastname: string; 
  firstname: string; 
  middlename: string; 

  university: string; 
  job: string;
  professional_interests: string; 
  experience: string; 
  participation: string; 
  comments: string; 
  pdc_consent: boolean;
}

export interface CoachApplicationNew extends Omit<CoachApplication, "_id" | "created_datetime" | "approval_status"> {
}

export const useCoachRegistration = () => {
  const data = useNewItem<CoachApplicationNew, CoachApplication>(
    "/api/coach/register",
    {
      email: "",
      phone: "",
      lastname: "",
      firstname: "",
      middlename: "",
      university: "",
      job: "",
      professional_interests: "",
      experience: "",
      participation: "",
      comments: "",
      pdc_consent: false,
    },
    {
      autoStartEdit: true,
    }
  );

  return {
    ...data,
  }
}
