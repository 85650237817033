import React, { useEffect, useState } from 'react';
import { useUser } from '../../presentation/UserContext/UserContext';
import { ArbitratorSignup, ValidationErrors } from '../../typings/schema';
import { apiFetch, apiUploadFile, fetchGet, FetchTypes, ValidationError } from '../../util/apiFetch';
import { handleValidationError } from '../../util/common';
import { LoadingStub } from '../LoadingStub';
import { useSnackbar } from '../Snackbar';
import ArbiterProfilePage from './presentation/ArbiterProfilePage';

interface Props {

}

const fetchProfile = () => {
    return fetchGet<ArbitratorSignup>('/api/arbiter/info');
}

const putProfile = (profile: ArbitratorSignup) => {
    return apiFetch<ArbitratorSignup>('/api/arbiter/info', FetchTypes.PUT, profile);
}

const uploadPhoto = (userId: string, photo: Blob) => {
    return apiUploadFile(`/api/arbiter/photo/${userId}`, 'file', photo);
}

interface ContainerState {
    isLoading: boolean;
    errors?: ValidationErrors;
}

const defaultState: ContainerState = { isLoading: false, errors: undefined };

const ArbiterProfileContainer: React.FC<Props> = (props: Props) => {
    const [state, setState] = useState<ContainerState>(defaultState);
    const [profile, setProfile] = useState<ArbitratorSignup>();
    const [avatar, setAvatar] = useState<Blob>();
    const { showSaveSuccess } = useSnackbar();

    const userContext = useUser();

    const updateProfile = (profile: ArbitratorSignup) => {
        setState({ ...state, isLoading: true });
        putProfile(profile)
            .then(updated => {
                setProfile(updated);
                if (avatar) {
                    return uploadPhoto(userContext.user._id, avatar);
                }
                return;
            })
            .then(() => {
                setState({ ...state, isLoading: false, errors: undefined });
                showSaveSuccess();
            })
    }

    const handleError = (e: any) => {
        if (e instanceof ValidationError) {
            const caught = e.caughtErrors;
            const parsedErrors = handleValidationError(caught);
            setState(s => { return { ...s, isLoading: false, errors: parsedErrors } });
        }
        else {
            setState(s => { return { ...s, isLoading: false } });
            throw e;
        }
    }

    const uploadAvatar = (blob: Blob) => {
        setAvatar(blob);
    }

    useEffect(() => {
        setState(s => { return { ...s, isLoading: true } });
        fetchProfile()
            .then(profile => {
                setState(s => { return { ...s, isLoading: false } });
                setProfile(profile)
            })
            .catch(error => handleError(error))
    }, []);

    if (!profile) return <LoadingStub />

    return (
        <ArbiterProfilePage
            profile={profile}
            updateProfile={updateProfile}
            photoUrl={`/api/arbiter/photo/${userContext.user._id}`}
            errors={state.errors}
            onAvatarUpload={uploadAvatar}
            isLoading={state.isLoading}
        />
    )
}

export default ArbiterProfileContainer;