import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { ArbiterList } from '../../../containers/admin/useArbiterList';
import { OnlineRound } from '../../../typings/schema';
import { ParticipationOptionNameShort } from '../../../util/constants';
import { CancelOutlined, Check, Help } from '@mui/icons-material';

const DialogLine = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 0.5rem;
`;

const TableStyled = styled(Table)`
`;

const SearchField = styled(TextField)`
    width: 300px;
`;

const DialogStyled = styled(Dialog)`
`;

const DialogContentStyled = styled(DialogContent)`
`;

const AssingedListItemContent = styled(ListItemText)`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
`;

const ScoresWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
`;

interface Props {
    round?: OnlineRound;
    close: () => void;
    arbiterList: ArbiterList;
    updateArbitrators: (arbitratorIds: string[]) => void;
}

export const TextStyles = {
    good: { color: '#00cc00'},
    bad: { color: '#cc0000'},
}

export const EditRoundDialog = (props: Props) => {
    const { round, close, arbiterList, updateArbitrators } = props;
    const [sortByRounds, setSortByRounds] = useState<boolean>(true);

    const arbiters = sortByRounds ?
        [...(arbiterList.arbiters || [])].sort((a, b) => (a.onlinerounds || []).length - (b.onlinerounds || []).length)
        : arbiterList.arbiters;

    return (
        <DialogStyled
            open={!!round}
            onClose={close}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle style={{ textTransform: 'capitalize' }}>
                {round?.title}
            </DialogTitle>
            <DialogContentStyled>
                <DialogLine key="assigned">
                    <Typography>
                        Назначенные арбитры:
                    </Typography>
                    <List>
                        {(round?.arbitrators || []).map(arbId => {
                            const arbiter = (arbiterList.arbitersDict || {})[arbId];
                            const scoring = (round?.arbiter_scoring || {})[arbId];
                            const docScores = scoring?.document_scores || 0;
                            const perfScores = scoring?.performance_scores || 0;
                            return <ListItem>
                                <AssingedListItemContent disableTypography>
                                    <IconButton size="small" onClick={() => updateArbitrators((round?.arbitrators || []).filter(aId => aId !== arbId))}>
                                        <CancelOutlined />
                                    </IconButton>
                                    {[arbiter.info.lastname, arbiter.info.firstname, arbiter.info.middlename].join(' ')}
                                    <IconButton size="small" >
                                        {arbiter.online_rounds_confirmation === "confirmed" ? <Check style={TextStyles.good} /> : <Help style={TextStyles.bad} />}
                                    </IconButton>
                                    <ScoresWrapper>
                                        <Typography variant="caption">Оценки. Документы:</Typography>
                                        <Typography style={docScores === 4 ? TextStyles.good : TextStyles.bad}>{docScores}</Typography>
                                        <Typography variant="caption">Выступления:</Typography>
                                        <Typography style={perfScores === 4 ? TextStyles.good : TextStyles.bad}>{perfScores}</Typography>
                                    </ScoresWrapper>
                                </AssingedListItemContent>
                            </ListItem>
                        })}
                    </List>
                </DialogLine>
                
                <DialogLine key="add">
                    <Typography>
                        Назначить
                    </Typography>
                    <div>
                        <SearchField
                            label="Поиск"
                            value={arbiterList.textFilter.value}
                            onChange={e => arbiterList.textFilter.setValue(e.target.value)} />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e, checked) => setSortByRounds(checked)}
                                    checked={sortByRounds}
                                />
                            }
                            label="Отсортировать по числу раундов"
                        />
                    </div>
                </DialogLine>
                <TableStyled>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'></TableCell>
                            <TableCell align='center'>Фамилия</TableCell>
                            <TableCell align='center'>Имя</TableCell>
                            <TableCell align='center'>Отчество</TableCell>
                            <TableCell align='center'>Место работы</TableCell>
                            <TableCell align='center'>Аффилированность</TableCell>
                            <TableCell align='center'>Формат участия</TableCell>
                            <TableCell align='center'>Число раундов</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arbiters.map(a => (
                            <TableRow key={a._id}>
                                <TableCell align='left'>
                                    <Button
                                        variant="outlined"
                                        disabled={(round?.arbitrators || []).length === 3 || (round?.arbitrators || []).includes(a._id)}
                                        onClick={() => updateArbitrators([...(round?.arbitrators || []), a._id])}>
                                            Назначить
                                    </Button>
                                </TableCell>
                                <TableCell align='center'>{a.info.lastname}</TableCell>
                                <TableCell align='center'>{a.info.firstname}</TableCell>
                                <TableCell align='center'>{a.info.middlename}</TableCell>
                                <TableCell align='center'>{a.info.company}</TableCell>
                                <TableCell align='center'>{a.info.affiliation}</TableCell>
                                <TableCell align='center'>{ParticipationOptionNameShort[a.info.willParticipateIn]}</TableCell>
                                <TableCell align='center'>{(a.onlinerounds || []).length}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableStyled>
            </DialogContentStyled>
        </DialogStyled>);
}