import React from "react";
import { TeamMember } from "../../../typings/schema";
import { TableContainer, Table, TableCell, TableBody, TableHead, TableRow } from "@mui/material";
import { getEducationLabel } from "../../Util/common";


export const TeamMembersTable = (props: { members: TeamMember[] }) => {
  return (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Фамилия</TableCell>
                    <TableCell align="center">Имя</TableCell>
                    <TableCell align="center">Отчество</TableCell>
                    <TableCell align="center">Контактный email</TableCell>
                    <TableCell align="center">Контактный телефон</TableCell>
                    <TableCell align="center">ВУЗ</TableCell>
                    <TableCell align="center">Уровень образования</TableCell>
                    <TableCell align="center">Курс</TableCell>
                    <TableCell align="center">Опыт участия в муткортах</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(props.members || []).map((m, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">{m.lastname}</TableCell>
                        <TableCell align="center">{m.firstname}</TableCell>
                        <TableCell align="center">{m.middlename}</TableCell>
                        <TableCell align="center">{m.email}</TableCell>
                        <TableCell align="center">{m.phone}</TableCell>
                        <TableCell align="center">{m.education.school}</TableCell>
                        <TableCell align="center">{getEducationLabel(m.education.level)}</TableCell>
                        <TableCell align="center">{m.education.year}</TableCell>
                        <TableCell align="center">{m.mootCourtsExperience}</TableCell>
                    </TableRow>
                    ))}
            </TableBody>
        </Table>
    </TableContainer>
  )
}
