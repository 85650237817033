import { downloadBuffer } from "../../toolympus/api/core";
import { toLocalDatetimeInternal } from "../../toolympus/api/datetimeUtil";
import { OpenRegistrationReviewData, RegistrationsData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations"
import { useTabsState } from "../../toolympus/components/primitives";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { Schema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { VolunteerApplication } from "../DynamicPages/Registrations/useVolunteerRegistration"
import ExcelJS from "exceljs";

const VolunteerAdminApiPath = "/api/volunteer/admin";

interface VolunteerApplicationX extends Omit<VolunteerApplication, "_id"> {
  _id: string;
}

interface VolunteersApplicationsData extends RegistrationsData<VolunteerApplicationX> {
  review: OpenRegistrationReviewData<VolunteerApplicationX>;
}

export const exportVolunteers = (items: VolunteerApplicationX[], schema: Schema, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Волонтеры");
  sheet.columns = [
    "_id",
    "email",
    "phone",
    "approval_status",
    "lastname",
    "firstname",
    "middlename",
    "participation",
    "created_datetime",
  ].map(f => ({ header: schema[f].label?.toString() || f, key: f }));

  items.forEach(item => sheet.addRow({
      ...item,
      created_datetime: toLocalDatetimeInternal(item.created_datetime),
  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));

}

export const useVolunteerApplications = (): VolunteersApplicationsData => {
  const tabs = useTabsState([
    ["new", "Новые"],
    ["approved","Утвержденные"],
    ["rejected","Отказ"],
    ["all","Все"],
  ]);
  const data = useLoadedData<VolunteerApplicationX[]>(`${VolunteerAdminApiPath}?view=${tabs.current}`, []);
  const filter = useTextFilter<VolunteerApplicationX>(a => `${a.email} ${a.lastname} ${a.firstname}`);
  const { schema } = useSingleSchema(`${VolunteerAdminApiPath}/uiconfig`);

  const review = useOpenRegistrationReview<VolunteerApplicationX>({
    apiPath: VolunteerAdminApiPath,
    onSaved: () => data.reload(),
  });

  const filteredData = filter.filterData(data.data); 
  
  return {
    ...data,
    data: filteredData,
    ...filter,
    schema,
    tabs,
    review,
    exportData: () => exportVolunteers(filteredData, schema, "Волонтеры.xlsx"),
  }
}
