import React from 'react';
import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import { LargeAvatar } from '../../presentation/Util/Avatar';
import { ArbitratorLimited } from '../../typings/schema';
import { LinkView } from '../../presentation/Util/common.styles';

const Name = styled(Typography)`
`;

Name.defaultProps = { component: 'span' } as any;

const NameEmpty = styled(Name)`
    font-style: italic;
`;

const ArbitratorContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;

    padding-bottom: 1rem;

    & > ${LargeAvatar} {
        margin-bottom: 1.2rem;
    }
`;

export const Arbitrators = styled.div`
    padding-top: 1rem;
    @media(min-width: 601px) {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
    }
    @media(max-width: 600px) {
        display: flex;
        flex-flow: column;
        align-items: center;

        & > ${ArbitratorContainer} {
            margin-bottom: 2rem;
        }
    }
`;

interface Props {
    arbitrator: ArbitratorLimited;
}

export const Arbitrator = (props: Props) => {
    const { arbitrator } = props;
    return <ArbitratorContainer>
        {/* length > 1 handles the special case of fake (not assigned) arbitrator */}
        <LargeAvatar size={150} src={(arbitrator._id || ' ').length > 1 ? `/api/arbiter/photo/${arbitrator._id}` : undefined} />
        {arbitrator.firstname && (<>
            <Name>{arbitrator.firstname}</Name>
            <Name>{arbitrator.middlename}</Name>
            <Name>{arbitrator.lastname}</Name>
            {arbitrator.email && <LinkView style={{ borderBottom: "none" }} href={`mailto:${arbitrator.email}`}>{<Email />}</LinkView>}
        </>)}
        {!arbitrator.firstname && <NameEmpty>еще не назначен</NameEmpty>}
    </ArbitratorContainer>
}
