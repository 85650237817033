import React, { useEffect, useState } from 'react';
import { TeamContainerProps } from '../../typings/TeamContainerProps';
import ConfirmEmailPage from '../../presentation/Team/ConfirmEmailPage';
import { useHistory } from 'react-router-dom';
import { fetchPost } from '../../util/apiFetch';

interface Props {
    isConfirmed?: boolean;
    code?: string | null;
}

export enum ConfirmationState {
    Loading = 1,
    Confirmed,
    Error
}

type PageProps = TeamContainerProps & Props;

const confirmEmail = (code: string) => {
    return fetchPost(`/api/confirm`, { code });
}

const ConfirmEmailContainer: React.FC<PageProps> = (props: PageProps) => {
    const [confirmation, setConfirmation] = useState<ConfirmationState>(props.isConfirmed ? ConfirmationState.Confirmed : ConfirmationState.Loading);
    const history = useHistory();

    useEffect(() => {
        if (props.isConfirmed){
            setConfirmation(ConfirmationState.Confirmed)
        }
        else if (!props.isConfirmed && props.code) {
            setConfirmation(ConfirmationState.Loading);
            confirmEmail(props.code)
            .then(response => setConfirmation(ConfirmationState.Confirmed))
            .catch(error => {
                setConfirmation(ConfirmationState.Error);
                throw error;
            })
        }
    }, [props.isConfirmed, props.code])

    const onMainClick = () => {
        history.push('/team');
    }

    return (
        <ConfirmEmailPage
            confirmationState={confirmation}
            onMainClick={onMainClick}
        />
    )
}

export default ConfirmEmailContainer;