import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { GenericTextField } from '../Util/UtilTextfields';
import { SignupTextfieldView, LoginButtonView, BottomTipView, LoginWrapper, LoginForm } from './LoginPage.styles';
import { TeamLogin } from '../../typings/schema';
import { Anchor, Link, Title } from '../../Common/Primitives';
import { Hint } from '../Util/common.styles';

interface Props {
    onLogin: (data: TeamLogin) => void;
    loginData: TeamLogin;
    onLoginDataChange: (data: TeamLogin) => void;
}

const LoginPage: React.FC<Props> = (props: Props) => {
    const [loginData, setLoginData] = useState<TeamLogin>(props.loginData);

    const onSubmit = (e: any) => {
        e.preventDefault();
        props.onLogin(loginData);
    }

    return (
        <LoginWrapper>
            <LoginForm onSubmit={e => {e.preventDefault(); onSubmit(e)}}>
                <Title marginBottom="20px" noLine>Войти в личный кабинет</Title>
                <Hint marginBottom="50px">
                    Еще не зарегистрировались? <Link href="/signup">Заполните форму.</Link>
                </Hint>
                <SignupTextfieldView>
                    <GenericTextField
                        value={loginData?.email}
                        onChange={e => setLoginData({ ...loginData, email: e })}
                        label="Контактный email"
                        type="email"
                    />
                </SignupTextfieldView>
                <SignupTextfieldView>
                    <TextField
                        style={{ width: '100%' }}
                        required
                        value={loginData?.password}
                        onChange={e => setLoginData({ ...loginData, password: e.target.value })}
                        variant="outlined"
                        type="password"
                        label="Пароль"
                    />
                </SignupTextfieldView>

                <BottomTipView>
                    Забыли пароль? <br />
                    Пройдите по <Link href="/reset-password">ссылке</Link> для восстановления.<br/><br/>
                </BottomTipView>

                <LoginButtonView>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={loginData?.password && loginData?.email ? false : true}
                        type="submit"
                        fullWidth
                    >
                        Войти
                    </Button>
                </LoginButtonView>

                
                <BottomTipView>
                    Не получается войти в систему? <br />
                    Свяжитесь с организаторами по email: <br />
                    <Anchor href="mailto:corp@mootcourt.ru">corp@mootcourt.ru</Anchor>
                </BottomTipView>
            </LoginForm>
            
        </LoginWrapper>
    )
}

export default LoginPage;