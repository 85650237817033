import { useLoadedData } from '../../hooks/useLoadedData';
import { ActionConfig } from './actions.types';
import { useApiConfiguration } from '../ApiConfguration';
import { createProvided } from '../primitives/createProvided';
import { FieldType, Schema, createSelectSchema, mergeSchema } from '../../hooks/useSchema';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

export interface IActionsConfig {
  actions: ActionConfig[];
  isLoading: boolean;
  reload: () => void;
  ensureLoaded: () => void;
  schema: Schema;
}

const ActionSchema: Schema = {
  action_type: { label: "Type", label_id: "extras.actions.fields.action_type", type: FieldType.select, values: [] },
  label: { label: "Label", label_id: "extras.actions.fields.label" },
}

const useActionsConfigLoaded = (apiPath?: string): IActionsConfig => {
  const apiConfig = useApiConfiguration();
  const apiPathX = apiPath || apiConfig.actionsApiPath;
  const [doLoad,setDoLoad] = useState<boolean>(false);

  const data = useLoadedData<ActionConfig[]>(apiPathX || "", [], !!apiPathX && doLoad);
  const { formatMessage } = useIntl();

  const schema = useMemo(() => {
    const typesOptions = data.data.map(a => ({
      value: a.action_type,
      label: a.label_id ? formatMessage({ id: a.label_id }) : (a.label || ""),
    }));

    return mergeSchema(
      ActionSchema,
      { action_type: createSelectSchema(typesOptions) }
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  const ensureLoaded = useCallback(() => setDoLoad(true), []);

  return {
    actions: data.data,
    isLoading: data.isLoading,
    reload: data.reload,
    schema,
    ensureLoaded,
  };
}

export const {
  Provider: ActionsConfigProvider,
  context: ActionsConfigContext,
  useValue: useActionsConfig
} = createProvided(
  useActionsConfigLoaded,
  {
    actions: [],
    isLoading: false,
    reload: () => {},
    ensureLoaded: () => {},
    schema: ActionSchema,
  },
)
