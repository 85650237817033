import { css, Global } from '@emotion/react';
import React from 'react';
import { GridConfigurationContainer } from '../../toolympus/components/Contests/Grid';
import { SpeakersResults } from './SpeakersResults';

const gridFormStyles = css`
    .grid-form {
        padding: 0 0 0.5rem !important;
        
        & > .form-header {
            display: none;
        }

        & > .form-body > :not(:first-child) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
`;

export const FinalsConfigurationPage = () => {
    return <>
        <GridConfigurationContainer
            outerFormClassName="grid-form"
            apiPath="/api/v2/final-rounds"
            tagsApiPath="/api/conflict/tag"
            extraTabs={{
                tabs: [["speakers", "Результаты спикеров"]],
                components: {
                    speakers: SpeakersResults,
                },
            }}
            />
        <Global styles={gridFormStyles} />
    </>;
}
