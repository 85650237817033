import { useEffect, useState } from "react";
import { FullChat, MessageType } from "./types";

export type UseChat = (id: string | null, socket?: WebSocket) => {
    sendMsg: (message: string) => void,
    editMsg: (id: string, message: string) => void,
    chat: FullChat | null,
};

export const useChat: UseChat = (id, socket) => {

    const [chat, setChat] = useState<FullChat | null>(null);

    useEffect(() => {
        id && socket && socket.send(JSON.stringify({_type: 'get-chat', _id: id}));
    }, [socket, id]);

    useEffect(() => {
        if (id) {
            const onMessage = (ev: MessageEvent) => {
                const data = JSON.parse(ev.data);

                switch ((data._type as MessageType)) {
                    case MessageType.chat:
                        setChat(data);
                        break;
                    case MessageType.message:
                        if (data.chat_id === chat?._id) {
                            setChat({...chat as FullChat, messages: [...(chat as FullChat).messages, data]});
                        }
                        break;
                    case MessageType.edit_message:
                        if (data.chat_id === chat?._id) {
                            setChat({...chat as FullChat, messages: ((chat as FullChat).messages || []).map(
                                m => m._id === data._id ? { ...m, text: data.text, edit_time: data.edit_time } : m,
                            )});
                        }
                        break;
                }
            }

            socket && socket.addEventListener('message', onMessage);

            return () => socket && socket.removeEventListener('message', onMessage);
        }
    }, [socket, id, chat]);

    return {
        chat,
        sendMsg: (message: string) => {
            id && socket && socket.send(JSON.stringify({_type: 'message', chat_id: id, text: message}))
        },
        editMsg: (messageId: string, messageText: string) => {
            id && socket && socket.send(JSON.stringify({_type: 'edit-message', chat_id: id, _id: messageId, text: messageText}))
        },
    };
}
