import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './presentation/App/App';
import * as serviceWorker from './serviceWorker';
import './fonts/Helvetica-Regular.woff';
import './fonts/Helvetica-Medium.woff';
import './fonts/UniversLTCYR-45Light.woff';
import './fonts/UniversLTCYR-55Roman.woff';
import './fonts/UniversLTCYR-57Condensed.woff';
import './fonts/UniversLTCYR-65Bold.woff';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
