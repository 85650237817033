import { useEffect, useState } from "react"
import { apiFetch, FetchTypes } from "../toolympus/api/core";
import { Notice } from "../typings/schema";
import { getContestInfo, updateContestInfo } from "./contestInfo";

const fetchMaintenance = (): Promise<Notice> => apiFetch<Notice>('/api/info/notice', FetchTypes.GET, undefined, { noAuth: true } );

const defaultNotice: Notice = { text: '', isOn: false };

export const useMaintenance = (): Notice => {
    const [notice, setNotice] = useState<Notice>(defaultNotice);

    useEffect(() => {
        fetchMaintenance()
            .then(n => setNotice(n))
    }, [])

    return notice;
}

export interface MaintenanceEdit {
    notice: Notice;
    updateNotice: (changes: Partial<Notice>) => void;
    save: () => Promise<Notice>;
}

export const useMaintenanceEdit = (): MaintenanceEdit => {
    const [notice, setNotice] = useState<Notice>(defaultNotice);
    useEffect(() => {
        getContestInfo().then(data => setNotice(data.notice));
    }, []);

    const save = () => updateContestInfo({ notice }).then(data => data.notice);

    return {
        notice,
        updateNotice: (changes: Partial<Notice>) => setNotice(n => ({ ...n, ...changes })),
        save,
    }
}
