import { IconButton, useTheme } from '@mui/material';
import { Clear, Check, GetAppOutlined } from '@mui/icons-material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ButtonOptionPicker } from '../../toolympus/components/primitives/ButtonOptionPicker';
import { SaveButton } from '../../toolympus/components/primitives/Buttons';
import { DeleteButton } from '../../toolympus/components/primitives/DeleteButton';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { useTabsState, TabsHeader, TabPanel } from '../../toolympus/components/primitives/Tabs';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { PremootApplication, PremootEditData, usePremootEdit, usePremootEditOrg } from './premootData';

interface Props {
    isForOrganizer?: boolean;
    data: PremootEditData;
}

export const PremootEditPageBase = ({ isForOrganizer, data }: Props) => {
    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            data={data.data}
            fields={fields}
            schema={data.schema}
            onChange={(o,c) => data.update(c)}
            />
    );

    const disabledForOrg = isForOrganizer ? { disabled: true } : {};

    const tabsState = useTabsState([
        ["info", "Информация"],
        ["applications", "Заявки"],
    ], undefined, 'tab');

    const theme = useTheme();

    const documentStatus = (kind: string, row: PremootApplication) => {
        const isHere = !!(kind === "s1" ? row.doc1 : row.doc2);
        return (
            <IconButton
                size="small"
                onClick={() => data.downloadDocument(row.team_id, kind)}
                style={{ color: isHere ? theme.palette.success.main : theme.palette.error.main }}>
                {isHere ? <Check /> : <Clear />}
            </IconButton>
        );
    };

    return (
        <Form
            title="Премут"
            headerItems={(
                <>
                    <OccupyFreeSpace />
                    {data.hasChanges && <SaveButton action={() => data.save()} />}
                    {!isForOrganizer && <DeleteButton title="Удалить премут?" remove={() => data.remove()} />}
                </>
            )}>
            <FormGrid columns="2fr 1fr">
                {controls([
                    ["title"],
                    ["is_published", disabledForOrg],
                    ["organizers_ids", { ...disabledForOrg, wrapperStyle: { gridColumn: "span 2" }}]
                    ])}
            </FormGrid>

            <TabsHeader state={tabsState} scrollable />

            <TabPanel state={tabsState.forTab("info")}>
                <FormGrid columns="1fr">
                    {controls([
                        ["brief_description"],
                        ["full_description"],
                        ])}
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabsState.forTab("applications")}>
                <ActionRow>
                    <OccupyFreeSpace />
                    <ButtonOptionPicker
                        options={[
                            ["Утвержденные", "premoot-approved"],
                            ["Не утвержденные организатором", "premoot-unapproved-org"],
                            ["Не утвержденные администратором", "premoot-unapproved-adm"],
                            ["Все", "premoot"],
                        ]}
                        selected={data.applicationsView}
                        setSelected={data.setApplicationsView} />
                    <IconButton size="small" onClick={() => data.exportApplications()}><GetAppOutlined /></IconButton>
                </ActionRow>
                
                <TableForFields
                    data={data.applications}
                    schema={data.applicationsSchema}
                    onChange={data.updateApplication}
                    fieldElement={f => {
                        switch(f) {
                            case "doc1":
                                return row => documentStatus("s1", row);
                            case "doc2":
                                return row => documentStatus("s2", row);
                            default:
                                return null;
                        }
                    }}
                    fields={[
                        ["teamnbr"],
                        ["schools"],
                        ["created_datetime"],
                        ["doc1", { width: 60 }],
                        ["doc2", { width: 60 }],
                        ["is_approved_org", { width: 80, editable: isForOrganizer }],
                        ["is_approved_adm", { width: 100, editable: !isForOrganizer }],
                    ]}
                    />
            </TabPanel>
            
        </Form>
    );
}

export const PremootEditPage = () => {
    const { id } = useParams<{ id: string }>();
    const data = usePremootEdit(id);

    return <PremootEditPageBase data={data} />
}

export const PremootEditPageForOrganizer = () => {
    const data = usePremootEditOrg();

    return <PremootEditPageBase data={data} isForOrganizer />
    
}