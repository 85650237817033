import React from 'react';
import { useUser } from '../../presentation/UserContext/UserContext';
import { Message } from '../../toolympus/components/Chats/types';
import { useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';

import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { renderMessageText } from '../../toolympus/components/Chats';

interface QuestionItemViewProps {
    our: boolean;
}

const MessageView = styled.div`
    align-self: ${ (props: QuestionItemViewProps) =>
        props.our ? "flex-end" : "flex-start"};
    background: ${ (props: QuestionItemViewProps) =>
        props.our ? '#0083b3' : '#405E7D'};
    color: white;

    /* flex: 0; */
    height: auto;

    padding: 6px 8px;
    margin: 16px 8px 0px 8px;
    border-radius: 12px;
    border-top-left-radius: ${ (props: QuestionItemViewProps) => !props.our ? "0" : "12px" };
    border-bottom-right-radius: ${ (props: QuestionItemViewProps) => props.our ? "0" : "12px" };
    max-width: 60%;
    min-width: 30%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;

    & span {
        font-style: italic;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        max-width: 75%;
    }

    & > p, & > div > p {
        line-height: 1.2em;
        margin: 0.5em 0 0;
        max-width: 100%;
        overflow-wrap: break-word;
        font-weight: 300;
    }
`

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 2em;

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices 
            iOS safari doesn't support gap
        */
        & > :first-child {
            margin-right: 2em;
        }

    }
`;

const AuthorView = styled(Typography)`
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 400;
    &::first-letter {
        text-transform: uppercase;
    }
`;

AuthorView.defaultProps = { component: 'span' } as any;

const TimeView = styled(Typography)`
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 100;
`;

TimeView.defaultProps = { component: 'span' } as any;

interface Props {
    message: Message;
    authorName: string;
}

export const ChatMessage = ({ message, authorName }: Props) => {
    const { formatDatetimeShort } = useFormats();
    const { user } = useUser();

    const isOwnMessage = !!user && user._id === message.author_id;
    
    return (
        <MessageView our={isOwnMessage}>
            <InfoWrapper>
                <AuthorView>{authorName}</AuthorView>
                <TimeView>{formatDatetimeShort(utc.toLocal(message.time))}</TimeView>
            </InfoWrapper>
            {renderMessageText(message.text)}
        </MessageView>
    );
}
