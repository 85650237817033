import React from 'react';
import { EditItemProps, NewItemProps } from '../../toolympus/api/useNewItem';
import { CaseQuestion } from './useTeamQuestionsData';
import { DeleteButton, Dialog, FormGrid } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Button, IconButton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';

interface Props {
  data: EditItemProps<CaseQuestion> | NewItemProps<CaseQuestion, CaseQuestion>;
  isNew?: boolean;
  remove?: (id: string) => void;
  schema: Schema;
  teamNbrById: (id: string) => string;
}

export const AdminQuestionEditDialog = (props: Props) => {
  const { data } = props;

  return (
    <Dialog
      dialogTitle={props.isNew ? "Создать вопрос по фабуле" : "Вопрос по фабуле"}
      isOpen={data.isEditing}
      close={() => data.cancel()}
      titleActions={<>
        {props.remove &&
          <DeleteButton
            remove={() => { props.remove && props.remove(data.item?._id || ""); return Promise.resolve({}); }}
            title="Удалить вопрос"
            preventGoBack
            button={<IconButton size="small"><DeleteOutlined /></IconButton>}
            />}
      </>}
      actions={<>
        <Button color="primary" variant="contained" onClick={() => data.save()}>сохранить</Button>
        <Button onClick={() => data.cancel()}>закрыть</Button>
      </>}
      noFullscreen>
      {data.item && <>
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            fields={[
              props.isNew ? null : ["team_id", { readOnly: true, controlProps: { value: props.teamNbrById(data.item?.team_id || "") } }],
              ["question", { autoRows: true }],
              ["answer", { autoRows: true }],
            ]}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={props.schema}
            errors={data.errors}
            />
        </FormGrid>
        <FormGrid columns="1fr 1fr" style={{ alignItems: "end" }} noMargin>
          <FormControlsForFields
            fields={[
              ["created_datetime", { utcToLocal: true, readOnly: true }],
              ["is_answer_published"],
            ]}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={props.schema}
            errors={data.errors}
            />
        </FormGrid>
      </>}
    </Dialog>
  );
}
