import React from 'react';
import styled from '@emotion/styled';
import { 
    PageHeaderBar,
    PageContent,
    PageSection,
    PageSectionHeader,
    PrimaryButton, ProgressIndicator
} from '../../../presentation/Util/common.styles';
import { ExportDocumentsData } from '../useExportDocuments';


interface Props extends ExportDocumentsData {
}

const Buttons = styled.div`
    & > * {
        margin-right: 2rem;
    }
`;

export const DocumentsExportSection = (props: Props) => {
    const { exportClaims1, exportClaims2, exportResponses1, exportResponses2, isLoading } = props;

    return (<>
            <PageSection>
                <PageSectionHeader>Выгрузка исков по командам</PageSectionHeader>

                <Buttons>
                    <PrimaryButton onClick={exportClaims1.action} disabled={isLoading}>
                        <ProgressIndicator isShown={exportClaims1.isLoading} invertColor />Иски №1
                    </PrimaryButton>
                    <PrimaryButton onClick={exportClaims2.action} disabled={isLoading}>
                        <ProgressIndicator isShown={exportClaims2.isLoading} invertColor />Иски №2
                    </PrimaryButton>
                </Buttons>
            </PageSection>

            <PageSection>
                <PageSectionHeader>Выгрузка отзывов по командам</PageSectionHeader>

                <Buttons>
                    <PrimaryButton onClick={exportResponses1.action} disabled={isLoading}>
                        <ProgressIndicator isShown={exportResponses1.isLoading} invertColor />Отзывы №1
                    </PrimaryButton>
                    <PrimaryButton onClick={exportResponses2.action} disabled={isLoading}>
                        <ProgressIndicator isShown={exportResponses2.isLoading} invertColor />Отзывы №2
                    </PrimaryButton>
                </Buttons>
            </PageSection>            
        </>);
}

export const DocumentsExportPage: React.FC<Props> = (props: Props) => {
    const { isLoading } = props;

    return (
        <div>
            <PageHeaderBar>
                <div />
                <ProgressIndicator isShown={isLoading} />
            </PageHeaderBar>
            <PageContent>
                <DocumentsExportSection {...props} />
            </PageContent>
        </div>
    )
}