import { useContestStateProvided } from "../../util/useContestState";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useEffect, useState } from "react";

export interface CaseQuestion {
  _id?: string;
  team_id?: string;
  question: string;
  answer?: string;
  created_datetime?: string;
}

const TeamQuestionsApiPath = "/api/question/team";
const PublicQuestionsApiPath = "/api/question/public";

export const useTeamQuestionsData = () => {
  const { data: contestState } = useContestStateProvided();
  const newQuestion = useNewItem<CaseQuestion, CaseQuestion>(TeamQuestionsApiPath, { question: "" }, {
    autoStartEdit: true,
  });

  const [isSubmittedSuccessfully,setIsSubmittedSuccessfully] = useState<boolean>(false);
  useEffect(() => {
    if(isSubmittedSuccessfully) {
      setTimeout(() => setIsSubmittedSuccessfully(false), 2000);
    }
  }, [isSubmittedSuccessfully]);

  const myQyestions = useLoadedData<CaseQuestion[]>(TeamQuestionsApiPath, []);
  const allQuestions = useLoadedData<CaseQuestion[]>(PublicQuestionsApiPath, [], !!contestState.case_questions_answers_available);

  return {
    contestState,
    newQuestion: {
      ...newQuestion,
      save: () => {
        newQuestion.save()
          .then(() => {
            newQuestion.startEditing({ question: "" });
            myQyestions.reload();
            setIsSubmittedSuccessfully(true);
          });
      }
    },
    isSubmittedSuccessfully,
    myQyestions,
    allQuestions,
    isLoading: newQuestion.isLoading || myQyestions.isLoading,
  }
}
