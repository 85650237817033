import React from "react";
import { SuitTitle, SuitItemView, IconView, SuccesFileLoadView, LoadedFileTitle, DeleteButtonView, DropzoneView } from "./DocumentsPage.styles";
import Dropzone from 'react-dropzone'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { LinkView } from "../../Util/common.styles";
import { downloadFile } from "../../../util/apiFetch";
import { useSnackbar } from "notistack";

interface SuitProps {
    title?: string;
    suitKey?: string;
    docId?: string;
    isLoaded?: boolean;
    fileName?: string;
    onFileAdd: (suitId: string, file: any) => void;
    onFileDelete: (suitId: string) => void;
    isLoading: boolean;
    uploadAllowed: boolean;
}

export const Suit: React.FC<SuitProps> = (props: SuitProps) => {
    const snack = useSnackbar();
    const onDocumentDelete = () => {
        if (props.suitKey) {
            props.onFileDelete(props.suitKey);
        }
    }

    const onDocumentAdd = (files: any[]) => {
        if (files && props.suitKey && files.length === 1) {
            props.onFileAdd(props.suitKey, files[0]);
        }
    }

    return (
        <div>
            <SuitTitle>
                {props.title}
            </SuitTitle>
            <SuitItemView>
                {props.isLoaded || props.isLoading ?
                    <SuccesFileLoadView>
                        <IconView>
                            {props.isLoading ?
                                <CircularProgress /> :
                                <CheckCircleOutline fontSize="inherit" />
                            }
                        </IconView>
                        <div>
                            <LoadedFileTitle title={props.fileName}>{props.fileName}</LoadedFileTitle>
                            <div>
                                {props.isLoading ?
                                    'загружается'
                                    :
                                    <LinkView onClick={() => downloadFile(`/api/document/${props.docId}`, `${props.fileName}`)}>
                                        {'успешно загружен'}
                                    </LinkView>}
                            </div>
                            {(props.isLoaded && props.uploadAllowed) && <DeleteButtonView onClick={onDocumentDelete}>Удалить</DeleteButtonView>}
                        </div>
                    </SuccesFileLoadView> :
                    <Dropzone
                        onDrop={acceptedFiles => onDocumentAdd(acceptedFiles)}
                        accept="application/pdf"
                        onDropRejected={() => snack.enqueueSnackbar("Файл не подходит. Принимаются только файлы в формате PDF", { variant: 'error', autoHideDuration: 5000 })}
                        multiple={false}
                        disabled={!props.uploadAllowed}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <DropzoneView {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>{props.uploadAllowed ? 'Кликните для загрузки или перетащите документ в это поле' : 'Срок загрузки документов вышел'}</p>
                            </DropzoneView>
                        )}
                    </Dropzone>}
            </SuitItemView>
        </div>
    )
}