import axios from 'axios';
import { getApiTokenForRequest } from './tokenStorage';
import { apiFetch as apiFetchT } from '../toolympus/api/core';

export enum FetchTypes {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export class ConnectivityError extends Error {
    constructor() {
        super("Failed to connect");
    }
}

export class UnauthenticatedError extends Error {
    constructor() {
        super("Authentication missing");
    }
}

export class ValidationError extends Error {
    public caughtErrors: { [property: string]: any };
    public errors: { [property: string]: any };
    public error_code: string;

    constructor(code: string | undefined, errors: any) {
        super("Validation failed")
        this.caughtErrors = errors;
        this.errors = errors;
        this.error_code = code || "error.validation";
    }
}

const reasonErrorMapping: { [message: string]: string } = {
    "email-registered": "Пользователь с данной почтой уже зарегистрирован.",
    "invalid-code": "Неверный код для подтверждения.",
    "pdf-only-allowed": "Файл должен быть в формате pdf."
}

export class CustomError extends Error {
    constructor(message: string) {
        super("Ошибка! " + message);
    }
}

export function apiFetch<T>(url: string, method: FetchTypes = FetchTypes.GET, body: any = undefined, extraParams: any = undefined): Promise<T> {
    return apiFetchT<T>(url, method, body, extraParams)
        .catch(error => {
            if (!error.response) {
                throw new ConnectivityError();
            } else if (error.response?.data?.errors) {
                throw new ValidationError(error.response.data.error_code, error.response.data.errors);
            } else if (error.response?.data?.reason) {
                const message = reasonErrorMapping[error.response.data.reason] ?? error.response.data.reason;
                throw new CustomError(message);
            }
            else {
                throw error;
            }
        });
}

export const apiFetchFile = (url: string) => apiFetch<Blob>(url, FetchTypes.GET, undefined, { responseType: 'blob' });

export function fetchGet<T>(url: string): Promise<T> {
    return apiFetch<T>(url, FetchTypes.GET);
}

export function fetchPost<T>(url: string, body: any) {
    return apiFetch<T>(url, FetchTypes.POST, body);
}

export function fetchFile(url: string): Promise<Blob> {
    return axios.get(url, { responseType: 'blob' })
        .then(({ data }) => data);
}

export function apiUploadFile(url: string, formfield: string, file: any): Promise<any> {
    const data = new FormData();
    data.append(formfield, file);
    return axios.put(url, data, {
        headers: {
            "Authorization": getApiTokenForRequest(),
        },
        timeout: 120000,
    })
        .catch(error => {
            if (error.response.status === 401) {
                throw new UnauthenticatedError();
            } else {
                throw error;
            }
        });
}

export function downloadFile(url: string, filename?: string) {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    return apiFetchFile(url)
        .then(blob => {
            let objectUrl = window.URL.createObjectURL(blob);
            
            anchor.href = objectUrl;
            anchor.download = filename || `${new Date().toLocaleDateString}-file.pdf`;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
}