import { downloadBuffer } from "../../toolympus/api/core";
import { toLocalDatetimeInternal } from "../../toolympus/api/datetimeUtil";
import { Schema } from "../../toolympus/hooks/useSchema";
import { PremootApplication } from "./premootData";
import ExcelJS from "exceljs";

export const exportPremootApplicationsToXlsx = (applications: PremootApplication[], schema: Schema, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Заявки");
  sheet.columns = [
    "teamnbr",
    "schools",
    "created_datetime",
    "doc1",
    "doc2",
    "is_approved_org",
    "is_approved_adm",
  ].map(f => ({ header: schema[f].label?.toString() || f, key: f }));

  applications.forEach(item => sheet.addRow({
      ...item,
      created_datetime: toLocalDatetimeInternal(item.created_datetime),
      doc1: item.doc1 ? "есть" : "",
      doc2: item.doc2 ? "есть" : "",
      is_approved_org: item.is_approved_org ? "да" : "",
      is_approved_adm: item.is_approved_adm ? "да" : "",
  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
}
