import { SignupContainer } from "../../containers/SignupContainer";
import { LoginContainer } from "../../containers/LoginContainer";
import { ContentApp } from "../ContentApp/ContentApp";
import { ArbiterSignupContainer } from "../../containers/ArbiterSignupContainer";
import { RequestRestorePasswordContainer } from "../../containers/RequestRestorePasswordContainer";
import { ChangePasswordContainer } from "../../containers/ChangePasswordContainer";
import { ScoreFormContainer } from "../../components/ScoreForm/ScoreFormContainer";
import { SignupInvitePage } from "../Signup/SignupInvitePage";
import { ArbiterORRejectPage } from "../../containers/ArbiterORRejectPage";
import { TermsPageNew } from "../../components/DynamicPages/TermsPageNew";
import { CoachRegistrationPage } from "../../components/DynamicPages/CoachRegistrationPage";
import { VolunteerRegistrationPage } from "../../components/DynamicPages/VolunteerRegistrationPage";
import { ScorePremootFormContainer } from "../../components/ScoreForm/ScorePremootFormContainer";

export const AppRoutes = [
    { path: '/scoreround', component: ScoreFormContainer },
    { path: '/score-premoot-round', component: ScorePremootFormContainer },
    { path: '/signup', component: SignupContainer },
    { path: '/signup-invite', component: SignupInvitePage },
    { path: '/arbitersignup', component: ArbiterSignupContainer },
    { path: '/arbiter-reject-or', component: ArbiterORRejectPage },
    { path: '/login', component: LoginContainer },
    { path: '/reset-password', component: RequestRestorePasswordContainer },
    { path: '/restore-access', component: ChangePasswordContainer },
    { path: '/terms', component: TermsPageNew },
    { path: '/coach-registration', component: CoachRegistrationPage },
    { path: '/volunteer-registration', component: VolunteerRegistrationPage },
    { path: '', component: ContentApp }
];
