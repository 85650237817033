import { useState, ReactNode, useMemo } from 'react';
import { TabFilter, TextFilter, useTabFilter, useTextFilter } from '../../util/useFilter';
import { AdminArbiterInfo, OnlineRound } from '../../typings/schema';
import { apiFetch, FetchTypes } from '../../util/apiFetch';
import { useActionWithLoading } from '../../util/useWithLoading';
import { UseActions } from '../../toolympus/components/StatusAction';
import { useOnlineRoundsArbsAutoassign } from './useOnlineRoundsArbsAutoassign';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { ArbiterList, useArbiterList } from './useArbiterList';
import { EditItemProps, useEditItem2 } from '../../toolympus/api/useNewItem';

interface OnlineRoundUpdate {
    arbitrators: string[];
}

export interface ArbitratorScoringStats {
  isScored: boolean;
  totalDocs: number;
  totalPerf: number;
  scoredDocs: number;
  scoredPerf: number;
  unscoredCount: number;
}

export interface ArbitratorScoringStatsExt extends ArbitratorScoringStats {
  arbitrator: AdminArbiterInfo;
  arbitrator_id: string;
}

export interface OnlineRoundsList {
    rounds: OnlineRound[];
    textFilter: TextFilter<OnlineRound>;
    tabFilter: TabFilter<OnlineRound>;
    isMatched: boolean;
    isLoading: boolean;
    initiateMatching: () => void;
    cancelMatching: () => void;
    updateRound: (roundId: string, changes: OnlineRoundUpdate) => Promise<void>;
    actions: ActionsData;
    arbiters: ArbiterList;
    arbitratorsScoringData: ArbitratorScoringStatsExt[];
    roundConference: EditItemProps<OnlineRound>;
}

const matchForOnlineRounds = () => apiFetch<OnlineRound[]>("/api/contest/match/onlinerounds", FetchTypes.POST);
const cancelMatchForOnlineRounds = () => apiFetch<OnlineRound[]>("/api/contest/match/onlinerounds", FetchTypes.DELETE);
const updateRound = (roundId: string, changes: OnlineRoundUpdate) => 
    apiFetch<OnlineRound>(`/api/contest/match/onlinerounds/${roundId}`, FetchTypes.PUT, changes);

export const useOnlineRoundsList = (): OnlineRoundsList => {
    const { data: rounds, setData: setRounds, reload, isLoading: isRoundsLoading } = useLoadedData<OnlineRound[]>("/api/contest/match/onlinerounds", []);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const arbiters = useArbiterList();

    const textFilter = useTextFilter<OnlineRound>(round => `${round.title} ${(round.arbitrators || []).map(a => arbiters.arbitersDict[a]?.info?.lastname || "" ).join(" ")}`);
    const tabFilter = useTabFilter<OnlineRound>("all", [
            ["all", "Все"],
            ["time_approved", "Время согласовано", r => Object.values(r.round_time_approvals || {}).filter(v => v).length === 5],
            ["time_not_approved", "не согласовано", r => Object.values(r.round_time_approvals || {}).filter(v => v).length < 5],
            ["arbiters_assigned", "Арбитры назначены", r => r.arbitrators.length === 3],
            ["arbiters_unassigned", "не назначены", r => r.arbitrators.length !== 3],
        ]);

    const actions = useOnlineRoundsActions(() => reload());

    const initiateMatching = () => {
        setIsLoading(true);
        matchForOnlineRounds()
            .then(setRounds)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    }

    const cancelMatching = () => {
        setIsLoading(true);
        cancelMatchForOnlineRounds()
            .then(setRounds)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    }

    const updateRound_ = (roundId: string, changes: OnlineRoundUpdate) => {
        return updateRound(roundId, changes)
            .then(round => setRounds(rounds.map(r => roundId === r._id ? round : r)));
    }

    const arbitratorsScoringData = useMemo(() => {
      if(!Object.keys(arbiters.arbitersDict).length || !rounds.length) {
        return [];
      }
      
      const resultByArb = rounds.reduce<Record<string, ArbitratorScoringStats>>(
        (r,round) => {
          Object.entries(round.arbiter_scoring || {}).forEach(([aId,scoring]) => {
            if(!r[aId]) {
              r[aId] = { isScored: true, totalDocs: 0, totalPerf: 0, scoredDocs: 0, scoredPerf: 0, unscoredCount: 0 };
            }
            const aRecord = r[aId];
            const isThisScored = scoring.document_scores === 4 && scoring.performance_scores === 4;
            aRecord.isScored = aRecord.isScored && isThisScored;
            aRecord.totalDocs += 4;
            aRecord.totalPerf += 4;
            aRecord.scoredDocs += scoring.document_scores || 0;
            aRecord.scoredPerf += scoring.performance_scores || 0;
            aRecord.unscoredCount += (8 - (scoring.document_scores || 0) - (scoring.performance_scores || 0))
          });
          return r;
        },
        {}
      );
      return Object.entries(resultByArb)
        .map(([aId, status]) => ({ arbitrator: arbiters.arbitersDict[aId], arbitrator_id: aId, ...status }))
        .sort((a,b) => a.unscoredCount < b.unscoredCount ?
          1
          : (a.unscoredCount > b.unscoredCount
            ? -1
            : ((a.arbitrator?.info?.lastname || "") < (b.arbitrator?.info?.lastname || "")) ? -1 : 1));
    }, [rounds, arbiters.arbitersDict]);

    const roundConference = useEditItem2<OnlineRound>({});

    return {
        rounds: rounds.filter(r => textFilter.filter(r) && tabFilter.filter(r)),
        textFilter,
        tabFilter,
        isLoading: isLoading || isRoundsLoading,
        isMatched: rounds.length > 0,
        initiateMatching,
        cancelMatching,
        updateRound: updateRound_,
        actions,
        arbiters,
        arbitratorsScoringData,
        roundConference,
    }
}

interface ActionsData {
    data: UseActions;
    extraActions: { label: string, action: () => void }[];
    popups: ReactNode;
}


export const useOnlineRoundsActions = (refresh: () => void): ActionsData => {
    const autoassign = useOnlineRoundsArbsAutoassign(refresh);

    const sendInvites = () => apiFetch("/api/contest/match/onlinerounds/invite-arbitrators", FetchTypes.POST)
        .then(() => refresh());

    const sendInvitesAction = useActionWithLoading(sendInvites);
    
    return {
        data: {
            data: [],
            loading: autoassign.isLoading || sendInvitesAction.isLoading,
            onAction: () => { },
            reload: () => { },
        },

        extraActions: [
            { label: "автоназначение арбитров", action: autoassign.start },
            { label: "пригласить арбитров", action: sendInvitesAction.action },
        ],

        popups: autoassign.popup,
    }
}