import React from 'react';
import { NotificationsActiveOutlined } from '@mui/icons-material';
import { MessageIconMenu } from '../../toolympus/components/Chats';
import { Chat } from '../../toolympus/components/Chats/types';

interface UnreadIndicatorProps {
    isThatChat?: (c: Chat) => boolean;
}

export const ChatsUnreadIndicator = (props: UnreadIndicatorProps) => {
    return (
        <MessageIconMenu isThatChat={props.isThatChat}>
            <NotificationsActiveOutlined style={{ marginLeft: '0.5rem' }} color="secondary" />
        </MessageIconMenu>
    );
}

export const OrgChatUnreadIndicator = () => {
    return <ChatsUnreadIndicator isThatChat={c => c.kind === "org"} />
}
