import React from 'react';
import { Typography } from '@mui/material';
import { Chat, SpecificChat, useSpecificChat } from '../../toolympus/components/Chats';
import { ChatMessage } from './ChatMessage';


export const OnlineRoundChat = ({ chatId }: { chatId: string }) => {
    const data = useSpecificChat(c => c._id === chatId, {
        kindForStart: "_",
        participantsForStart: [],
    });

    return <SpecificChatX data={data} />;
}

interface SpecificChatPageProps {
    data: SpecificChat;
}

const SpecificChatX = ({ data }: SpecificChatPageProps) => {
    const { chat } = data;
    
    return <>
        {chat &&
            <Chat
                hideTitleRow
                iconButton
                renderMessage={(m,an) => <ChatMessage key={m._id} message={m} authorName={an || ""} />}
                />}
        {!chat && <Typography>Не удалось загрузить чат. Пожалуйста свяжитесь с организатором.</Typography>}
    </>;
}