import { useState, useEffect, useCallback } from 'react';
import { apiFetch, FetchTypes } from './apiFetch';

type Loader<StateType> = () => Promise<StateType>;
type Updater<StateType> = (_: StateType) => Promise<StateType>;

export const usePersistedState = <StateType>(get: Loader<StateType>, update: Updater<StateType>) => {
    const [state,setState] = useState<StateType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getCb = useCallback(get, []);

    useEffect(() => {
        setIsLoading(true);
        getCb()
            .then(received => {
                setState(received);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                throw error;
            });
    }, [getCb]);

    const sendUpdate = (newState: StateType) => {
            setState(newState);
            setIsLoading(true);
            return update(newState)
                .then(received => {
                    setState(received);
                    setIsLoading(false);
                    return received;
                })
                .catch(error => {
                    setIsLoading(false);
                    throw error;
                });
        };

    return {
        state,
        update: sendUpdate,
        isLoading,
    }
}

export const useLoadedState = <StateType>(get: Loader<StateType>) => {
    const [state,setState] = useState<StateType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const load = useCallback(() => {
        setIsLoading(true);
        return get()
            .then(received => {
                setState(received);
                setIsLoading(false);
                return received;
            })
            .catch(error => {
                setIsLoading(false);
                throw error;
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        load();
    }, [load]);

    return {
        state,
        reload: load,
        isLoading,
    }
}

export const useFetchState = <T>(urlLessApiPrefix: string, defValue: T, noAuth = false): {data:T, loading: boolean, errStatus: any} => {
    const [data, setData] = useState<T>(defValue);
    const [errStatus, setErrStatus] = useState<number>(NaN);
    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        apiFetch<T>('/api' + urlLessApiPrefix, FetchTypes.GET, {}, {noAuth})
            .then(setData)
            .catch(e => setErrStatus(e.response.status))
            .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        data, loading, errStatus
    }
}