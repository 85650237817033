import { TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Star } from "@mui/icons-material";
import React from "react";
import { ListView } from "../../../presentation/Admin/Admin.styles";
import { OnlineRoundsResult } from "../../../presentation/Admin/OnlineRounds/useOnlineRoundsResults";
import { useUser } from "../../../presentation/UserContext/UserContext";
import LoadingPage from "../../../presentation/Util/LoadingPage";

export interface Props {
  data: {
    results: OnlineRoundsResult[];
    isLoading: boolean;
  }
} 

export const OnlineRoundResultsPage = ({data: {results, isLoading}}: Props) => {
  const user = useUser();

  const format = (n: number) => n.toFixed(2);

  if (isLoading) {
    return <LoadingPage/>
  }

  return (
    <ListView>
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableCell align='left'></TableCell>
            <TableCell align='center'>Команда</TableCell>
            <TableCell align='center'>Оценка за документы</TableCell>
            <TableCell align='center'>Оценка за выступления</TableCell>
            <TableCell align='center'>Штрафные баллы</TableCell>
            <TableCell align='center'>Дополнительные баллы</TableCell>
            <TableCell align='center'>Итог</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(({team_id, team_nbr, documents, performance, penalty, bonus, result}, i) => (
            <TableRow key={team_id}>
              <TableCell align='center'>{user.user._id === team_id? <Star color="primary"/> : i + 1}</TableCell>
              <TableCell align='center'>{team_nbr}</TableCell>
              <TableCell align='center'>{format(documents)}</TableCell>
              <TableCell align='center'>{format(performance)}</TableCell>
              <TableCell align='center'>{penalty !== 0 ? format(penalty) : ""}</TableCell>
              <TableCell align='center'>{bonus !== 0 ? format(bonus) : ""}</TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold'}}>{format(result)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </ListView>)
}