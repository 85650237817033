import React from 'react';
import {
    AccountBalance,
    Person,
    SportsHockey,
    StarOutlined,
    EmojiEvents,
} from '@mui/icons-material';
import { IUserContext } from '../UserContext/UserContext';
import { ArbiterOnlineRoundContainer } from '../../components/Arbiter/ArbiterOnlineRoundContainer';
import ArbiterProfileContainer from '../../components/Arbiter/ArbiterProfileContainer';
import { OnlineRoundResultsContainer } from '../../components/OnlineRoundResults/OnlineRoundResultsContainer';
import TermsTeamSection from '../Terms/TermsTeamSection';
import { ArbiterOnlineRoundsListPage } from '../../components/Arbiter/presentation/ArbiterOnlineRoundsListPage';
import { ArbiterFinalsResultsPage } from '../../components/MyRounds/PublicFinalsResultsPage';

export const ArbiterRoutes = [
    { path: 'terms', component: TermsTeamSection, title: 'Главная', icon: <AccountBalance />, hidden: false },
    { 
        path: 'results',
        component: ArbiterFinalsResultsPage,
        title: 'Результаты конкурса',
        icon: <EmojiEvents />,
        hidden: false,
        hiddenFn: (user: IUserContext) => !user.contestState?.finals_results_available,
    },
    { 
        path: 'onlineround/:roundid',
        component: ArbiterOnlineRoundContainer,
        title: '',
        hidden: true,
        icon: <SportsHockey />,
    },
    { 
        path: 'onlineround',
        component: ArbiterOnlineRoundsListPage,
        title: 'Онлайн раунды',
        icon: <SportsHockey />,
        hiddenFn: (user: IUserContext) => !user.contestState?.online_rounds_available,
    },
    {
        path: 'results-online', 
        component: OnlineRoundResultsContainer, 
        title: 'Результаты онлайн раундов',
        icon: <StarOutlined />,
        hiddenFn: (user: IUserContext) => !user.contestState?.onlinerounds_results_available
    },
    { 
        path: 'profile',
        component: ArbiterProfileContainer,
        title: 'Профиль',
        icon: <Person />
    }
];