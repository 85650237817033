import { useCallback, useEffect, useState } from "react";
import { Chat } from "./types";

export type UseChatList = (open: boolean, send: (data: any) => void, socket?: WebSocket) => {
    addChat: (message: string, parcipantsList: string[], kind: string) => void;
    list: Chat[],
    selectedChat: Chat | null,
    setSelectedChat: (c: Chat | null) => void,
    loading: boolean
};

export const useChatsList: UseChatList = (open, send, socket) => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [selectedChat, setSelectedChat] = useState<Chat | null>(null);

    useEffect(() => {
        if (socket) {
            const onMessage = (ev: MessageEvent) => {
                const data = JSON.parse(ev.data);
    
                if (data._type === 'chats') {
                    setChats(data.chats);
                }
    
                if (data._type === 'message') {
                    const chat = chats.find(chat => data.chat_id === chat._id);
                    if (chat) {
                        chat.last_message = data;
                            
                        chat.has_unread = selectedChat && selectedChat._id === chat._id? false : true;
    
                        setChats([chat, ...chats.filter(item => item._id !== chat._id)]);
                    }
                }
            }
    
            socket.addEventListener('message', onMessage);
            
            return () => socket.removeEventListener('message', onMessage);
        }
        

    }, [socket, selectedChat, chats]);

    const getList = useCallback(() => open && send({_type: 'get-chats'}), [open, send]);

    useEffect(() => {
        getList();
    }, [getList]);

    return { 
        list: chats,
        selectedChat,
        setSelectedChat: chat => {
            setSelectedChat(chat);
            if (chat) {
                chat.has_unread = false;
                setChats(chats.map(item => item._id === chat._id? chat : item))
            }

        },

        addChat: (message, participants, kind) => {
            send({_type: 'create-chat', kind, participants, message});
            getList();
        },
        
        refresh: getList,
        loading: !open,
    };
}