import React from 'react';
import { CmsPage } from './CmsPage';
import { PublicPageWrapper } from '../../presentation/Util/common.styles';
import { VolunteerRegistrationForm } from './Registrations/VolunteerRegistrationForm';

const Blocks = {
  volunteer_registration_form: VolunteerRegistrationForm,
}

export const VolunteerRegistrationPage = () => {
  return <CmsPage
    slug="volunteer-registration"
    wrapperClasses="page-content"
    wrapperComponent={PublicPageWrapper}
    customBlocks={Blocks}
    />
}
