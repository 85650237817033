import { useEffect } from "react";

export const useInjectScriptTag = (scriptUrl?: string, isAsync: boolean = false) => {
    useEffect(() => {
        if(scriptUrl) {
            const script = document.createElement('script');
          
            script.src = scriptUrl;
            if(isAsync) {
                script.async = true;
            }
          
            document.body.appendChild(script);
          
            return () => {
              document.body.removeChild(script);
            }
        }
      }, [scriptUrl, isAsync]);
}
