import React, { useEffect, useState } from 'react';
import { AdminArbiterInfo, OnlineRoundsConfirmationStatus } from '../../../typings/schema';
import { TableContainer, TableHead, TableBody, Table, TableRow, TableCell, Tooltip, Button, Checkbox, IconButton } from '@mui/material';
import { getLocalDateTime } from '../../../util/common';
import { ListViewStuffed, TotalParticipantsText, TableControls } from '../Admin.styles';
import { PageHeader } from '../../Util/PageHeader';
import ArbiterPopup from './ArbiterPopup';
import { GetApp, Email, CallMade } from '@mui/icons-material';
import { LinkView, Underline } from '../../Util/common.styles';
import { useTextFilter, useTabFilter } from '../../../util/useFilter';
import { TabFilter } from '../../Util/TabFilter';
import { TableComments } from '../../../toolympus/components/Comments/useTableComments';
import { ParticipationOptionNameShort, PremootParticipationOptionName } from '../../../util/constants';
import { useTags } from '../../../toolympus/components/Tags';
import { useInviteArbiter } from './useInviteArbiter';
import { Buttons, OccupyFreeSpace, SearchField, useCopyText } from '../../../toolympus/components/primitives';
import { Link } from 'react-router-dom';
import { useFormats } from '../../../toolympus/components/schemed';
import { exportArbitrators } from './ExportArbitrators';
import { utc } from '../../../toolympus/components/timezone';

interface Props {
    arbiters: AdminArbiterInfo[];
    toggleDuplicate: (id: string, isDuplicate: boolean) => void;
    clearOnlineRoundsConfirmation: (id: string) => void;
    deleteArbiter: (id: string) => Promise<void>;
    comments: TableComments;
}

interface ArbiterListState {
    selectedArbiter: AdminArbiterInfo | null;
}

const defaultState: ArbiterListState = {
    selectedArbiter: null,
}

const ArbiterListPage: React.FC<Props> = (props: Props) => {
    const [pageState, setPageState] = useState<ArbiterListState>(defaultState);
    const textFilter = useTextFilter<AdminArbiterInfo>(a => `${a.info.lastname} ${a.info.firstname} ${a.info.middlename} ${a.info.email}`);

    const arbiterInvite = useInviteArbiter();

    const duplicateFilters = useTabFilter<AdminArbiterInfo>("nodupes", [
        ["nodupes", "Без дубликатов", a => !a.is_duplicate],
        ["dupes", "Только дубликаты", a => a.is_duplicate],
        ["or-rejects", "Отказавшиеся (ОР)", a => a.online_rounds_confirmation === "rejected"],
        ["or-unknown", "Нет ответа (ОР)", a => !a.online_rounds_confirmation && a.onlinerounds.length > 0],
        ["all", "Все",],
    ]);

    const conflicts = useTags("/api/conflict/tag", "arbiter");
    const copyText = useCopyText();

    useEffect(() => {
        if (pageState.selectedArbiter !== null) {
            setPageState(p => { return { ...p, selectedArbiter: props.arbiters.find(a => a._id === p.selectedArbiter?._id) || null } })
        }
    }, [props.arbiters, pageState.selectedArbiter]);

    const openPopup = (arbiter: AdminArbiterInfo) => {
        setPageState({ ...pageState, selectedArbiter: arbiter });
    }

    const closePopup = () => {
        setPageState({ ...pageState, selectedArbiter: null });
    }

    const toggleDuplicate = (isDuplicate: boolean) => {
        if (pageState.selectedArbiter) {
            props.toggleDuplicate(pageState.selectedArbiter._id, isDuplicate);
        }
    }

    const shownArbiters: AdminArbiterInfo[] =
        (props.arbiters || []).filter(a => duplicateFilters.filter(a) && textFilter.filter(a));

    const { formatDate, formatDatetimeShort } = useFormats();

    return (
        <ListViewStuffed>
            <PageHeader useMargin={false} />
            <TableControls>
                <TabFilter
                    selectedTab={duplicateFilters.selectedKey}
                    selectTab={duplicateFilters.selectKey}
                    tabs={duplicateFilters.tabs}
                    />
            </TableControls>
            <Buttons>
                <SearchField
                  filter={textFilter.value}
                  setFilter={v => textFilter.setValue(v)}
                  noButton
                  autoFocus
                  />

                <OccupyFreeSpace />

                <Button
                    color="primary"
                    size="small"
                    startIcon={<GetApp />}
                    onClick={() => exportArbitrators(shownArbiters, `arbitrators-${formatDate(new Date())}.xlsx`)}>
                    Выгрузить
                </Button>
                <TotalParticipantsText style={{ flex: "initial" }}>Всего арбитров: {shownArbiters.length}</TotalParticipantsText>
            </Buttons>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'></TableCell>
                            <TableCell align='center'>Фамилия</TableCell>
                            <TableCell align='center'>Имя</TableCell>
                            <TableCell align='center'>Отчество</TableCell>
                            <TableCell align='center'>Дата регистрации</TableCell>
                            <TableCell>Контактный email</TableCell>
                            <TableCell>Контактный телефон</TableCell>
                            <TableCell align='center'>Тренер</TableCell>
                            <TableCell>Конфликты</TableCell>
                            <TableCell align='center'>Место работы</TableCell>
                            <TableCell align='center'>Должность</TableCell>
                            <TableCell align='center'>Онлайн раундов</TableCell>
                            <TableCell align='center'>Приглашение в ОР</TableCell>
                            <TableCell align='center'>Дубликат</TableCell>
                            <TableCell align='center'>Участие</TableCell>
                            <TableCell align='center'>Участие в премутах</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shownArbiters.map((arb) => (
                            <TableRow key={arb.info.email + arb.registered_datetime}>
                                <TableCell align='center'>{props.comments.buttonForRecord(arb._id)}</TableCell>
                                <TableCell align='center'>
                                    <LinkView onClick={e => openPopup(arb)}>
                                        {arb.info.lastname}
                                    </LinkView>
                                </TableCell>
                                <TableCell align='center'>
                                    <LinkView onClick={e => openPopup(arb)}>{arb.info.firstname}
                                    </LinkView>
                                </TableCell>
                                <TableCell align='center'>
                                    <LinkView onClick={e => openPopup(arb)}>
                                        {arb.info.middlename}
                                    </LinkView>
                                </TableCell>
                                <Tooltip title={getLocalDateTime(arb.registered_datetime)}>
                                    <TableCell align='center'>{formatDate(utc.toLocal(arb.registered_datetime))}</TableCell>
                                </Tooltip>
                                <TableCell onClick={() => copyText(arb.info.email)} style={{ whiteSpace: "nowrap" }}>
                                    <Underline>{arb.info.email}</Underline>
                                    <Link to={`/admin/system/users?filter=${arb.info.email}`} onClick={e => e.stopPropagation()}>
                                        <IconButton size="small"><CallMade /></IconButton>
                                    </Link>
                                </TableCell>
                                <TableCell onClick={() => copyText(arb.info.phone)}><Underline>{arb.info.phone}</Underline></TableCell>
                                <TableCell align='center'>{arb.info.team_coach}</TableCell>
                                <TableCell>
                                    {conflicts.displayForRecord(arb._id)}
                                </TableCell>
                                <TableCell align='center'>{arb.info.company}</TableCell>
                                <TableCell align='center'>{arb.info.position}</TableCell>
                                <TableCell align='center'>{(arb.onlinerounds || []).length}</TableCell>
                                <TableCell align='center'>
                                    {arb.online_rounds_confirmation ?
                                        OnlineRoundsConfirmationStatus[arb.online_rounds_confirmation]
                                        : <Tooltip title={`Пригласить ${arb.online_rounds_invite_datetime ? `(Приглашен: ${formatDatetimeShort(arb.online_rounds_invite_datetime)})` : ""}`}>
                                            <IconButton size="small" onClick={() => arbiterInvite.openFor(arb)}><Email /></IconButton>
                                        </Tooltip> }
                                </TableCell>
                                <TableCell align='center'>
                                    <Checkbox
                                        color="primary"
                                        disabled
                                        checked={arb.is_duplicate}
                                    />
                                </TableCell>
                                <TableCell align='center'>{ParticipationOptionNameShort[arb.info.willParticipateIn || ""]}</TableCell>
                                <TableCell align='center'>{PremootParticipationOptionName[arb.info.will_participate_in_premoots || ""]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {pageState.selectedArbiter &&
                <ArbiterPopup
                    arbiter={pageState.selectedArbiter}
                    onClosePopup={closePopup}
                    toggleDuplicate={toggleDuplicate}
                    deleteArbiter={props.deleteArbiter}
                    clearOnlineRoundsConfirmation={props.clearOnlineRoundsConfirmation}
                    arbiterInvite={arbiterInvite}
                    conflicts={conflicts}
                />}
            {props.comments.popup}
            {conflicts.popup}
            {arbiterInvite.popup}
        </ListViewStuffed>
    )
}

export default ArbiterListPage;