import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { TabFilter as TF } from '../../util/useFilter';

interface Props {
    filterState?: TF<any>;
    tabs?: { key: string, label: string }[];
    selectedTab?: string;
    selectTab?: (k: string) => void;
}

export const TabFilter = (props: Props) => {
    const tabs = props.filterState?.tabs || props.tabs;
    const selectedTab = props.filterState?.selectedKey || props.selectedTab;
    const selectTab = props.filterState?.selectKey || props.selectTab;

    if(!tabs || !selectTab) {
        return <></>;
    }

    return (
        <ButtonGroup size="small" color="primary" variant="outlined">
            {tabs.map(({ key, label }) => (
                <Button
                    key={key}
                    variant={key === selectedTab ? "contained" : "outlined"}
                    onClick={() => selectTab(key)}>
                    {label}
                </Button>
            ))}
        </ButtonGroup>
    )
}
