import { useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../toolympus/api/core";
import { ContestState } from "../typings/schema";
import { isAuthenticated } from "./tokenStorage";
import { createProvided } from "../toolympus/components/primitives/createProvided";

export const getContestState = () => apiFetch<ContestState>('/api/contest/state', FetchTypes.GET);

export const useContestState = () => {
    const [state, setState] = useState<ContestState>({} as ContestState);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        apiFetch<ContestState>('/api/contest/state', FetchTypes.GET, undefined, isAuthenticated() ? {} : { noAuth: true })
            .then(data => {
                setState(data);
                setIsLoading(false);
            });
    }, []);

    return {
        data: state,
        isLoading,
    }
};


export const {
  Provider: ContestStateProvider,
  useValue: useContestStateProvided,
} = createProvided(useContestState, { data: {} as ContestState, isLoading: true });
