import React from 'react';
import { GenericTextField } from '../Util/UtilTextfields';
import SuccessButton from '../Util/SuccessButton';
import { LoginTitle, SignupTextfieldView, LoginButtonView, BottomTipView, LoginWrapper, LoginForm } from '../Login/LoginPage.styles';
import { PasswordChange } from '../../typings/schema';
import { Link } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { Title } from '../../Common/Primitives';

interface Props {
    onRequest: (data: PasswordChange ) => void;
    formData: PasswordChange;
    onDataChanged: (data: PasswordChange) => void;
    isRequested: boolean;
}

const ChangePasswordPage: React.FC<Props> = (props: Props) => {

    const onSubmit = (e: any) => {
        e.preventDefault();
        props.onRequest(props.formData);
    }

    return (
        <LoginWrapper>
            {!props.isRequested &&
                <Box maxWidth={400}>
                    <LoginForm onSubmit={e => onSubmit(e)}>
                        <Title justifyContent="center" noLine marginBottom="15px">
                            Создание нового пароля
                        </Title>
                        <LoginTitle>
                            Задайте новый пароль для входа в личный кабинет
                        </LoginTitle>
                        <SignupTextfieldView>
                            <GenericTextField
                                disabled
                                value={props.formData?.email}
                                onChange={e => props.onDataChanged({ ...props.formData, email: e })}
                                label="Контактный email"
                                type="email"
                            />
                        </SignupTextfieldView>

                        <SignupTextfieldView>
                            <TextField
                                style={{ width: '100%' }}
                                required
                                value={props.formData?.password}
                                onChange={e => props.onDataChanged({ ...props.formData, password: e.target.value })}
                                variant="outlined"
                                type="password"
                                label="Новый пароль"
                            />
                        </SignupTextfieldView>
                        <SignupTextfieldView>
                            <TextField
                                style={{ width: '100%' }}
                                required
                                value={props.formData?.passwordRepeat}
                                onChange={e => props.onDataChanged({ ...props.formData, passwordRepeat: e.target.value })}
                                variant="outlined"
                                type="password"
                                label="Повторите пароль"
                            />
                        </SignupTextfieldView>
                        
                        <LoginButtonView>
                            <SuccessButton
                                disabled={props.formData?.email && props.formData?.password && props.formData?.passwordRepeat ? false : true}
                                title="Сменить пароль"
                                type="submit"
                                width="100%"
                            />
                        </LoginButtonView>
                    </LoginForm>
                </Box>}
                
            {props.isRequested &&
                <BottomTipView>
                    Пароль успешно изменен. <Link to="/login">Перейдите на страницу авторизации</Link>, чтобы войти в личный кабинет.
                </BottomTipView>}
        </LoginWrapper>
    )
}

export default ChangePasswordPage;