import React from 'react';
import { PremootRoundsConfigData, TeamSchema } from './usePremootRoundsConfig';
import { ActionRow, Buttons, LoadingIndicator, OccupyFreeSpace, SaveButton, SimpleDialog } from '../../toolympus/components/primitives';
import { TableForFields } from '../../toolympus/components/schemed';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';

interface Props {
  data: PremootRoundsConfigData;
}

export const PremootRoundsTeamList = (props: Props) => {
  const { data } = props;

  return (
    <>
      <ActionRow
        firstItemNoMargin
        >

        <OccupyFreeSpace />

        <LoadingIndicator style={{ visibility: data.isLoading ? "visible" : "hidden" }}/>
        <IconButton size="small" onClick={() => data.newTeams.startEditing({ numbers: "" })}><Add /></IconButton>
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        <Button size="small" color="secondary" startIcon={<DeleteOutlined />} onClick={() => data.removeAllTeams.run()}>удалить всех</Button>
      </ActionRow>
      
      <TableForFields
        data={data.data.teams}
        fields={[
          ["display_name"],
          ["_id"],
        ]}
        schema={TeamSchema}
        rowButtons={row => <Buttons><IconButton size="small" onClick={() => data.removeTeam.run(row)}><DeleteOutlined /></IconButton></Buttons>}
        />

      <SimpleDialog
        dialogTitle="Добавить команды"
        isOpen={data.newTeams.isEditing}
        close={() => data.newTeams.cancel()}
        save={() => data.newTeams.save()}
        noFullscreen
        noSubmitOnEnter>
        <Typography>Укажите (произвольные) номера команд - каждый на отдельной строке</Typography>
        {data.newTeams.item && <TextField
          fullWidth
          multiline
          value={data.newTeams.item.numbers}
          onChange={e => data.newTeams.update({ numbers: e.target.value })}
          />}
      </SimpleDialog>
    </>
  );
}
