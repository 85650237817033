import React, { ReactNode } from "react";
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material";
import { Jutsu } from 'react-jutsu';
import { useInjectScriptTag } from "../../toolympus/components/primitives/useInjectScriptTag";

const TB_FOR_MODERATOR = [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'profile', 'chat', 
    // 'recording', 'livestreaming',
    'sharedvideo', 'settings', 'raisehand',
    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];


const TB_FOR_NO_MODERATOR = [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'chat',
    'sharedvideo', 'settings', 'raisehand',
    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];

interface ConferenceRoom {
    room: string;
    name: string;
    token: string;
    domain: string;
    is_available: boolean;
}

interface RoomProps {
    isModerator?: boolean;
    room: ConferenceRoom | null;
    disconnect: () => void;
}

export const useConferenceApiScript = (room: RoomProps["room"]) => {
  useInjectScriptTag(
    room?.is_available && room?.domain ? `https://${room.domain}/external_api.js` : undefined,
  );
}

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  border: none;
`;

export const ExternalConferencingLinkBlock = (props: { conferencingExternalLink: string | undefined }) => {
  return props.conferencingExternalLink ? (
    <>
      <Typography>
        Участники раунда согласовали использование внешней системы для проведения раунда. Используйте ссылку для подключения:
      </Typography>
      <Typography>
        <StyledLink href={props.conferencingExternalLink} target="_blank" rel="noopener noreferrer">{props.conferencingExternalLink}</StyledLink>
      </Typography>

      <Divider style={{ margin: "1rem 0" }} />
    </>
  ) : null;
}

export const Conference = ({ room, disconnect, isModerator }: RoomProps) => {
    const theme = useTheme();
    
    const TOOLBAR_BUTTONS = isModerator? TB_FOR_MODERATOR : TB_FOR_NO_MODERATOR;

    return room ? (<>
        {room.is_available && <Jutsu
            loadingComponent={<>...</>}
            containerStyles={{
                width: '100%',
                minHeight: '400px',
                border: '2px solid ' + (theme as any).palette.primary.main,
                marginBottom: '1rem',
            }}

            roomName={room.room}
            displayName={room.name}
            domain={room.domain}
            jwt={room.token}
            onMeetingEnd={disconnect}
            interfaceConfigOverwrite={{
                HIDE_INVITE_MORE_HEADER: true,
                TOOLBAR_BUTTONS
            }}
        />}
        {room.is_available && <Typography variant="caption" key="hint" style={{ display: "block" }}>
            Полноэкранный режим можно включить в меню - три точки в правом нижнем углу окна видеоконференции.
        </Typography>}
        {room.is_available && <Typography variant="caption" key="conflink" style={{ display: "block" }}>
            Для подключения также можно использовать <a style={{ color: (theme as any).palette.primary.main, textDecoration: 'none' }} href={`https://${room.domain}/${room.room}?jwt=${room.token}`} target="_blank" rel="noopener noreferrer">персональную ссылку</a>.
        </Typography>}
        {!room.is_available && <Typography key="unavailable">
            К сожалению, комната недоступна.
        </Typography>}
    </>) : null;
}

interface DialogProps {
    close: () => void;
    isOpen: boolean;
    children: ReactNode;
    conferencingExternalLink?: string;
}

export const ConferenceDialogBase = (props: DialogProps) => {
    const { close, isOpen } = props;
    return (
        <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth>
            <DialogTitle>
                Слушания
            </DialogTitle>

            <DialogContent>
                <ExternalConferencingLinkBlock conferencingExternalLink={props.conferencingExternalLink} />

                <Typography key="instructions1">
                    Для проведения слушаний по онлайн раундам Вы можете воспользоваться встроенной платформой для видеоконференций.
                </Typography>
                <Typography key="instructions2">
                    Виртуальные комнаты доступны в любое время - можно предварительно протестировать подключение или использовать их для обсуждения. Доступ к комнате имеют все участники данного онлайн раунда.
                </Typography>
                <Typography key="instructions3">
                    Если вы испытываете проблемы с подключением, попробуйте отключить VPN и использовать браузер Chrome последней версии.
                </Typography>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    )
}
