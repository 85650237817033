import { Button } from '@mui/material';
import React from 'react';
import { apiFetch, FetchTypes } from '../../toolympus/api/core';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { TagManagementForm, useTagManagement } from '../../toolympus/components/Tags';

const useConflictTagManagement = () => {
    const data = useTagManagement('/api/conflict/tag');

    const prefill = () => {
        apiFetch('/api/conflict/prefill', FetchTypes.POST)
            .then(() => data.reload());
    }

    return {
        ...data,
        prefill,
    }
}

export const ConflictTagManagement = () => {
    const data = useConflictTagManagement();

    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                <Button color="primary" onClick={data.prefill}>собрать теги</Button>
            </ActionRow>
            <TagManagementForm data={data} />
        </>
    );
}
