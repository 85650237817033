import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { SimpleDialog } from '../../toolympus/components/primitives/Dialogs';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { FormControl, TableForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { useArchives } from './useArchives';

export const ArchivesForm = () => {
    const {
        isLoading,
        data,
        create,
        clean,
        switchToArchive,
    } = useArchives();

    const [newArchiveName, setNewArchiveName] = useState<string | null>(null);

    const schema = {
        name: { type: FieldType.text, label: "Название" },
        created_datetime: { type: FieldType.datetime, label: "Создан" },
    };

    const startClean = useActionWithConfirmation(clean, {
        confirmationText: "очистить",
        confirmationHint: <>
            <Typography key="l1">База данных будет очищена для запуска новой итерации ♾ конкурса.</Typography>
            <Typography key="l2">Это действие необратимо. Чтобы продолжить введите слово 'очистить' в поле ниже и нажмите кнопку.</Typography>
            </>,
        title: "Очистка базы данных",
        confirmationLabel: "я понимаю последствия, очистить",

    });

    return (<>
        <ActionRow>
            <Button color="primary" onClick={() => setNewArchiveName("")}>создать архив</Button>
            <OccupyFreeSpace />
            <Button color="secondary" onClick={startClean.run}>очистить базу данных</Button>
            {isLoading && <LoadingIndicator />}
        </ActionRow>
        <TableForFields
            data={data}
            schema={schema}
            fields={[
                ["name"],
                ["created_datetime", { utcToLocal: true }]
            ]}
            rowButtons={r => <Button size="small" onClick={() => switchToArchive(r.name)}>перейти</Button>}
            />


        <SimpleDialog
            isOpen={newArchiveName !== null}
            close={() => setNewArchiveName(null)}
            dialogTitle="Создать архив"
            saveLabel="создать"
            save={() => {
                if((newArchiveName || "").trim().length > 0) {
                    create(newArchiveName || "");
                }
                setNewArchiveName(null);
            }}>

            <FormControl
                field="name"
                row={{ name: newArchiveName }}
                onChange={(o,c) => setNewArchiveName(c.name)}
                schema={schema.name}
                />
        </SimpleDialog>
        </>);
}
