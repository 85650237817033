import React from 'react';
import styled from '@emotion/styled';
import { Divider, Typography, Button, Paper } from '@mui/material';
import { PageHeader } from '../../presentation/Util/PageHeader';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { usePremootList } from './premootParticipationData';
import { Link } from 'react-router-dom';
import { ApplicationStatus } from './ApplicationStatus';
import { CmsPage } from '../DynamicPages/CmsPage';

const PremootsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;

    padding-top: 1rem;

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`;

const UnstyledLink = styled(Link)`
    text-decoration: none;
    color: initial;
`;

const Premoot = styled(Paper)`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;

    &>:last-child {
        justify-self: flex-end;
        align-self: flex-end;
    }

    padding: 0.25rem 0.75rem;
`;

const Content = styled.div`
    flex: 1 0 auto;
    & p:last-child {
        margin-bottom: 0;
    }
`;


const PremootsList = (props: { onePremootPath: string }) => {
  const premoots = usePremootList();

  return (
    <PremootsWrapper>
        {premoots.data.map(p => (
            <Premoot key={p._id}>
                <Content>
                    <Typography variant="h5">{p.title}</Typography>
                    <MarkdownDisplay text={p.brief_description || ""} />
                </Content>
                
                <UnstyledLink to={`${props.onePremootPath}/${p._id}`}>
                    {p.is_applied ?
                        <ApplicationStatus premoot={p} /> :
                        <Button size="small" color="primary">детали и участие</Button>}
                </UnstyledLink>
            </Premoot>
            ))}
    </PremootsWrapper>
  )
}

const TeamPremootsBlocks = {
  premoots: () => <PremootsList onePremootPath="/team/premoot" />,
}

const PremootOrgPremootsBlocks = {
  premoots: () => <PremootsList onePremootPath="/premoot-org/premoot" />,
}


export const PremootPublicListPage = ({ kind }: { kind: "team" | "premoot-org" }) => {
  return (
      <div>
          <PageHeader />
          <Divider />

          <CmsPage slug="premoots" customBlocks={kind === "premoot-org" ? PremootOrgPremootsBlocks : TeamPremootsBlocks} />
      </div>
  );
}