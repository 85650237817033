import styled from '@emotion/styled';
import React from 'react';
import { scrollStyle } from '../../../toolympus/components/primitives';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { PageContent } from '../../Util/common.styles';
import { OnlineRoundsListPage } from './OnlineRoundsListPage';
import { OnlineRoundsResults } from './OnlineRoundsResults';
import { useOnlineRoundsList } from '../../../containers/admin/useOnlineRoundsList';
import { OnlineRoundsArbitratorsScoringStatus } from './OnlineRoundsArbitratorsScoringStatus';

const PageWrapper = styled.div`
    ${props => scrollStyle(props.theme, {})};
`;

export const OnlineRoundsAdminPage = () => {
    const tabs = useTabsState([
        ["rounds", "Раунды"],
        ["arbitrators", "Арбитры"],
        ["results", "Результаты"],
    ], "rounds", "tab");

    const roundsData = useOnlineRoundsList();

    return (
        <PageWrapper>
            <TabsHeader state={tabs} noMargin />

            <PageContent>
            <TabPanel state={tabs.forTab("rounds")}>
                <OnlineRoundsListPage roundsData={roundsData} />
            </TabPanel>

            <TabPanel state={tabs.forTab("arbitrators")}>
                <OnlineRoundsArbitratorsScoringStatus roundsData={roundsData} />
            </TabPanel>

            <TabPanel state={tabs.forTab("results")}>
                <OnlineRoundsResults />
            </TabPanel>
            </PageContent>
        </PageWrapper>
    );
}
