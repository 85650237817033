import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { SimpleActions } from '../../../toolympus/components/StatusAction';
import { ItemRow } from '../../../toolympus/components/primitives/Common.styles';
import { useOnlineRoundsResults } from './useOnlineRoundsResults';

export const OnlineRoundsResults = () => {
    const {
        isLoading,
        isActionLoading,
        data,
        schema,
        recalc,
        exportResults,
        penaltyEdit,
        updateFinalsStage,
    } = useOnlineRoundsResults();
    
    return (
        <>
            <ActionRow>
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                <OccupyFreeSpace />
                <SimpleActions isLoading={isActionLoading}
                    actions={[
                        { label: data.length === 0 ? "Рассчитать результаты" : "Пересчитать результаты", action: recalc },
                        { label: "Выгрузить оценки", action: exportResults },
                    ]}
                    buttonProps={{ color: 'primary', size: 'small' }}
                />
            </ActionRow>
            <TableForFields
                schema={schema}
                data={data}
                fields={[
                    ["team_nbr"],
                    ["finals_stage", { label: "В финалы"}],
                    ["has_unscored"],
                    ["result", { precision: 2 }],
                    ["documents", { precision: 2 }],
                    ["performance", { precision: 2 }],
                    ["total", { precision: 2 }],
                    ["penalty", { precision: 2 }],
                    ["bonus", { precision: 2 }],
                    ["penalty_comment"],
                ]}
                rowStyle={row => row.has_unscored ? { background: '#cc000020'} : {}}
                withRowNbr
                dense
                fieldElement={f => {
                    if(f === "finals_stage") {
                        return (row) => (
                            <Button onClick={() => updateFinalsStage(row.team_id, row.finals_stage ? null : 'rating')}
                                    variant={row.finals_stage ? 'contained' : 'outlined'}
                                    color={row.finals_stage ? 'primary' : 'inherit'}
                                    size="small">
                                {row.finals_stage ? 'да' : 'нет'}
                            </Button>
                        )
                    }
                    if(f === "penalty" || f === "bonus") {
                        return (row, s, o) => <ItemRow style={{ justifyContent: 'flex-end' }}>
                            {o} <IconButton size="small" onClick={() => penaltyEdit.startEditing(row)}><EditOutlined /></IconButton>
                        </ItemRow>;
                    }
                }}
                />

            <Dialog open={penaltyEdit.isEditing} onClose={penaltyEdit.cancel}>
                {penaltyEdit.item && <>
                    <DialogTitle>
                        Штрафные и дополнительные баллы для команды {penaltyEdit.item.team_nbr}
                    </DialogTitle>
                    <DialogContent>
                        <FormControlsForFields
                            schema={schema}
                            data={penaltyEdit.item}
                            fields={[
                                ["penalty"],
                                ["bonus"],
                                ["penalty_comment"],
                            ]}
                            onChange={(o,c) => penaltyEdit.update(c)}
                            />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={penaltyEdit.cancel}>отмена</Button>
                        <Button color="primary" variant="contained" onClick={() => penaltyEdit.save()}>сохранить</Button>
                    </DialogActions>
                </>}
            </Dialog>
        </>
    );
}
