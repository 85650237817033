import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Avatar as MUIAvatar } from '@mui/material';
import { readBlobAsDataURL } from '../../util/files';

interface InternalAvatarProps {
    size?: number;
}

export const LargeAvatar = styled(MUIAvatar)`
    width: ${(props: InternalAvatarProps) => props.size || 200}px;
    height: ${(props: InternalAvatarProps) => props.size || 200}px;
`;

interface Props {
    src?: string;
    size?: number;
    setUploadedFile?: (blob: Blob) => void;
}

const Avatar = (props: Props) => {
    const fileInput = useRef(null);
    const [imageSrc, setImageSrc] = useState(props.src);

    const onImageUploaded = () => {
        // @ts-ignore
        if (fileInput && fileInput.current && fileInput.current.files && fileInput.current.files[0]) {
            // @ts-ignore
            const file = fileInput.current.files[0];

            if (props.setUploadedFile) {
                props.setUploadedFile(file);
            }

            return readBlobAsDataURL(file as Blob).then(url => setImageSrc(url));
        }
    }

    return <>
        <LargeAvatar
            size={props.size || 200}
            src={imageSrc}
            onClick={() => {
                if (props.setUploadedFile === undefined){
                    return;
                }
                // @ts-ignore
                fileInput?.current?.click();
            }} />
        <input type="file" hidden ref={fileInput} onChange={onImageUploaded} />
    </>;
};

export default Avatar;