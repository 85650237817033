import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCrudItem } from '../../toolympus/api/useSimpleCrud';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { FormControlsForFields, PlaceholderField } from '../../toolympus/components/schemed';
import { useSingleSchema } from '../../toolympus/hooks/useSchema';

export interface ConferencingSettings {
    domain: string;
    token_secret: string;
    is_available: boolean;
}

const ApiPath = '/api/contest/conferencing';

const useConferencingControl = () => {
    const data = useCrudItem<ConferencingSettings>(ApiPath, {
        defaultValue: { domain: "", token_secret: "", is_available: false }
    });

    const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

    return {
        ...data,
        schema,
    };
}

export const ConferencingControl = () => {
    const { data, hasChanges, isLoading, save, update, schema } = useConferencingControl();
    return (
        <FormGrid columns="300px 1fr">
            <FormControlsForFields
                data={data}
                onChange={(o,c) => update(c)}
                schema={schema}
                fields={[
                    ["is_available"],
                    [PlaceholderField],
                    ["domain"],
                    ["token_secret"],
                ]}
            />
            {hasChanges && <Button variant="contained" color="primary" onClick={() => save()}><FormattedMessage id="common.save" /></Button>}
            {isLoading && <LoadingIndicator />}
        </FormGrid>
    );
}
