import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
} from '@mui/material';
import React, { useState } from 'react';
import { OnlineRoundsList } from '../../../containers/admin/useOnlineRoundsList';
import { getDateTime } from '../../../util/common';
import { useDialogState } from '../../../util/useDialogState';
import {
    LinkView,
    PageSection,
    PageSectionHeader,
    PrimaryButton,
    ProgressIndicator
} from '../../Util/common.styles';
import { TabFilter } from '../../Util/TabFilter';
import { TableControls, TotalParticipantsText } from '../Admin.styles';
import { CancelDialog } from './CancelDialog';
import { EditRoundDialog } from './EditRoundDialog';
import styled from '@emotion/styled';
import { SimpleActions } from '../../../toolympus/components/StatusAction';
import { OnlineRound } from '../../../typings/schema';
import { OccupyFreeSpace, PseudoLink2, SearchField } from '../../../toolympus/components/primitives';
import { AdminOnlineRoundConference } from './AdminOnlineRoundConference';


export const SuccessTableCell = styled(TableCell)`
    color: ${props => props.theme.palette.success.main};
`;
export const FailureTableCell = styled(TableCell)`
    color: ${props => props.theme.palette.error.main};
`;

const TotalDocumentScoresPerRound = 3*2*2;
const TotalPerformanceScoresPerRound = 3*2*2;

interface Props {
  roundsData: OnlineRoundsList;
}

export const OnlineRoundsListPage = (props: Props) => {
    const roundsData = props.roundsData;
    const arbitersData = roundsData.arbiters;
    const [selectedRoundId, setSelectedRoundId] = useState<string>();

    const cancelDialogState = useDialogState();

    const getConfirmationsCount = (round: OnlineRound): number => {
        return round.arbitrators.map(aid => arbitersData.arbiters.find(a => a._id === aid)?.online_rounds_confirmation === "confirmed" ? 1 : 0).reduce((r,v) => r+v, 0 as number);
    }

    return (
        <>
            {roundsData.isMatched && (<>
                <TableControls>
                    <TabFilter filterState={roundsData.tabFilter} />
                    
                    <OccupyFreeSpace />
                    {roundsData.isMatched && <Button size="small" color="secondary" onClick={cancelDialogState.show} disabled={roundsData.isLoading}>
                        отменить матчинг
                    </Button>}
                    <SimpleActions isLoading={roundsData.actions.data.loading}
                        actions={roundsData.actions.extraActions}
                        buttonProps={{ color: 'primary', size: 'small' }}
                    />
                </TableControls>
                <TableControls key="controls">
                    <SearchField
                      filter={roundsData.textFilter.value}
                      setFilter={v => roundsData.textFilter.setValue(v)}
                      noButton
                      autoFocus
                      />
                    
                    <TotalParticipantsText>Всего раундов: {roundsData.rounds.length}</TotalParticipantsText>
                </TableControls>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>Раунд</TableCell>
                                <TableCell align='center'>Время онлайн раунда</TableCell>
                                <TableCell align='center'>Согласование времени</TableCell>
                                <TableCell align='center'>ВКС</TableCell>
                                <TableCell align='center'>Арбитров назначено</TableCell>
                                <TableCell align='center'>Оценки по документам</TableCell>
                                <TableCell align='center'>Оценки по слушаниям</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                roundsData.rounds.map(round => {
                                    const confirmationsCount = getConfirmationsCount(round);
                                    return (
                                    <TableRow key={round._id}>
                                        <TableCell align='left' style={{ textTransform: "capitalize" }}>
                                            <LinkView onClick={() => setSelectedRoundId(round._id)}>
                                                {round.title}
                                            </LinkView>
                                        </TableCell>
                                        <TableCell align='center'>{round.round_time && getDateTime(round.round_time)}</TableCell>
                                        <TableCell align='center'>{Object.values(round.round_time_approvals || {}).filter(v => v).length} / 5</TableCell>
                                        <TableCell align='center'><PseudoLink2 onClick={() => roundsData.roundConference.startEditing(round)}>{round.conferencing_tool === "external" ? "внешняя" : "внутренняя"}</PseudoLink2></TableCell>
                                        <TableCell align='center'>
                                            {round.arbitrators.length} (<span style={{ color: confirmationsCount === 3 ? '#00cc00' : '#cc0000'}}>{confirmationsCount}</span>)
                                        </TableCell>
                                        {round.document_scores === TotalDocumentScoresPerRound ?
                                            <SuccessTableCell align='center'>{round.document_scores} / {TotalDocumentScoresPerRound}</SuccessTableCell> : 
                                            <FailureTableCell align='center'>{round.document_scores} / {TotalDocumentScoresPerRound}</FailureTableCell>}
                                        {round.performance_scores === TotalPerformanceScoresPerRound ?
                                            <SuccessTableCell align='center'>{round.performance_scores} / {TotalPerformanceScoresPerRound}</SuccessTableCell> : 
                                            <FailureTableCell align='center'>{round.performance_scores} / {TotalPerformanceScoresPerRound}</FailureTableCell>}
                                        <TableCell align='center'>{round.extra_pair && "дополнительный раунд"}</TableCell>
                                    </TableRow>)}
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>)}
            
            <AdminOnlineRoundConference data={roundsData.roundConference} />
            
            <PageSection>
                <PageSectionHeader></PageSectionHeader>
                {!roundsData.isMatched &&
                    <TableControls key="launch">
                        <PrimaryButton onClick={roundsData.initiateMatching} disabled={roundsData.isLoading}>
                            <ProgressIndicator isShown={roundsData.isLoading} invertColor />запустить матчинг
                        </PrimaryButton>
                    </TableControls>}
            </PageSection>
            
            
            <CancelDialog
                dialogState={cancelDialogState}
                confirmationText="отменить матчинг"
                cancel={roundsData.cancelMatching}
                isCancelling={roundsData.isLoading} />
            <EditRoundDialog
                round={roundsData.rounds.find(r => r._id === selectedRoundId)}
                close={() => setSelectedRoundId(undefined)}
                arbiterList={arbitersData}
                updateArbitrators={(arbitratorIds: string[]) => {
                    if(selectedRoundId) {
                        roundsData.updateRound(selectedRoundId, { arbitrators: arbitratorIds });
                    }
                }} />
            {roundsData.actions.popups}
        </>
    );
}