import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import { AdminTeamInfo, ExludedItem } from "../../../typings/schema";
import { Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import DialogTitle from "../../Util/DialogTitle";
import { getFullLocalDateTime, getLocalDateTime } from "../../../util/common";
import { ChatOutlined, Check, Close, GetAppOutlined } from "@mui/icons-material";
import { GenericTextField } from "../../Util/UtilTextfields";
import { DeleteButton } from "../../../toolympus/components/primitives/DeleteButton";
import { useTeamCertificate } from "../../../containers/team/useTeamCertificate";
import { FormGrid, OccupyFreeSpace, SectionTitle } from "../../../toolympus/components/primitives";
import { TeamMembersTable } from "./ParticipantsDialog";
import { TeamCoachesTable } from "./CoachesDialog";
import { DownloadCertificateDialog } from "../../Team/Documents/TeamCertificateBlock";
import { Link } from "react-router-dom";

interface Props {
    team: AdminTeamInfo | null;
    onClose: () => void;
    resendConfirmationRequest: (_: AdminTeamInfo) => Promise<void>;
    excludeTeam: (team: AdminTeamInfo, exlItem: ExludedItem | null) => Promise<void>;
    deleteTeam: (team: AdminTeamInfo) => Promise<void>;
}

enum ButtonState {
    Ready,
    Executing,
    Executed,
    Error,
}

const DialogLine = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    
    gap: 1rem;
    padding: 0.5rem 0;
`;

export const TeamControlDialog: React.FC<Props> = (props: Props) => {
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Ready);
    const [excluded, setExcluded] = useState<ExludedItem | undefined | null>(props.team ? props.team.excluded : null);

    const sendRequest = () => {
        if (!props.team)
            return;

        setButtonState(ButtonState.Executing);
        props.resendConfirmationRequest(props.team)
            .then(() => setButtonState(ButtonState.Executed))
            .catch((error) => {
                setButtonState(ButtonState.Error);
                throw error;
            });
    }

    useEffect(
        () => { setButtonState(ButtonState.Ready); },
        [props.team]);


    const putExcluded = (exl: ExludedItem | null) => {
        if (props.team) {
            props.excludeTeam(props.team, exl)
        }
    }

    const certificate = useTeamCertificate(false);

    return (
        <Dialog
            open={!!props.team}
            onClose={props.onClose}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle
              onClose={props.onClose}
              actions={<>
                <OccupyFreeSpace />
                <Tooltip title="Написать сообщение команде">
                  <Link to={`/admin/chats/${props.team?._id}`}>
                    <IconButton size="small">
                      <ChatOutlined />
                    </IconButton>
                  </Link>
                </Tooltip>
                <DeleteButton
                  button={<Button color="secondary" fullWidth>удалить</Button>}
                  title="Удалить команду?"
                  hint="Внимание: все данные команды будут удалены. Операцию невозможно будет отменить. Для подтверждения введите номер команды:"
                  confirmationText={props.team?.number || "never-guess"}
                  preventGoBack
                  remove={() => {
                      if(props.team) {
                          return props.deleteTeam(props.team)
                      } else {
                          return Promise.resolve({});
                      }
                  }}
                  />
              </>}>
                Команда {props.team?.number}
            </DialogTitle>
            <DialogContent>

              <FormGrid columns="1fr 1fr" noMargin>

                <DialogLine>
                  <Typography>Сертификаты</Typography>
                  <OccupyFreeSpace />

                  <Button
                    key="team-cert"
                    startIcon={<GetAppOutlined />}
                    color="primary"
                    onClick={() => certificate.forAdmin.startEditing({ partyId: props.team?._id, names: "", template: "", coach: undefined })}
                  >
                    команда
                  </Button>

                  {new Array((props.team?.info?.coaches || []).length).fill(0).map((_,coachIdx) => (
                    <Button
                      size="medium"
                      color="primary"
                      onClick={() => certificate.forAdmin.startEditing({ partyId: props.team?._id, names: "", template: "", coach: coachIdx })}
                      startIcon={<GetAppOutlined />}
                    >
                      тренер {coachIdx+1}
                    </Button>))}

                  <DownloadCertificateDialog data={certificate.forAdmin} />
                </DialogLine>


                <DialogLine>
                    {props.team?.excluded && props.team?.excluded.datetime &&
                        <>
                            <Typography>
                                <b>Команда исключена:</b> {props.team?.excluded.reason}, {getFullLocalDateTime(props.team?.excluded.datetime)}
                            </Typography>
                            <OccupyFreeSpace />
                            <Button
                                onClick={() => putExcluded(null)}
                                color="secondary"
                            >
                                Отменить исключение
                            </Button>
                        </>
                    }
                    {!props.team?.excluded && !excluded &&
                        <>
                            <OccupyFreeSpace />
                            <Button
                                color="primary"
                                onClick={() => setExcluded({ reason: '' })}
                            >
                                Исключить команду
                            </Button>
                        </>
                    }
                    {!props.team?.excluded && excluded &&
                        <>
                            <GenericTextField
                                required
                                label="Причина"
                                value={excluded.reason}
                                onChange={val => setExcluded({ ...excluded, reason: val })}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!excluded?.reason || excluded?.reason === '' ? true : false}
                                onClick={() => props.team && putExcluded(excluded)}
                            >
                                Исключить
                            </Button>
                        </>
                    }
                </DialogLine>



                {!props.team?.email_confirmed
                    ? <DialogLine>
                        {props.team?.repeated_confirmation_request_at
                            ? (<Typography>Повторный запрос подтверждения email отправлен {getLocalDateTime(props.team.repeated_confirmation_request_at)}</Typography>)
                            : (<Typography>Повторный запрос подтверждения email не отправлялся</Typography>)}

                        <OccupyFreeSpace />
                        <Button
                            onClick={sendRequest}
                            disabled={buttonState !== ButtonState.Ready}
                            color='primary'>
                            {buttonState === ButtonState.Executing && <CircularProgress size={25} />}
                            {buttonState === ButtonState.Executed && <Check />}
                            {buttonState === ButtonState.Error && <Close />}
                            {buttonState === ButtonState.Ready ? "Отправить" : "Отправлен"}
                        </Button>
                    </DialogLine>
                    : <div />}


                <DialogLine>
                    <OccupyFreeSpace />
                    
                </DialogLine>

              </FormGrid>

              <Divider />
              <br />
              <br />

              <SectionTitle>Участники</SectionTitle>
              <Divider/>
              <TeamMembersTable members={props.team?.info?.members || []} />

              <br />
              <SectionTitle>Тренеры</SectionTitle>
              <Divider/>
              <TeamCoachesTable coaches={props.team?.info?.coaches || []} />

            </DialogContent>
        </Dialog>);
}
