import { useTableComments } from "../toolympus/components/Comments/useTableComments";
import { useUserManagement } from "../toolympus/hooks/useUserManagement";


export const useComments = (entity: string) => {
    const users = useUserManagement('/api/user', '');

    return useTableComments('/api/comment', entity, {
        isRemoveAllowed: () => true,
        getUserName: id => ((users.users.find(u => u._id === id) || { email: ''}) as { email: string }).email,
    })
}
