import { useState } from 'react';

type FilterFnType<ItemType> = (_: ItemType) => boolean;

export interface TextFilter<ItemType> {
    value: string;
    setValue: (v: string) => void;
    filter: (_: ItemType) => boolean;
}

export const useTextFilter = <ItemType>(itemToSearchableText: (item: ItemType) => string): TextFilter<ItemType> => {
    const [filterValue, setFilter] = useState<string>("");

    const updateFilter = (value: string) => {
        setFilter((value || "").trim().toLowerCase());
    }

    const filterFn = filterValue === "" 
        ? () => true
        : (item: ItemType) => (itemToSearchableText(item) || "").toLowerCase().includes(filterValue);

    return {
        value: filterValue,
        setValue: updateFilter,
        filter: filterFn,
    };
}

export const useFlagFilter = <ItemType>(filterOnChecked: boolean, defaultValue: boolean, shouldShowItem: (item: ItemType) => boolean) => {
    const [filterValue, setFilter] = useState<boolean>(defaultValue);

    const filterFn = ((filterOnChecked && filterValue) || (!filterOnChecked && !filterValue))
        ? (item: ItemType) => shouldShowItem(item)
        : () => true;

    return [filterValue, setFilter, filterFn] as [boolean, (_: boolean) => void, FilterFnType<ItemType>];
}

type TabFilterRow<ItemType> = [string, string, ((_: ItemType) => boolean)] | [string, string];

export interface TabFilter<ItemType> {
    selectedKey: string;
    selectKey: (k: string) => void;
    filter: (_: ItemType) => boolean;
    tabs: { key: string, label: string }[];
}

export const useTabFilter = <ItemType>(defaultKey: string, tabs: TabFilterRow<ItemType>[]): TabFilter<ItemType> => {
    const [selectedFilterKey, setFilterKey] = useState<string>(defaultKey);

    const filtersDict = tabs.reduce<{[_: string]: TabFilterRow<ItemType>}>(
        (result, item) => ({ ...result, [item[0]]: item }), {});
    const filterTabs = tabs.map(([key, label]) => ({ key, label }));
    const selectedFilter = filtersDict[selectedFilterKey] || filtersDict[defaultKey];

    return {
        selectedKey: selectedFilter[0],
        selectKey: setFilterKey,
        filter: selectedFilter[2] || (() => true),
        tabs: filterTabs,
    }
    
}