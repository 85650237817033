import { Box, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ArbitratorSignup, ParticipationOption, ValidationErrors } from '../../typings/schema';
import { ParticipationOptionName } from '../../util/constants';
import { TextFieldTip } from '../Signup/SignupPage.styles';
import Avatar from '../Util/Avatar';
import { FullWidthTextField, MarginGrid } from '../Util/common.styles';
import { GenericTextField } from '../Util/UtilTextfields';
import { ArbiterAvatar, ArbiterTeamCoachField, ArbiterTextFieldView, AvatarView } from './ArbiterForm.styles';
import { OccupyFreeSpace } from '../../toolympus/components/primitives';

interface Props {
    arbiterData: ArbitratorSignup;
    updateArbiter: (arb: ArbitratorSignup) => void;
    children?: any;
    errors?: ValidationErrors;
    photoUrl?: string;
    onAvatarUpload: (blob: Blob) => void;
}

const ArbiterForm: React.FC<Props> = (props: Props) => {
    const { arbiterData, updateArbiter } = props;
    const [isTeamCoach, setIsTeamCoach] = useState<boolean>(!!arbiterData.team_coach);

    const onIsTeamCoachChange = (v: "yes" | "no") => {
      if(v === "yes") {
        setIsTeamCoach(true);
        updateArbiter({ ...arbiterData, team_coach: "" });
      } else {
        setIsTeamCoach(false);
        updateArbiter({ ...arbiterData, team_coach: "" });
      }
    }

    const doesFieldContainsError = (field: string) => {
        return (
            props.errors
                && props.errors?.errors
                && props.errors?.errors?.find(x => x === field) ? true : false
        );
    }

    return (
        <Box width="100%">
          
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={arbiterData?.email}
                        onChange={e => updateArbiter({ ...arbiterData, email: e })}
                        type="email"
                        error={doesFieldContainsError("email")}
                        label="Контактный email"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={arbiterData?.phone}
                        onChange={e => updateArbiter({ ...arbiterData, phone: e })}
                        error={doesFieldContainsError("phone")}
                        label="Контактный номер телефона"
                    />
                </Grid>
            </MarginGrid>
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <GenericTextField
                        value={arbiterData?.lastname}
                        onChange={e => updateArbiter({ ...arbiterData, lastname: e })}
                        error={doesFieldContainsError("lastname")}
                        label="Фамилия"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <GenericTextField
                        value={arbiterData?.firstname}
                        onChange={e => updateArbiter({ ...arbiterData, firstname: e })}
                        error={doesFieldContainsError("firstname")}
                        label="Имя"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <GenericTextField
                        value={arbiterData?.middlename}
                        onChange={e => updateArbiter({ ...arbiterData, middlename: e })}
                        error={doesFieldContainsError("middlename")}
                        label="Отчество"
                    />
                </Grid>
            </MarginGrid>
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={arbiterData?.company}
                        onChange={e => updateArbiter({ ...arbiterData, company: e })}
                        error={doesFieldContainsError("company")}
                        label="Место работы"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={arbiterData?.position}
                        onChange={e => updateArbiter({ ...arbiterData, position: e })}
                        error={doesFieldContainsError("position")}
                        label="Должность"
                    />
                </Grid>
            </MarginGrid>
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                    <GenericTextField
                        value={arbiterData?.city?.toString()}
                        onChange={e => updateArbiter({ ...arbiterData, city: e })}
                        error={doesFieldContainsError("city")}
                        label="Город"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <FullWidthTextField
                        select
                        required
                        variant="outlined"
                        label="Уровень оконченного образования"
                        value={arbiterData?.educationLevel}
                        error={doesFieldContainsError("educationLevel")}
                        onChange={e => updateArbiter({ ...arbiterData, educationLevel: e.target.value })}
                    >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value='BS'>Бакалавриат</MenuItem>
                        <MenuItem value='MS'>Магистратура</MenuItem>
                        <MenuItem value='SP'>Специалитет</MenuItem>
                    </FullWidthTextField>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <GenericTextField
                        value={arbiterData?.educationFinishedYear?.toString()}
                        onChange={e => updateArbiter({ ...arbiterData, educationFinishedYear: parseInt(e) })}
                        error={doesFieldContainsError("educationFinishedYear")}
                        type="number"
                        label="Год окончания"
                    />
                </Grid>
            </MarginGrid>
            <ArbiterTextFieldView>
                <GenericTextField
                    value={arbiterData?.affiliation}
                    onChange={e => updateArbiter({ ...arbiterData, affiliation: e })}
                    error={doesFieldContainsError("affiliation")}
                    label="Аффилированность"
                />
                <TextFieldTip>
                    место обучения, место преподавания, тренерство
                </TextFieldTip>
            </ArbiterTextFieldView>
            <ArbiterTeamCoachField>
              <Typography>Являетесь ли вы тренером команды?</Typography>
              <OccupyFreeSpace />
              <RadioGroup row name="isTeamCoach" value={isTeamCoach ? "yes" : "no"} onChange={e => onIsTeamCoachChange(e.target.value as "yes" | "no")}>
                <FormControlLabel value="no" control={<Radio />} label="Нет" />
                <FormControlLabel value="yes" control={<Radio />} label="Да" />
              </RadioGroup>
              {isTeamCoach &&
                <GenericTextField
                  value={arbiterData?.team_coach}
                  onChange={e => updateArbiter({ ...arbiterData, team_coach: e })}
                  error={doesFieldContainsError("team_coach")}
                  label="Номер команды"
                />}
            </ArbiterTeamCoachField>
            <ArbiterTextFieldView>
                <GenericTextField
                    value={arbiterData?.mootCourtsExperience}
                    onChange={e => updateArbiter({ ...arbiterData, mootCourtsExperience: e })}
                    error={doesFieldContainsError("mootCourtsExperience")}
                    label="Опыт участия в муткортах"
                />
                <TextFieldTip>
                    в качестве арбитра / тренера / участника в прошлом
                        </TextFieldTip>
            </ArbiterTextFieldView>
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <FullWidthTextField
                        select
                        required
                        variant="outlined"
                        label="Планирую принять участие"
                        value={arbiterData?.willParticipateIn}
                        error={doesFieldContainsError("willParticipateIn")}
                        onChange={e => updateArbiter({ ...arbiterData, willParticipateIn: e.target.value as ParticipationOption })}
                    >
                        {['both', 'online', 'inperson'].map(option => (
                            <MenuItem key={option} value={option}>{ParticipationOptionName[option as ParticipationOption]}</MenuItem>
                        ))}
                    </FullWidthTextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FullWidthTextField
                        select
                        required
                        variant="outlined"
                        label="Планирую принять участие в премутах"
                        value={arbiterData?.will_participate_in_premoots}
                        error={doesFieldContainsError("will_participate_in_premoots")}
                        onChange={e => updateArbiter({ ...arbiterData, will_participate_in_premoots: e.target.value as "yes" | "no" | null })}
                    >
                        <MenuItem value="">
                        </MenuItem>
                        <MenuItem value='yes'>
                            да
                        </MenuItem>
                        <MenuItem value='no'>
                            нет
                        </MenuItem>
                    </FullWidthTextField>
                </Grid>
            </MarginGrid>
            <AvatarView>
                <div>
                    Фотография*
                        </div>
                <ArbiterAvatar>
                    <Avatar
                        key={props.photoUrl}
                        src={props.photoUrl}
                        setUploadedFile={props.onAvatarUpload}
                    />
                </ArbiterAvatar>
            </AvatarView>
            {props.children}
        </Box>
    )

}


export default ArbiterForm;