import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Divider, Grid } from '@mui/material';
import { PageContent, PageHeaderView } from '../../presentation/Util/common.styles';

const headerHeight = 80;

const FixedHeader = styled(PageHeaderView)`
    @media(min-width: 601px) {
        height: ${headerHeight}px;
        box-sizing: border-box;
    }    
`;

FixedHeader.defaultProps = { useMargin: true };

const scrollColor = `rgba(0, 0, 0, 0.2)`;
const borderColor = `rgba(0, 0, 0, 0.1)`;

const PageColumn = styled(Grid)`
    @media(min-width: 601px) {
        height: calc(100vh - ${headerHeight+1}px);
        max-height: calc(100vh - ${headerHeight+1}px);
        min-height: calc(100vh - ${headerHeight+1}px);
        overflow-y: auto;
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: ${scrollColor};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollColor};
    }

    &:not(:last-child) {
        @media(min-width: 601px) {
            border-right: 1px solid ${borderColor};
        }
        @media(max-width: 601px) {
            border-bottom: 1px solid ${borderColor};
        }
    }
`;

PageColumn.defaultProps = { item: true };

interface Props {
    title: string;
    roundView: ReactNode;
    chatView: ReactNode;
}

export const OnlineRoundPageView = (props: Props) => {
    const { title, roundView, chatView } = props;

    return (
        <div>
            <FixedHeader>
                {title}
            </FixedHeader>
            <Divider />

            <Grid container>
                <PageColumn item xs={12} md={7}>
                    <PageContent>
                        {roundView}
                    </PageContent>
                </PageColumn>
                <PageColumn item xs={12} md={5}>
                    {chatView}
                </PageColumn>
            </Grid>
            
        </div>
    );
}
