import React from 'react';
import { PageHeader } from '../Util/PageHeader';
import { TermsPageNew } from '../../components/DynamicPages/TermsPageNew';

const TermsTeamSection = () => {
    return (
        <div>
            <PageHeader />
            
            <TermsPageNew />
        </div>
    )
}

export default TermsTeamSection;