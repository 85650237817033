import React from 'react';
import { OnlineRound } from '../../../typings/schema';
import { Dialog } from '../../../toolympus/components/primitives';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { AdminConference } from '../../../components/Contest/useOnlineRoundConference';
import { ExternalConferencingLinkBlock } from '../../../components/Contest/Conference';



interface Props {
  data: EditItemProps<OnlineRound>;
}

export const AdminOnlineRoundConference = (props: Props) => {
  
  return (
    <Dialog
      isOpen={props.data.isEditing}
      close={() => props.data.cancel()}
      dialogTitle="Онлайн раунд"
      noFullscreen
      >
      <ExternalConferencingLinkBlock conferencingExternalLink={props.data.item?.conferencing_external_link} />
      <AdminConference roundId={props.data.item?._id} />
    </Dialog>
  );
}
