import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { Link } from "../../../toolympus/components/primitives";

const ControlsSectionLabelLabel = styled(Typography)`
`;
ControlsSectionLabelLabel.defaultProps = { variant: "caption" };

const ControlsSectionLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 1rem;
    color: ${props => props.theme.palette.text.secondary};

    &::after {
        display: block;
        content: ' ';
        min-width: 20px;
        border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
        flex: 1 0 auto;
        margin-left: 6px;
        margin-top: 2px;
    }

    &::before {
        display: block;
        content: ' ';
        min-width: 42px;
        border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
        margin-right: 6px;
        margin-top: 2px;
    }
`;

export const ControlsSectionLabel = ({ label }: { label?: ReactNode }) => {
    return <ControlsSectionLabelWrapper>
        {label && <ControlsSectionLabelLabel>{label}</ControlsSectionLabelLabel>}
    </ControlsSectionLabelWrapper>
}

const StyledLink = styled(Link)`
    font-size: 0.8em;
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    padding-bottom: 1px;
`;

const ToggleWrapper = styled.div`
    display: flex;
    align-items: baseline;

    gap: 12px;


`;

interface ToggleProps {
    label?: ReactNode;
    value: boolean;
    update: (v: boolean) => void;
    disabled?: boolean;
    name?: string;

    links?: [ReactNode, string][];
}

export const ControlToggle = ({ label, value, update, disabled, name, links }: ToggleProps) => {
    return (
        <ToggleWrapper>
            <FormGroup row>
                <FormControlLabel
                    control={<Switch
                        color="primary"
                        disabled={disabled}
                        checked={value}
                        onChange={(e) => update(e.target.checked)}
                        name={name} />}
                    label={label}
                />
            </FormGroup>
      
            {(links || []).map(([linkLabel, link]) => <StyledLink to={link}>{linkLabel}</StyledLink>)}
        </ToggleWrapper>
    );
}
