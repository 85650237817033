export const cropText = (text?: string, maxLength?: number) => {
    if(!text || !maxLength || text.length <= maxLength) {
        return text;
    }

    const terminator = '...';

    const cut = text.substr(0, maxLength);
    if(text.length > maxLength && [' ', '\n'].includes(text[maxLength])) {
        return cut + terminator;
    }

    let lastWhitespace = Math.max(cut.lastIndexOf(' '), cut.lastIndexOf('\n'));
    if(lastWhitespace < 0) {
        return cut + terminator;
    }

    return cut.substring(0, lastWhitespace) + terminator;
}
