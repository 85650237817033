import { useState, useEffect } from 'react';
import { fetchGet, FetchTypes, apiUploadFile, apiFetch } from '../../util/apiFetch';
import { TeamDocuments } from '../../typings/schema';

const getDocuments = () => {
    return fetchGet<TeamDocuments>('/api/team/documents');
}

const uploadDocument = (docId: string, file: any) => {
    return apiUploadFile(`/api/team/documents/${docId}`, 'file', file);
}

const deleteDocument = (docId: string) => {
    return apiFetch<TeamDocuments>(`/api/team/documents/${docId}`, FetchTypes.DELETE);
}

export const useTeamDocuments = () => {
  const [documents, setDocuments] = useState<TeamDocuments>({});

  useEffect(() => {
      getDocuments()
          .then(docs => setDocuments(docs))
  }, [])

  const onFileAdd = (id: string, file: any) => {
      uploadDocument(id, file)
          .then(response => setDocuments(response.data as TeamDocuments));
  }

  const onFileDelete = (id: string) => {
      deleteDocument(id)
          .then(docs => setDocuments(docs))
  }

  return {
    documents,
    onFileAdd,
    onFileDelete,
  }
}
