import React from 'react';
import { useVolunteerApplications } from './useVolunteerApplications';
import { RegistrationReviewPopup, RegistrationsListPage } from '../../toolympus/components/Contests/Registrations';
import { FormGrid, PseudoLink2 } from '../../toolympus/components/primitives';

interface Props {
  
}

export const VolunteerApplicationsList = (props: Props) => {
  const data = useVolunteerApplications();
  return (<>
    <RegistrationsListPage
      data={data}
      title="Заявки волонтеров"
      fields={{
        defaultFields: [
          "lastname",
          "firstname",
          "middlename",
          "email",
          "phone",
          "participation",
          "approval_status",
          "created_datetime",
        ],
        schema: data.schema,
        extraSettings: {
          created_datetime: { utcToLocal: true },
        },
      }}
      fieldElement={f => {
        switch(f) {
          case "email":
          case "lastname":
          case "approval_status":
            return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.open(row._id)}>{orig}</PseudoLink2>
        }
      }}
      />
    <RegistrationReviewPopup
      data={data.review}
      title="Волонтер"
      dialogProps={{
        maxWidth: "md",
        fullWidth: true,
      }}>
        <FormGrid noMargin gap="dense">
          {data.review.controls([
            ["email"],
            ["phone"],
            ["approval_status"],
          ])}
        </FormGrid>
        <FormGrid noMargin gap="dense">
          {data.review.controls([
            ["lastname"],
            ["firstname"],
            ["middlename"],
          ])}
        </FormGrid>
        <FormGrid columns="1fr">
          {data.review.controls([
            ["participation"],
          ])}
        </FormGrid>
    </RegistrationReviewPopup>
  </>);
}
