import { useNewItem } from "../../../toolympus/api/useNewItem";

export interface VolunteerApplication {
  _id: number;
  created_datetime: string;
  approval_status?: string;

  email: string;
  phone: string;
  lastname: string;
  firstname: string;
  middlename: string;
  participation: string;
  pdc_consent: boolean;
}

export interface VolunteerApplicationNew extends Omit<VolunteerApplication, "_id" | "created_datetime" | "approval_status"> {
}

export const useVolunteerRegistration = () => {
  const data = useNewItem<VolunteerApplicationNew, VolunteerApplication>(
    "/api/volunteer/register",
    {
      email: "",
      phone: "",
      lastname: "",
      firstname: "",
      middlename: "",
      participation: "",
      pdc_consent: false,
    },
    {
      autoStartEdit: true,
    }
  );

  return {
    ...data,
  }
}
