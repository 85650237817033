import React from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

export const LoadingStub = () => {
    return <Wrapper>
        <CircularProgress />
    </Wrapper>
}