import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { scrollStyle } from '../../toolympus/components/frame/new';

export const ListView = styled.div`
    padding: 3rem 3vw;
    max-width: 100%;

    & > :first-child {
        margin-bottom: 1rem;
    }
`

export const ListViewStuffed = styled(ListView)`
    padding: 0 3vw 0.5rem;
    max-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;

    ${props => scrollStyle(props.theme, { size: 10 })}
`;

export const TableControls = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 1rem;

    & > :not(:first-child) {
        margin-left: 2rem;
    }
`;

export const TotalParticipantsText = styled(Typography)`
    flex: 1 1 auto;
    text-align: right;
`
TotalParticipantsText.defaultProps = { variant: "caption" };

export const CancelButton = styled(Button)`
    background-color: ${props => props.theme.palette.error.main};
`