import styled from '@emotion/styled/macro';
import { Divider, TextField, Button, Select, Typography, CircularProgress, Grid, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Link } from 'react-router-dom';

export const CustomDivider = styled(Divider)`
    background-color: rgba(221,215,215,0.41);
`

export const FullWidthTextField = styled(TextField)`
    width: 100%;
`

export const FullWidthSelect = styled(Select)`
    width: 100%;
`;

export const Hint = styled(Box)`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    color: #ABABAB;
`;
Hint.defaultProps = { className: 'Hint' };

interface PageHeaderViewProps {
    useMargin?: boolean;
}

export const PageHeaderView = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1.5em;
    padding: ${(props: PageHeaderViewProps) => props.useMargin ? '24px 36px' : '0px'};
    
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};

    @media(max-width: 600px){
        justify-content: center;
        flex-direction: column-reverse;
    }
`;

export const PageSection = styled.div`
`;

export const PageSectionPadded = styled(PageSection)`
    margin-bottom: 1rem;
`;

export const PageColumn = styled.div`
    width: 300px;
    display: flex;
    flex-flow: column;
    & > * {
        flex: 0 0 auto;
        width: auto;
        margin-top: 0.5rem;
    }
`;

export const PageSectionHeader = styled(Typography)`
    margin-bottom: 0.5rem;

    & > svg {
        vertical-align: middle;
        margin: 0 0.5rem;
    }
`;
PageSectionHeader.defaultProps = { variant: 'h6' };

export const PageContentSidePadding = "3vw";

export const PageContent = styled.div`
    padding: 1rem ${PageContentSidePadding} 3rem;
    max-width: 100%;
    min-height: 100%;

    & ${PageSection}:not(:first-child) {
        margin-top: 2rem;
    }

    & .no-gutter {
      width: calc(100% + 2 * ${PageContentSidePadding});
      margin-left: -${PageContentSidePadding};
      margin-right: -${PageContentSidePadding};
    }

    & h2 {
      font-size: 28px;
      line-height: 34px;
      padding-top: 15px;
      font-weight: normal;
      text-transform: uppercase;
      color: ${props => props.theme.palette.primary.main};
      border-top: 7px solid ${props => props.theme.palette.primary.main};
    }

    & p a {
      text-decoration: none;
      color: ${props => props.theme.palette.secondary.main};
      font-weight: bold;
      border-bottom: none;
    }

    & .highlight {
      color: ${props => props.theme.palette.secondary.main};
      background: transparent;
    }
`;

export const PageContentNoGutter = styled(PageContent)`
  padding-left: 0;
  padding-right: 0;

  & .no-gutter {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;



export const PublicPageWrapper = styled.div`
    padding: 60px 78px;
    font-size: 18px;

    ${props => props.theme.breakpoints.down('md')} {
        padding: 50px 35px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        padding: 40px 20px;
    }

    & h1:first-child, & h2:first-child {
      margin-top: 0;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

  & h2 {
    font-size: 28px;
    line-height: 34px;
    padding-top: 15px;
    font-weight: normal;
    text-transform: uppercase;
    color: ${props => props.theme.palette.primary.main};
    border-top: 7px solid ${props => props.theme.palette.primary.main};
  }

  & p a {
    text-decoration: none;
    color: ${props => props.theme.palette.secondary.main};
    font-weight: bold;
    border-bottom: none;
  }

  & .highlight {
    color: ${props => props.theme.palette.secondary.main};
    background: transparent;
  }

  & table {
      border-collapse: collapse;
      
      tr {
        border-bottom: 1px solid #cccccc;;
      }

      td {
          padding: 16px 0px 16px 4px;
          border: none;
      }
  }


`;
PublicPageWrapper.defaultProps = { className: "page-content" };

export const PageHeaderBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    min-height: 3em;
    padding: 0.5em 3vh;
    
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};

    @media(max-width: 600px){
        justify-content: center;
        flex-direction: column-reverse;
    }
`;

interface SuccessButtonProps {
    width?: string;
}

export const SuccessButtonView = styled(Button)`
    background-color: #0F9D58;
    width: ${(props: SuccessButtonProps) => props.width || 'auto'};
`

export const SaveButtonView = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    @media(max-width: 600px){
        justify-content: center;
        padding-top: 2rem;
    }
`

export const PrimaryButton = styled(Button)`
`;
PrimaryButton.defaultProps = { variant: 'contained', color: 'primary' };

export const DangerButton = styled(Button)`
    background: ${props => props.theme.palette.error.main};
    color: ${props => props.theme.palette.error.contrastText};
`;
DangerButton.defaultProps = { variant: 'contained' };

interface ProgressIndicatorProps {
    isShown: boolean;
    invertColor?: boolean;
}

export const ProgressIndicator = styled(CircularProgress)`
    display: ${(props: ProgressIndicatorProps) => props.isShown ? 'inline-block' : 'none'};
    color: ${(props: any) => props.invertColor ? props.theme.palette.primary.contrastText : props.theme.palette.primary.main};
    margin-left: 0.5rem;
    margin-right: 1rem;
`;
ProgressIndicator.defaultProps = { size: 25 };

export const LinkView = styled.a`
    color: ${props => props.theme.palette.primary.light};
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px dotted ${props => props.theme.palette.primary.light};
    cursor: pointer;
`

export const Underline = styled.span`
    padding-bottom: 1px;
    border-bottom: 1px dotted ${props => props.theme.palette.text.secondary};
    cursor: pointer;
`;

export const StyledLink = styled(Link)`
    color: ${props => props.theme.palette.primary.light};
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px dotted ${props => props.theme.palette.primary.light};
    cursor: pointer;
`

export const LoadingPageView = styled.div`
    width: 100%;
    height: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LoadingView = styled.div`
    margin-right: 1rem;
`

export const layoutWidthBreakpoint = 1200;

export const Links = styled.div`
    display: flex;
    font-size: 1.2rem;
    margin-bottom: 24px;

    @media(min-width: ${layoutWidthBreakpoint + 1}px) {
        flex-flow: row;
        justify-content: space-around;
        
        & > *:not(:last-child) {
            border-right: 1px solid ${props => props.theme.palette.grey[200]};
        }
    }
    @media(max-width: ${layoutWidthBreakpoint}px) {
        flex-flow: column;
        align-items: center;
        text-align: center;
    }

    & > a {
        display: inline-block;
        text-decoration: none;
        color: ${props => props.theme.palette.primary.light};
        padding: 5px 12px;
    }

`;

export const LoginLogoView = styled.div`
    @media(min-width: 401px) {
        width: 450px;
        height: 250px; 
        margin: -30px 0;
    }
    @media(max-width: 400px){
        width:300px;
        height:100px;
        margin: 0;
    }
`

export const teamPagesTopPadding = 32;

export const WarningText = styled(Typography)`
    color: ${props => props.theme.palette.error.dark};
    font-style: italic;
`;

export const MarginGrid = styled(Grid)`
    margin-bottom: 0.5rem; 
`

export const FullWidthDatePicker = styled(DatePicker)`
    width: 100%;
`
