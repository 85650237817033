import React, { ReactNode } from 'react';
import { Button, useTheme } from '@mui/material';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { Premoot } from './premootData';
import { DescriptionOutlined, HourglassEmpty, Check } from '@mui/icons-material';

interface Props {
    premoot: Premoot;
    emptyState?: ReactNode;
}

const Titles = {
    good: "Заявка утверждена",
    uploaddocs: "Загрузите документы",
    pending: "Заявка рассматривается",
};

const Messages = {
    good: "Ваша заявка утверждена, документы загружены, все отлично!",
    uploaddocs: "Ваша заявка утверждена. Для участия загрузите документы",
    pending: "Ваша заявка рассматривается. Загрузите документы",
}

const Icons = {
    good: <Check />,
    uploaddocs: <DescriptionOutlined />,
    pending: <HourglassEmpty />,
}

export const ApplicationStatus = ({ premoot, emptyState }: Props) => {
    const st = premoot.is_application_approved ?
        (premoot.doc1 && premoot.doc2 ? "good" : "uploaddocs")
        : "pending";

    const theme = useTheme();

    const colors = {
        "good": theme.palette.success.main,
        "uploaddocs": theme.palette.warning.main,
        "pending": theme.palette.secondary.main,
    }

    if(!premoot.is_applied) {
        return emptyState === undefined ? null : emptyState as JSX.Element;
    }

    return (
        <Tooltip text={Messages[st]}>
            <Button startIcon={Icons[st]} style={{ color: colors[st]}}>{Titles[st]}</Button>
        </Tooltip>
    );
}
