import React from 'react';
import styled from '@emotion/styled';
import { ChatList } from '../../toolympus/components/Chats/ChatList';
import { ChatMessage } from './ChatMessage';

export const ChatListPageView = styled.div`
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
`

export const ChatListWrapper = styled.div`
    display: flex;
    overflow-y: hidden;
    height: 100%;
    padding-left: 0;

    @media (max-width: 600px) {
        flex-direction: column;
        overflow-y: auto;
    }

    & > :first-child {
        column-gap: 12px;

        & > ul:first-child {
            box-shadow: 2px 0px 5px 0px rgba(43, 79, 112, 0.25);
            
            .MuiListItem-root {
                border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
            }
        }
    }
`

export const ChatListOrgPage = () => {
    return (
        <ChatListPageView>
            <ChatListWrapper>
                <ChatList
                    iconButton
                    cropLastMessageInList={140}
                    renderMessage={(m,an) => <ChatMessage key={m._id} message={m} authorName={an || ""} />}
                    allowMarkdownEditor
                    />
            </ChatListWrapper>
        </ChatListPageView>
    );
}
