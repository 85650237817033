import React from 'react';
import TermTimer from './TermTimer';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { PageHeaderView } from './common.styles';
import { useTimerState } from '../../util/useTimerState';
import { useUser } from '../UserContext/UserContext';

const CountdownTitle = styled.div`
    @media(max-width: 600px){
        font-size: 0.8em;

        & > span {
            display: block;
        }
}
`

const TeamNumberTitle = styled.div`
`

const TeamNumberTitleView = styled.div`
    display: flex;
`

const TeamNumberDigits = styled(Typography)`
    font-size: 1em;
    font-weight: 600;
`

interface TeamTitleProps {
    useMargin?: boolean;
}

export const PageHeader: React.FC<TeamTitleProps> = (props: TeamTitleProps) => {
    const useMargin = props.useMargin === undefined ? true : false;

    const { timerInfo } = useTimerState();
    const { user } = useUser();

    return (
        <PageHeaderView useMargin={useMargin}>
            {timerInfo?.isOn && <CountdownTitle>
                <TermTimer info={timerInfo} />
            </CountdownTitle>}
            {user.teamnbr !== undefined && <TeamNumberTitleView>
                <TeamNumberTitle>
                    Команда №&nbsp;
                </TeamNumberTitle>
                <TeamNumberDigits>{user.teamnbr}</TeamNumberDigits>
            </TeamNumberTitleView>}
        </PageHeaderView>
    )
}