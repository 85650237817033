import React from "react"
import { useQuery } from "../../toolympus/hooks/useQueryParamsState";
import { CmsPage } from "../DynamicPages/CmsPage";
import { RoundInfoBlock, RoundScoreBlock } from "../../toolympus/components/Contests/Grid/Parties/ScoreRoundForm";
import { useScoreRoundByCode } from "../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { createProvided } from "../../toolympus/components/primitives/createProvided";


const useScoreRound = (): ReturnType<typeof useScoreRoundByCode> | null => {
  const query = useQuery();
  return useScoreRoundByCode("/api/premoot-rounds/grid", {
    arbiter_id: query.get("arbiter_id") || "",
    round_id: query.get("id") || "",
    access_code: query.get("code") || "",
    });
}

const {
  Provider: RoundScoringProvider,
  useValue: useRoundScoring,
} = createProvided(useScoreRound, null);


const RoundScoreBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundScoreBlock data={data} />
    : null;
}

const RoundInfoBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundInfoBlock data={data} />
    : null;
}


export const ScorePremootFormContainer = () => {
    return (
      <RoundScoringProvider>
        <CmsPage slug="premoot-scoring" customBlocks={{
          info: RoundInfoBlockP,
          scores: RoundScoreBlockP,
        }}
          />
      </RoundScoringProvider>
    )
    
}