import React from 'react';
import styled from '@emotion/styled';
import { SubHeading, ParticipantsFormView, SectionView, MarginGrid, AddParticipantContainer, BottomTipView, CloseButtonView, ParticipantItemView, PrcticipantNumber } from './Participants.styles';
import ParticipantItem from './ParticipantItem';
import { TeamMember, ValidationErrors, Coach } from '../../typings/schema';
import {  Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { GenericTextField } from '../Util/UtilTextfields';
import { defaultParticipant } from '../../util/constants';
import { ItalicText } from '../../Common/Primitives';

interface Props {
    participants: TeamMember[];
    email: string;
    phone: string;
    no_coach?: boolean;
    children?: any;
    align?: string;
    isEditing: boolean;
    saveButtonName: string;
    updateParticipants: (participants: TeamMember[]) => void;
    updatePhone: (phone: string) => void;
    updateEmail: (email: string) => void;
    updateNoCoach: (noCoaches: boolean) => void;
    updateCoach?: (idx: number, coach: Coach) => void;
    addCoach?: () => void;
    deleteCoach?: (idx: number) => void;
    validationErrors?: ValidationErrors;
    coaches?: Coach[];
}

interface CoachBlockProps {
    idx: number;
    updateCoach: (idx: number, field: string, value: string) => void;
    coach: Coach;
    deleteCoach: (idx: number) => void;
    errors?: string[];
}

const AddButton = styled(Button)`
`;
AddButton.defaultProps = { color: 'primary' };

const CoachBlock = ({ idx, updateCoach, errors, deleteCoach, coach }: CoachBlockProps) => {
    const doesFieldContainError = (field: string) => !!(errors && errors?.find(x => x === field));

    return (<ParticipantItemView>
        <PrcticipantNumber>Тренер {idx + 1}</PrcticipantNumber>
        <CloseButtonView onClick={() => deleteCoach(idx)} />
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <GenericTextField
                    onChange={e => updateCoach(idx, 'email', e)}
                    value={coach.email || ''}
                    required={false}
                    error={doesFieldContainError("email")}
                    label="Контактный email тренера"
                    type="email"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <GenericTextField
                    onChange={e => updateCoach(idx, 'phone', e)}
                    value={coach.phone || ''}
                    error={doesFieldContainError("phone")}
                    required={false}
                    label="Контактный телефон тренера"
                />
            </Grid>
        </MarginGrid>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <GenericTextField
                    onChange={e => updateCoach(idx, 'name', e)}
                    value={coach.name || ''}
                    error={doesFieldContainError("name")}
                    required={false}
                    label="ФИО"
                />
            </Grid>
        </Grid>
    </ParticipantItemView>);
}

const MaxCoaches = 2;
const MaxParticipants = 7;

const ParticipantsForm: React.FC<Props> = (props: Props) => {
    const participants = props.participants.slice();

    const onParticipantUpdate = (index: number, updatedParticipant: TeamMember) => {
        let updatedParticipants = participants.slice();

        updatedParticipants[index] = updatedParticipant;
        props.updateParticipants(updatedParticipants);
    }

    const onAddParticipant = () => {
        let updatedParticipants = participants.slice();

        updatedParticipants.push(defaultParticipant);
        props.updateParticipants(updatedParticipants);
    }

    const onDeleteParticipant = (index: number) => {
        let updatedParticipants = participants.slice();

        updatedParticipants.splice(index, 1);
        if (updatedParticipants[0] && updatedParticipants[0].education) {
            updatedParticipants[0].education.sameAsFirst = false;
        }
        props.updateParticipants(updatedParticipants);
    }

    const doesFieldContainsError = (field: string) => {
        return (
            props.validationErrors
                && props.validationErrors?.errors
                && props.validationErrors?.errors?.find(x => x === field) ? true : false
        );
    }

    const updateCoach = (idx: number, field: string, value: string) => {
        if (props.updateCoach !== undefined && props.coaches) {
            props.updateCoach(idx, { ...props.coaches[idx], [field]: value });
        }
    }

    const validationErrors = props.validationErrors;

    return (
        <ParticipantsFormView align={props.align}>
            <SubHeading>Контактные данные</SubHeading>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <GenericTextField
                        onChange={e => props.updateEmail(e)}
                        disabled={props.isEditing}
                        value={props.email}
                        error={doesFieldContainsError("email")}
                        label="Контактный email команды"
                        type="email"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GenericTextField
                        onChange={e => props.updatePhone(e)}
                        value={props.phone}
                        error={doesFieldContainsError("phone")}
                        label="Контактный телефон команды"
                    />
                </Grid>
            </Grid>
            
            <SectionView>
                <SubHeading>Тренеры</SubHeading>
                {props.coaches && props.coaches.map(
                    (c, i) => <CoachBlock idx={i}
                                        coach={c}
                                        updateCoach={updateCoach}
                                        deleteCoach={props.deleteCoach || (() => { })}
                                        errors={validationErrors?.coachErrors && validationErrors?.coachErrors[i] ? validationErrors.coachErrors[i] : []}
                                        />)}

                {!props.no_coach && (props.coaches || []).length < MaxCoaches && props.addCoach && (
                    <AddButton onClick={() => props.addCoach && props.addCoach()}>добавить тренера</AddButton>)}
                {(props.coaches || []).length === 0 &&
                    (<FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e, checked) => props.updateNoCoach(checked)}
                                checked={props.no_coach || false}
                            />
                        }
                        label="Команда выступает без тренера"
                    />)}
                {((props.coaches || []).length === MaxCoaches) && 
                        <BottomTipView>
                            <ItalicText>
                                Можно указать не более двух тренеров
                            </ItalicText>
                        </BottomTipView>}
            </SectionView>
            
                
            <SectionView>
                <SubHeading>Участники</SubHeading>
                {participants.map((p, index) => (
                    <ParticipantItem
                        participantIndex={index}
                        key={`${index}-participant`}
                        participant={p}
                        updateParticipant={onParticipantUpdate}
                        deleteParticipant={onDeleteParticipant}
                        errors={validationErrors?.memberErrors && validationErrors?.memberErrors[index] ? validationErrors.memberErrors[index] : []}
                    />
                ))}
                <AddParticipantContainer>
                    {((participants || []).length < MaxParticipants) && <AddButton onClick={e => onAddParticipant()}>
                        Добавить участника
                    </AddButton>}
                    {((participants || []).length < MaxParticipants) && !props.isEditing && 
                        <BottomTipView key="note">
                            <ItalicText>
                                * Участников можно будет добавить в личном кабинете после регистрации
                            </ItalicText>
                        </BottomTipView>}
                    {((participants || []).length === MaxParticipants) && 
                        <BottomTipView key="max-participants-note">
                            <ItalicText>
                                Можно указать не более семи участников
                            </ItalicText>
                        </BottomTipView>}
                </AddParticipantContainer>
                
            </SectionView>
            {props.children}
        </ParticipantsFormView>
    )
}

export default ParticipantsForm;