import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import SuccessButton from '../../../presentation/Util/SuccessButton';
import { useUser } from '../../../presentation/UserContext/UserContext';
import { OnlineRoundScoring2 } from '../../Contest/useOnlineRoundScoring2';
import { ScoringSheetForm } from '../../../toolympus/components/Contests/ScoringSheets';

interface Props {
    scoring: OnlineRoundScoring2;
}

const Info = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
`;


export const ScoreDialog = (props: Props) => {
    const {
        currentInfo,
        currentScoring,
        isActive,
        cancelScoring,
    } = props.scoring;
    
    const context = useUser();

    return (
        <Dialog open={isActive} onClose={cancelScoring} maxWidth="md">
            <DialogTitle>
                {currentInfo?.type === "document" ?
                    "Пожалуйста оцените процессуальный документ по указанным критериям"
                    : "Пожалуйста оцените выступление команды"}
            </DialogTitle>
            <DialogContent>
                <Info>
                    {currentInfo?.teamNbr && <div key="team">
                        <Typography variant="caption">Команда</Typography>
                        <Typography>{currentInfo?.teamNbr}</Typography>
                    </div>}
                    {currentInfo?.documentTitle && <div key="doc">
                        <Typography variant="caption">Документ</Typography>
                        <Typography>{currentInfo?.documentTitle}</Typography>
                    </div>}
                    {currentInfo?.performanceTitle && <div key="performance">
                        <Typography variant="caption">Выступление</Typography>
                        <Typography>{currentInfo?.performanceTitle}</Typography>
                    </div>}
                </Info>
                <Divider />

                {currentScoring && <ScoringSheetForm data={currentScoring} hideTitle noButtons />}
                
                <DialogActions>
                    <SuccessButton
                        onClick={() => currentScoring && currentScoring.canSubmit && currentScoring.submit()}
                        disabled={!currentScoring || !currentScoring.canSubmit || !context.contestState?.is_online_rounds_scoring_active}
                        title="Сохранить"
                        size="medium"
                        />
                    <Button  onClick={cancelScoring}>
                        Отмена
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
