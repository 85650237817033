import React, { useState } from 'react';
import LoginPage from '../presentation/Login/LoginPage';
import { TeamLogin } from '../typings/schema';
import { defaultLoginData } from '../util/constants';
import { apiFetch, FetchTypes } from '../util/apiFetch';
import { isAuthenticated } from '../util/tokenStorage';
import { useHistory, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUser, login, User, checkRole } from '../presentation/UserContext/UserContext';
import { useReturnUrl } from '../toolympus/hooks/auth/useReturnUrl';

interface Props {

}

export const apiLogin = (email: string, password: string) => {
    return apiFetch<string>("/api/login", FetchTypes.POST, { "login": email, "password": password }, { noAuth: true })
}

export const LoginContainer: React.FC<Props> = (props: Props) => {
    const [loginData, setLoginData] = useState<TeamLogin>(defaultLoginData);
    const snack = useSnackbar();
    const history = useHistory();
    const context = useUser();
    const returnUrl = useReturnUrl();

    const redirectTarget = (user: User) => {
        if(returnUrl.fromParam) {
            return returnUrl.fromParam;
        }

        let redirectTarget = '/team';
        if(checkRole(user, 'team')) {
            redirectTarget = '/team';
        } else if (checkRole(user, 'arbiter')) {
            redirectTarget = '/arbiter/onlineround';
        } else if (checkRole(user, 'admin')) {
            redirectTarget = '/admin/team';
        }
        return redirectTarget;
    }

    const onLogin = (data: TeamLogin) => {
        apiLogin(data.email, data.password)
            .then(token => login(context, token))
            .then(decoded => { history.push(redirectTarget(decoded)); })
            .catch(error => snack.enqueueSnackbar(
                "Не удалось авторизоваться - неправильный email или пароль.",
                { variant: 'error', autoHideDuration: 5000 }))
    }

    const onSignupDataChange = (data: TeamLogin) => {
        setLoginData(data);
    }

    if (isAuthenticated()) {
        return <Redirect to={{ pathname: redirectTarget(context.user) }} />
    } else return (
        <LoginPage
            loginData={loginData}
            onLoginDataChange={onSignupDataChange}
            onLogin={onLogin}
        />
    )
}