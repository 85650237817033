import React from 'react'
import styled from '@emotion/styled';
import { Button, ButtonGroup, Divider, Typography } from '@mui/material';
import { RoundType, TeamOnlineRounds } from '../../../components/Contest/useOnlineRound'
import { OnlineRound } from '../../../typings/schema';
import { useUser } from '../../UserContext/UserContext';
import { PageSectionPadded } from '../../Util/common.styles';
import LoadingPage from '../../Util/LoadingPage';
import { Star } from '@mui/icons-material';
import { OnlineRoundView } from '../../../components/OnlineRounds/OnlineRoundView';
import { OnlineRoundPageView } from '../../../components/OnlineRounds/OnlineRoundPageView';
import { OnlineRoundChat } from '../../../components/Chats/OnlineRoundChat';

interface Props {
    roundsData: TeamOnlineRounds;
}

const ExtraRoundControls = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 1rem;
    }
`;

export const OnlineRoundPage = (props: Props) => {
    const { roundsData } = props;
    
    const user = useUser();
    
    const roundData = roundsData.active;
    if(roundData.isLoading || !roundData.round || !roundData.round.team1_nbr || !roundData.round.team2_nbr) {
        return <LoadingPage />
    }

    const round = roundData.round as OnlineRound;

    const teamNbr = round.team1_id === user.user._id ? round.team1_nbr : round.team2_nbr;

    const roundView = (<>
        {roundsData.isExtraAvailable && (
            <>
                <PageSectionPadded>
                    <ExtraRoundControls>
                        <Star color="primary" />
                        <Typography>Вы участвуете в двух онлайн раундах! Показать:</Typography>
                        <ButtonGroup size="small" color="primary" variant="outlined">
                            <Button
                                key='normal'
                                variant={roundsData.active === roundsData.normal ? "contained" : "outlined"}
                                onClick={() => roundsData.activate(RoundType.Normal)}>
                                    1
                            </Button>
                            <Button
                                key='extra'
                                variant={roundsData.active === roundsData.extra ? "contained" : "outlined"}
                                onClick={() => roundsData.activate(RoundType.Extra)}>
                                    2
                            </Button>
                        </ButtonGroup>
                    </ExtraRoundControls>
                </PageSectionPadded>
                <Divider />
            </>)}

        <OnlineRoundView roundData={roundData} currentTeamId={user.user._id} />
    </>);

    return <OnlineRoundPageView
                title={`Команда №  ${teamNbr ? parseInt(teamNbr) : undefined}`}
                roundView={roundView}
                chatView={round && round.chat_id && <OnlineRoundChat chatId={round.chat_id} />}
                />;
}
