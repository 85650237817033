import React from 'react';
import { GenericTextField } from '../Util/UtilTextfields';
import { FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SectorHeading, ParticipantItemView, MarginGrid, CloseButtonView, EducationCheckboxView, GridWithNumber, PrcticipantNumber } from './Participants.styles';
import { TeamMember } from '../../typings/schema';
import { FullWidthTextField } from '../Util/common.styles';

interface Props {
    participantIndex: number;
    participant: TeamMember;
    updateParticipant: (index: number, participant: TeamMember) => void;
    deleteParticipant: (index: number) => void;
    errors?: string[];
}

const defaultEducation = {
    sameAsFirst: true,
    school: '',
    level: '',
    year: 0
}

const ParticipantItem: React.FC<Props> = (props: Props) => {
    const participant = props.participant;

    const onItemChange = (updatedParticipant: TeamMember) => {
        props.updateParticipant(props.participantIndex, updatedParticipant);
    }

    const onEducationChange = (fieldName: string, value: any) => {
        if (fieldName === 'year') {
            let val: number | string = parseInt(value);
            if (isNaN(val)) {
                val = "";
            } else if(val < 1 || val > 6) {
                return;
            }
        }

        const education = participant.education || defaultEducation;
        const updatedEducation = { ...education, [fieldName]: value };
        onItemChange({ ...participant, education: updatedEducation });
    }

    const doesFieldContainError = (field: string) => {
        return (props.errors && props.errors?.find(x => x === field) ? true : false);
    }

    return (
        <ParticipantItemView>
            <PrcticipantNumber>Участник {props.participantIndex + 1}</PrcticipantNumber>
            <CloseButtonView
                    onClick={() => props.deleteParticipant(props.participantIndex)}
                />
            <GridWithNumber container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <GenericTextField
                        value={participant.lastname}
                        onChange={e => onItemChange({ ...participant, lastname: e })}
                        error={doesFieldContainError("lastname")}
                        label="Фамилия"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <GenericTextField
                        value={participant.firstname}
                        onChange={e => onItemChange({ ...participant, firstname: e })}
                        error={doesFieldContainError("firstname")}
                        label="Имя"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <GenericTextField
                        value={participant.middlename}
                        onChange={e => onItemChange({ ...participant, middlename: e })}
                        error={doesFieldContainError("middlename")}
                        label="Отчество"
                    />
                </Grid>
            </GridWithNumber>
            <SectorHeading>Образование</SectorHeading>
            {props.participantIndex > 0 &&
                <EducationCheckboxView>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e, checked) => onEducationChange("sameAsFirst", checked)}
                                checked={participant.education?.sameAsFirst || false}
                            />
                        }
                        label="Совпадает с данными первого участника"
                    />
                </EducationCheckboxView>
            }
            {!props.participant.education?.sameAsFirst &&
                <MarginGrid container spacing={3}>
                    <Grid item xs={12} sm={5} md={5}>
                        <GenericTextField
                            onChange={e => onEducationChange("school", e)}
                            value={participant.education?.school}
                            error={doesFieldContainError("school")}
                            label="ВУЗ"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FullWidthTextField
                            id={`${props.participantIndex}-education-level`}
                            select
                            required
                            variant="outlined"
                            label="Уровень образования"
                            error={doesFieldContainError("level")}
                            value={participant.education?.level}
                            onChange={e => onEducationChange("level", e.target.value)}
                        >
                            <MenuItem value="">
                            </MenuItem>
                            <MenuItem value='BS'>
                                Бакалавриат
                            </MenuItem>
                            <MenuItem value='MS'>
                                Магистратура
                            </MenuItem>
                            <MenuItem value='SP'>
                                Специалитет
                            </MenuItem>
                        </FullWidthTextField>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <GenericTextField
                            onChange={e => onEducationChange("year", parseInt(e))}
                            value={participant.education?.year.toString()}
                            label="Курс"
                            error={doesFieldContainError("year")}
                            type="number"
                        />
                    </Grid>
                </MarginGrid>
            }
            <MarginGrid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={participant.email}
                        onChange={e => onItemChange({ ...participant, email: e })}
                        type="email"
                        error={doesFieldContainError("email")}
                        label="Контактный email"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <GenericTextField
                        value={participant.phone}
                        onChange={e => onItemChange({ ...participant, phone: e })}
                        error={doesFieldContainError("phone")}
                        label="Контактный телефон"
                    />
                </Grid>
            </MarginGrid>
            <Grid item xs>
                <GenericTextField
                    value={participant.mootCourtsExperience}
                    onChange={e => onItemChange({ ...participant, mootCourtsExperience: e })}
                    required={false}
                    error={doesFieldContainError("mootCourtsExperience")}
                    label="Опыт участия в муткортах"
                />
            </Grid>
        </ParticipantItemView>
    )
}

export default ParticipantItem;