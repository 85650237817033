import React from 'react';
import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { Links } from '../Util/common.styles';

export const MainTitle = styled(Typography)`
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 2em;
    color: #333;
`;
MainTitle.defaultProps = { component: 'h2' } as any;

export const CenteredView = styled.div`
    display: flex;
    justify-content: center;
`;

export const CenteredText = styled.div`
    text-align: center;
`;

export const MainContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 40vw;

    @media(max-width: 1200px){
        width: 70vw;
    }

    @media(max-width: 600px){
        width: 65vw;
    }
`

export const HeaderContainer = styled.div`
    margin-bottom: 1rem;

    & ${Links} {
        margin-top: 24px;
    }
`

export const PasswordButtonsView = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
`

export const SecurityView = styled.div`
    padding-top: 2rem;
    margin-bottom: 2.5rem;
    
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
`

export const TextFieldTip = styled.div`
    padding-left: 4px;
    font-style: italic;
`

export const SignupContainer = styled.div`
    display: grid;
    background-color: #FFFFFF;
    padding: 60px 78px;

    ${props => props.theme.breakpoints.down('md')} {
        padding: 50px 35px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        padding: 40px 20px;
    }
`

export const LoadedView = styled.div`
    display: flex;
    justify-content: center;
`

export const InformationTextView = styled(Typography)`
    margin-bottom: 1.5rem;
`

const RegistrationDisabledWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 2em 0;
`;

export const RegistrationDisabled = () => (
    <RegistrationDisabledWrapper>
        <Typography>Регистрация закрыта</Typography>
    </RegistrationDisabledWrapper>
);
