import { downloadBuffer } from "../../toolympus/api/core";
import { toLocalDatetimeInternal } from "../../toolympus/api/datetimeUtil";
import { OpenRegistrationReviewData, RegistrationsData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations"
import { useTabsState } from "../../toolympus/components/primitives";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { Schema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { CoachApplication } from "../DynamicPages/Registrations/useCoachRegistration"
import ExcelJS from "exceljs";

const CoachAdminApiPath = "/api/coach/admin";

interface CoachApplicationX extends Omit<CoachApplication, "_id"> {
  _id: string;
}

interface CoachsApplicationsData extends RegistrationsData<CoachApplicationX> {
  review: OpenRegistrationReviewData<CoachApplicationX>;
}

export const exportCoaches = (items: CoachApplicationX[], schema: Schema, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Тренеры");
  sheet.columns = [
    "_id",
    "email",
    "phone",
    "approval_status",
    "lastname",
    "firstname",
    "middlename",
    "university",
    "job",
    "professional_interests",
    "experience",
    "participation",
    "comments",
    "created_datetime",
  ].map(f => ({ header: schema[f].label?.toString() || f, key: f }));

  items.forEach(item => sheet.addRow({
      ...item,
      created_datetime: toLocalDatetimeInternal(item.created_datetime),
  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));

}

export const useCoachApplications = (): CoachsApplicationsData => {
  const tabs = useTabsState([
    ["new", "Новые"],
    ["approved","Утвержденные"],
    ["rejected","Отказ"],
    ["all","Все"],
  ]);
  const data = useLoadedData<CoachApplicationX[]>(`${CoachAdminApiPath}?view=${tabs.current}`, []);
  const filter = useTextFilter<CoachApplicationX>(a => `${a.email} ${a.lastname} ${a.firstname}`);
  const { schema } = useSingleSchema(`${CoachAdminApiPath}/uiconfig`);

  const review = useOpenRegistrationReview<CoachApplicationX>({
    apiPath: CoachAdminApiPath,
    onSaved: () => data.reload(),
  });

  const filteredData = filter.filterData(data.data); 
  
  return {
    ...data,
    data: filteredData,
    ...filter,
    schema,
    tabs,
    review,
    exportData: () => exportCoaches(filteredData, schema, "Тренеры.xlsx"),
  }
}
