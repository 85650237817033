import React, { ReactNode } from "react";
import { Typography, IconButton, DialogTitle as MuiDialogTitle } from "@mui/material";
import { OccupyFreeSpace } from "../../toolympus/components/primitives";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";

const DialogTitleX = styled(MuiDialogTitle)`
  margin: 0;
  padding: ${props => props.theme.spacing(2)};
  display: flex;
  flex-flow: row;
  align-items: center;
  
  & .close-btn {
    color: ${props => props.theme.palette.grey[500]};
  }
`;

export interface DialogTitleProps {
    children: React.ReactNode;
    actions?: ReactNode;
    onClose?: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, actions, ...other } = props;
    const extraProps = { ...other, component: "div" };
    return (
      <DialogTitleX {...extraProps}>
        <Typography variant="h6">{children}</Typography>
        
        {actions}
        {!actions && onClose ? <OccupyFreeSpace /> : null}
        
        {onClose ? (
            <IconButton aria-label="close" className="close-btn" size="small" onClick={onClose}>
                <Close />
            </IconButton>
        ) : null}
      </DialogTitleX>
    );
};

export default DialogTitle;