import React, { ComponentType } from 'react';
import { PowerEditorBaseProps, PowerEditorBase } from '../../toolympus/components/PowerDoc';
import { PowerEditorSettingsProvider } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';
import { ExpanderPlugin } from '../../toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PanelPlugin } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { MediaLibProvider } from '../../toolympus/components/medialib';
import { CustomTilesPlugin } from './Plugins/CustomTilesPlugin';
import { RenderElementProps } from 'slate-react';
import { useCustomFieldsViewerPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ScriptInjectPlugin } from '../../toolympus/components/PowerDoc/plugins/Script/ScriptInjectPlugin';

interface Props extends PowerEditorBaseProps {
  customBlocks?: Record<string, ComponentType<RenderElementProps>>;
}


export const MozolinViewer = (props: Props) => {
  return <MediaLibProvider apiPath="/api/medialib" spaceId="1" getFilepath={mf => `/media/${mf.filename}`} noLoad>
    <PowerEditorSettingsProvider EditorComponent={MozolinViewerUnwrapped}>
      <MozolinViewerUnwrapped {...props} />
    </PowerEditorSettingsProvider>
  </MediaLibProvider>
}

export const MozolinViewerUnwrapped = (props: Props) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const customBlocksPlugin = useCustomFieldsViewerPlugin(props.customBlocks || {});

  return (
    <PowerEditorBase
        {...props}
        plugins={[
            mediaFilesPlugin,
            customBlocksPlugin,
            CustomTilesPlugin,
            ColumnsPlugin,
            PanelPlugin,
            ScriptInjectPlugin,
            ExpanderPlugin,
            TablesPlugin,
        ]}
        />
  );
}
