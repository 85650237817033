import React, { useState } from 'react';
import { PasswordButtonsView, SecurityView, SignupContainer, InformationTextView, RegistrationDisabled } from './SignupPage.styles';
import { GenericTextField } from '../Util/UtilTextfields';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Grid, CircularProgress, Box } from '@mui/material';
import { TeamSignupData, ValidationErrors } from '../../typings/schema';
import ParticipantsForm from '../Participants/ParticipantsForm';
import { SaveButtonView, LoadingView, Hint, } from '../Util/common.styles';
import SuccessButton from '../Util/SuccessButton';
import { Link, Title } from '../../Common/Primitives';
import { useUser } from '../UserContext/UserContext';
import { useEditTeamData } from '../Team/TeamPage';

interface Props {
    signupData: TeamSignupData;
    onSignup: (data: TeamSignupData) => void;
    isLoading: boolean;
    errors?: ValidationErrors;
}

const SignupPage: React.FC<Props> = (props: Props) => {
    const [isPDAgreed, setIsPDAgreed] = useState<boolean>(false);

    const {
        teamData: signupData,
        setTeamData: setSignupData,
        updatePhone,
        updateParticipants,
        updateCoach,
        addCoach,
        deleteCoach,
        isValidCoach,
    } = useEditTeamData(props.signupData);

    const { contestState, isContestStateLoaded } = useUser();
    const registrationDisabled = isContestStateLoaded && !contestState?.registration_enabled;

    const onSaveButtonClick = () => {
        props.onSignup(signupData);
    }

    const updateEmail = (email: string) => {
        setSignupData({ ...signupData, email: email });
    }

    const doesFieldContainsError = (field: string) => {
        return (
            props.errors
                && props.errors?.errors
                && props.errors?.errors?.find(x => x === field) ? true : false
        );
    }

    const isSignupAllowed = () => {
        const isAllowed =
            signupData
                && signupData.email && signupData.phone
                && signupData.password && signupData.passwordRepeat
                && signupData.password === signupData.passwordRepeat
                && isPDAgreed
                && signupData.members
                && signupData.members.length >= 2
                && signupData.members
                    .every(m => m.phone && m.email
                        && m.firstname && m.middlename && m.lastname
                        && m.education
                        && ((m.education.level !== undefined && m.education.school && m.education.year) || m.education.sameAsFirst) ? true : false)
                && (signupData.coaches || []).every(c => isValidCoach(c))
                ? true : false;
        return isAllowed;
    }

    return (
        <SignupContainer>
            <Title>Регистрация команды</Title>
            <Box height={10} />
            <Hint>Хотите выступить в качестве арбитра? <Link href="/arbitersignup">Заполните другую форму</Link></Hint>
            
            {registrationDisabled && <RegistrationDisabled />}

            {!registrationDisabled && (            
                <ParticipantsForm
                    participants={signupData.members}
                    align="center"
                    phone={signupData.phone}
                    email={signupData.email}
                    saveButtonName="Зарегистрироваться"
                    isEditing={false}
                    updateParticipants={updateParticipants}
                    updateEmail={updateEmail}
                    updatePhone={updatePhone}
                    validationErrors={props.errors}
                    coaches={signupData.coaches || []}
                    updateCoach={updateCoach}
                    addCoach={addCoach}
                    deleteCoach={deleteCoach}
                    no_coach={signupData.no_coach}
                    updateNoCoach={v => setSignupData(x => ({ ...x, no_coach: v }))}
                >
                    <SecurityView>
                        <InformationTextView>
                            Информация, предоставляемая участниками, является конфиденциальной и используется организаторами для регистрации и идентификации участников Конкурса.
                        </InformationTextView>
                        <FormControlLabel
                            control={<Checkbox checked={isPDAgreed} onChange={(e, checked) => setIsPDAgreed(checked)} />}
                            label="Согласен на хранение и обработку персональных данных"
                        />
                    </SecurityView>
                    <PasswordButtonsView>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <GenericTextField
                                    value={signupData?.password}
                                    type="password"
                                    error={doesFieldContainsError("password")}
                                    onChange={e => setSignupData({ ...signupData, password: e })}
                                    label="Пароль"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <GenericTextField
                                    value={signupData?.passwordRepeat}
                                    type="password"
                                    error={doesFieldContainsError("passwordRepeat")}
                                    onChange={e => setSignupData({ ...signupData, passwordRepeat: e })}
                                    label="Повторите пароль"
                                />
                            </Grid>
                        </Grid>
                    </PasswordButtonsView>
                    <SaveButtonView>
                        {props.isLoading &&
                            <LoadingView>
                                <CircularProgress />
                            </LoadingView>
                        }
                        <SuccessButton
                            onClick={onSaveButtonClick}
                            disabled={!isSignupAllowed()}
                            title="Зарегистрироваться"
                        />
                    </SaveButtonView>
                </ParticipantsForm>
            )}
        </SignupContainer>
    )
}

export default SignupPage;