import { getApiToken as gt } from "../toolympus/api/core";
export {
    getApiToken,
    getApiTokenForRequest,
    getApiTokenThrowing,
    storeApiToken,
    clearApiToken,
    decodeToken,
    createAuthHeader,
} from "../toolympus/api/core";

export const isAuthenticated = () => gt() ? true : false;