import React from 'react';
import LoadingPage from '../../../presentation/Util/LoadingPage';
import { OnlineRoundDataArbiter } from '../../Contest/useOnlineRound';
import { OnlineRoundView } from '../../OnlineRounds/OnlineRoundView';
import { OnlineRoundPageView } from '../../OnlineRounds/OnlineRoundPageView';
import { ScoreDialog } from '../../OnlineRounds/Scoring/ScoreDialog';
import { OnlineRoundChat } from '../../Chats/OnlineRoundChat';
import { ArbiterORConfirmation } from '../../Contest/ArbiterORConfirmation';

interface Props {
    roundData: OnlineRoundDataArbiter;
}


export const ArbiterOnlineRoundPage = (props: Props) => {
    const { roundData } = props;
    const round = roundData.round;

    if(roundData.isLoading || !roundData.round) {
        return <LoadingPage />
    }

    return <>
            <OnlineRoundPageView
                title={`Онлайн раунд: ${roundData.round.title}`}
                roundView={<>
                    <ArbiterORConfirmation confirmation={roundData.confirmation} />
                    <OnlineRoundView
                        roundData={roundData}
                        scoring={roundData.scoring}
                        showAllDocs />
                    </>}
                chatView={round && round.chat_id && <OnlineRoundChat chatId={round.chat_id} />}
                />;
            <ScoreDialog scoring={roundData.scoring} />
        </>
};