import React from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from '@mui/material';
import {LoadingView, MarginGrid, SaveButtonView } from '../../../presentation/Util/common.styles';
import { GenericTextField } from '../../../presentation/Util/UtilTextfields';
import SuccessButton from '../../../presentation/Util/SuccessButton';
import { RenderElementProps } from 'slate-react';
import { extractCustomBlockFields } from '../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { useCoachRegistration } from './useCoachRegistration';


export const CoachRegistrationForm = (props: RenderElementProps) => {
  const data = useCoachRegistration();
  const { update, errors } = data;
  const item = data.createdItem || data.item;
  const isSent = !!data.createdItem;

  const blockFields = extractCustomBlockFields(props.element);
  const successMessage = blockFields.success_message || "Заявка отправлена. Спасибо!";

  return (
    <Box width="100%">
      
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <GenericTextField
                value={item?.email || ""}
                onChange={e => update({ email: e })}
                type="email"
                error={errors.fieldHasErrors("email")}
                label="E-mail"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <GenericTextField
                value={item?.phone || ""}
                onChange={e => update({ phone: e })}
                error={errors.fieldHasErrors("phone")}
                label="Телефон"
                disabled={isSent}
                />
            </Grid>
        </MarginGrid>
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <GenericTextField
                value={item?.lastname || ""}
                onChange={e => update({ lastname: e })}
                error={errors.fieldHasErrors("lastname")}
                label="Фамилия"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <GenericTextField
                value={item?.firstname || ""}
                onChange={e => update({ firstname: e })}
                error={errors.fieldHasErrors("firstname")}
                label="Имя"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <GenericTextField
                value={item?.middlename || ""}
                onChange={e => update({ middlename: e })}
                error={errors.fieldHasErrors("middlename")}
                label="Отчество"
                disabled={isSent}
                required={false}
                />
            </Grid>
        </MarginGrid>
        <MarginGrid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.job || ""}
                onChange={e => update({ job: e })}
                error={errors.fieldHasErrors("job")}
                label="Должность, место работы"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.university || ""}
                onChange={e => update({ university: e })}
                error={errors.fieldHasErrors("university")}
                label="ВУЗ"
                disabled={isSent}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.professional_interests || ""}
                onChange={e => update({ professional_interests: e })}
                error={errors.fieldHasErrors("professional_interests")}
                label="Практика/профессиональные интересы"
                disabled={isSent}
                multiline
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.experience || ""}
                onChange={e => update({ experience: e })}
                error={errors.fieldHasErrors("experience")}
                label="Опыт работы"
                disabled={isSent}
                multiline
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.participation || ""}
                onChange={e => update({ participation: e })}
                error={errors.fieldHasErrors("participation")}
                label="Участвовали ли Вы ранее в муткортах (игровых процессах)? Если да, то в каких?"
                disabled={isSent}
                multiline
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextField
                value={item?.comments || ""}
                onChange={e => update({ comments: e })}
                error={errors.fieldHasErrors("comments")}
                label="Ваши пожелания и комментарии"
                disabled={isSent}
                multiline
                required={false}
                />
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={<Checkbox disabled={isSent} checked={item?.pdc_consent || false} onChange={(e, checked) => update({ pdc_consent: checked})} />}
                label="На основании ФЗ РФ от 27.07.2006 № 152-ФЗ «О персональных данных» даю согласие на обработку персональных данных"
                />
            </Grid>
        </MarginGrid>

        <SaveButtonView>
          {data.isLoading &&
            <LoadingView>
              <CircularProgress />
            </LoadingView>
          }
          {!isSent && <SuccessButton
            disabled={!item?.pdc_consent || data.isLoading}
            onClick={() => data.save()}
            title="Отправить заявку"
          />}
          {isSent && <Typography>
            {successMessage}
            </Typography>}
        </SaveButtonView>
    </Box>
  );
}


