import React from 'react';
import styled from '@emotion/styled';
import { PageHeader } from '../../presentation/Util/PageHeader';
import { ChatWithOrg, OrgChatWithOrgPage } from './ChatWithOrgPage';


const QuestionPageView = styled.div`
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    align-items: center;

    padding: 6px 12px;
    box-sizing: border-box;

    & > * {
        width: 100%;
    }
`

export const QuestionsPage = () => {
    return (
        <QuestionPageView>
            <PageHeader />
            <ChatWrapper>
                <ChatWithOrg />
            </ChatWrapper>
        </QuestionPageView>
    )
}

export const OrgStartQuestionsPage = () => {
    return (
        <QuestionPageView>
            <PageHeader />
            <ChatWrapper>
                <OrgChatWithOrgPage />
            </ChatWrapper>
        </QuestionPageView>
    )
}
