import React, { useState } from 'react';
import ArbiterSignupPage from '../presentation/Signup/ArbiterSignupPage';
import { defaultArbiterSignup } from '../util/constants';
import { ArbitratorSignup, ValidationErrors } from '../typings/schema';
import { ValidationError, apiFetch, FetchTypes, apiUploadFile } from '../util/apiFetch';
import { handleValidationError } from '../util/common';
import { storeApiToken, clearApiToken } from '../util/tokenStorage';
import { decodeToken } from '../toolympus/api/core';

interface Props {

}

interface ArbiterContainerState {
    isLoading: boolean;
    errors?: ValidationErrors;
}

const defaultArbiterContainerState: ArbiterContainerState = { isLoading: false };

const registerArbiter = (data: ArbitratorSignup) => {
    return apiFetch('/api/arbiter/register', FetchTypes.POST, data, { noAuth: true });
}

const uploadPhoto = (token: string, photo: Blob) => {
    const user = decodeToken(token) as { _id: string };
    return apiUploadFile(`/api/arbiter/photo/${user._id}`, 'file', photo);
}

export const ArbiterSignupContainer: React.FC<Props> = (props: Props) => {
    const [arbiterData, setArbiterData] = useState<ArbitratorSignup>(defaultArbiterSignup)
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [containerState, setContainerState] = useState<ArbiterContainerState>(defaultArbiterContainerState);
    const [avatar, setAvatar] = useState<Blob | null>(null);

    const onSignup = (data: ArbitratorSignup) => {
        setArbiterData(data);
        setContainerState({ ...containerState, isLoading: true, errors: {} })
        registerArbiter(data)
            .then(token => {
                if(avatar) {
                    storeApiToken(token as string);
                    return uploadPhoto(token as string, avatar)
                        .then(() => {
                            clearApiToken();
                            setIsLoaded(true);
                        })
                        .catch(() => {
                            setIsLoaded(true);
                        });
                } else {
                    setIsLoaded(true);
                }
            })
            .catch(error => {
                if (error instanceof ValidationError) {
                    const caught = error.caughtErrors;
                    const parsedErrors = handleValidationError(caught);
                    setContainerState({ ...containerState, isLoading: false, errors: parsedErrors });
                    throw error;
                }
                else {
                    setContainerState({ ...containerState, isLoading: false });
                    throw error;
                }
            })
    }

    const onAvatarUpload = (blob: Blob) => {
        setAvatar(blob);
    }

    return (
        <ArbiterSignupPage
            photoUrl={''}
            isLoaded={isLoaded}
            onAvatarUpload={onAvatarUpload}
            arbiterData={arbiterData}
            onSignup={onSignup}
            isLoading={containerState.isLoading}
            errors={containerState.errors}
        />
    )
}