import { useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useUserManagement } from "../../toolympus/hooks/useUserManagement"

const PremootOrgRole = "premoot-org";

export const usePremootOrganizers = () => {
    const users = useUserManagement("/api/user", "");
    const [isAdding, setIsAdding] = useState<boolean>(false);

    const organizers = users.users.filter(u => (u.roles || []).includes(PremootOrgRole));

    const addOrganizers = (emails: string[]) => {
        setIsAdding(true);
        return Promise.all(emails.map(
            email => apiFetch<{ _id: string, roles: string[]}>("/api/user", "post", { email })
                .then(u => apiFetch(`/api/user/${u._id}`, "put", { roles: [...(u.roles || []), PremootOrgRole] }))))
            .then(() => {
                users.reload()
                setIsAdding(false);
            })
            .catch(e => {
                users.reload()
                setIsAdding(false);
                throw e;
            })
    }

    const inviteOrganizer = (id: string) => users.invites.sendUserInvite(id);

    const inviteUninvited = () => {
        organizers.filter(o => !(o as any).invited_datetime).forEach(u => inviteOrganizer(u._id));
        setTimeout(() => users.reload(), 3000);
    }

    return {
        isLoading: users.isLoading,
        organizers: organizers,
        addOrganizers,
        inviteOrganizer,
        inviteUninvited,
        isAdding,        
    }
}