import { Button, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { useSnackbar } from '../components/Snackbar';
import { AdminRoutes } from '../presentation/ContentApp/AdminRoutes';
import { ArbiterRoutes } from '../presentation/ContentApp/ArbiterRoutes';
import { PremootOrgRoutes } from '../presentation/ContentApp/PremootOrgRoutes';
import { TeamRoutes, RouteDef } from '../presentation/ContentApp/TeamRoutes';
import { useUser, checkContextRole, EmptyUser } from '../presentation/UserContext/UserContext';
import { TeamNumber } from '../typings/schema';
import { apiFetch, fetchGet, FetchTypes } from '../util/apiFetch';
import { clearApiToken, isAuthenticated } from '../util/tokenStorage';
import { useMaintenance } from '../util/useMaintenance';
import { Link, Sidebar } from './Sidebar';
import { ChatsProvider } from '../components/Chats/ChatsProvider';
import { scrollStyle } from '../toolympus/components/primitives';

const Container = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;

    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
        grid-template-rows: max-content 1fr;
        overflow: auto;
    }

    ${props => scrollStyle(props.theme, { size: 4 })}
`;
Container.defaultProps = { className: 'Container' };

const AppContent = styled.div`
    ${props => props.theme.breakpoints.up('md')} {
        overflow: auto;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        max-width: 100vw;
    }
`;
AppContent.defaultProps = { className: 'AppContent' };

export const ConfirmEmailView = styled.div`
    background-color: ${props => props.theme.palette.error.dark};
    color: ${props => props.theme.palette.error.contrastText};
    min-height: 40px;
    padding: 6px 36px;
`

interface AppWrappProps {
    children: ReactNode;
}

const startLinks = [
    {path: '/terms', name: 'Условия конкурса'},
    {path: '/signup', name: 'Регистрация команды'},
    {path: '/arbitersignup', name: 'Регистрация в качестве арбитра'},
    {path: '/coach-registration', name: 'Хочу стать тренером'},
    {path: '/volunteer-registration', name: 'Регистрация в качестве волонтёра'},
    {path: '/login', name: 'Войти в личный кабинет'},
];

const renderWarning = (isOn: boolean, text: ReactNode) => {
    return isOn &&
        <ConfirmEmailView>
            {(typeof text === 'string' || text instanceof String)
                ? text.split('\n').map(t => <Typography>{t}</Typography>)
                : text}
        </ConfirmEmailView>
}

export const AppWrapp = ({ children }: AppWrappProps) => {
    const user = useUser();
    const history = useHistory();
    const notice = useMaintenance();
    const { showSuccess } = useSnackbar();
    const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(true);
    const [requestedRepeatConfirmation, setRequestedRepeatConfirmation] = useState<boolean>(false);
    const [links, setLinks] = useState<Link[]>([]);

    const requestRepeatConfirmation = () => {
        setRequestedRepeatConfirmation(true);
        return apiFetch<void>('/api/request-confirmation', FetchTypes.POST)
            .then(() => showSuccess('Ссылка отправлена'));
    }

    const routeMap = (prefix: string) => ({ path, title, icon, alsoActivateForPath }: any) => ({
        path: prefix + '/' + path,
        icon,
        name: title,
        alsoActivateForPath,
    });

    const isVisible = (x: RouteDef) => !x.hidden && (!x.hiddenFn || !x.hiddenFn(user));
    const extractRoutes = (x: RouteDef[], prefix: string) => x.filter(isVisible).map(routeMap(prefix));

    useEffect(() => {
        setLinks(
            !isAuthenticated()? 
                startLinks
                :
                [
                    ...(checkContextRole(user, 'team')? extractRoutes(TeamRoutes, '/team') : []),
                    ...(checkContextRole(user, 'arbiter')? extractRoutes(ArbiterRoutes, '/arbiter') : []),
                    ...(checkContextRole(user, 'admin')? extractRoutes(AdminRoutes, '/admin') : []),
                    ...(checkContextRole(user, 'premoot-org')? extractRoutes(PremootOrgRoutes, '/premoot-org') : []),
                ]
                .reduce((list, current) => 
                    list.concat(list.map(i => i.name).includes(current.name)? [] : [current])
                , [] as any[])
        );

        if (isAuthenticated() && checkContextRole(user, 'team')) {
            fetchGet<TeamNumber>('/api/team/number')
                .then(number => {
                    setEmailConfirmed(number.email_confirmed);
                });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user, user.contestState]);

    return (
        <ChatsProvider>
            <Container>
                <Sidebar 
                    logout={
                        isAuthenticated()? 
                            () => {
                                clearApiToken();
                                history.push('/login');
                                setLinks(startLinks);
                                user.setUser(EmptyUser);
                            }
                            :
                            null
                    }
                    links={links}
                />
                <AppContent>
                    {renderWarning(notice.isOn, notice.text)}
                    {!notice.isOn && isAuthenticated() &&
                        renderWarning(!isEmailConfirmed, (
                            <>
                                <Typography>Для завершения регистрации, пожалуйста, перейдите по ссылке для подтверждения email.</Typography>
                                <Typography>Мы отправили ее на {(user.user as any).email}</Typography>
                                {!requestedRepeatConfirmation && <Button color="inherit" onClick={requestRepeatConfirmation}>отправить еще раз</Button>}
                            </>
                        ))}
                    {children}
                </AppContent>
            </Container>
    </ChatsProvider>)
}