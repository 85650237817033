import { IconButton, List, Theme } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Message } from './types';
import { useUser } from '../../userContext/UserContext';
import { useFormats } from '../schemed';
import { utc } from '../timezone';
import { MarkdownDisplay } from '../schemed/Markdown';
import { EditOutlined } from '@mui/icons-material';

export { SelectUserDialog } from "./SelectUsersDialog";

export const ChatsList = styled(List)`
    width: 100%;
    overflow-y: auto;
`;

export const MessengerContainer = styled.div<{chatsWidth: number}>`
    display: grid;
    grid-template-columns: ${props => props.chatsWidth}px 1fr;
    height: 100%;
    width: 100%;
`;
MessengerContainer.defaultProps = {className: 'MessengerContainer'};

export const ChatContainer = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    gap: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
`;
ChatContainer.defaultProps = {className: 'ChatContainer'};

export const MessagesList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;
MessagesList.defaultProps = {className: 'MessagesList'};

export const MessageEnterer = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 5px;
    align-items: center;
    padding-bottom: 3px;
`;
MessageEnterer.defaultProps = {className: 'MessageEnterer'};

export const MessageTextWrapper = styled.div`
    & p {
        margin: 0 0 5px;
    }
`;

export const MessageContent = styled.div`
    position: relative;
    max-width: 60%;

    ${props => props.theme.breakpoints.down("xs")} {
        max-width: 100%;
    }

    & .edit-button {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &:hover {
        & .edit-button {
            display: block;
        }
    }
`;

export const avatarStyle = (theme: Theme, isMyMessage: boolean) => css`
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
  margin-left: ${isMyMessage ? 0 : 10}px;
  margin-right: ${isMyMessage ? 10 : 1}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  background: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
`;

export const authorStyle = (theme: Theme, isMyMessage: boolean) => css`
  color: ${theme.palette.primary.main};
  align-self: ${isMyMessage ? 'flex-start' : 'flex-end'};
  justify-content: ${isMyMessage ? 'flex-start' : 'flex-end'};
  margin-bottom: 5;
  display: flex;
  flex-flow: row;
  align-items: baseline;
  font-weight: 300;
`;

export const timeStyle = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  margin-left: 1.5em;
  font-size: 0.8em;
  font-weight: 300;
  min-width: 80px;
`;


export const MessageContainer = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  padding: 10px 0;
  flex-direction: ${props => props.isMyMessage ? 'row' : 'row-reverse'};
  width: '100%';

  & .avatar {
    ${props => avatarStyle(props.theme, props.isMyMessage)}
  }

  & .author {
    ${props => authorStyle(props.theme, props.isMyMessage)}
  }

  & .time {
    ${props => timeStyle(props.theme)}
  }
`;


type MessageItemType = (props: {
    authorName: string,
    message: Message,
    editMessage?: (message: Message) => void,

}) => JSX.Element

export const renderMessageText = (text: string): ReactNode => {
    return text.startsWith("!md")
        ? <MarkdownDisplay text={text.replace(/!md\s*/, "")} />
        : text.split('\n').map((line,idx) => <p key={idx} className={text}>{line}</p>);
}

export const stripMessageTextFormatting = (text: string): string => {
    return text.startsWith("!md")
        ? text.replace(/!md\s*/, "").replaceAll(/[*_[]+/g, "").replaceAll(/\]/g, " ")
        : text;
}

export const MessageItem: MessageItemType = ({ message, authorName, editMessage }) => {
    const { formatDatetimeShort } = useFormats();
    const { user } = useUser();
    const isMyMessage = !!user && user._id === message.author_id;

    const ref = useRef<HTMLImageElement | null>(null);
    const [avatarError, setAvatarError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.onerror = () => setAvatarError(true);
            }
        }, 0);
    }, []);

    return <MessageContainer isMyMessage={isMyMessage} className="message">
        {avatarError ? 
            <div className="avatar">
                {authorName[0].toUpperCase() + (authorName.split(' ').length === 2)? (authorName[authorName.length - 1].toLocaleLowerCase()) : ''}
            </div>
            :
            <img ref={ref} className="avatar" src={'/api/avatar/' + message.author_id} alt=""/>
        }
        <MessageContent className="content">
            <div className="author name">
                {authorName}
                <div className="time">
                    {formatDatetimeShort(utc.toLocal(message.time))}
                </div>
            </div>
            <MessageTextWrapper className="text">
                {renderMessageText(message.text)}
            </MessageTextWrapper>
            {isMyMessage && editMessage && (
                <IconButton className="edit-button" size="small" onClick={() => editMessage(message)}>
                    <EditOutlined />
                </IconButton>)}
        </MessageContent>
    </MessageContainer>
}