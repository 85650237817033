import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { ArbitratorLimited, OnlineRound, OtherTeamNumber } from '../../typings/schema';
import { apiFetch, downloadFile, FetchTypes } from '../../util/apiFetch';
import { OnlineRoundConferenceConnection, useArbiterOnlineRoundConference, useTeamOnlineRoundConference } from './useOnlineRoundConference';
import { OnlineRoundScoring2, useOnlineRoundScoring2 } from './useOnlineRoundScoring2';
import { EditItemProps, useEditItem2 } from '../../toolympus/api/useNewItem';

export interface OnlineRoundData {
    round: OnlineRound | undefined;
    arbitrators: ArbitratorLimited[];
    isLoading: boolean;
    downloadDocument: (id: string, name: string) => Promise<void>;

    // expected format: 2020-28-10T18:30:00
    suggestTime: (time: string) => void;
    approveTime: () => void;
    externalConferencing: EditItemProps<Pick<OnlineRound, "conferencing_external_link" | "conferencing_tool">>;

    reload: () => void;
}

export interface OnlineRoundDataWithConference extends OnlineRoundData {
    conference: OnlineRoundConferenceConnection;
}

export enum RoundType {
    Normal,
    Extra,
}

const loadTeamRound = (roundType: RoundType) => apiFetch<OnlineRound>(`/api/team/onlineround${roundType === RoundType.Extra ? '/extra' : ''}`);
const loadArbiterRound = (roundId: string) => apiFetch<OnlineRound>(`/api/arbiter/onlinerounds/${roundId}`);
const loadArbiterRoundsList = () => apiFetch<OnlineRound[]>(`/api/arbiter/onlinerounds`);

const loadTeamInfo = (teamId: string) => apiFetch<OtherTeamNumber>(`/api/team/number/${teamId}`);
const loadArbitratorInfo = (arbitratorId: string) => apiFetch<ArbitratorLimited>(`/api/arbiter/info-for-team/${arbitratorId}`);

const sendSuggestTime = (roundId: string, time: string) => apiFetch<void>(`/api/onlineround/${roundId}/time`, FetchTypes.POST, { time });
const sendApproveTime = (roundId: string) => apiFetch<void>(`/api/onlineround/${roundId}/time/approve`, FetchTypes.POST);

const downloadDocument = (id: string, name: string): Promise<void> => {
    if (id) {
        return downloadFile(`/api/document/${id}`, name);
    }
    return Promise.resolve();
}

const emptyArbitrator: ArbitratorLimited = {
    firstname: '',
    middlename: '',
    lastname: '',
    _id: '',
}

const useOnlineRound = (loadRound: () => Promise<OnlineRound>): OnlineRoundData => {
    const [round, setRound] = useState<OnlineRound>();
    const [arbitratorsDict, setArbitratorsDict] = useState<{ [key: string]: ArbitratorLimited}>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // always yield a list of 3 - everyone who's present + empty ones
    const arbitrators =
        (round?.arbitrators || [])
        .concat(['1','2','3'])
        .slice(0, 3)
        .map(id => arbitratorsDict[id] || { ...emptyArbitrator, _id: id });

    const doLoadRound = useCallback(() => {
        setIsLoading(true);
        loadRound()
            .then(newRound => setRound(oldRound => ({ ...oldRound, ...newRound})))
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    }, [loadRound])

    useEffect(() => {
        doLoadRound();
    }, [doLoadRound]);

    const reload = () => doLoadRound();

    const team1_id = round?.team1_id;
    useEffect(() => {
        if(team1_id) {
            loadTeamInfo(team1_id).then(team => setRound(r => ({ ...(r as OnlineRound), team1_nbr: team.number })));
        }
    }, [team1_id]);
    
    const team2_id = round?.team2_id;
    useEffect(() => {
        if(team2_id) {
            loadTeamInfo(team2_id).then(team => setRound(r => ({ ...(r as OnlineRound), team2_nbr: team.number })));
        }
    }, [team2_id]);

    const arbs = round?.arbitrators;
    useEffect(() => {
        if(arbs) {
            arbs
                .filter(id => !!id)
                .forEach(id => 
                    loadArbitratorInfo(id)
                    .then(a => setArbitratorsDict(dict => ({ ...dict, [a._id]: a }))));
        }
    }, [arbs]);

    const suggestTime = (time: string) => {
        if(round?._id) {
            sendSuggestTime(round._id, time)
                .then(() => doLoadRound());
        }
    }

    const approveTime = () => {
        if(round?._id) {
            sendApproveTime(round._id)
                .then(() => doLoadRound());
        }
    }

    const externalConferencing = useEditItem2<Pick<OnlineRound, "conferencing_external_link" | "conferencing_tool">>({
      save: (item, changes) => {
        return apiFetch<Pick<OnlineRound, "conferencing_external_link" | "conferencing_tool">>(`/api/onlineround/${round?._id}/conference-settings`, FetchTypes.PUT, changes)
          .then(x => { doLoadRound(); return x; });
      }
    });

    return {
        round,
        isLoading,
        arbitrators,
        downloadDocument,
        suggestTime,
        approveTime,
        reload,
        externalConferencing,
    }
};

const useTeamOnlineRound = (roundType: RoundType): OnlineRoundDataWithConference => {
    const loadRound = useCallback(
        () => loadTeamRound(roundType),
        [roundType]);

    const data = useOnlineRound(loadRound);
    const conference = useTeamOnlineRoundConference(data.round?._id || "", data.round?.conferencing_external_link);

    return {
        ...data,
        conference,
    }
}

export interface OnlineRoundDataArbiter extends OnlineRoundDataWithConference {
    scoring: OnlineRoundScoring2;
    confirmation: OnlineRoundsConfirmation;
}

export const useArbiterOnlineRound = (roundId: string): OnlineRoundDataArbiter => {
    const loadRound = useCallback(
        () => loadArbiterRound(roundId),
        [roundId]);

    
    const roundData = useOnlineRound(loadRound);
    const scoring = useOnlineRoundScoring2(roundData.round, () => roundData.reload());
    
    const confirmation = useArbiterOnlineRoundsConfirmation(() => roundData.reload());

    const conference = useArbiterOnlineRoundConference(roundId, roundData.round?.conferencing_external_link);

    return {
        ...roundData,
        scoring,
        confirmation,
        conference,
    };
}


export interface TeamOnlineRounds {
    normal: OnlineRoundDataWithConference;
    extra: OnlineRoundDataWithConference;
    isExtraAvailable: boolean;

    active: OnlineRoundDataWithConference;
    activate: (roundType: RoundType) => void;
}

export const useTeamOnlineRounds = (): TeamOnlineRounds => {
    const normal = useTeamOnlineRound(RoundType.Normal);
    const extra = useTeamOnlineRound(RoundType.Extra);
    const [active, activate] = useState<RoundType>(RoundType.Normal);

    return {
        normal,
        extra,
        isExtraAvailable: !!extra.round,
        active: active === RoundType.Extra ? extra : normal,
        activate,
    };
}

export interface ArbiterOnlineRoundsList {
    rounds: OnlineRound[];
    isLoading: boolean;
    getCountScoresSet: (roundId: string) => number;
}

export const useArbiterOnlineRoundsList = (): ArbiterOnlineRoundsList => {
    const [rounds, setRounds] = useState<OnlineRound[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reload = useCallback(() => {
        setIsLoading(true);
        loadArbiterRoundsList()
            .then(setRounds)
            .then(() => setIsLoading(false))
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }, []);

    useEffect(() => {
        reload();
    }, [reload]);

    

    const getCountScoresSet = (roundId: string) => {
        const round = rounds.find(r => r._id === roundId);
        console.log(round);
        if(!round || !round.scoring) {
            return 0;
        }
        return Object.values(round.scoring).map(s => Object.values(s).reduce((r,item) => item.is_scored ? r+1 : r, 0)).reduce((r,v) => r+v, 0);
    }

    return {
        rounds,
        isLoading,
        getCountScoresSet,
    }
}

interface Confirmation {
    online_rounds_confirmation?: string;
}

export interface OnlineRoundsConfirmation {
    isLoaded: boolean;
    isProcessing: boolean;
    isAnswered: boolean;
    confirm: () => void;
    reject: () => void;
}

export const useArbiterOnlineRoundsConfirmation = (onReject: () => void) => {
    const confirmation = useLoadedData<Confirmation>('/api/arbiter/status', {});
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const history = useHistory();

    const process = (url: string, cb?: () => void) => {
        setIsProcessing(true);
        apiFetch(url, FetchTypes.POST)
            .then(() => {
                setIsProcessing(false);
                confirmation.reload();
                if(cb) {
                    cb();
                }
            })
            .catch(e => {
                setIsProcessing(false);
                throw e;
            })
    }

    const confirm = () => process('/api/arbiter/onlinerounds/confirm');
    const reject = () => process('/api/arbiter/onlinerounds/reject', () => history.replace('/arbiter/terms'));


    return {
        isProcessing,
        isLoaded: !confirmation.isLoading,
        isAnswered: ["confirmed", "rejected"].includes(confirmation.data.online_rounds_confirmation || "x"),
        confirm,
        reject,
    }

}