import React from 'react';
import styled from '@emotion/styled';
import { Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { Check } from '@mui/icons-material';

export const Info = styled.div`
    display: flex;
    flex-flow: row;

    align-items: baseline;
    & > * {
        margin-right: 1rem;
    }
`;
export const InfoKind = styled.span`
    color: ${props => props.theme.palette.grey[500]};
    width: 4rem;
`;
export const InfoText = styled(Typography)`
    font-size: 1.25em;
    margin-bottom: 0.5em;

    @media(max-width: 600px) {
        margin-top: 1em;
    }
`;

InfoText.defaultProps = { variant: "body1" };

const RecommendationsTitle = styled(InfoText)`
    border-top: 1px solid ${props => props.theme.palette.grey[100]};
`;
const RecommendationsItem = styled(Typography)`
    font-style: italic;
    display: inline-block;
    vertical-align: top;
    min-width: 7rem;
    max-width: 50rem;
`;

export const ScoreRecommendationsBlock = ({ title, recommendations }: { title: string, recommendations: [string, string][] }) => {
    return <>
        <RecommendationsTitle>
            {title}
        </RecommendationsTitle>
        
        {recommendations.map(([scoresRange, hint]) => (
            <div key={scoresRange}>
                <RecommendationsItem>{scoresRange}</RecommendationsItem>
                <RecommendationsItem>{hint}</RecommendationsItem>
            </div>
        ))}
    </>;
}

export const CriteriaGroupTitle = styled(Typography)`
    padding-top: 1rem;
    border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

CriteriaGroupTitle.defaultProps = { variant: "h6" };

export const CriteriaTitle = styled(Typography)`
`;


export const CriteriaGroup = styled(Grid)`
`;
CriteriaGroup.defaultProps = { container: true };

export const CriteriaGroupTitleCell = styled(Grid)`
`;
CriteriaGroupTitleCell.defaultProps = { item: true, xs: 12, sm: 12 };

export const CriteriaCell = styled(Grid)`
    display: flex;
    flex-flow: row;
    align-items: center;
`;
CriteriaCell.defaultProps = { item: true, xs: 12, sm: 4 };

export const ScoreCell = styled(Grid)`
    display: flex;

    @media(min-width: 601px) {
        flex-flow: row nowrap;
    }
    @media(max-width: 600px) {
        flex-flow: row wrap;
    }
`;
ScoreCell.defaultProps = { item: true, xs: 12, sm: 8 };

interface ScoreChipProps {
    scored?: boolean;
}

const scoredColor = ({ theme }: { theme: Theme }): string => theme.palette.primary.light;
const scoredTextColor = ({ theme }: { theme: Theme }): string => theme.palette.primary.contrastText;
const unscoredColor = ({ theme }: { theme: Theme }): string => theme.palette.grey[200];
const unscoredTextColor = ({ theme }: { theme: Theme }): string => theme.palette.primary.main;

const ScoreChip = styled.div<ScoreChipProps>`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.8rem;
    margin: 0.8rem 0.2rem;
    background: ${props => props.scored ? scoredColor(props) : unscoredColor(props)};
    color: ${props => props.scored ? scoredTextColor(props) : unscoredTextColor(props)};
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 7px 0px ${scoredColor};
    }
`;


interface ScoreProps {
    value?: number;
    setValue: (v: number) => void;
    min: number;
    max: number;
}

const getRange = (min: number, max: number): number[] => {
    return [...Array(max-min+1).fill(0).map((_, i) => i+min)];
}

export const Score = ({ value, setValue, min, max }: ScoreProps) => {
    const vals = getRange(min, max);
    return (
        <>
            {vals.map(v => (
                <ScoreChip key={v} scored={(value || min-1) >= v} onClick={() => setValue(v)}>
                    {v}
                </ScoreChip>))}
        </>
    );
}

const SmallButton = styled(Button)`
`;
SmallButton.defaultProps = { size: 'small', color: 'primary' };


interface StartScoringProps {
    startScoring: () => void;
    isScored?: boolean;
}
export const StartScoringButton = (props: StartScoringProps) => {
  const theme = useTheme();

  return (
      <SmallButton
          onClick={e => { e.stopPropagation(); props.startScoring(); }}
          style={props.isScored ? { color: theme.palette.success.main } : {}}>
          {props.isScored ? <Check /> : "оценить"}
      </SmallButton>)
};
