import { useState, useEffect } from "react";
import { useMessager } from "./MessagerContext";
import { Chat } from "./types";

export interface SpecificChat {
    messageForCreate: string;
    setMessageForCreate: (v: string) => void;
    startChat: () => void;
    canStartChat: boolean;
    chat: Chat | null;
}

interface Config {
    noCreate?: boolean;
    participantsForStart?: string[];
    kindForStart?: string;
}

export const useSpecificChat = (isThatChat: (c: Chat) => boolean, config?: Config): SpecificChat => {
    
    const messager = useMessager();
    const { list: chats, setSelectedChat, selectedChat: chat, addChat } = messager.chats;
    const [messageForCreate, setMessageForCreate] = useState<string>("");

    useEffect(() => {
        if(chats.length && !chat) {
            const chatWithOrg = chats.find(isThatChat);
            if(chatWithOrg) {
                setSelectedChat(chatWithOrg);
            }
        }
    }, [chat, setSelectedChat, chats, isThatChat]);

    const startChat = () => {
        if(!chat && !config?.noCreate) {
            addChat(messageForCreate, config?.participantsForStart || [], config?.kindForStart || "private");
        }
    }

    return {
        chat,
        canStartChat: !config?.noCreate && !chat,
        startChat,
        messageForCreate,
        setMessageForCreate,
    }
}
