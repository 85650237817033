import React from 'react';
import { GenericTextField } from '../Util/UtilTextfields';
import SuccessButton from '../Util/SuccessButton';
import { LoginTitle, SignupTextfieldView, LoginButtonView, BottomTipView, LoginWrapper, LoginForm } from '../Login/LoginPage.styles';
import { PasswordResetRequest } from '../../typings/schema';
import { Link } from 'react-router-dom';
import { Links } from '../Util/common.styles';
import { Title } from '../../Common/Primitives';
import { Box } from '@mui/material';

interface Props {
    onRequest: (data: PasswordResetRequest ) => void;
    formData: PasswordResetRequest;
    onDataChanged: (data: PasswordResetRequest) => void;
    isRequested: boolean;
}

const RequestRestorePasswordPage: React.FC<Props> = (props: Props) => {

    const onSubmit = (e: any) => {
        e.preventDefault();
        props.onRequest(props.formData);
    }

    return (
        <LoginWrapper>  
            {!props.isRequested &&
                <Box maxWidth={400}>

                    <LoginForm onSubmit={e => onSubmit(e)}>
                        <Title justifyContent="center" noLine marginBottom="15px">
                            Сброс пароля
                        </Title>
                        <LoginTitle>
                            Введите email, указанный при регистрации, чтобы сбросить пароль
                        </LoginTitle>
                        <SignupTextfieldView>
                            <GenericTextField
                                value={props.formData?.email}
                                onChange={e => props.onDataChanged({ ...props.formData, email: e })}
                                label="Контактный email"
                                type="email"
                            />
                        </SignupTextfieldView>
                        
                        <LoginButtonView>
                            <SuccessButton
                                disabled={props.formData?.email ? false : true}
                                title="Сбросить пароль"
                                type="submit"
                                width="100%"
                            />
                        </LoginButtonView>
                        <Links>
                            <Link to="/login">Войти в личный кабинет</Link>
                        </Links>
                    </LoginForm>
                </Box>}
            
            {props.isRequested &&
                <BottomTipView>
                    Мы отправили вам ссылку для установки нового пароля - проверьте почту.
                </BottomTipView>
            }  
        </LoginWrapper>
    )
}

export default RequestRestorePasswordPage;